<template>
  <div
    v-if="$can($USER_CLAIMS.LEADS)"
    class="quick-search"
    :class="{ 'quick-search_open': isDropdownShown }"
    v-on-clickaway="closeDropdown"
  >
    <button
      class="quick-search__start-btn"
      @click="openDropdown"
    >
      <ui-icon
        class="quick-search__start-btn-ico"
        icon="search"
      />
    </button>

    <div class="quick-search__inner">
      <button
        class="quick-search__close-btn"
        @click="closeDropdown"
      >
        <ui-icon
          class="quick-search__close-btn-ico"
          icon="close"
        />
      </button>

      <quick-search-input
        class="quick-search__input"
        v-model="form.search"
        @input="onSearchInput"
        @focus="openDropdown"
        @click.self="openDropdown"
        @clear="clear"
      />

      <div
        class="quick-search__dropdown"
        :class="{
          'quick-search__dropdown_shown': isDropdownShown,
          'quick-search__dropdown_loading': isLoading && !isEmpty,
        }"
      >
        <div class="quick-search__results">
          <template v-if="results.leads.length">
            <section class="quick-search__results-section">
              <div class="quick-search__results-section-list">
                <quick-search-lead
                  class="quick-search__results-section-list-item"
                  v-for="lead in results.leads"
                  :key="lead.id"
                  :lead="lead"
                  @view-started="closeDropdown"
                />
              </div>
            </section>
          </template>

          <template v-if="isEmpty">
            <template v-if="isLoading">
              <ui-spinner
                class="quick-search__results-spinner"
                type="pills"
              />
            </template>

            <template v-else-if="form.search">
              <p class="quick-search__results-empty-txt">
                {{ $t('RESULTS_EMPTY_TXT') }}
              </p>
            </template>

            <template v-else>
              <p class="quick-search__results-start-hint-txt">
                {{ $t('START_HINT_TXT') }}
              </p>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  UiIcon,
  UiSpinner,
  BodyScrollPreventer,
  viewport,
} from '@shelf.network/ui-kit'
import { LeadListEntry } from 'Models/LeadListEntry'
import debounce from 'lodash/debounce'
import { sdk } from 'Services/shelfNetworkSdk'
import { mapGetters } from 'vuex'
import { directive as onClickaway } from 'vue-clickaway'
import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { userGetters } from 'Store/entities/User/types'
import QuickSearchInput from './QuickSearchInput'
import QuickSearchLead from './QuickSearchLead'
import { summarizerBy } from 'Utils/arrayHelpers'

const leadsSdkCall = new LoneSdkCall()

export default {
  name: 'quick-search',
  directives: { onClickaway },
  components: {
    UiIcon,
    UiSpinner,
    QuickSearchInput,
    QuickSearchLead,
  },

  props: {
    value: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      form: {
        search: '',
      },
      results: {
        leads: [],
      },
      isDropdownShown: false,
      isLoading: false,

      // NOTE: populated on created,
      // should be a property to be able to cancel the execution
      loadResultsDebounced: null,
    }
  },

  computed: {
    ...mapGetters('entities/user', {
      platformId: userGetters.PLATFORM_ID,
      accountId: userGetters.ACCOUNT_ID,
      isBroker: userGetters.IS_BROKER,
    }),

    isEmpty () {
      return !Object.values(this.results)
        .reduce(summarizerBy('length'), 0)
    },
  },

  created () {
    this.loadResultsDebounced = debounce(function () {
      this.loadResults()
    }, 1000)

    window.addEventListener('keydown', this.onKeyDown)
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeyDown)
  },

  methods: {
    onKeyDown (event) {
      if (event.key === 'Escape') {
        this.closeDropdown()
      }
    },

    openDropdown () {
      if (this.isDropdownShown) {
        return
      }

      this.isDropdownShown = true

      if (!this.isEmpty && !this.isLoading) {
        this.loadResults()
      }

      if (viewport.isSmall) {
        BodyScrollPreventer.on()
      }
    },

    closeDropdown () {
      this.isDropdownShown = false
      if (viewport.isSmall) {
        BodyScrollPreventer.off()
      }
    },

    onSearchInput (value) {
      if (!this.form.search) {
        this.clear()
        return
      }

      if (this.isLoading) {
        this.cancelLatestRequest()
      }

      this.isLoading = true
      this.loadResultsDebounced()
    },

    clear () {
      this.cancelLatestRequest()
      this.isLoading = false
      this.form.search = ''
      for (const key of Object.keys(this.results)) {
        this.results[key] = []
      }
    },

    cancelLatestRequest () {
      leadsSdkCall.cancelLatest()
      this.loadResultsDebounced.cancel()
    },

    async loadResults () {
      this.isLoading = true

      const leads = await this.getLeads(this.form.search)
      this.results.leads = leads || this.results.leads

      this.isLoading = false
    },

    async getLeads (search) {
      try {
        const { data } = await leadsSdkCall.takeLatest(
          sdk.backOffice.v2.getLeadsBasics({
            search,
            filter: {
              platform: this.platformId,
              ...(this.isBroker ? { broker: this.accountId } : {}),
            }
          })
        )
        return data.map(el => new LeadListEntry(el))
      } catch (error) {
        if (!error.isCanceled) {
          console.error(error)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.quick-search {
  &__inner {
    position: relative;
  }

  &__close-btn {
    display: none;
  }

  &__start-btn {
    display: none;
  }

  &__results {
    padding: 1em;
    max-height: 70vh;
    overflow: auto;
    display: grid;
    grid: auto-flow auto / auto;
    gap: 2em;

    &-spinner {
      width: 100%;
      display: block;
      text-align: center;
    }

    &-start-hint-txt {
      text-align: center;
      color: $color-dark-grey;
    }

    &-empty-txt {
      text-align: center;
      color: $color-dark;
    }

    &-section {
      width: 100vw;
      max-width: 25em;

      &-title {
        margin-left: 1.2em;
        margin-bottom: 0.6em;
        font-size: 0.8em;
        font-weight: bold;
        display: inline-block;
      }

      &-list {
        display: grid;
        grid: auto-flow auto / auto;
        gap: 0.5em;
      }
    }
  }

  &__dropdown {
    @include respond-above(sm) {
      border: none;
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.2);
      border-color: transparent white white transparent;
      border-radius: 1em;
      background: $color-light;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      min-width: 100%;
      margin-top: 3.5em;
      visibility: hidden;
      opacity: 0;
      transition: all 185ms;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        border: 0.75em solid;
        border-color: transparent $color-light $color-light transparent;
        width: 0;
        height: 0;
        transform: translate(-50%, -35%) scaleX(0.65) rotate(-135deg);
        z-index: z-index(negative);
      }

      &_shown {
        margin-top: 4.5em;
        visibility: visible;
        opacity: 1;
      }

      &_loading {
        @include loading-fog() {
          position: absolute;
          pointer-events: unset;

          &::after {
            border-radius: 1em;
          }
        }
      }
    }

    @include respond-below(sm) {
      display: none;
    }
  }

  @include respond-below(sm) {
    &__input {
      display: none;
    }

    &__start-btn {
      display: block;
      border: none;
      background: none;
      font-size: 1.5em;
      color: $color-dark;
      padding: 0.25em;

      &-ico {
        display: block;
      }
    }

    &_open &__inner {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: z-index(modal);
      background: $color-ui-bg;
      display: flex;
      flex-direction: column;
      height: 100vh;
    }

    &_open &__close-btn {
      display: block;
      border: none;
      background: none;
      font-size: 1.25em;
      color: $color-dark-grey;
      padding: 0.25em;
      position: absolute;
      top: 1em;
      right: 5vw;

      &-ico {
        display: block;
      }
    }

    &_open &__input {
      display: block;
      max-width: 90vw;
      margin: 4em 5vw 0;
      align-self: stretch;
      flex: 0;
    }

    &_open &__dropdown {
      display: flex;
      box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.2);
      flex-direction: column;
      margin-top: 2em;
      flex: 1;
      overflow: auto;
    }

    &__results {
      max-height: 100%;
      flex: 1;
      padding: 1.5em 5vw 4em;

      &-section {
        width: unset;
        max-width: unset;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "RESULTS_EMPTY_TXT": "No results found",
    "START_HINT_TXT": "Type Lead’s phone or name to start search"
  },
  "ka": {
    "RESULTS_EMPTY_TXT": "არ მოიძებნა",
    "START_HINT_TXT": "ჩაწერეთ ლიდის სახელი ან ტელ. ნომერი"
  },
  "ru": {
    "RESULTS_EMPTY_TXT": "Ничего не найдено",
    "START_HINT_TXT": "Чтобы начать поиск, введите телефон или название Лида"
  },
  "uk": {
    "RESULTS_EMPTY_TXT": "Нічого не знайдено",
    "START_HINT_TXT": "Щоб розпочати пошук, введіть телефон або назву Ліда"
  }
}
</i18n>
