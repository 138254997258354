<template>
  <div
    class="leads-list"
    :class="{ 'leads-list_loading': isLoading }"
  >
    <template v-if="isLoading && !leads.length">
      <div class="leads-list__items">
        <lead-skeleton />
        <lead-skeleton />
        <lead-skeleton />
        <lead-skeleton />
        <lead-skeleton />
      </div>
    </template>

    <template v-else>
      <div
        class="leads-list__group"
        v-for="({ title, items }, index) of leadsGrouped"
        :key="title"
      >
        <div class="leads-list__group-title-wrp">
          <label class="leads-list__group-title">
            {{ title }}
          </label>

          <template v-if="index === 0">
            <div class="leads-list__actions">
              <leads-export :leads="leads" />

              <button
                class="leads-list__refresh-btn"
                @click="$emit('update-list-ask')"
              >
                {{ $t('REFRESH_BTN') }}
              </button>
            </div>
          </template>
        </div>

        <template v-if="items.length">
          <div class="leads-list__items">
            <lead-item
              v-for="lead in items"
              :key="lead.id"
              :lead="lead"
            />
          </div>
        </template>

        <template v-else>
          <p class="leads-list__no-items-msg">
            {{ $t('NO_ITEMS_MSG') }}
          </p>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import LeadItem from './LeadItem'
import LeadSkeleton from './LeadSkeleton'
import LeadsExport from './LeadsExport'

import { isBefore, isSameDay } from 'Utils/dateHelpers'
import { LeadListEntry } from 'Models/LeadListEntry'
import { validateArrayOf } from 'Models/modelUtils'
import { groupByPeriod } from 'Utils/grouping'

const GROUP_BY_ENUM = {
  dueDate: 'dueDate',
  lastOfferSentAt: 'lastOfferSentAt',
}

export default {
  name: 'leads-list',
  components: {
    LeadItem,
    LeadSkeleton,
    LeadsExport,
  },

  props: {
    leads: {
      type: Array,
      required: true,
      validator: validateArrayOf(LeadListEntry),
    },

    groupBy: {
      type: String,
      default: '',
      validator (value) {
        return !value || Object.values(GROUP_BY_ENUM).includes(value)
      },
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    leadsGrouped () {
      if (this.leads.length) {
        if (this.groupBy === GROUP_BY_ENUM.dueDate) {
          return groupByPeriod({
            collection: this.leads,
            groupsConfig: {
              unset: { title: this.$t('NOT_SET_DUE_GROUP_TITLE') },
              today: {
                title: this.$t('TODAY_DUE_GROUP_TITLE'),
                predicate: (date, today) =>
                  isSameDay(today, date) || isBefore(date, today)
              },
              week: { title: this.$t('WEEK_DUE_GROUP_TITLE') },
              month: { title: this.$t('MONTH_DUE_GROUP_TITLE') },
              year: { title: this.$t('YEAR_DUE_GROUP_TITLE') },
              other: { title: this.$t('OTHER_DUE_GROUP_TITLE') },
            },
            dateField: 'dueDate',
          })
        }
        if (this.groupBy === GROUP_BY_ENUM.lastOfferSentAt) {
          return groupByPeriod({
            collection: this.leads,
            groupsConfig: {
              unset: { title: this.$t('NOT_SET_OFFERED_GROUP_TITLE') },
              today: { title: this.$t('TODAY_OFFERED_GROUP_TITLE') },
              week: { title: this.$t('WEEK_OFFERED_GROUP_TITLE') },
              month: { title: this.$t('MONTH_OFFERED_GROUP_TITLE') },
              year: { title: this.$t('YEAR_OFFERED_GROUP_TITLE') },
              other: { title: this.$t('OTHER_OFFERED_GROUP_TITLE') },
            },
            dateField: 'lastOfferSentAt',
          })
        }
      }

      return [
        {
          title: this.$t('COMMON.LIST_GROUPING.TITLE_FORMAT', {
            groupTitle: this.$t('REQUESTS_GROUP_TITLE'),
            itemsCount: this.leads.length,
          }),
          items: this.leads
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.leads-list {
  &_loading {
    @include loading-fog();
  }

  &__group {
    &-title-wrp {
      padding: 2em 3em 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      display: block;
      font-size: 1.4em;
      font-weight: 500;
      line-height: 1.5;
    }
  }

  &__actions {
    display: flex;
  }

  &__refresh-btn {
    margin-left: 1.6em;
    background: none;
    border: none;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1.5;
    color: $color-sys-info;
    transition: color 150ms;

    &:hover {
      color: rgba($color-sys-info, 0.8);
    }
  }

  &__items {
    @include respond(grid-gap, 25px, 20px, 15px, 15px);

    display: grid;
    padding: 2em 3em;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-template-rows: auto;
    grid-auto-columns: max-content;

    @include respond-below(lg) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }

  &__no-items-msg {
    @include respond(margin-top, 2.5em, 2em, 2em 1.5em);

    color: $color-ui-secondary;
    font-size: 1.4em;
    font-weight: 500;
    text-align: center;
    padding: 1em;
  }
}
</style>

<i18n>
{
  "en": {
    "NO_ITEMS_MSG": "No items here yet",
    "NOT_SET_DUE_GROUP_TITLE": "Not set",
    "TODAY_DUE_GROUP_TITLE": "Call today",
    "WEEK_DUE_GROUP_TITLE": "This week",
    "MONTH_DUE_GROUP_TITLE": "This month",
    "YEAR_DUE_GROUP_TITLE": "This year",
    "OTHER_DUE_GROUP_TITLE": "Next years",
    "NOT_SET_OFFERED_GROUP_TITLE": "Not set",
    "TODAY_OFFERED_GROUP_TITLE": "Today",
    "WEEK_OFFERED_GROUP_TITLE": "This week",
    "MONTH_OFFERED_GROUP_TITLE": "Last month",
    "YEAR_OFFERED_GROUP_TITLE": "Last year",
    "OTHER_OFFERED_GROUP_TITLE": "Earlier years",
    "REQUESTS_GROUP_TITLE": "Requests",
    "REFRESH_BTN": "Refresh"
  },
  "ka": {
    "NO_ITEMS_MSG": "არ მოიძებნა",
    "NOT_SET_DUE_GROUP_TITLE": "არ არის მითითებული",
    "TODAY_DUE_GROUP_TITLE": "დღევანდელი",
    "WEEK_DUE_GROUP_TITLE": "ამ კვირაში",
    "MONTH_DUE_GROUP_TITLE": "ამ თვეში",
    "YEAR_DUE_GROUP_TITLE": "ამ წელს",
    "OTHER_DUE_GROUP_TITLE": "შემდეგი წელი",
    "NOT_SET_OFFERED_GROUP_TITLE": "არ არის მითითებული",
    "TODAY_OFFERED_GROUP_TITLE": "დღევანდელი",
    "WEEK_OFFERED_GROUP_TITLE": "ამ კვირაში",
    "MONTH_OFFERED_GROUP_TITLE": "ამ თვეში",
    "YEAR_OFFERED_GROUP_TITLE": "წინა წელს",
    "OTHER_OFFERED_GROUP_TITLE": "წინა წლებში",
    "REQUESTS_GROUP_TITLE": "მოთხოვნები",
    "REFRESH_BTN": "განახლება"
  },
  "ru": {
    "NO_ITEMS_MSG": "Здесь пока ничего нет",
    "NOT_SET_DUE_GROUP_TITLE": "Не установлено",
    "TODAY_DUE_GROUP_TITLE": "Сегодня",
    "WEEK_DUE_GROUP_TITLE": "На этой недели",
    "MONTH_DUE_GROUP_TITLE": "В этом месяце",
    "YEAR_DUE_GROUP_TITLE": "В этом году",
    "OTHER_DUE_GROUP_TITLE": "В следующем году",
    "NOT_SET_OFFERED_GROUP_TITLE": "Не установлено",
    "TODAY_OFFERED_GROUP_TITLE": "Сегодня",
    "WEEK_OFFERED_GROUP_TITLE": "На этой неделе",
    "MONTH_OFFERED_GROUP_TITLE": "В прошлом месяце",
    "YEAR_OFFERED_GROUP_TITLE": "В прошлом году",
    "OTHER_OFFERED_GROUP_TITLE": "Предыдущие годы",
    "REQUESTS_GROUP_TITLE": "Запросы",
    "REFRESH_BTN": "Обновить"
  },
  "uk": {
    "NO_ITEMS_MSG": "Тут поки що нічого немає",
    "NOT_SET_DUE_GROUP_TITLE": "Не встановлено",
    "TODAY_DUE_GROUP_TITLE": "Зателефонувати сьогодні",
    "WEEK_DUE_GROUP_TITLE": "На цьому тижні",
    "MONTH_DUE_GROUP_TITLE": "У цьому місяці",
    "YEAR_DUE_GROUP_TITLE": "У цьому році",
    "OTHER_DUE_GROUP_TITLE": "В наступному році",
    "NOT_SET_OFFERED_GROUP_TITLE": "Не встановлено",
    "TODAY_OFFERED_GROUP_TITLE": "Сьогодні",
    "WEEK_OFFERED_GROUP_TITLE": "На цьому тижні",
    "MONTH_OFFERED_GROUP_TITLE": "Минулого місяця",
    "YEAR_OFFERED_GROUP_TITLE": "В минулому році",
    "OTHER_OFFERED_GROUP_TITLE": "Попередні роки",
    "REQUESTS_GROUP_TITLE": "Запити",
    "REFRESH_BTN": "Оновити"
  }
}
</i18n>
