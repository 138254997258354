<template>
  <router-link
    class="bell-notification"
    @click.native="onClick"
    v-bind="buildNotificationRedirectionRouteParams(notification)"
  >
    <cell-deed
      class="bell-notification__deed"
      :notification="notification"
    />

    <template v-if="notification.cargo.lot">
      <cell-deed-lot
        class="bell-notification__deed-lot"
        :lot="notification.cargo.lot"
        :is-attributes-shown="false"
      />
    </template>
  </router-link>
</template>

<script>
import { Notification } from 'Models/Notification'
import { CellDeed, CellDeedLot } from 'Common/cells'
import { buildNotificationRedirectionRouteParams } from 'Utils/buildNotificationRedirectionRouteParams'
import { mapActions } from 'vuex'
import { bellActions } from 'Store/entities/Bell/types'

export default {
  name: 'bell-notification',

  components: {
    CellDeed,
    CellDeedLot,
  },

  props: {
    notification: {
      type: Notification,
      required: true,
    },
  },

  methods: {
    ...mapActions('entities/bell', {
      markRead: bellActions.MARK_READ,
    }),

    buildNotificationRedirectionRouteParams,

    onClick () {
      this.$emit('redirected')
      this.markRead({ notificationIds: [this.notification.id] })
    },
  },
}
</script>

<style lang="scss" scoped>
.bell-notification {
  display: inline-grid;
  width: 100%;
  text-decoration: none;
  color: $color-ui-default;
  cursor: pointer;
  padding: 1em;
  grid: auto / auto-flow minmax(0, auto);
  gap: 1em;

  &:nth-child(odd) {
    background: $color-ui-grey-blue3;
  }

  &:hover {
    background: mix($color-ui-grey-blue3, $color-ui-default, 96);
  }

  &__deed {
    gap: 0.75em;
  }

  &__deed-lot {
    font-size: 0.8em;
    justify-self: end;
  }
}
</style>
