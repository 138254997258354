<template>
  <ui-date-picker
    class="calendar"
    :locale="$i18n.locale"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { UiDatePicker } from '@shelf.network/ui-kit'

export default {
  name: 'calendar',

  components: {
    UiDatePicker,
  },
}
</script>

<style lang="scss" scoped>
.calendar {
  /deep/ .numInput,
  /deep/ .flatpickr-monthDropdown-months {
    height: 2.5em;
    line-height: 2.5em;
  }

  /deep/ .flatpickr-months {
    margin-bottom: 0.5em;
    height: 2.5em;
    line-height: 2.5em;
  }

  /deep/ .flatpickr-month {
    height: 2.5em;
    line-height: 2.5em;
  }

  /deep/ .flatpickr-weekdays {
    margin-bottom: 0;
  }

  /deep/ .flatpickr-current-month {
    font-size: 1em;
    padding: 0;
    height: unset;
  }

  /deep/ .flatpickr-prev-month,
  /deep/ .flatpickr-next-month {
    top: 0.2em;
    padding: 0.5em 0.5em;
    border-radius: 50%;
    height: unset;

    svg {
      width: 1em;
      height: 1em;
      fill: $color-dark;
      display: block;
    }

    &:hover {
      background: #e6e6e6;

      svg {
        fill: $color-dark;
      }
    }
  }

  /deep/ .flatpickr-calendar {
    width: 17.05em !important;
    background: transparent;
  }

  /deep/ .dayContainer,
  /deep/ .flatpickr-weekdaycontainer {
    max-width: 17.05em;
    min-width: 17.05em;
  }

  /deep/ .flatpickr-day {
    font-size: 1em;
    border-radius: 50%;
    border: none;
    max-width: 2.15em;
    min-width: 2.15em;
    height: 2.15em;
    line-height: 2.3em;
    color: $color-dark;
    margin-top: 0.1em;

    &.selected,
    &.selected:hover {
      border-radius: 50%;
      color: white;
      background: $color-dark;
    }

    &.prevMonthDay,
    &.nextMonthDay {
      color: $color-grey;
      opacity: 1;

      &:hover {
        color: $color-grey;
      }
    }

    &.flatpickr-disabled {
      color: $color-grey;
      background: none;
    }

    &:not(:nth-child(7n)) {
      margin-right: 0.333333em;
    }

    &:not(:nth-child(-n + 7)) {
      margin-top: 0.1em;
    }
  }

  /deep/ .flatpickr-weekday {
    font-size: 1em;
    margin-right: 0;
    max-width: 2.15em;
    min-width: 2.15em;
    height: 2.15em;
    line-height: 2.3em;
    color: $color-dark-grey;

    &:not(:nth-child(7n)) {
      margin-right: 0.333333em;
    }
  }
}
</style>
