import { getNullDate } from './dateHelpers'

/**
 * Build a reducer that summarizes by the specified field
 * @param {string} field
 * @returns {reducerFn}
 */
export function summarizerBy (field) {
  return (acc, cur) => acc + Number(cur[field])
}

/**
 * Build a field comparator with the provided compareFn
 * @param {string} field
 * @param {comparatorFn} compareFn
 * @param {string} [modifiers]
 * Currently '-' is allowed only, that reverses the comparator result
 * @returns {comparatorFn}
 */
export function fieldComparator (field, compareFn, modifiers = '') {
  if (modifiers.includes('-')) {
    return (elA, elB) => compareFn(elB[field], elA[field])
  }

  return (elA, elB) => compareFn(elA[field], elB[field])
}

/**
 * Compare dates
 * @param {Date} [dateA]
 * @param {Date} [dateB]
 * @returns {number}
 */
export function compareDate (dateA = getNullDate(), dateB = getNullDate()) {
  return dateA.getTime() - dateB.getTime()
}

/**
 * Invoke .localeCompare over the strings
 * @param {string} [strA]
 * @param {string} [strB]
 * @returns {number}
 */
export function compareAlphabetically (strA = '', strB = '') {
  return strA.localeCompare(strB)
}

/**
 * @callback comparatorFn
 * @param {any} elA
 * @param {any} elB
 * @returns {number}
 */

/**
 * @callback reducerFn
 * @param {any} accumulator
 * @param {any} current
 * @returns {any}
 */
