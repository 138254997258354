<template>
  <div
    class="lead-lot-calculator-field"
    :class="{ 'lead-lot-calculator-field_loading': isLoading }"
  >
    <template v-if="isEnablerShown">
      <button
        class="lead-lot-calculator-field__enabler"
        :class="{
          'lead-lot-calculator-field__enabler_deselect': isEnabled,
        }"
        @click="toggleIsEnabled"
      >
        <ui-icon
          class="lead-lot-calculator-field__enabler-ico"
          icon="success"
        />
      </button>
    </template>

    <label class="lead-lot-calculator-field__label">
      {{ label }}
    </label>

    <ui-text
      class="lead-lot-calculator-field__input"
      :class="{
        'lead-lot-calculator-field__input_readonly': isReadOnly,
      }"
      type="number"
      min="0"
      :is-disabled="isReadOnly"
      :value="value"
      @input="$emit('input', $event)"
    >
      <span
        class="lead-lot-calculator-field__input-currency"
        slot="dock-right"
      >
        {{ currencySymbol }}
      </span>
    </ui-text>
  </div>
</template>

<script>
import { UiText, UiIcon } from '@shelf.network/ui-kit'
import { currencySymbols } from 'Utils/currencySymbols'

export default {
  name: 'lead-lots-calculator-field',

  components: {
    UiText,
    UiIcon,
  },

  props: {
    value: {
      type: String,
      default: '0',
    },

    currency: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: '',
    },

    isReadOnly: {
      type: Boolean,
      default: false,
    },

    isEnablerShown: {
      type: Boolean,
      default: false,
    },

    isEnabled: {
      type: Boolean,
      default: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currencySymbol () {
      return currencySymbols[this.currency]
    }
  },

  methods: {
    toggleIsEnabled () {
      this.$emit('update:isEnabled', !this.isEnabled)
    }
  },
}
</script>

<style lang="scss" scoped>
.lead-lot-calculator-field {
  display: flex;
  border-bottom: 1px solid $color-grey;
  padding: 0 0 0.5em;
  align-items: center;

  &_loading {
    @include loading-fog();
  }

  &__enabler {
    border: 1px solid $color-dark-grey;
    background: $color-white;
    border-radius: 0.25em;
    transition: all linear 180ms;
    width: 2.15em;
    height: 2.15em;
    position: relative;
    margin-right: 0.4em;

    &_deselect {
      background: $color-dark-grey;
    }

    &-ico {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-white;
      font-size: 1.6em;
    }

    @include respond-below(xs) {
      width: 1.5em;
      height: 1.5em;

      &-ico {
        font-size: 1.2em;
      }
    }
  }

  &__label {
    display: block;
    font-size: 1.142857em; // 16px when font-size: 14px;
    line-height: 1.5;
    color: $color-dark-grey;

    @include respond-below(xs) {
      font-size: 1.05em;
    }
  }

  &__input {
    max-width: 9.5em;
    margin-left: auto;

    /deep/ .ui-text__input[fill][look] {
      border-radius: 1em;
      background: rgba(240, 242, 245, 0.4);
      border: 1px solid $color-grey;
      color: $color-dark;
      text-overflow: ellipsis;
      padding-right: 2.52em;

      &[is-disabled] {
        border-color: transparent !important; // rewrite ui-input styles
        color: $color-dark !important; // rewrite ui-input styles
      }

      &:invalid {
        background: mix(rgba(240, 242, 245, 0.4), $color-sys-warning, 90%);
        border-color: $color-sys-warning;
      }
    }

    /deep/ .ui-text__dock {
      width: 2.5em;
      height: 100%;
      right: 0;
    }

    @include respond-below(xs) {
      max-width: 8em;

      /deep/ .ui-text__input[fill][look] {
        padding-right: 2.2em;
      }

      /deep/ .ui-text__dock {
        width: 2em;
      }
    }

    &-currency {
      font-weight: 900;
      font-size: 1.35em;
      color: $color-dark;
    }
  }
}
</style>
