<template>
  <div
    class="list-params-brokers list-params-row"
    v-if="$can($USER_CLAIMS.BROKER_ASSIGNMENT)"
  >
    <label class="list-params-row__label">
      {{ label || $t('BROKER_LBL') }}
    </label>

    <ui-multi-select
      class="list-params-row__field"
      fill="frame"
      look="secondary"
      :options="options"
      :placeholder="placeholder || $t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
      :null-option-label="$t('COMMON.SELECT_FIELD.ALL_OPT')"
      :value="arrValue"
      @input="onMultiInput"
    />
  </div>
</template>

<script>
import { UiMultiSelect } from '@shelf.network/ui-kit'
import { mapGetters } from 'vuex'
import { brokersGetters } from 'Store/entities/Brokers/types'

export default {
  name: 'list-params-brokers',
  components: { UiMultiSelect },

  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },

  computed: {
    ...mapGetters({
      options: `entities/brokers/${brokersGetters.BROKER_OPTIONS}`,
    }),

    arrValue () {
      const value = this.value || this.defaultValue
      return value ? value.split(',') : []
    },
  },
  methods: {
    onMultiInput (value) {
      this.$emit('input', value.join())
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./styles/list-params-row.scss";
</style>

<i18n>
{
  "en": {
    "BROKER_LBL": "BY BROKER"
  },
  "ka": {
    "BROKER_LBL": "ᲑᲠᲝᲙᲔᲠᲘᲡ ᲛᲘᲮᲔᲓᲕᲘᲗ"
  },
  "ru": {
    "BROKER_LBL": "ПО БРОКЕРУ"
  },
  "uk": {
    "BROKER_LBL": "ЗА БРОКЕРОМ"
  }
}
</i18n>
