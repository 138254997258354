import CarfaxSkeleton from './components/CarfaxSkeleton'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'
import store from 'Store'

const moduleName = 'carfax'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: CarfaxSkeleton,
  importComponent: () => import('./Carfax.vue'),
  async createDependencies () {
    const { default: carfaxModule } = await import('./store')
    store.registerModule(['ui', moduleName], carfaxModule)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
