<template>
  <ui-select
    class="select-location select-location_field"
    fill="frame"
    :options="options"
    :placeholder="isLoaded
      ? placeholder || $t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')
      : $t('LOADING_MSG')
    "
    :null-option="nullOption"
    has-filter
    @filter="loadLocationsDebounced"
    filter-method="none"
    :search-placeholder="$t('SEARCH_MSG')"
    :empty-message="$t('NO_LIST_MSG')"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
    <slot
      name="error"
      slot="error"
    />
    <slot
      name="dock-left"
      slot="dock-left"
    />
  </ui-select>
</template>

<script>
import { sdk } from 'Services/shelfNetworkSdk'
import { mapGetters } from 'vuex'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import { userGetters } from 'Store/entities/User/types'
import { UiSelect } from '@shelf.network/ui-kit'

export default {
  name: 'select-location',

  components: {
    UiSelect,
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      locations: [],
      isLoaded: false,
    }
  },

  computed: {
    ...mapGetters({
      platform: `entities/user/${userGetters.PLATFORM}`,
    }),

    options () {
      return this.locations.map(item => {
        const r11s = item.relationships || {}
        const arr = [r11s.country || {}, r11s.region || {}, r11s.city || {}]
        return {
          value: arr.map(el => el.id || '0').join(':'),
          label: arr.map(el => el.name).filter(str => str)
            .join(this.$t('COMMON.SYMBOLS.COMMA')),
        }
      })
    },

    nullOption () {
      return {
        value: '',
        label: this.$t('COMMON.SELECT_FIELD.RESET_OPT'),
      }
    },
  },

  methods: {
    async loadLocations (search) {
      const locale = (this.$i18n || {}).locale
      if (!locale) return // cover case when the component destroyed

      const list = []

      if (this.value) {
        const [countryId, regionId, cityId] =
          this.value.split(':').map(el => el === '0' ? '' : el)

        const { data: [currentCity] } = await sdk.identity.locations.getAll({
          ...(countryId ? { 'filter[country.id]': countryId } : {}),
          ...(regionId ? { 'filter[region.id]': regionId } : {}),
          ...(cityId ? { 'filter[city.id]': cityId } : {}),
        }, locale)

        list.push(currentCity)
      }

      if (search) {
        const { data: cities } = await sdk.identity.locations
          .getAll({ search }, locale)

        list.push(...cities)
      } else {
        const { data: { id: countryId } } = await sdk.identity.countries
          .getByCode(this.platform.countryAlpha3)

        const { data: defaultCities } = await sdk.identity.locations
          .getAll({ 'filter[country.id]': countryId }, locale)

        list.push(...defaultCities)
      }

      this.locations = uniqBy(
        list.filter(item => item),
        el => get(el, 'relationships.city.id') || ''
      )

      this.isLoaded = true
    },

    loadLocationsDebounced: debounce(async function (search) {
      await this.loadLocations(search)
    }, 500),
  },
}
</script>

<i18n>
{
  "en": {
    "SEARCH_MSG": "Search location",
    "LOADING_MSG": "Loading…",
    "NO_LIST_MSG": "No locations matching the search"
  },
  "ka": {
    "SEARCH_MSG": "ლოკაციის ძებნა",
    "LOADING_MSG": "იტვირთება…",
    "NO_LIST_MSG": "არ მოიძებნა"
  },
  "ru": {
    "SEARCH_MSG": "Поиск местонахождения",
    "LOADING_MSG": "Загрузка…",
    "NO_LIST_MSG": "Мест соответствующих поисковому запросу не найдено"
  },
  "uk": {
    "SEARCH_MSG": "Пошук місця розташування",
    "LOADING_MSG": "Завантаження…",
    "NO_LIST_MSG": "Місць відповідних пошуковому запиту не знайдено"
  }
}
</i18n>
