<template>
  <div class="lead-tab-user">
    <ui-row>
      <ui-col
        lg="6"
        xs="12">
        <b class="lead-tab-user__lbl">
          {{ $t('USER_TRAFFIC_SOURCE_LBL') }}
        </b>
        <ui-dropdown
          class="lead-tab-user__select-channel"
          select
          fill="frame"
          look="secondary"
        >
          <template v-if="lead.channel">
            <channel-icon
              :channel="lead.channel"
              class="lead-tab-user__select-channel-icon" />
            {{ channels[lead.channel] }}
          </template>
          <template v-else>
            {{ $t('USER_TRAFFIC_SOURCE_PH') }}
          </template>

          <div slot="content">
            <ui-dropdown-item
              v-for="(label, val) in channels"
              :key="val"
              interactive
              :active="val === lead.channel"
              @click="setChannel(val)"
              auto-close
            >
              <channel-icon
                :channel="val"
                class="lead-tab-user__select-channel-icon" />
              {{ label }}
            </ui-dropdown-item>
          </div>
        </ui-dropdown>
      </ui-col>

      <ui-col
        lg="6"
        xs="12">
        <b class="lead-tab-user__lbl">
          {{ $t('USER_EMAIL_LBL') }}
        </b>
        <ui-text
          look="secondary"
          :value="lead.contactEmail"
          :placeholder="$t('COMMON.NO_LEAD_EMAIL_PH')"
          readonly
        />
      </ui-col>

      <ui-col
        lg="6"
        xs="12">
        <b class="lead-tab-user__lbl">
          {{ $t('USER_FINANCING_LBL') }}
        </b>
        <div class="lead-tab-user__toggle">
          <ui-button
            @click="setFinancing(false)"
            class="lead-tab-user__toggle-btn"
            :look="!lead.financing ? 'secondary' : 'default'"
            :fill="!lead.financing ? 'solid' : 'none'"
          >
            {{ $t('COMMON.NO_MSG') }}
          </ui-button>
          <ui-button
            @click="setFinancing(true)"
            class="lead-tab-user__toggle-btn"
            :look="lead.financing ? 'secondary' : 'default'"
            :fill="lead.financing ? 'solid' : 'none'"
          >
            {{ $t('COMMON.YES_MSG') }}
          </ui-button>
        </div>
      </ui-col>

      <ui-col
        lg="6"
        xs="12">
        <b class="lead-tab-user__lbl">
          {{ $t('USER_LOCATION_LBL') }}
        </b>
        <i18n-location
          :location="lead.location"
          look="secondary"
          readonly
          :placeholder="$t('USER_LOCATION_PH')"
        />
      </ui-col>

      <ui-col
        lg="6"
        xs="12">
        <b class="lead-tab-user__lbl">
          {{ $t('USER_TOTAL_BALANCE_LBL') }}
        </b>
        <ui-text
          class="lead-tab-user__balance-field"
          look="secondary"
          readonly
        >
          <template v-if="lead.activeDepositsIds.length">
            <deposit-payments-hint
              class="lead-tab-user__balance lead-tab-user__balance_success"
              :deposit-id="lead.activeDepositsIds.join()"
              :exclude-timeouts="false"
              :exclude-pending="false"
              slot="dock-left"
            >
              {{ $fc(lead.activeDepositsTotal, lead.activeDepositsCurrency) }}
            </deposit-payments-hint>

            <span
              class="lead-tab-user__balance-date"
              :title="
                formatDateNumeralRelative(lead.activeDepositLatestCreatedAt)
              "
              slot="dock-right"
            >
              {{ formatDateNumeralRelative(lead.activeDepositLatestCreatedAt) }}
            </span>
          </template>

          <template v-else>
            <span
              class="lead-tab-user__balance"
              slot="dock-left"
            >
              {{ $fc(ZERO_USER_BALANCE) }}
            </span>
          </template>
        </ui-text>
      </ui-col>

      <ui-col
        lg="6"
        xs="12">
        <b class="lead-tab-user__lbl">
          {{ $t('USER_PRO_CANDIDATE_LBL') }}
        </b>
        <div class="lead-tab-user__toggle">
          <ui-button
            @click="setProCandidate(false)"
            class="lead-tab-user__toggle-btn"
            :look="!lead.interestedInPro ? 'secondary' : 'default'"
            :fill="!lead.interestedInPro ? 'solid' : 'none'"
          >
            {{ $t('COMMON.NO_MSG') }}
          </ui-button>
          <ui-button
            @click="setProCandidate(true)"
            class="lead-tab-user__toggle-btn"
            :look="lead.interestedInPro ? 'secondary' : 'default'"
            :fill="lead.interestedInPro ? 'solid' : 'none'"
          >
            {{ $t('COMMON.YES_MSG') }}
          </ui-button>
        </div>
      </ui-col>
    </ui-row>
    <ui-row>
      <ui-col lg="12">
        <lead-attachments :lead="lead" />
      </ui-col>
    </ui-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { actions } from '../store/types'
import { Lead } from 'Models/Lead'
import I18nLocation from 'Common/I18nLocation'
import {
  UiText,
  UiCol,
  UiRow,
  UiButton,
  UiDropdown,
  UiDropdownItem,
} from '@shelf.network/ui-kit'
import { showSuccess } from 'Utils/notifications'
import { getFirstWord } from 'Utils/stringHelpers'
import LeadAttachments from './LeadAttachments'
import ChannelIcon from 'Common/ChannelIcon'
import { DepositPaymentsHint } from 'Common/DepositPaymentsHint'
import { formatDateNumeralRelative } from 'Utils/dateHelpers'

const ZERO_USER_BALANCE = 0

export default {
  name: 'lead-tab-user',
  components: {
    I18nLocation,
    UiText,
    UiCol,
    UiRow,
    UiButton,
    UiDropdown,
    UiDropdownItem,
    LeadAttachments,
    ChannelIcon,
    DepositPaymentsHint
  },
  props: {
    lead: { type: Lead, required: true }
  },
  data () {
    return {
      ZERO_USER_BALANCE,
    }
  },
  computed: {
    channels () {
      return {
        [Lead.channelsEnum.phone]: this.$t('COMMON.LEAD_CHANNELS.PHONE'),
        [Lead.channelsEnum.email]: this.$t('COMMON.LEAD_CHANNELS.EMAIL'),
        [Lead.channelsEnum.website]: this.$t('COMMON.LEAD_CHANNELS.WEBSITE'),
        [Lead.channelsEnum.telegram]: this.$t('COMMON.LEAD_CHANNELS.TELEGRAM'),
        [Lead.channelsEnum.carfax]: this.$t('COMMON.LEAD_CHANNELS.CARFAX'),
        [Lead.channelsEnum.facebook]: this.$t('COMMON.LEAD_CHANNELS.FACEBOOK'),
        [Lead.channelsEnum.rajdeba]: this.$t('COMMON.LEAD_CHANNELS.RAJDEBA'),
        [Lead.channelsEnum.facebookWeb]: this.$t('COMMON.LEAD_CHANNELS.FACEBOOK_WEB'),
      }
    }
  },
  methods: {
    ...mapActions('ui/leads', {
      updateLead: actions.UPDATE_LEAD
    }),

    formatDateNumeralRelative,

    async setChannel (channel) {
      if (channel !== this.lead.channel) {
        const undoAction =
          await this.updateLead({ id: this.lead.id, channel })

        const leadName =
          getFirstWord(this.lead.fullName) || this.$t('COMMON.UNNAMED_USER_PH')

        showSuccess({
          text: this.$t('SUCCESS_CHANNEL_NOTIFY', {
            leadName,
            channel: this.channels[channel]
          }),
          undoAction
        })
      }
    },
    async setProCandidate (interestedInPro) {
      if (interestedInPro !== this.lead.interestedInPro) {
        const undoAction =
          await this.updateLead({ id: this.lead.id, interestedInPro })

        const leadName =
          getFirstWord(this.lead.fullName) || this.$t('COMMON.UNNAMED_USER_PH')

        showSuccess({
          text: this.$t(
            interestedInPro
              ? 'SUCCESS_INTERESTED_IN_PRO_ENABLED'
              : 'SUCCESS_INTERESTED_IN_PRO_DISABLED',
            { leadName }
          ),
          undoAction
        })
      }
    },
    async setFinancing (financing) {
      if (financing !== this.lead.financing) {
        const undoAction =
          await this.updateLead({ id: this.lead.id, financing })

        const leadName =
          getFirstWord(this.lead.fullName) || this.$t('COMMON.UNNAMED_USER_PH')

        showSuccess({
          text: this.$t(
            financing
              ? 'SUCCESS_FINANCING_ALLOWED'
              : 'SUCCESS_FINANCING_FORBIDDEN',
            { leadName }
          ),
          undoAction
        })
      }
    }
  },

}
</script>

<style scoped lang="scss">
.lead-tab-user {
  &__lbl {
    margin: 1em 0;
    font-weight: 500;
    display: block;
  }

  &__select-channel {
    display: block;

    &-icon {
      font-size: 1.5em;
      margin-right: 0.4em;
      transform: scale(1.3);
    }
  }

  &__toggle {
    background: $color-light-grey;
    border-radius: $layout-border-radius;
    display: flex;

    &-btn {
      flex: 1;

      &:first-child /deep/ .ui-button__button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        transition: none !important;
      }

      &:last-child /deep/ .ui-button__button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        transition: none !important;
      }
    }
  }

  &__balance-field {
    /deep/ .ui-text__input[fill][look] {
      background: $color-light-grey;
      border-color: transparent;

      &:hover {
        box-shadow: none;
      }
    }

    /deep/ .ui-text__dock {
      padding: var(--input-padding);
      width: unset;

      &_left {
        left: 0;
      }

      &_right {
        right: 0;
      }
    }
  }

  &__balance {
    color: $color-ui-secondary;

    &_success {
      color: $color-flag-is-success;
    }
  }

  &__balance-date {
    color: $color-ui-secondary;
  }

  & /deep/ .ui-col {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
</style>

<i18n>
{
  "en": {
    "USER_TRAFFIC_SOURCE_LBL": "LEAD CHANNEL",
    "USER_TRAFFIC_SOURCE_PH": "Unknown",
    "USER_EMAIL_LBL": "USER EMAIL",
    "USER_LOCATION_LBL": "USER LOCATION",
    "USER_LOCATION_PH": "Location not specified",
    "USER_FINANCING_LBL": "FINANCING",
    "USER_TOTAL_BALANCE_LBL": "TOTAL BALANCE",
    "USER_PRO_CANDIDATE_LBL": "This user wants to become a Pro.",
    "SUCCESS_CHANNEL_NOTIFY": "Channel for {leadName} changed to {channel}",
    "SUCCESS_FINANCING_ALLOWED": "Financing allowed for {leadName}",
    "SUCCESS_FINANCING_FORBIDDEN": "Financing forbidden for {leadName}",
    "SUCCESS_INTERESTED_IN_PRO_ENABLED": "{leadName} marked as Want to become Pro.",
    "SUCCESS_INTERESTED_IN_PRO_DISABLED": "{leadName} marked as don't want to become Pro."
  },
  "ka": {
    "USER_TRAFFIC_SOURCE_LBL": "ᲚᲘᲓᲘᲡ ᲬᲧᲐᲠᲝ",
    "USER_TRAFFIC_SOURCE_PH": "უცნობი",
    "USER_EMAIL_LBL": "ᲔᲚ. ᲤᲝᲡᲢᲐ",
    "USER_LOCATION_LBL": "ᲚᲝᲙᲐᲪᲘᲐ",
    "USER_LOCATION_PH": "არ არის მითითებული",
    "USER_FINANCING_LBL": "ᲓᲐᲤᲘᲜᲐᲜᲡᲔᲑᲐ",
    "USER_TOTAL_BALANCE_LBL": "ᲑᲐᲚᲐᲜᲡᲘ",
    "USER_PRO_CANDIDATE_LBL": "მომხმარებელს უნდა გახდეს პრო.",
    "SUCCESS_CHANNEL_NOTIFY": "{leadName} წყარო შეიცვალა {channel}",
    "SUCCESS_FINANCING_ALLOWED": "დაფინანსებით {leadName}",
    "SUCCESS_FINANCING_FORBIDDEN": "დაფინანსების გარეშე {leadName}",
    "SUCCESS_INTERESTED_IN_PRO_ENABLED": "{leadName} მოინიშნა როგორც Pro-ს კანდიდატი.",
    "SUCCESS_INTERESTED_IN_PRO_DISABLED": "{leadName} გაუქმდა როგორც Pro-ს კანდიდატი."
  },
  "ru": {
    "USER_TRAFFIC_SOURCE_LBL": "КАНАЛ",
    "USER_TRAFFIC_SOURCE_PH": "Неизвестный",
    "USER_EMAIL_LBL": "ЭЛЕКТРОННЫЙ АДРЕС",
    "USER_LOCATION_LBL": "МЕСТОНАХОЖДЕНИЕ",
    "USER_LOCATION_PH": "Местонахождение не указано",
    "USER_FINANCING_LBL": "ФИНАНСИРОВАНИЕ",
    "USER_TOTAL_BALANCE_LBL": "СУММАРНЫЙ БАЛАНС",
    "USER_PRO_CANDIDATE_LBL": "Этот пользователь хочет стать Pro.",
    "SUCCESS_CHANNEL_NOTIFY": "Канал для {leadName} изменен на {channel}",
    "SUCCESS_FINANCING_ALLOWED": "Для {leadName} финансирование разрешено",
    "SUCCESS_FINANCING_FORBIDDEN": "Для {leadName} финансирование запрещено",
    "SUCCESS_INTERESTED_IN_PRO_ENABLED": "{leadName} помечен как желающий стать Pro.",
    "SUCCESS_INTERESTED_IN_PRO_DISABLED": "{leadName} помечен как нежелающий стать Pro."
  },
  "uk": {
    "USER_TRAFFIC_SOURCE_LBL": "КАНАЛ",
    "USER_TRAFFIC_SOURCE_PH": "Невідомий",
    "USER_EMAIL_LBL": "ЕЛЕКТРОННА АДРЕСА",
    "USER_LOCATION_LBL": "МІСЦЕ РОЗТАШУВАННЯ",
    "USER_LOCATION_PH": "Місце не вказано",
    "USER_FINANCING_LBL": "ФІНАНСУВАННЯ",
    "USER_TOTAL_BALANCE_LBL": "СУМАРНИЙ БАЛАНС",
    "USER_PRO_CANDIDATE_LBL": "Цей користувач хоче стати Pro.",
    "SUCCESS_CHANNEL_NOTIFY": "Канал для {leadName} змінений на {channel}",
    "SUCCESS_FINANCING_ALLOWED": "Для {leadName} фінансування дозволено",
    "SUCCESS_FINANCING_FORBIDDEN": "Для {leadName} фінансування заборонено",
    "SUCCESS_INTERESTED_IN_PRO_ENABLED": "{leadName} позначений як охочий стати Pro.",
    "SUCCESS_INTERESTED_IN_PRO_DISABLED": "{leadName} позначений як неохочий стати Pro."
  }
}
</i18n>
