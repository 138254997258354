<template>
  <div class="analytics-skeleton">
    <page-subnav class="analytics-skeleton__subnav" />

    <div class="analytics-skeleton__list">
      <analytics-item-skeleton />
      <analytics-item-skeleton />
      <analytics-item-skeleton />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import AnalyticsItemSkeleton from './AnalyticsItemSkeleton'

export default {
  name: 'analytics-skeleton',
  components: {
    PageSubnav,
    AnalyticsItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
@import "../styles/analytics";

.analytics-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 0 $list-padding-side;
  }
}
</style>
