import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import AccountsSkeleton from './components/AccountsSkeleton'

const moduleName = 'extension-accounts'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: AccountsSkeleton,
  importComponent: () => import('./Accounts.vue'),
  async createDependencies () {
    const { default: accounts } = await import('./store')
    store.registerModule(['ui', moduleName], accounts)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
