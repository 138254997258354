export const LEADS_URL_PARAM_ASSIGNMENTS = Object.freeze({
  unassigned: 'unassigned',
  assigned: 'assigned',
})

export const LEADS_URL_PARAM_TABS = Object.freeze({
  waiting: 'waiting',
  hold: 'hold',
  looking: 'looking',
  offered: 'offered',
})

export const LEADS_URL_PARAM_LEAD_TABS = Object.freeze({
  user: 'user',
  cars: 'cars',
  messages: 'messages',
  history: 'history',
})

export const LEADS_QUERY_SORTS = Object.freeze({
  supportRequestCreatedAt: 'support_request.created_at',
  supportRequestCreatedAtDesc: '-support_request.created_at',
  priority: 'priority',
  priorityDesc: '-priority',
  dueDate: 'due_date',
  dueDateDesc: '-due_date',
  lastOfferSentAt: 'last_offer_sent_at',
  lastOfferSentAtDesc: '-last_offer_sent_at',
})

export const LEADS_URL_PARAM_TAB_DEFAULT_SORT = Object.freeze({
  [LEADS_URL_PARAM_TABS.waiting]: LEADS_QUERY_SORTS.supportRequestCreatedAtDesc,
  [LEADS_URL_PARAM_TABS.hold]: LEADS_QUERY_SORTS.dueDate,
  [LEADS_URL_PARAM_TABS.looking]: LEADS_QUERY_SORTS.dueDate,
  [LEADS_URL_PARAM_TABS.offered]: LEADS_QUERY_SORTS.lastOfferSentAtDesc,
})
