/**
 * Make a component lazy-loadable, with a skeleton.
 *
 * @param {object} params Lazy load options.
 * @param {object} params.name Component name.
 * @param {function.<Promise>} params.importComponent Loads the component.
 * @param {object} [params.skeletonComponent] Skeleton component.
 * @param {function.<Promise>} [params.createDependencies] Initialize
 * dependencies before the component is rendered.
 * @param {function} [params.destroyDependencies] Destroy
 * component dependencies after component destruction.
 *
 * @return {function.<Promise>} Returns an async component factory.
 */
export function createLazyLoadWrapper ({
  name,
  skeletonComponent,
  importComponent,
  createDependencies = () => Promise.resolve(),
  destroyDependencies = () => {}
}) {
  return async () => ({
    name: `${name}-module`,
    data () {
      return {
        ready: false,
        component: null
      }
    },
    render (createElement) {
      if (!this.ready) {
        return createElement(skeletonComponent)
      }

      return createElement(this.component)
    },
    async created () {
      const [componentModule] = await Promise.all([
        importComponent(),
        createDependencies()
      ])
      this.component = componentModule.default
      this.ready = true
    },
    destroyed () {
      destroyDependencies()
    }
  })
}
