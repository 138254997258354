import { rootActions, rootMutations } from './types'
import { init as initSdk } from 'Services/shelfNetworkSdk'
import { showError } from 'Utils/notifications'
import { i18n } from 'I18n/instance'
import { DEFAULT_LOCALE } from 'Constants/locales'
import { can } from 'Utils/userHelpers'
import { USER_CLAIMS } from 'Constants/userClaims'
import { subscribeSignOutAfter } from 'Store/helpers/subscribeHelpers'
import get from 'lodash/get'

import { userActions, userGetters } from 'Store/entities/User/types'
import { brokersActions } from 'Store/entities/Brokers/types'
import { enumsActions } from 'Store/entities/Enums/types'
import { exRatesActions } from 'Store/entities/ExRates/types'
import { platformsActions } from 'Store/entities/Platforms/types'
import { bellActions } from 'Store/entities/Bell/types'
import { actions as leadsActions } from 'Components/Leads/store/types'

export default {
  async [rootActions.INITIALIZE_APP] ({ dispatch, commit }) {
    try {
      initSdk()

      await Promise.all([
        dispatch(`entities/enums/${enumsActions.FETCH_ENUMS}`),
        dispatch(`entities/exRates/${exRatesActions.START_RATES_UPDATER}`),
        dispatch(`entities/user/${userActions.START_TOKEN_REFRESHER}`),
        dispatch(`entities/user/${userActions.TRY_RESTORE_SESSION}`),
        dispatch(`entities/platforms/${platformsActions.FETCH_PLATFORMS}`),
      ])

      await dispatch(rootActions.INITIALIZE_USER)
    } catch (err) {
      await dispatch(`entities/user/${userActions.CHANGE_LOCALE}`, i18n.locale)
      showError(i18n.t('TOASTS.INITIALIZE_APP_ERROR'))
    }

    subscribeSignOutAfter(() => {
      dispatch(`entities/bell/${bellActions.DISCONNECT_CLIENT}`)
      dispatch(`entities/brokers/${brokersActions.RESET}`)
      dispatch(`ui/leads/${leadsActions.RESET}`)
    })

    commit(rootMutations.SET_APP_INITIALIZED)
  },
  async [rootActions.INITIALIZE_USER] ({ getters, dispatch }) {
    try {
      await dispatch(`entities/user/${userActions.TRY_GET_PROFILE_DATA}`)

      const locale = get(getters[`entities/user/${userGetters.PROFILE}`], 'locale', DEFAULT_LOCALE)
      await dispatch(`entities/user/${userActions.CHANGE_LOCALE}`, locale)

      const promises = []
      if (can(USER_CLAIMS.BROKER_ASSIGNMENT)) {
        promises.push(dispatch(`entities/brokers/${brokersActions.LOAD_BROKERS}`))
      }

      const canNotifications = can(USER_CLAIMS.NOTIFICATIONS) ||
        can(USER_CLAIMS.EXTENSION_NOTIFICATIONS)
      if (canNotifications) {
        promises.push(dispatch(`entities/bell/${bellActions.CONNECT_CLIENT}`))
      }
      await Promise.all(promises)
    } catch (err) {
      showError(i18n.t('TOASTS.INITIALIZE_USER_ERROR'))
    }
  }
}
