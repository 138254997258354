<template>
  <div class="tbc-result-code">
    <span
      class="tbc-result-code__short"
      :class="'tbc-result-code__short_' + codeClass"
    >
      {{ $t('CODE_SHORT_MSG_FMT', { code, shortMessage }) }}
    </span>
    <div
      class="tbc-result-code__description"
      v-if="longMessage !== shortMessage"
    >
      {{ longMessage }}
    </div>
  </div>
</template>
<script>
const APPROVED = `APPROVED`
const APPROVED_WITH_ID = `APPROVED_WITH_ID`
const DECLINE = `DECLINE`
const DECLINE_NO_FUNDS = `DECLINE_NO_FUNDS`
const PICK_UP = `PICK_UP`
const CALL_ACQUIRER = `CALL_ACQUIRER`
const ACCEPTED = `ACCEPTED`
const LIST_READY = `LIST_READY`
const LIST_NOT_READY = `LIST_NOT_READY`
const NOT_ACCEPTED = `NOT_ACCEPTED`
const UNDEFINED = `UNDEFINED`

const MESSAGES_BY_CODE = {
  '000': APPROVED,
  '001': APPROVED_WITH_ID,
  '002': APPROVED,
  '003': APPROVED,
  '004': APPROVED,
  '005': APPROVED,
  '006': APPROVED,
  '007': APPROVED,
  '100': DECLINE,
  '101': DECLINE,
  '102': DECLINE,
  '103': DECLINE,
  '104': DECLINE,
  '105': DECLINE,
  '106': DECLINE,
  '107': DECLINE,
  '108': DECLINE,
  '109': DECLINE,
  '110': DECLINE,
  '111': DECLINE,
  '112': DECLINE,
  '113': DECLINE,
  '114': DECLINE,
  '115': DECLINE,
  '116': DECLINE_NO_FUNDS,
  '117': DECLINE,
  '118': DECLINE,
  '119': DECLINE,
  '120': DECLINE,
  '121': DECLINE,
  '122': DECLINE,
  '123': DECLINE,
  '124': DECLINE,
  '125': DECLINE,
  '126': DECLINE,
  '127': DECLINE,
  '128': DECLINE,
  '129': DECLINE,
  '180': DECLINE,
  '200': PICK_UP,
  '201': PICK_UP,
  '202': PICK_UP,
  '203': PICK_UP,
  '204': PICK_UP,
  '205': PICK_UP,
  '206': PICK_UP,
  '207': PICK_UP,
  '208': PICK_UP,
  '209': PICK_UP,
  '210': PICK_UP,
  '300': CALL_ACQUIRER,
  '301': CALL_ACQUIRER,
  '302': CALL_ACQUIRER,
  '303': CALL_ACQUIRER,
  '304': CALL_ACQUIRER,
  '305': CALL_ACQUIRER,
  '306': CALL_ACQUIRER,
  '307': CALL_ACQUIRER,
  '308': CALL_ACQUIRER,
  '309': CALL_ACQUIRER,
  '400': ACCEPTED,
  '499': APPROVED,
  '500': CALL_ACQUIRER,
  '501': CALL_ACQUIRER,
  '502': CALL_ACQUIRER,
  '503': CALL_ACQUIRER,
  '504': CALL_ACQUIRER,
  '600': ACCEPTED,
  '601': CALL_ACQUIRER,
  '602': CALL_ACQUIRER,
  '603': CALL_ACQUIRER,
  '604': CALL_ACQUIRER,
  '605': CALL_ACQUIRER,
  '606': CALL_ACQUIRER,
  '680': LIST_READY,
  '681': LIST_NOT_READY,
  '700': ACCEPTED,
  '800': ACCEPTED,
  '900': ACCEPTED,
  '901': ACCEPTED,
  '902': CALL_ACQUIRER,
  '903': CALL_ACQUIRER,
  '904': CALL_ACQUIRER,
  '905': CALL_ACQUIRER,
  '906': CALL_ACQUIRER,
  '907': CALL_ACQUIRER,
  '908': CALL_ACQUIRER,
  '909': CALL_ACQUIRER,
  '910': CALL_ACQUIRER,
  '911': CALL_ACQUIRER,
  '912': CALL_ACQUIRER,
  '913': CALL_ACQUIRER,
  '914': CALL_ACQUIRER,
  '915': CALL_ACQUIRER,
  '916': CALL_ACQUIRER,
  '917': CALL_ACQUIRER,
  '918': CALL_ACQUIRER,
  '919': CALL_ACQUIRER,
  '920': CALL_ACQUIRER,
  '921': CALL_ACQUIRER,
  '922': CALL_ACQUIRER,
  '923': CALL_ACQUIRER,
  '950': NOT_ACCEPTED,
  'XXX': UNDEFINED
}

export default {
  name: 'tbc-result-code',
  props: {
    code: { type: String, required: true }
  },
  computed: {
    codeClass () {
      if ([APPROVED, UNDEFINED].includes(
        MESSAGES_BY_CODE[this.code]
      )) { return 'success' }

      if ([NOT_ACCEPTED, DECLINE, DECLINE_NO_FUNDS, PICK_UP].includes(
        MESSAGES_BY_CODE[this.code]
      )) { return 'fail' }

      return 'none'
    },
    shortMessage () {
      return this.$t(MESSAGES_BY_CODE[this.code])
    },
    longMessage () {
      return this.$t('RESULT_CODE_' + this.code)
    }
  }
}
</script>

<style scoped lang="scss">
.tbc-result-code {
  &__short {
    color: $color-dark;

    &_success {
      color: $color-sys-success;
    }

    &_fail {
      color: $color-sys-warning;
    }
  }

  &__description {
    font-size: 1em;
    margin-top: 0.75em;
    color: $color-dark-grey;
    font-weight: normal;
  }
}
</style>

<i18n>
{
  "en": {
    "APPROVED": "Approved",
    "APPROVED_WITH_ID": "Approved with ID",
    "DECLINE": "Decline",
    "DECLINE_NO_FUNDS": "Decline, no funds",
    "PICK_UP": "Pick-up",
    "CALL_ACQUIRER": "Call acquirer",
    "ACCEPTED": "Accepted",
    "LIST_READY": "List ready",
    "LIST_NOT_READY": "List not ready",
    "NOT_ACCEPTED": "Not accepted",
    "UNDEFINED": "Undefined",
    "CODE_SHORT_MSG_FMT": "{code} — {shortMessage}",
    "RESULT_CODE_000": "Approved",
    "RESULT_CODE_001": "Approved, honour with identification",
    "RESULT_CODE_002": "Approved for partial amount",
    "RESULT_CODE_003": "Approved for VIP",
    "RESULT_CODE_004": "Approved, update track 3",
    "RESULT_CODE_005": "Approved, account type specified by card issuer",
    "RESULT_CODE_006": "Approved for partial amount, account type specified by card issuer",
    "RESULT_CODE_007": "Approved, update ICC",
    "RESULT_CODE_100": "Decline (general, no comments)",
    "RESULT_CODE_101": "Decline, expired card",
    "RESULT_CODE_102": "Decline, suspected fraud",
    "RESULT_CODE_103": "Decline, card acceptor contact acquirer",
    "RESULT_CODE_104": "Decline, restricted card",
    "RESULT_CODE_105": "Decline, card acceptor call acquirer's security department",
    "RESULT_CODE_106": "Decline, allowable PIN tries exceeded",
    "RESULT_CODE_107": "Decline, refer to card issuer",
    "RESULT_CODE_108": "Decline, refer to card issuer's special conditions",
    "RESULT_CODE_109": "Decline, invalid merchant",
    "RESULT_CODE_110": "Decline, invalid amount",
    "RESULT_CODE_111": "Decline, invalid card number",
    "RESULT_CODE_112": "Decline, PIN data required",
    "RESULT_CODE_113": "Decline, unacceptable fee",
    "RESULT_CODE_114": "Decline, no account of type requested",
    "RESULT_CODE_115": "Decline, requested function not supported",
    "RESULT_CODE_116": "Decline, not sufficient funds",
    "RESULT_CODE_117": "Decline, incorrect PIN",
    "RESULT_CODE_118": "Decline, no card record",
    "RESULT_CODE_119": "Decline, transaction not permitted to cardholder",
    "RESULT_CODE_120": "Decline, transaction not permitted to terminal",
    "RESULT_CODE_121": "Decline, exceeds withdrawal amount limit",
    "RESULT_CODE_122": "Decline, security violation",
    "RESULT_CODE_123": "Decline, exceeds withdrawal frequency limit",
    "RESULT_CODE_124": "Decline, violation of law",
    "RESULT_CODE_125": "Decline, card not effective",
    "RESULT_CODE_126": "Decline, invalid PIN block",
    "RESULT_CODE_127": "Decline, PIN length error",
    "RESULT_CODE_128": "Decline, PIN kay synch error",
    "RESULT_CODE_129": "Decline, suspected counterfeit card",
    "RESULT_CODE_180": "Decline, by cardholders wish",
    "RESULT_CODE_200": "Pick-up (general, no comments)",
    "RESULT_CODE_201": "Pick-up, expired card",
    "RESULT_CODE_202": "Pick-up, suspected fraud",
    "RESULT_CODE_203": "Pick-up, card acceptor contact card acquirer",
    "RESULT_CODE_204": "Pick-up, restricted card",
    "RESULT_CODE_205": "Pick-up, card acceptor call acquirer's security department",
    "RESULT_CODE_206": "Pick-up, allowable PIN tries exceeded",
    "RESULT_CODE_207": "Pick-up, special conditions",
    "RESULT_CODE_208": "Pick-up, lost card",
    "RESULT_CODE_209": "Pick-up, stolen card",
    "RESULT_CODE_210": "Pick-up, suspected counterfeit card",
    "RESULT_CODE_300": "Status message: file action successful",
    "RESULT_CODE_301": "Status message: file action not supported by receiver",
    "RESULT_CODE_302": "Status message: unable to locate record on file",
    "RESULT_CODE_303": "Status message: duplicate record, old record replaced",
    "RESULT_CODE_304": "Status message: file record field edit error",
    "RESULT_CODE_305": "Status message: file locked out",
    "RESULT_CODE_306": "Status message: file action not successful",
    "RESULT_CODE_307": "Status message: file data format error",
    "RESULT_CODE_308": "Status message: duplicate record, new record rejected",
    "RESULT_CODE_309": "Status message: unknown file",
    "RESULT_CODE_400": "Accepted (for reversal)",
    "RESULT_CODE_499": "Approved, no original message data",
    "RESULT_CODE_500": "Status message: reconciled, in balance",
    "RESULT_CODE_501": "Status message: reconciled, out of balance",
    "RESULT_CODE_502": "Status message: amount not reconciled, totals provided",
    "RESULT_CODE_503": "Status message: totals for reconciliation not available",
    "RESULT_CODE_504": "Status message: not reconciled, totals provided",
    "RESULT_CODE_600": "Accepted (for administrative info)",
    "RESULT_CODE_601": "Status message: impossible to trace back original transaction",
    "RESULT_CODE_602": "Status message: invalid transaction reference number",
    "RESULT_CODE_603": "Status message: reference number/PAN incompatible",
    "RESULT_CODE_604": "Status message: POS photograph is not available",
    "RESULT_CODE_605": "Status message: requested item supplied",
    "RESULT_CODE_606": "Status message: request cannot be fulfilled - required documentation is not available",
    "RESULT_CODE_680": "List ready",
    "RESULT_CODE_681": "List not ready",
    "RESULT_CODE_700": "Accepted (for fee collection)",
    "RESULT_CODE_800": "Accepted (for network management)",
    "RESULT_CODE_900": "Advice acknowledged, no financial liability accepted",
    "RESULT_CODE_901": "Advice acknowledged, finansial liability accepted",
    "RESULT_CODE_902": "Decline reason message: invalid transaction",
    "RESULT_CODE_903": "Status message: re-enter transaction",
    "RESULT_CODE_904": "Decline reason message: format error",
    "RESULT_CODE_905": "Decline reason message: acqiurer not supported by switch",
    "RESULT_CODE_906": "Decline reason message: cutover in process",
    "RESULT_CODE_907": "Decline reason message: card issuer or switch inoperative",
    "RESULT_CODE_908": "Decline reason message: transaction destination cannot be found for routing",
    "RESULT_CODE_909": "Decline reason message: system malfunction",
    "RESULT_CODE_910": "Decline reason message: card issuer signed off",
    "RESULT_CODE_911": "Decline reason message: card issuer timed out",
    "RESULT_CODE_912": "Decline reason message: card issuer unavailable",
    "RESULT_CODE_913": "Decline reason message: duplicate transmission",
    "RESULT_CODE_914": "Decline reason message: not able to trace back to original transaction",
    "RESULT_CODE_915": "Decline reason message: reconciliation cutover or checkpoint error",
    "RESULT_CODE_916": "Decline reason message: MAC incorrect",
    "RESULT_CODE_917": "Decline reason message: MAC key sync error",
    "RESULT_CODE_918": "Decline reason message: no communication keys available for use",
    "RESULT_CODE_919": "Decline reason message: encryption key sync error",
    "RESULT_CODE_920": "Decline reason message: security software/hardware error - try again",
    "RESULT_CODE_921": "Decline reason message: security software/hardware error - no action",
    "RESULT_CODE_922": "Decline reason message: message number out of sequence",
    "RESULT_CODE_923": "Status message: request in progress",
    "RESULT_CODE_950": "Decline reason message: violation of business arrangement",
    "RESULT_CODE_XXX": "Code to be replaced by card status code or stoplist insertion reason code"
  },
  "ka": {
    "APPROVED": "დადასტურდა",
    "APPROVED_WITH_ID": "დადასტურდა ID-ით",
    "DECLINE": "უარყოფილია",
    "DECLINE_NO_FUNDS": "უარყოფა, არასაკმარისი თანხა",
    "PICK_UP": "Pick-up",
    "CALL_ACQUIRER": "დაუკავშირდი ბანკს",
    "ACCEPTED": "მიღებულია",
    "LIST_READY": "სია მზად არის",
    "LIST_NOT_READY": "სია არ არის მზად",
    "NOT_ACCEPTED": "არ მიიღება",
    "UNDEFINED": "უცნობი",
    "CODE_SHORT_MSG_FMT": "{code} — {shortMessage}",
    "RESULT_CODE_000": "Approved",
    "RESULT_CODE_001": "Approved, honour with identification",
    "RESULT_CODE_002": "Approved for partial amount",
    "RESULT_CODE_003": "Approved for VIP",
    "RESULT_CODE_004": "Approved, update track 3",
    "RESULT_CODE_005": "Approved, account type specified by card issuer",
    "RESULT_CODE_006": "Approved for partial amount, account type specified by card issuer",
    "RESULT_CODE_007": "Approved, update ICC",
    "RESULT_CODE_100": "Decline (general, no comments)",
    "RESULT_CODE_101": "Decline, expired card",
    "RESULT_CODE_102": "Decline, suspected fraud",
    "RESULT_CODE_103": "Decline, card acceptor contact acquirer",
    "RESULT_CODE_104": "Decline, restricted card",
    "RESULT_CODE_105": "Decline, card acceptor call acquirer's security department",
    "RESULT_CODE_106": "Decline, allowable PIN tries exceeded",
    "RESULT_CODE_107": "Decline, refer to card issuer",
    "RESULT_CODE_108": "Decline, refer to card issuer's special conditions",
    "RESULT_CODE_109": "Decline, invalid merchant",
    "RESULT_CODE_110": "Decline, invalid amount",
    "RESULT_CODE_111": "Decline, invalid card number",
    "RESULT_CODE_112": "Decline, PIN data required",
    "RESULT_CODE_113": "Decline, unacceptable fee",
    "RESULT_CODE_114": "Decline, no account of type requested",
    "RESULT_CODE_115": "Decline, requested function not supported",
    "RESULT_CODE_116": "Decline, not sufficient funds",
    "RESULT_CODE_117": "Decline, incorrect PIN",
    "RESULT_CODE_118": "Decline, no card record",
    "RESULT_CODE_119": "Decline, transaction not permitted to cardholder",
    "RESULT_CODE_120": "Decline, transaction not permitted to terminal",
    "RESULT_CODE_121": "Decline, exceeds withdrawal amount limit",
    "RESULT_CODE_122": "Decline, security violation",
    "RESULT_CODE_123": "Decline, exceeds withdrawal frequency limit",
    "RESULT_CODE_124": "Decline, violation of law",
    "RESULT_CODE_125": "Decline, card not effective",
    "RESULT_CODE_126": "Decline, invalid PIN block",
    "RESULT_CODE_127": "Decline, PIN length error",
    "RESULT_CODE_128": "Decline, PIN kay synch error",
    "RESULT_CODE_129": "Decline, suspected counterfeit card",
    "RESULT_CODE_180": "Decline, by cardholders wish",
    "RESULT_CODE_200": "Pick-up (general, no comments)",
    "RESULT_CODE_201": "Pick-up, expired card",
    "RESULT_CODE_202": "Pick-up, suspected fraud",
    "RESULT_CODE_203": "Pick-up, card acceptor contact card acquirer",
    "RESULT_CODE_204": "Pick-up, restricted card",
    "RESULT_CODE_205": "Pick-up, card acceptor call acquirer's security department",
    "RESULT_CODE_206": "Pick-up, allowable PIN tries exceeded",
    "RESULT_CODE_207": "Pick-up, special conditions",
    "RESULT_CODE_208": "Pick-up, lost card",
    "RESULT_CODE_209": "Pick-up, stolen card",
    "RESULT_CODE_210": "Pick-up, suspected counterfeit card",
    "RESULT_CODE_300": "Status message: file action successful",
    "RESULT_CODE_301": "Status message: file action not supported by receiver",
    "RESULT_CODE_302": "Status message: unable to locate record on file",
    "RESULT_CODE_303": "Status message: duplicate record, old record replaced",
    "RESULT_CODE_304": "Status message: file record field edit error",
    "RESULT_CODE_305": "Status message: file locked out",
    "RESULT_CODE_306": "Status message: file action not successful",
    "RESULT_CODE_307": "Status message: file data format error",
    "RESULT_CODE_308": "Status message: duplicate record, new record rejected",
    "RESULT_CODE_309": "Status message: unknown file",
    "RESULT_CODE_400": "Accepted (for reversal)",
    "RESULT_CODE_499": "Approved, no original message data",
    "RESULT_CODE_500": "Status message: reconciled, in balance",
    "RESULT_CODE_501": "Status message: reconciled, out of balance",
    "RESULT_CODE_502": "Status message: amount not reconciled, totals provided",
    "RESULT_CODE_503": "Status message: totals for reconciliation not available",
    "RESULT_CODE_504": "Status message: not reconciled, totals provided",
    "RESULT_CODE_600": "Accepted (for administrative info)",
    "RESULT_CODE_601": "Status message: impossible to trace back original transaction",
    "RESULT_CODE_602": "Status message: invalid transaction reference number",
    "RESULT_CODE_603": "Status message: reference number/PAN incompatible",
    "RESULT_CODE_604": "Status message: POS photograph is not available",
    "RESULT_CODE_605": "Status message: requested item supplied",
    "RESULT_CODE_606": "Status message: request cannot be fulfilled - required documentation is not available",
    "RESULT_CODE_680": "List ready",
    "RESULT_CODE_681": "List not ready",
    "RESULT_CODE_700": "Accepted (for fee collection)",
    "RESULT_CODE_800": "Accepted (for network management)",
    "RESULT_CODE_900": "Advice acknowledged, no financial liability accepted",
    "RESULT_CODE_901": "Advice acknowledged, finansial liability accepted",
    "RESULT_CODE_902": "Decline reason message: invalid transaction",
    "RESULT_CODE_903": "Status message: re-enter transaction",
    "RESULT_CODE_904": "Decline reason message: format error",
    "RESULT_CODE_905": "Decline reason message: acqiurer not supported by switch",
    "RESULT_CODE_906": "Decline reason message: cutover in process",
    "RESULT_CODE_907": "Decline reason message: card issuer or switch inoperative",
    "RESULT_CODE_908": "Decline reason message: transaction destination cannot be found for routing",
    "RESULT_CODE_909": "Decline reason message: system malfunction",
    "RESULT_CODE_910": "Decline reason message: card issuer signed off",
    "RESULT_CODE_911": "Decline reason message: card issuer timed out",
    "RESULT_CODE_912": "Decline reason message: card issuer unavailable",
    "RESULT_CODE_913": "Decline reason message: duplicate transmission",
    "RESULT_CODE_914": "Decline reason message: not able to trace back to original transaction",
    "RESULT_CODE_915": "Decline reason message: reconciliation cutover or checkpoint error",
    "RESULT_CODE_916": "Decline reason message: MAC incorrect",
    "RESULT_CODE_917": "Decline reason message: MAC key sync error",
    "RESULT_CODE_918": "Decline reason message: no communication keys available for use",
    "RESULT_CODE_919": "Decline reason message: encryption key sync error",
    "RESULT_CODE_920": "Decline reason message: security software/hardware error - try again",
    "RESULT_CODE_921": "Decline reason message: security software/hardware error - no action",
    "RESULT_CODE_922": "Decline reason message: message number out of sequence",
    "RESULT_CODE_923": "Status message: request in progress",
    "RESULT_CODE_950": "Decline reason message: violation of business arrangement",
    "RESULT_CODE_XXX": "Code to be replaced by card status code or stoplist insertion reason code"
  },
  "ru": {
    "APPROVED": "Одобрено",
    "APPROVED_WITH_ID": "Одобрено с идентификацией",
    "DECLINE": "Отклонено",
    "DECLINE_NO_FUNDS": "Отклонено, нет средств",
    "PICK_UP": "Pick-up",
    "CALL_ACQUIRER": "Звонок получателя",
    "ACCEPTED": "Принято",
    "LIST_READY": "Список готов",
    "LIST_NOT_READY": "Список не готов",
    "NOT_ACCEPTED": "Не принято",
    "UNDEFINED": "Не определено",
    "CODE_SHORT_MSG_FMT": "{code} — {shortMessage}",
    "RESULT_CODE_000": "Одобрено",
    "RESULT_CODE_001": "Одобрено, с идентификацией",
    "RESULT_CODE_002": "Одобрено для частичной суммы",
    "RESULT_CODE_003": "Одобрено для VIP",
    "RESULT_CODE_004": "Одобрено, обновление трека 3",
    "RESULT_CODE_005": "Одобрено, тип счета указан эмитентом карты",
    "RESULT_CODE_006": "Одобрено для частичной суммы, тип счета, указанный эмитентом карты",
    "RESULT_CODE_007": "Одобрено, обновить ICC",
    "RESULT_CODE_100": "Отклонено (общее, без комментариев)",
    "RESULT_CODE_101": "Отклонено, просроченная карта",
    "RESULT_CODE_102": "Отклонено, подозрение на мошенничество",
    "RESULT_CODE_103": "Отклонено, получатель карты связывается с эквайером",
    "RESULT_CODE_104": "Отклонено, карта с ограниченным доступом",
    "RESULT_CODE_105": "Отклонено, отдел безопасности по приему звонка получателю",
    "RESULT_CODE_106": "Отклонено, допустимые попытки PIN превышены",
    "RESULT_CODE_107": "Отклонено, обратитесь к эмитенту карты",
    "RESULT_CODE_108": "Отклонено, см. Особые условия эмитента карты",
    "RESULT_CODE_109": "Отклонено, недействительный продавец",
    "RESULT_CODE_110": "Отклонено, недействительная сумма",
    "RESULT_CODE_111": "Отклонено, неверный номер карты",
    "RESULT_CODE_112": "Отклонено, требуется PIN-код",
    "RESULT_CODE_113": "Отклонено, недопустимая плата",
    "RESULT_CODE_114": "Отклонено, аккаунт типа не запрошен",
    "RESULT_CODE_115": "Отклонено, запрошенная функция не поддерживается",
    "RESULT_CODE_116": "Отклонено, недостаточно средств",
    "RESULT_CODE_117": "Отклонено, неверный PIN-код",
    "RESULT_CODE_118": "Отклонено, нет записи карты",
    "RESULT_CODE_119": "Отклонено, транзакция не разрешена владельцу карты",
    "RESULT_CODE_120": "Отклонено, транзакция не разрешена к терминалу",
    "RESULT_CODE_121": "Отклонено, превышает лимит суммы снятия",
    "RESULT_CODE_122": "Отклонено, нарушение безопасности",
    "RESULT_CODE_123": "Отклонено, превышение предела частоты снятия",
    "RESULT_CODE_124": "Отклонено, нарушение закона",
    "RESULT_CODE_125": "Отклонено, карта не действует",
    "RESULT_CODE_126": "Отклонено, недействительный блок PIN",
    "RESULT_CODE_127": "Отклонено, ошибка длины PIN",
    "RESULT_CODE_128": "Отклонено, ошибка синхронизации PIN-кода",
    "RESULT_CODE_129": "Отклонено, подозрение на поддельную карту",
    "RESULT_CODE_180": "Отклонено по желанию держателя карты",
    "RESULT_CODE_200": "Пикап (вообще, без комментариев)",
    "RESULT_CODE_201": "Пикап, просроченная карта",
    "RESULT_CODE_202": "Пикап, подозрение на мошенничество",
    "RESULT_CODE_203": "Пикап, получатель карты, получатель карты контакта",
    "RESULT_CODE_204": "Пикап, карта с ограниченным доступом",
    "RESULT_CODE_205": "Пикап, обращение принимающего карту лица в отдел безопасности",
    "RESULT_CODE_206": "Пикап, допустимые попытки PIN превышены",
    "RESULT_CODE_207": "Пикап, особые условия",
    "RESULT_CODE_208": "Пикап, карта потерянная",
    "RESULT_CODE_209": "Пикап, карта украденная",
    "RESULT_CODE_210": "Пикап, подозревается поддельная карта",
    "RESULT_CODE_300": "Сообщение о статусе: файл успешно завершен",
    "RESULT_CODE_301": "Сообщение о статусе: действие файла не поддерживается получателем",
    "RESULT_CODE_302": "Сообщение о статусе: невозможно найти запись в файле",
    "RESULT_CODE_303": "Сообщение о статусе: повторяющаяся запись, старая запись заменена",
    "RESULT_CODE_304": "Сообщение о статусе: ошибка редактирования поля записи файла",
    "RESULT_CODE_305": "Сообщение о статусе: файл заблокирован",
    "RESULT_CODE_306": "Сообщение о статусе: действие файла не выполнено",
    "RESULT_CODE_307": "Сообщение о статусе: ошибка формата данных файла",
    "RESULT_CODE_308": "Сообщение о статусе: повторяющаяся запись, новая запись отклонена",
    "RESULT_CODE_309": "Сообщение о статусе: неизвестный файл",
    "RESULT_CODE_400": "Принято (для обращения)",
    "RESULT_CODE_499": "Одобрено, нет оригинальных данных сообщения",
    "RESULT_CODE_500": "Сообщение о статусе: согласовано, в балансе",
    "RESULT_CODE_501": "Сообщение о статусе: согласовано, несбалансировано",
    "RESULT_CODE_502": "Сообщение о статусе: сумма не сверена, итоги предоставлены",
    "RESULT_CODE_503": "Сообщение о статусе: итоги для сверки недоступны",
    "RESULT_CODE_504": "Сообщение о статусе: не согласовано, итоги предоставлены",
    "RESULT_CODE_600": "Принято (для административной информации)",
    "RESULT_CODE_601": "Сообщение о статусе: невозможно отследить исходную транзакцию",
    "RESULT_CODE_602": "Сообщение о статусе: неверный номер транзакции",
    "RESULT_CODE_603": "Сообщение о статусе: номер ссылки / PAN несовместимы",
    "RESULT_CODE_604": "Сообщение о статусе: POS фотография недоступна",
    "RESULT_CODE_605": "Сообщение о статусе: запрошенный товар предоставлен",
    "RESULT_CODE_606": "Сообщение о статусе: запрос не может быть выполнен - необходимая документация недоступна",
    "RESULT_CODE_680": "Список готов",
    "RESULT_CODE_681": "Список не готов",
    "RESULT_CODE_700": "Принято (для сбора платежей)",
    "RESULT_CODE_800": "Принято (для управления сетью)",
    "RESULT_CODE_900": "Консультация подтверждена, финансовое обязательство не принято",
    "RESULT_CODE_901": "Консультация подтверждена, финансовая ответственность принята",
    "RESULT_CODE_902": "Сообщение о причине отклонения: недействительная транзакция",
    "RESULT_CODE_903": "Сообщение о статусе: введите повторно транзакцию",
    "RESULT_CODE_904": "Сообщение о причине отклонения: ошибка формата",
    "RESULT_CODE_905": "Сообщение о причине отклонения: acqiurer не поддерживается коммутатором",
    "RESULT_CODE_906": "Сообщение о причине отклонения: переход в процессе",
    "RESULT_CODE_907": "Сообщение о причине отклонения: эмитент карты или коммутатор не работают",
    "RESULT_CODE_908": "Сообщение о причине отклонения: невозможно найти место назначения транзакции для маршрутизации",
    "RESULT_CODE_909": "Сообщение о причине отклонения: неисправность системы",
    "RESULT_CODE_910": "Сообщение о причине отклонения: эмитент карты подписан",
    "RESULT_CODE_911": "Сообщение о причине отклонения: тайм-аут эмитента карты",
    "RESULT_CODE_912": "Сообщение о причине отклонения: эмитент карты недоступен",
    "RESULT_CODE_913": "Сообщение о причине отклонения: повторная передача",
    "RESULT_CODE_914": "Сообщение о причине отклонения: невозможно отследить исходную транзакцию",
    "RESULT_CODE_915": "Сообщение о причине отклонения: ошибка при сверке или контрольная точка",
    "RESULT_CODE_916": "Сообщение о причине отклонения: MAC неверен",
    "RESULT_CODE_917": "Сообщение о причине отклонения: ошибка синхронизации MAC-ключа",
    "RESULT_CODE_918": "Сообщение о причине отклонения: нет доступных ключей связи",
    "RESULT_CODE_919": "Сообщение о причине отклонения: ошибка синхронизации ключа шифрования",
    "RESULT_CODE_920": "Сообщение о причине отклонения: ошибка ПО/устройства - повторите попытку",
    "RESULT_CODE_921": "Сообщение о причине отклонения: ошибка ПО/устройства - никаких действий",
    "RESULT_CODE_922": "Сообщение о причине отклонения: номер сообщения вне последовательности",
    "RESULT_CODE_923": "Сообщение о статусе: запрос в процессе",
    "RESULT_CODE_950": "Сообщение о причине отклонения: нарушение порядка ведения бизнеса",
    "RESULT_CODE_XXX": "Код, подлежащий замене кодом состояния карты или кодом причины вставки стоп-листа"
  },
  "uk": {
    "APPROVED": "Схвалено",
    "APPROVED_WITH_ID": "Схвалено с ідентифікацією",
    "DECLINE": "Відхилено",
    "DECLINE_NO_FUNDS": "Відхилено, немає коштів",
    "PICK_UP": "Pick-up",
    "CALL_ACQUIRER": "Дзвінок отримувача",
    "ACCEPTED": "Прийнято",
    "LIST_READY": "Список готовий",
    "LIST_NOT_READY": "Список не готовий",
    "NOT_ACCEPTED": "Не принято",
    "UNDEFINED": "Не определено",
    "CODE_SHORT_MSG_FMT": "{code} — {shortMessage}",
    "RESULT_CODE_000": "Схвалено",
    "RESULT_CODE_001": "Схвалено, с ідентифікацією",
    "RESULT_CODE_002": "Схвалено для часткової суми",
    "RESULT_CODE_003": "Схвалено для VIP",
    "RESULT_CODE_004": "Схвалено, оновлення треку 3",
    "RESULT_CODE_005": "Схвалено, тип рахунку вказан емитентом карти",
    "RESULT_CODE_006": "Схвалено для часткової суми, тип рахунку вказанный емитентом карти",
    "RESULT_CODE_007": "Схвалено, оновлення ICC",
    "RESULT_CODE_100": "Відхилено (зальний, без моментарів)",
    "RESULT_CODE_101": "Відхилено, прострочена картка",
    "RESULT_CODE_102": "Відхилено, підозра на шахрайство",
    "RESULT_CODE_103": "Відхилено, отримувач карти связався з еквайером",
    "RESULT_CODE_104": "Відхилено, картка с обмеженим доступом",
    "RESULT_CODE_105": "Відхилено, отримувач звернувся у відділом безпеки",
    "RESULT_CODE_106": "Відхилено, доступні спробі PIN перевищені",
    "RESULT_CODE_107": "Відхилено, зверніться до відавника карти",
    "RESULT_CODE_108": "Відхилено, див. Особливі умови відавника карти",
    "RESULT_CODE_109": "Відхилено, не дійсний продавець",
    "RESULT_CODE_110": "Відхилено, не дійсгна сума",
    "RESULT_CODE_111": "Відхилено, не вірний номер картки",
    "RESULT_CODE_112": "Відхилено, необхідний PIN-код",
    "RESULT_CODE_113": "Відхилено, не допустима оплата",
    "RESULT_CODE_114": "Відхилено, немає запитуємого типу акаунта",
    "RESULT_CODE_115": "Відхилено, запитана функція не підтримується",
    "RESULT_CODE_116": "Відхилено, недостатьно коштів",
    "RESULT_CODE_117": "Відхилено, невірний PIN-код",
    "RESULT_CODE_118": "Відхилено, немає запису карты",
    "RESULT_CODE_119": "Відхилено, транзація не дозволенеа власнику карти",
    "RESULT_CODE_120": "Відхилено, транзакція не дозволена терміналу",
    "RESULT_CODE_121": "Відхилено, перевищено ліміт зняття сумми",
    "RESULT_CODE_122": "Відхилено, порушення безпеки",
    "RESULT_CODE_123": "Відхилено, перевищено ліміт частоти зняття",
    "RESULT_CODE_124": "Відхилено, порушення",
    "RESULT_CODE_125": "Відхилено, картка не дійсна",
    "RESULT_CODE_126": "Відхилено, недійсний блок PIN",
    "RESULT_CODE_127": "Відхилено, опомилка довжини PIN",
    "RESULT_CODE_128": "Відхилено, помилка синхронізации PIN-коду",
    "RESULT_CODE_129": "Відхилено, підозра на підробну картку",
    "RESULT_CODE_180": "Відхилено за бажання власника карти",
    "RESULT_CODE_200": "Пикап (загальний, без коментарів)",
    "RESULT_CODE_201": "Пикап, прострочена картка",
    "RESULT_CODE_202": "Пикап, підозра на шахрайство",
    "RESULT_CODE_203": "Пикап, отримувач картки звїязався з власником",
    "RESULT_CODE_204": "Пикап, картка з обмеженим доступом",
    "RESULT_CODE_205": "Пикап, звернення приймача карти у вітділ безпеки",
    "RESULT_CODE_206": "Пикап, доступні спроби PIN вичерпано",
    "RESULT_CODE_207": "Пикап, особливі умови",
    "RESULT_CODE_208": "Пикап, картка загублена",
    "RESULT_CODE_209": "Пикап, картка вкрадена",
    "RESULT_CODE_210": "Пикап, підозра на підробну карту",
    "RESULT_CODE_300": "Повідомлення про статус: операція з файлом успішна",
    "RESULT_CODE_301": "Повідомлення про статус: операція файлу не підтримуєтся отримувачем",
    "RESULT_CODE_302": "Повідомлення про статус: неможливо знайти запис про файл",
    "RESULT_CODE_303": "Повідомлення про статус: повторній запис, старий запис замінено",
    "RESULT_CODE_304": "Повідомлення про статус: помилка редагування поля запису файла",
    "RESULT_CODE_305": "Повідомлення про статус: файл заблоковано",
    "RESULT_CODE_306": "Повідомлення про статус: операцію з файлом не виконано",
    "RESULT_CODE_307": "Повідомлення про статус: помилка формату данних файлу",
    "RESULT_CODE_308": "Повідомлення про статус: повторний запис, новий запис відхилено",
    "RESULT_CODE_309": "Повідомлення про статус: невідомий файл",
    "RESULT_CODE_400": "Принято (для звернення)",
    "RESULT_CODE_499": "Схвалено, немає оригінальных данних повідомлення",
    "RESULT_CODE_500": "Повідомлення про статус: узгоджено, у балансі",
    "RESULT_CODE_501": "Повідомлення про статус: узгоджено, не сбалансовано",
    "RESULT_CODE_502": "Повідомлення про статус: сума не звірена, ітоги надані",
    "RESULT_CODE_503": "Повідомлення про статус: ітоги для звірки недоступні",
    "RESULT_CODE_504": "Повідомлення про статус: не узгоджено, ітоги надані",
    "RESULT_CODE_600": "Принято (для адміністративноі інформації)",
    "RESULT_CODE_601": "Повідомлення про статус: не можливо відслідити початкову транзакцію",
    "RESULT_CODE_602": "Повідомлення про статус: не вірный номер транзакції",
    "RESULT_CODE_603": "Повідомлення про статус: номер посилання / PAN несумісні",
    "RESULT_CODE_604": "Повідомлення про статус: POS фотографія не доступна",
    "RESULT_CODE_605": "Повідомлення про статус: запитаний товар надано",
    "RESULT_CODE_606": "Повідомлення про статус: запит не може бути віконано - необхідна документація не доступна",
    "RESULT_CODE_680": "Список готовий",
    "RESULT_CODE_681": "Список не готовий",
    "RESULT_CODE_700": "Принято (для збору платежів)",
    "RESULT_CODE_800": "Принято (для управління мережею)",
    "RESULT_CODE_900": "Консультація підтверджена, фінансове зобов'язання не прийнято",
    "RESULT_CODE_901": "Консультація підтверджена, фінансова відповідальність прийнята",
    "RESULT_CODE_902": "Повідомлення про причини відхилення: недійсна транзакція",
    "RESULT_CODE_903": "Повідомлення про статус: введіть повторно транзакцію",
    "RESULT_CODE_904": "Повідомлення про причини відхилення: помилка формату",
    "RESULT_CODE_905": "Повідомлення про причини відхилення: acqiurer не підтримує комутатором",
    "RESULT_CODE_906": "Повідомлення про причини відхилення: перехід в процесі",
    "RESULT_CODE_907": "Повідомлення про причини відхилення: емітент карти або комутатор не працюють",
    "RESULT_CODE_908": "Повідомлення про причини відхилення: неможливо знайти місце призначення транзакції для маршрутизації",
    "RESULT_CODE_909": "Повідомлення про причини відхилення: несправність системи",
    "RESULT_CODE_910": "Повідомлення про причини відхилення: емітент карти підписаний",
    "RESULT_CODE_911": "Повідомлення про причини відхилення: тайм-аут емітента карти",
    "RESULT_CODE_912": "Повідомлення про причини відхилення: емітент карти недоступний",
    "RESULT_CODE_913": "Повідомлення про причини відхилення: повторна передача",
    "RESULT_CODE_914": "Повідомлення про причини відхилення: неможливо відстежити вихідну транзакцію",
    "RESULT_CODE_915": "Повідомлення про причини відхилення: помилка при звірці або контрольна точка",
    "RESULT_CODE_916": "Повідомлення про причини відхилення: MAC невірний",
    "RESULT_CODE_917": "Повідомлення про причини відхилення: помилка синхронізації MAC-ключа",
    "RESULT_CODE_918": "Повідомлення про причини відхилення: немає доступних ключів зв'язку",
    "RESULT_CODE_919": "Повідомлення про причини відхилення: помилка синхронізації ключа шифрування",
    "RESULT_CODE_920": "Повідомлення про причини відхилення: помилка ПО / пристрої - спробуйте ще раз",
    "RESULT_CODE_921": "Повідомлення про причини відхилення: помилка ПО / пристрої - ніяких дій",
    "RESULT_CODE_922": "Повідомлення про причини відхилення: номер повідомлення поза послідовності",
    "RESULT_CODE_923": "Повідомлення про статус: запит в процесі",
    "RESULT_CODE_950": "Повідомлення про причини відхилення: порушення порядку ведення бізнесу",
    "RESULT_CODE_XXX": "Код, що підлягає заміні кодом стану карти або кодом причини вставки стоп-листа"
  }
}
</i18n>
