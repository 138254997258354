<template>
  <div class="lead-lot-calculator">
    <div class="lead-lot-calculator__heading-row">
      <h2 class="lead-lot-calculator__heading">
        {{ $t('SETTINGS_HEADING') }}
      </h2>

      <button
        class="lead-lot-calculator__config-reset-btn"
        @click="resetFormConfig"
      >
        {{ $t('RESET_SETTINGS_BTN') }}
      </button>
    </div>

    <div class="lead-lot-calculator__config-wrp">
      <lead-lot-calculator-config
        class="lead-lot-calculator__config"
        :year.sync="formConfig.year"
        :fuel-type.sync="formConfig.fuelType"
        :engine-volume.sync="formConfig.engineVolumeL"
        :departure-location="formConfig.departureLocation"
        :arrival-location="formConfig.arrivalLocation"
        :is-locations-loading="isTransportationFeesLoading"
      />
    </div>

    <h2 class="lead-lot-calculator__heading">
      {{ $t('VEHICLE_HEADING') }}
    </h2>

    <div class="lead-lot-calculator__row">
      <lead-lot-calculator-field
        class="lead-lot-calculator__field"
        :label="$t('VEHICLE_PRICE_LBL')"
        v-model="form.vehiclePrice"
        :currency="lot.currency"
      />
      <lead-lot-calculator-field
        class="lead-lot-calculator__field"
        v-model="form.auctionFee"
        :currency="lot.currency"
        :label="$t('AUCTION_FEE_LBL')"
        :is-read-only="true"
        :is-loading="isAuctionFeeLoading"
      />
    </div>

    <h2 class="lead-lot-calculator__heading">
      {{ $t('TRANSPORTATION_HEADING') }}
    </h2>
    <div class="lead-lot-calculator__row">
      <lead-lot-calculator-field
        class="lead-lot-calculator__field"
        v-model="form.shippingPrice"
        :currency="lot.currency"
        :label="$t('SHIPPING_LBL')"
        :is-read-only="true"
        :is-loading="isTransportationFeesLoading"
      />
      <lead-lot-calculator-field
        class="lead-lot-calculator__field"
        v-model="form.containerPrice"
        :currency="lot.currency"
        :label="$t('CONTAINER_PRICE_LBL', {
          location: formattedDeparturePort
        })"
        :is-read-only="true"
        :is-loading="isTransportationFeesLoading"
      />
    </div>
    <div class="lead-lot-calculator__row">
      <lead-lot-calculator-field
        class="lead-lot-calculator__field"
        v-model="form.yourFee"
        :currency="lot.currency"
        :label="$t('YOUR_FEE_LBL')"
        :is-enabler-shown="true"
        :is-enabled.sync="form.yourFeeEnabled"
      />
      <lead-lot-calculator-field
        class="lead-lot-calculator__field"
        v-model="form.registrationFee"
        :currency="lot.currency"
        :label="$t('REGISTRATION_LBL')"
        :is-read-only="true"
        :is-enabler-shown="true"
        :is-enabled.sync="form.registrationFeeEnabled"
        :is-loading="isRegistrationFeeLoading"
      />
    </div>

    <div class="lead-lot-calculator__totals">
      <div class="lead-lot-calculator__totals-inner">
        <span class="lead-lot-calculator__totals-part">
          {{
            $t('TOTAL_VEHICLE_TXT', {
              total: $fc(totalVehicle, lot.currency, { isShorten: false })
            })
          }}
        </span>

        <span class="lead-lot-calculator__totals-part">
          {{ $t('COMMON.SYMBOLS.PLUS') }}
        </span>

        <span class="lead-lot-calculator__totals-part">
          {{
            $t('TOTAL_TRANSPORTATION_TXT', {
              total: $fc(totalTransportation, lot.currency, {
                isShorten: false,
              })
            })
          }}
        </span>

        <span class="lead-lot-calculator__totals-sum">
          {{
            $t('TOTAL_SUM_TXT', {
              total: $fc(totalVehicle + totalTransportation, lot.currency, {
                isShorten: false,
              })
            })
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LeadLotCalculatorConfig from './LeadLotCalculatorConfig'
import LeadLotCalculatorField from './LeadLotCalculatorField'
import { sdk } from 'Services/shelfNetworkSdk'
import { mapGetters } from 'vuex'
import { Lot } from 'Models/Lot'
import sum from 'lodash/sum'
import debounce from 'lodash/debounce'
import { userGetters } from 'Store/entities/User/types'
import { formatLocation } from 'Utils/formatLocation'

const ELECTRIC_REGISTRATION_ENGINE_VOLUME = 100

export default {
  name: 'lead-lots-calculator',

  components: {
    LeadLotCalculatorConfig,
    LeadLotCalculatorField,
  },

  props: {
    lot: {
      type: Lot,
      default: new Lot({}),
    },
  },

  data () {
    return {
      formConfig: {
        year: '',
        fuelType: '',
        engineVolumeL: '',
        departureLocation: { country: '', city: '' },
        arrivalLocation: { country: '', city: '' },
      },
      form: {
        vehiclePrice: '0',
        auctionFee: '0',
        shippingPrice: '0',
        containerPrice: '0',
        yourFee: '0',
        yourFeeEnabled: false,
        registrationFee: '0',
        registrationFeeEnabled: false,
      },
      isTransportationFeesLoading: false,
      isAuctionFeeLoading: false,
      isRegistrationFeeLoading: false,
      departurePort: { city: '' },
    }
  },

  computed: {
    ...mapGetters('entities/user', {
      platform: userGetters.PLATFORM,
    }),

    totalVehicle () {
      return Number(this.form.vehiclePrice) + Number(this.form.auctionFee)
    },

    totalTransportation () {
      return sum([
        this.form.shippingPrice,
        this.form.containerPrice,
        (this.form.yourFeeEnabled ? this.form.yourFee : 0),
        (this.form.registrationFeeEnabled ? this.form.registrationFee : 0),
      ].map(Number))
    },

    formattedDeparturePort () {
      return formatLocation(this.departurePort)
    },
  },

  created () {
    this.$watch(
      'formConfig.year',
      (value) => this.$emit('year-changed', value)
    )

    this.$watch(
      () => ([
        this.lot.id,
      ]),
      () => {
        this.resetFormConfig()
        this.form.vehiclePrice = Number(this.lot.price).toFixed(0)

        this.isTransportationFeesLoading = true
        this.loadTransportationFeesDebounced()
      },
      { immediate: true },
    )

    this.$watch(
      () => ([
        this.form.vehiclePrice,
      ]),
      () => {
        this.isAuctionFeeLoading = true
        this.loadAuctionFeeDebounced()
      },
      { immediate: true },
    )

    this.$watch(
      () => ([
        this.form.auctionFee,
        this.formConfig.year,
        this.formConfig.fuelType,
        this.formConfig.engineVolumeL,
      ]),
      () => {
        this.isRegistrationFeeLoading = true
        this.loadRegistrationFeeDebounced()
      },
      { immediate: true },
    )
  },

  methods: {
    resetFormConfig () {
      this.formConfig.year = String(this.lot.manufactureYear || '')
      this.formConfig.fuelType = this.lot.fuelType || ''
      this.formConfig.engineVolumeL = String(this.lot.engineVolumeL || '')
    },

    async loadTransportationFees () {
      this.isTransportationFeesLoading = true

      if (!this.lot.zip) {
        this.form.containerPrice = '0'
        this.form.shippingPrice = '0'
        this.formConfig.departureLocation = { country: '', city: '' }
        this.formConfig.arrivalLocation = { country: '', city: '' }
        this.departurePort = { city: '' }
      } else {
        const { data } = await sdk.transportation.v2.getPrices({
          filter: {
            requestingPlatform: this.platform.id,
            lotPlatform: this.lot.platformId,
            currency: this.lot.currency,
            zip: this.lot.zip,
            state: this.lot.region || undefined,
            subLot: this.lot.isSubLot || undefined,
          },
        })
        this.form.containerPrice = Number(data.containerPrice).toFixed(0)
        this.form.shippingPrice = Number(data.amount - data.containerPrice)
          .toFixed(0)
        this.departurePort = { city: data.port }
        this.formConfig.departureLocation = {
          country: this.lot.country,
          city: this.lot.city,
          zip: this.lot.zip
        }
        this.formConfig.arrivalLocation = {
          country: this.platform.countryAlpha3,
          city: data.destinationPort,
        }
      }

      this.isTransportationFeesLoading = false
    },

    async loadAuctionFee () {
      this.isAuctionFeeLoading = true

      const { data: fee } = await sdk.feeCalculator.getPurchaseFee({
        filter: {
          price: this.form.vehiclePrice,
          priceCurrency: this.lot.currency,
          platform: this.lot.platformId,
          feeCurrency: this.lot.currency
        }
      })
      this.form.auctionFee = Number(fee.amount).toFixed(0)

      this.isAuctionFeeLoading = false
    },

    async loadRegistrationFee () {
      this.isRegistrationFeeLoading = true

      let registrationFee
      if (this.lot.isRegistered) {
        registrationFee = 0
      } else if (this.platform.countryCode === 'ua') {
        const totalPrice = Number(this.form.vehiclePrice) +
          Number(this.form.auctionFee)

        if (!totalPrice) {
          registrationFee = 0
        } else {
          const { data: fees } = await sdk.feeCalculator.getRegistrationFeeUa({
            filter: {
              engineVolumeCm: this.formConfig.fuelType === 'ELECTRIC'
                ? ELECTRIC_REGISTRATION_ENGINE_VOLUME
                : Number(this.formConfig.engineVolumeL) * 1000,
              productionYear: Number(this.formConfig.year),
              fuelType: this.formConfig.fuelType,
              price: totalPrice,
              priceCurrency: this.lot.currency,
              feeCurrency: this.lot.currency
            }
          })

          registrationFee = sum([
            fees.excise,
            fees.importDuty,
            fees.vat,
            fees.pensionFund,
          ].map(Number))
        }
      } else {
        const { data: fee } = await sdk.feeCalculator.getRegistrationFeeGe({
          filter: {
            productionYear: Number(this.formConfig.year),
            engineVolumeCm: Number(this.formConfig.engineVolumeL) * 1000,
            wheelPosition: this.lot.wheelPosition,
            fuelType: this.formConfig.fuelType
          }
        })

        registrationFee =
          this.$cc(fee.amount, fee.currency, { to: this.lot.currency })
      }

      this.form.registrationFee = Number(registrationFee).toFixed(0)

      this.isRegistrationFeeLoading = false
    },

    loadTransportationFeesDebounced: debounce(function () {
      return this.loadTransportationFees()
    }, 1000),

    loadAuctionFeeDebounced: debounce(function () {
      return this.loadAuctionFee()
    }, 1000),

    loadRegistrationFeeDebounced: debounce(function () {
      return this.loadRegistrationFee()
    }, 1000),
  },
}
</script>

<style lang="scss" scoped>
.lead-lot-calculator {
  &__heading-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    font-size: 1.285714em; // 18px when font-size: 14px
    line-height: 1.5;
    font-weight: 600;

    &:not(:first-child) {
      margin-top: 3.333333em; // 60px when font-size: 18px;
    }
  }

  &__config-reset-btn {
    background: none;
    white-space: nowrap;
    border: none;
    color: $color-sys-warning;
    display: inline-block;
    width: auto;
    padding: 0 0.5em;
    line-height: 1.5;
  }

  &__config {
    margin-top: 1.4em;
  }

  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr 1fr;
    grid-gap: 2.85em;
    margin-top: 2.35em;

    & + & {
      margin-top: 3.4em;
    }

    @include respond-below(sm) {
      grid-auto-flow: row;
      grid-auto-columns: unset;
      grid-auto-rows: 1fr 1fr;
      grid-gap: 3.4em;
    }
  }

  &__totals {
    margin-top: 2.85em;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 1.5em 2em;
    background: $color-light-grey;
    border-radius: 1em;

    @include scrollbar-invisible();

    &-inner {
      display: flex;
      align-items: baseline;
    }

    &-part,
    &-sum {
      line-height: 1.5;
      display: inline-block;
      white-space: nowrap;
    }

    &-part {
      margin-right: 2.85em;
    }

    &-sum {
      font-size: 1.285714em; // 18px when font-size: 14px;
      font-weight: 600;
      margin-left: auto;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SETTINGS_HEADING": "Calculator Fields",
    "RESET_SETTINGS_BTN": "Reset fields",
    "VEHICLE_HEADING": "Vehicle",
    "TRANSPORTATION_HEADING": "Transportation",
    "VEHICLE_PRICE_LBL": "Vehicle Price",
    "AUCTION_FEE_LBL": "Auction Fee",
    "SHIPPING_LBL": "Shipping",
    "CONTAINER_PRICE_LBL": "Container Price ({location})",
    "YOUR_FEE_LBL": "Your Fee",
    "REGISTRATION_LBL": "Registration",
    "TOTAL_VEHICLE_TXT": "Vehicle - {total}",
    "TOTAL_TRANSPORTATION_TXT": "Transportation - {total}",
    "TOTAL_SUM_TXT": "Total Price - {total}"
  },
  "ka": {
    "SETTINGS_HEADING": "კალკულატორი",
    "RESET_SETTINGS_BTN": "გასუფთავება",
    "VEHICLE_HEADING": "ავტომობილი",
    "TRANSPORTATION_HEADING": "ტრანსპორტირება",
    "VEHICLE_PRICE_LBL": "ავტომობილის ფასი",
    "AUCTION_FEE_LBL": "აუქციონის მოსაკრებელი",
    "SHIPPING_LBL": "შიდა",
    "CONTAINER_PRICE_LBL": "კონტეინერი",
    "YOUR_FEE_LBL": "საკომისიო",
    "REGISTRATION_LBL": "განბაჟება",
    "TOTAL_VEHICLE_TXT": "ავტომობილი {total}",
    "TOTAL_TRANSPORTATION_TXT": "ტრანსპორტირება - {total}",
    "TOTAL_SUM_TXT": "სრული ფასი - {total}"
  },
  "ru": {
    "SETTINGS_HEADING": "Поля калькулятора",
    "RESET_SETTINGS_BTN": "Очистить поля",
    "VEHICLE_HEADING": "Транспортное средство",
    "TRANSPORTATION_HEADING": "Транспортировка",
    "VEHICLE_PRICE_LBL": "Стоимость",
    "AUCTION_FEE_LBL": "Аукционный сбор",
    "SHIPPING_LBL": "Перевозка",
    "CONTAINER_PRICE_LBL": "Цена контейнера ({location})",
    "YOUR_FEE_LBL": "Ваша комиссия",
    "REGISTRATION_LBL": "Регистрация",
    "TOTAL_VEHICLE_TXT": "Транспортное средство - {total}",
    "TOTAL_TRANSPORTATION_TXT": "Транспортировка - {total}",
    "TOTAL_SUM_TXT": "Итоговая цена - {total}"
  },
  "uk": {
    "SETTINGS_HEADING": "Поля калькулятора",
    "RESET_SETTINGS_BTN": "Очистити поля",
    "VEHICLE_HEADING": "Транспортний засіб",
    "TRANSPORTATION_HEADING": "Транспортування",
    "VEHICLE_PRICE_LBL": "Вартість",
    "AUCTION_FEE_LBL": "Аукціонний збір",
    "SHIPPING_LBL": "Перевезення",
    "CONTAINER_PRICE_LBL": "Ціна контейнера ({location})",
    "YOUR_FEE_LBL": "Ваша комісія",
    "REGISTRATION_LBL": "Реєстрація",
    "TOTAL_VEHICLE_TXT": "Транспортний засіб - {total}",
    "TOTAL_TRANSPORTATION_TXT": "Транспортування - {total}",
    "TOTAL_SUM_TXT": "Кінцева ціна - {total}"
  }
}
</i18n>
