// example:
// "LOTS": "нет лотов | {count} лот | {count} лота | {count} лотов',
// where:
// 0 | 1, 21, 31 ... | 2, 3, 4, 22, 23, 34 ... | 11, 12, 13, 55, 67 ...
export function slavicPluralization (choice, choicesLength) {
  if (choice === 0) {
    return 0
  }

  const isTeen = choice > 10 && choice < 20

  if (!isTeen && choice % 10 === 1) {
    return 1
  }

  if (!isTeen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return (choicesLength < 4) ? 2 : 3
}
