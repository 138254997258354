<template>
  <div class="admin-panel">
    <div class="admin-panel__container admin-panel__content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'admin-panel',

  metaInfo () {
    return {
      title: this.$t('COMMON.APP_TITLE')
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-panel {
  &__container {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__preloader {
    height: calc(100vh - 6em);
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tabs {
    display: flex;
    margin-top: 60px;
  }

  &__tab {
    margin-right: 60px;

    &:last-child {
      margin: 0;
    }
  }

  &__lots {
    display: flex;
  }

  &__stub {
    color: #8a8c8e;
    font-size: 62px;
    font-variant: all-small-caps;
    text-align: center;
    margin-top: 100px;
    opacity: 0.5;
  }

  /deep/ .search-lots {
    width: 65%;
    margin: -10px 0 25px;

    .search {
      width: 100%;
    }
  }
}
</style>
