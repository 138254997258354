import localeIconEn from 'Assets/icons/lang-en.svg'
import localeIconGe from 'Assets/icons/lang-ge.svg'
import localeIconRu from 'Assets/icons/lang-ru.svg'
import localeIconUk from 'Assets/icons/lang-uk.svg'

import pick from 'lodash/pick'
import { config } from '@/config'

export const LOCALES = {
  en: {
    text: 'ENG',
    isoCode: 'en',
    icon: localeIconEn
  },
  ru: {
    text: 'RUS',
    isoCode: 'ru',
    icon: localeIconRu
  },
  uk: {
    text: 'UKR',
    isoCode: 'uk',
    icon: localeIconUk
  },
  ka: {
    text: 'GEO',
    isoCode: 'ka',
    icon: localeIconGe
  }
}

export const SUPPORTED_LOCALES = config.SUPPORTED_LOCALES.split(',')
export const AVAILABLE_LOCALES = Object.freeze(pick(LOCALES, SUPPORTED_LOCALES))
export const DEFAULT_LOCALE = config.DEFAULT_LOCALE
