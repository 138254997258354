export const currencySymbols = Object.freeze({
  'GEL': '₾',
  'USD': '$',
  'EUR': '€',
  'UAH': '₴',
  'CAD': 'CA$'
})

export function mapCurrencySymbol (currencyCode) {
  let symbol = currencySymbols[currencyCode]

  return symbol || currencyCode
}
