<template>
  <list-params
    class="leads-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <list-params-sort
        class="leads-list-params__row"
        v-model="form.sort"
        :options="sortOptions"
        :default-value="defaultSort"
      />

      <list-params-channel
        class="leads-list-params__row"
        v-model="form.channel"
      />

      <list-params-broker
        class="leads-list-params__row"
        v-model="form.broker"
      />

      <list-params-location
        class="leads-list-params__row"
        v-model="form.location"
      />

      <list-params-flags
        class="leads-list-params__row"
        :value="{
          isVerified: {
            label: $t('FLAG_VERIFIED_LBL'),
            value: form.isVerified,
          },
          isUnverified: {
            label: $t('FLAG_UNVERIFIED_LBL'),
            value: form.isUnverified,
          },
          isPrioritized: {
            label: $t('FLAG_PRIORITIZED_LBL'),
            value: form.isPrioritized,
          },
          isFinanced: {
            label: $t('FLAG_FINANCED_LBL'),
            value: form.isFinanced,
          },
          isProCandidate: {
            label: $t('FLAG_INTERESGTED_IN_PRO_LBL'),
            value: form.isProCandidate,
          },
        }"
        @input="form[$event.key] = $event.value"
      />
    </template>
  </list-params>
</template>

<script>
import {
  LEADS_URL_PARAM_TABS,
  LEADS_QUERY_SORTS,
  LEADS_URL_PARAM_TAB_DEFAULT_SORT,
} from 'Constants/leadUrlParams'
import {
  ListParams,
  ListParamsSort,
  ListParamsChannel,
  ListParamsBroker,
  ListParamsLocation,
  ListParamsFlags,
} from 'Common/ListParams'

export default {
  name: 'leads-list-params',
  components: {
    ListParams,
    ListParamsSort,
    ListParamsChannel,
    ListParamsBroker,
    ListParamsLocation,
    ListParamsFlags,
  },

  props: {
    value: {
      type: Object,
      required: true,
      validator (value) {
        return typeof value.sort === 'string' &&
          typeof value.channel === 'string' &&
          typeof value.broker === 'string' &&
          typeof value.location === 'string' &&
          typeof value.isVerified === 'boolean' &&
          typeof value.isUnverified === 'boolean' &&
          typeof value.isPrioritized === 'boolean' &&
          typeof value.isFinanced === 'boolean' &&
          typeof value.isProCandidate === 'boolean'
      },
    },
  },

  data () {
    return {
      form: {
        sort: '',
        channel: '',
        broker: '',
        location: '',
        isVerified: false,
        isUnverified: false,
        isPrioritized: false,
        isFinanced: false,
        isProCandidate: false,
      },
    }
  },

  computed: {
    sortOptions () {
      return [
        {
          value: LEADS_QUERY_SORTS.supportRequestCreatedAtDesc,
          label: this.$t('SORT_RECENT_FIRST_OPT'),
        },
        {
          value: LEADS_QUERY_SORTS.supportRequestCreatedAt,
          label: this.$t('SORT_PAST_FIRST_OPT'),
        },
        {
          value: LEADS_QUERY_SORTS.priorityDesc,
          label: this.$t('SORT_PRIORITIZED_FIRST_OPT'),
        },
        {
          value: LEADS_QUERY_SORTS.priority,
          label: this.$t('SORT_UNPRIORITIZED_FIRST_OPT'),
        },
        ...(
          this.$route.params.tab === LEADS_URL_PARAM_TABS.hold ||
            this.$route.params.tab === LEADS_URL_PARAM_TABS.looking
            ? [
              {
                value: LEADS_QUERY_SORTS.dueDate,
                label: this.$t('SORT_DUE_SOON_FIRST_OPT'),
              },
              {
                value: LEADS_QUERY_SORTS.dueDateDesc,
                label: this.$t('SORT_DUE_SOON_LAST_OPT'),
              },
            ]
            : []
        ),
        ...(
          this.$route.params.tab === LEADS_URL_PARAM_TABS.offered
            ? [
              {
                value: LEADS_QUERY_SORTS.lastOfferSentAtDesc,
                label: this.$t('SORT_OFFERED_RECENTLY_FIRST_OPT'),
              },
              {
                value: LEADS_QUERY_SORTS.lastOfferSentAt,
                label: this.$t('SORT_OFFERED_RECENTLY_LAST_OPT'),
              },
            ]
            : []
        )
      ]
    },

    defaultSort () {
      return LEADS_URL_PARAM_TAB_DEFAULT_SORT[this.$route.params.tab]
    },
  },
}
</script>

<i18n>
{
  "en": {
    "SORT_RECENT_FIRST_OPT": "Recent requests first",
    "SORT_PAST_FIRST_OPT": "Past requests first",
    "SORT_PRIORITIZED_FIRST_OPT": "Prioritized first",
    "SORT_UNPRIORITIZED_FIRST_OPT": "Unprioritized first",
    "SORT_DUE_SOON_FIRST_OPT": "Due soon first",
    "SORT_DUE_SOON_LAST_OPT": "Due soon last",
    "SORT_OFFERED_RECENTLY_FIRST_OPT": "Offered recently first",
    "SORT_OFFERED_RECENTLY_LAST_OPT": "Offered recently last",
    "FLAG_VERIFIED_LBL": "Verified",
    "FLAG_UNVERIFIED_LBL": "Unverified",
    "FLAG_PRIORITIZED_LBL": "Prioritized",
    "FLAG_FINANCED_LBL": "Financed",
    "FLAG_INTERESGTED_IN_PRO_LBL": "Pro candidate"
  },
  "ka": {
    "SORT_RECENT_FIRST_OPT": "ბოლო მომართვები",
    "SORT_PAST_FIRST_OPT": "ადრინდელი მომართვები",
    "SORT_PRIORITIZED_FIRST_OPT": "პრიორიტეტში პირველი",
    "SORT_UNPRIORITIZED_FIRST_OPT": "პრიორიტეტში ბოლო",
    "SORT_DUE_SOON_FIRST_OPT": "კალენდრის მიხედვით მზარდი",
    "SORT_DUE_SOON_LAST_OPT": "კალენდრის მიხედვით კლებადი",
    "SORT_OFFERED_RECENTLY_FIRST_OPT": "ბოლოს გაგზავნილი",
    "SORT_OFFERED_RECENTLY_LAST_OPT": "ადრე გაგზავნილი",
    "FLAG_VERIFIED_LBL": "მომხმარებელი",
    "FLAG_UNVERIFIED_LBL": "უცნობი",
    "FLAG_PRIORITIZED_LBL": "პრიორიტეტი",
    "FLAG_FINANCED_LBL": "დაფინანსება",
    "FLAG_INTERESGTED_IN_PRO_LBL": "Pro კანდიდატი"
  },
  "ru": {
    "SORT_RECENT_FIRST_OPT": "Сначала новые запросы",
    "SORT_PAST_FIRST_OPT": "Сначала старые запросы",
    "SORT_PRIORITIZED_FIRST_OPT": "Сначала приоритетные",
    "SORT_UNPRIORITIZED_FIRST_OPT": "Сначала неприоритетные",
    "SORT_DUE_SOON_FIRST_OPT": "По дате (от новых к старым)",
    "SORT_DUE_SOON_LAST_OPT": "По дате (от старых к новым)",
    "SORT_OFFERED_RECENTLY_FIRST_OPT": "По отправленным предложениям (от новых к старым)",
    "SORT_OFFERED_RECENTLY_LAST_OPT": "По отправленным предложениям (от старых к новым)",
    "FLAG_VERIFIED_LBL": "Пользователи",
    "FLAG_UNVERIFIED_LBL": "Гости",
    "FLAG_PRIORITIZED_LBL": "Приоритетные",
    "FLAG_FINANCED_LBL": "Финансированы",
    "FLAG_INTERESGTED_IN_PRO_LBL": "Pro кандидат"
  },
  "uk": {
    "SORT_RECENT_FIRST_OPT": "Спочатку нові запити",
    "SORT_PAST_FIRST_OPT": "Спочатку старі запити",
    "SORT_PRIORITIZED_FIRST_OPT": "Спочатку пріоритетні",
    "SORT_UNPRIORITIZED_FIRST_OPT": "Спочатку непріоритетні",
    "SORT_DUE_SOON_FIRST_OPT": "За датою (від нових до старих)",
    "SORT_DUE_SOON_LAST_OPT": "За датою (від старих до нових)",
    "SORT_OFFERED_RECENTLY_FIRST_OPT": "За відправленими пропозиціями (від нових до старих)",
    "SORT_OFFERED_RECENTLY_LAST_OPT": "За відправленими пропозиціями (від старих до нових)",
    "FLAG_VERIFIED_LBL": "Користувачі",
    "FLAG_UNVERIFIED_LBL": "Гості",
    "FLAG_PRIORITIZED_LBL": "Пріоритетні",
    "FLAG_FINANCED_LBL": "Фінансовані",
    "FLAG_INTERESGTED_IN_PRO_LBL": "Pro кандидат"
  }
}
</i18n>
