
import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import MyTradesSkeleton from './components/MyTradesSkeleton'

const moduleName = 'my-trades'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: MyTradesSkeleton,
  importComponent: () => import('./MyTrades'),
  async createDependencies () {
    const { default: myTrades } = await import('./store')
    store.registerModule(['ui', moduleName], myTrades)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
