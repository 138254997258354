import Vue from 'vue'
import ConfirmDialog from 'Common/ConfirmDialog'

import { i18n } from 'I18n/instance'

/**
 * Renders a confirmation dialog and returns a Promise, that will
 * resolve a true/false value once user decides which action to choose.
 *
 * @param message - the message to be rendered to end user. Will be rendered as
 *                  is, so you should pass already translated messages.
 *
 * @returns {Promise<bool>}
 *
 */
export function confirmAction (message) {
  // eslint-disable-next-line promise/avoid-new
  return new Promise(resolve => {
    const Dialog = Vue.extend(ConfirmDialog)
    const dialog = new Dialog({ propsData: { message } })

    // vue-i18n plugin sets "$i18n.locale" value of component based on it's
    // parent. But because out "dialog" component hasn't any parent, it's locale
    // automatically will be set to the default value. That's why we have to
    // override it:
    dialog.$i18n.setLocale(i18n.locale)

    dialog.$once('confirm', () => resolve(true) || dialog.$destroy())
    dialog.$once('cancel', () => resolve(false) || dialog.$destroy())
    dialog.$mount()
  })
}
