<template>
  <ui-dropdown
    ref="dropdown"
    class="bell"
    bubble
  >
    <ui-button
      class="bell__btn"
      :class="{ 'bell__btn_has-notifications': hasNotifications }"
      fill="none"
    >
      <ui-icon
        class="bell__btn-ico"
        icon="bell-fill"
      />
    </ui-button>

    <template #content>
      <bell-dropdown @redirected="closeDropdown()" />
    </template>
  </ui-dropdown>
</template>

<script>
import { UiButton, UiIcon, UiDropdown } from '@shelf.network/ui-kit'
import BellDropdown from './BellDropdown'
import { mapGetters } from 'vuex'
import { bellGetters } from 'Store/entities/Bell/types'

export default {
  name: 'bell',

  components: {
    UiButton,
    UiIcon,
    UiDropdown,
    BellDropdown
  },

  computed: {
    ...mapGetters('entities/bell', {
      urgentCount: bellGetters.URGENT_UNREAD_COUNT,
      infoCount: bellGetters.INFO_UNREAD_COUNT,
      extensionCount: bellGetters.EXTENSION_UNREAD_COUNT,
    }),

    hasNotifications () {
      return this.urgentCount + this.infoCount + this.extensionCount > 0
    },
  },

  methods: {
    closeDropdown () {
      this.$refs.dropdown.closeDropdown()
    },
  },
}
</script>

<style lang="scss" scoped>
.bell {
  &__btn {
    line-height: unset;

    &-ico {
      color: $color-ui-secondary;
      display: block;
      font-size: 1.25em;
    }

    &_has-notifications::after {
      background: $color-ui-primary;
      height: 0.5em;
      width: 0.5em;
      border-radius: 100%;
      content: "";
      position: absolute;
      right: 0.3em;
      top: 0.3em;
    }

    &_has-notifications &-ico {
      color: $color-ui-default;
      animation: belly-jiggle linear 3s infinite alternate;
      transform-origin: 50% 25%;
    }

    /deep/ .ui-button__button {
      padding: 0.5em;
    }
  }

  @include respond-below(xs) {
    /deep/ .ui-dropdown__content {
      position: fixed;
      top: 50px;
      transform: none;
      left: 0;
      right: 0;
    }

    /deep/ .ui-dropdown__content_has-bubble-pick::before {
      left: unset;
      right: 5.85em;
    }
  }
}

@keyframes belly-jiggle {
  0% {
    transform: rotateZ(0deg);
  }

  2% {
    transform: rotateZ(-10deg);
  }

  4% {
    transform: rotateZ(10deg);
  }

  6% {
    transform: rotateZ(-10deg);
  }

  8% {
    transform: rotateZ(10deg);
  }

  10% {
    transform: rotateZ(-10deg);
  }

  12% {
    transform: rotateZ(10deg);
  }

  14% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
}
</style>
