import { bellActions, bellMutations, bellGetters } from './types'

import {
  URGENT_TOPICS,
  INFO_TOPICS,
  EXTENSION_TOPICS
} from 'Constants/notificationTopics'
import { NOTIFICATIONS_URL_PARAM_TABS } from 'Constants/notificationsUrlParams'

import { sdk } from 'Services/shelfNetworkSdk'
import {
  notificationsClient,
  NOTIFICATIONS_CLIENT_EVENTS
} from 'Services/notificationsClient'

import { showInfo } from 'Utils/notifications'
import { Notification } from 'Models/Notification'

import { can } from 'Utils/userHelpers'
import { USER_CLAIMS } from 'Constants/userClaims'

const BASE_GET_PARAMS = {
  'page[order]': 'desc',
  'page[limit]': 3,
  'filter[is_read]': false,
}

const URGENT_GET_PARAMS = {
  ...BASE_GET_PARAMS,
  'filter[topic]': URGENT_TOPICS.join(),
}

const INFO_GET_PARAMS = {
  ...BASE_GET_PARAMS,
  'filter[topic]': INFO_TOPICS.join(),
}

const EXTENSION_GET_PARAMS = {
  ...BASE_GET_PARAMS,
  'filter[topic]': EXTENSION_TOPICS.join(),
}

export default {
  async [bellActions.LOAD_NOTIFICATIONS] ({ commit }) {
    const [
      { data: urgent },
      { data: info },
      { data: extension }
    ] = await Promise.all([
      sdk.ringer.getAll(URGENT_GET_PARAMS),
      sdk.ringer.getAll(INFO_GET_PARAMS),
      sdk.ringer.getAll(EXTENSION_GET_PARAMS),
    ])

    commit(bellMutations.SET_URGENT_NOTIFICATIONS, urgent)
    commit(bellMutations.SET_INFO_NOTIFICATIONS, info)
    commit(bellMutations.SET_EXTENSION_NOTIFICATIONS, extension)
  },

  async [bellActions.LOAD_UNREAD_COUNT] ({ commit }) {
    const { data } = await sdk.ringer.getTopicsAttributes()

    const urgentCount = data
      .filter(el => URGENT_TOPICS.includes(Number(el.id)))
      .reduce((acc, cur) => acc + cur.unreadCount, 0)
    const infoCount = data
      .filter(el => INFO_TOPICS.includes(Number(el.id)))
      .reduce((acc, cur) => acc + cur.unreadCount, 0)
    const extensionCount = data
      .filter(el => EXTENSION_TOPICS.includes(Number(el.id)))
      .reduce((acc, cur) => acc + cur.unreadCount, 0)

    commit(bellMutations.SET_URGENT_UNREAD_COUNT, urgentCount)
    commit(bellMutations.SET_INFO_UNREAD_COUNT, infoCount)
    commit(bellMutations.SET_EXTENSION_UNREAD_COUNT, extensionCount)
  },

  async [bellActions.MARK_READ] ({ dispatch }, payload = {}) {
    const { notificationIds = [], topics = [] } = payload
    if (notificationIds.length) {
      await sdk.ringer.markAsSeenBulk(notificationIds)
      return
    }

    if (topics.length) {
      await Promise.all(topics.map(id => sdk.ringer.markAsSeenTopic(id)))
    }

    await Promise.all([
      dispatch(bellActions.LOAD_UNREAD_COUNT),
      dispatch(bellActions.LOAD_NOTIFICATIONS)
    ])
  },

  async [bellActions.CONNECT_CLIENT] ({ dispatch }) {
    notificationsClient.on(NOTIFICATIONS_CLIENT_EVENTS.NOTIFICATION, data => {
      const notification = new Notification(data)

      const isUrgent = can(USER_CLAIMS.NOTIFICATIONS) &&
        URGENT_TOPICS.includes(notification.topic)
      const isInfo = can(USER_CLAIMS.NOTIFICATIONS) &&
        INFO_TOPICS.includes(notification.topic)
      const isExtension = can(USER_CLAIMS.EXTENSION_NOTIFICATIONS) &&
        EXTENSION_TOPICS.includes(notification.topic)

      if (!isUrgent && !isInfo && !isExtension) return

      if (isUrgent) {
        dispatch(bellActions.UPDATE_URGENT_NOTIFICATION, data)
      } else if (isInfo) {
        dispatch(bellActions.UPDATE_INFO_NOTIFICATION, data)
      } else {
        dispatch(bellActions.UPDATE_EXTENSION_NOTIFICATION, data)
      }

      if (notification.isRead) return

      const tab = isUrgent
        ? NOTIFICATIONS_URL_PARAM_TABS.urgent
        : NOTIFICATIONS_URL_PARAM_TABS.info
      const route = isExtension
        ? '/extension/copart/notifications'
        : `/notifications/${tab}`

      showInfo({ text: notification.translate(), route })
    })

    await Promise.all([
      dispatch(bellActions.LOAD_UNREAD_COUNT),
      dispatch(bellActions.LOAD_NOTIFICATIONS)
    ])

    notificationsClient.connect(sdk.token.rawJWT)
  },

  [bellActions.UPDATE_URGENT_NOTIFICATION] ({ commit, getters }, data) {
    const notification = new Notification(data)
    const unreadCount = getters[bellGetters.URGENT_UNREAD_COUNT]

    if (!notification.isRead) {
      commit(bellMutations.UNSHIFT_URGENT_NOTIFICATIONS, data)
      commit(bellMutations.SET_URGENT_UNREAD_COUNT, unreadCount + 1)
      return
    }

    const found = getters[bellGetters.URGENT_NOTIFICATIONS]
      .find(item => item.id === data.id)
    if (!found) return

    commit(bellMutations.REMOVE_URGENT_NOTIFICATION, found.id)
    commit(bellMutations.SET_URGENT_UNREAD_COUNT, unreadCount - 1)
  },

  [bellActions.UPDATE_INFO_NOTIFICATION] ({ commit, getters }, data) {
    const notification = new Notification(data)
    const unreadCount = getters[bellGetters.INFO_UNREAD_COUNT]

    if (!notification.isRead) {
      commit(bellMutations.UNSHIFT_INFO_NOTIFICATIONS, data)
      commit(bellMutations.SET_INFO_UNREAD_COUNT, unreadCount + 1)
      return
    }

    const found = getters[bellGetters.INFO_NOTIFICATIONS]
      .find(item => item.id === data.id)
    if (!found) return

    commit(bellMutations.REMOVE_INFO_NOTIFICATION, found.id)
    commit(bellMutations.SET_INFO_UNREAD_COUNT, unreadCount - 1)
  },

  [bellActions.UPDATE_EXTENSION_NOTIFICATION] ({ commit, getters }, data) {
    const notification = new Notification(data)
    const unreadCount = getters[bellGetters.EXTENSION_UNREAD_COUNT]

    if (!notification.isRead) {
      commit(bellMutations.UNSHIFT_EXTENSION_NOTIFICATIONS, data)
      commit(bellMutations.SET_EXTENSION_UNREAD_COUNT, unreadCount + 1)
      return
    }

    const found = getters[bellGetters.EXTENSION_NOTIFICATIONS]
      .find(item => item.id === data.id)
    if (!found) return

    commit(bellMutations.REMOVE_EXTESION_NOTIFICATION, found.id)
    commit(bellMutations.SET_EXTENSION_UNREAD_COUNT, unreadCount - 1)
  },

  [bellActions.DISCONNECT_CLIENT] (_) {
    notificationsClient.off(NOTIFICATIONS_CLIENT_EVENTS.NOTIFICATION)
    notificationsClient.disconnect()
  },
}
