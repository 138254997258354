import { config } from '@/config'
import { userGetters } from 'Store/entities/User/types'
import { exRatesGetters } from 'Store/entities/ExRates/types'

export function convertMoney ({ rates, from, to, amount }) {
  if (isNaN(amount) || !from || from === to) {
    return Number(amount)
  }

  const fromXBase = rates[from]
  const toXBase = rates[to]

  const quotient = toXBase / fromXBase

  return amount * quotient
}

export function createConvertMoneyShortcut ({ store }) {
  return (value, from, params = {}) => {
    const to = params.to ||
      store.getters[`entities/user/${userGetters.PROFILE}`].currency ||
      config.DEFAULT_CURRENCY
    const rates =
      store.getters[`entities/exRates/${exRatesGetters.EXCHANGE_RATES}`]
    return convertMoney({ amount: value, from, to, rates })
  }
}
