import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import ArchiveSkeleton from './components/skeletons/ArchiveSkeleton'

const moduleName = 'archive'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: ArchiveSkeleton,
  importComponent: () => import('./Archive'),
  async createDependencies () {
    const { default: archive } = await import('./store')
    store.registerModule(['ui', moduleName], archive)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
