<template>
  <div class="list-params-location list-params-row">
    <label class="list-params-row__label">
      {{ label || $t('LOCATION_LBL') }}
    </label>

    <select-location
      class="list-params-row__field"
      look="secondary"
      :value="value || defaultValue"
      :placeholder="placeholder"
      :is-disabled="isDisabled"
      @input="onInput"
    />
  </div>
</template>

<script>
import SelectLocation from 'Common/SelectLocation'

export default {
  name: 'list-params-location',
  components: { SelectLocation },

  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    defaultValue: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
  },

  methods: {
    onInput (value) {
      this.$emit('input', value === this.defaultValue ? '' : value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./styles/list-params-row.scss";
</style>

<i18n>
{
  "en": {
    "LOCATION_LBL": "BY LOCATION",
    "SEARCH_MSG": "Search location",
    "LOADING_MSG": "Loading…",
    "NO_LIST_MSG": "No locations matching the search"
  },
  "ka": {
    "LOCATION_LBL": "ᲐᲓᲒᲘᲚᲛᲓᲔᲑᲐᲠᲔᲝᲑᲐ",
    "SEARCH_MSG": "ლოკაციის ძებნა",
    "LOADING_MSG": "იტვირთება…",
    "NO_LIST_MSG": "არ მოიძებნა"
  },
  "ru": {
    "LOCATION_LBL": "ПО МЕСТОНАХОЖДЕНИЮ",
    "SEARCH_MSG": "Поиск местонахождения",
    "LOADING_MSG": "Загрузка…",
    "NO_LIST_MSG": "Мест соответствующих поисковому запросу не найдено"
  },
  "uk": {
    "LOCATION_LBL": "ЗА МІСЦЕМ РОЗТАШУВАННЯ",
    "SEARCH_MSG": "Пошук місця розташування",
    "LOADING_MSG": "Завантаження…",
    "NO_LIST_MSG": "Місць відповідних пошуковому запиту не знайдено"
  }
}
</i18n>
