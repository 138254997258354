export const COPART_WON_URL_PARAM_TABS = Object.freeze({
  notPaid: 'notPaid',
  won: 'won',
  openItems: 'openItems',
  history: 'history',
})

export const IMPACT_WON_URL_PARAM_TABS = Object.freeze({
  invoices: 'invoices',
  pickups: 'pickups',
})
