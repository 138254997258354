<template>
  <div class="search-skeleton">
    <page-subnav class="search-skeleton__subnav" />

    <div class="search-skeleton__list">
      <search-item-skeleton
        v-for="i in 6"
        :key="i"
      />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import SearchItemSkeleton from './SearchItemSkeleton'

export default {
  name: 'search-skeleton',
  components: {
    PageSubnav,
    SearchItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
.search-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 3em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6em;

    @include respond-below(sm) {
      padding: 1.6em;
      grid-template-columns: 1fr;
    }
  }
}
</style>
