import { slavicPluralization } from 'I18n/slavicPluralization'

export * from './common.json'
export * from './car.json'
export * from './locations.json'
export * from './managing.json'
export * from './popups.json'
export * from './snackbar.json'
export * from './enums.json'
export * from './errors.json'
export * from './dates.json'
export * from './commands.json'
export * from './validators.json'
export * from './notifications.json'

export const config = {
  pluralization: slavicPluralization,
}
