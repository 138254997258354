import router from 'Router'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import camelCase from 'lodash/camelCase'
import { JWT_REFRESH_INTERVAL } from './constants'
import * as errors from './errors'
import { userMutations, userGetters, userActions } from './types'
import { sdk } from 'Services/shelfNetworkSdk'
import { Token, accountTypes } from '@shelf.network/js-sdk'
import { rootActions } from 'Store/root/types'
import { enumsActions } from 'Store/entities/Enums/types'
import { i18n } from 'I18n/instance'
import { showError } from 'Utils/notifications'
import cropImage from 'Utils/cropImage'
import { rootDispatch } from 'Store/helpers/rootHelpers'
import { enableTracking, disableTracking } from 'Services/analytics'

export default {
  async [userActions.LOGIN_USER] ({ commit }, { email, password, platformId }) {
    const requiredFields = { email, password, platformId }
    const errorFields = Object
      .keys(requiredFields)
      .filter(fieldName => {
        const field = requiredFields[fieldName]
        return isNil(field) || isEmpty(field)
      })

    if (errorFields.length > 0) {
      throw new errors.LoginUserInvalidFieldsError(errorFields)
    }

    let token
    try {
      token = await sdk.auth.getJwtToken({ email, password, platformId })
    } catch (error) {
      if (error.httpStatus === 400 && error.nestedErrors) {
        const invalidFields = error.nestedErrors
          .map(fieldError => get(fieldError, 'meta.field'))
          .filter(fieldName => !isNil(fieldName))
          .map(fieldName => camelCase(fieldName))

        throw new errors.LoginUserInvalidFieldsError(invalidFields)
      } else if (error.httpStatus && error.httpStatus < 500) {
        throw new errors.LoginUserInvalidCredentialsError()
      }

      throw error
    }

    assertAdminAccountType(token)

    sdk.useToken(token)
    serializeToken(token)
    commit(userMutations.SET_ACCOUNT_ID, token.accountId)

    await rootDispatch(rootActions.INITIALIZE_USER)
  },

  async [userActions.TRY_RESTORE_SESSION] ({ commit, dispatch }) {
    const rawToken = localStorage.getItem('token')

    if (rawToken) {
      let token

      try {
        token = new Token(rawToken)
      } catch (err) {
        showError(i18n.t('TOASTS.RESTORE_SESSION_ERROR'))
      }

      assertAdminAccountType(token)

      sdk.useToken(token)
      commit(userMutations.SET_ACCOUNT_ID, token.accountId)
      dispatch(userActions.TRY_REFRESH_TOKEN)
    }
  },

  async [userActions.TRY_GET_PROFILE_DATA] ({ getters, commit, dispatch }) {
    if (!getters[userGetters.ACCOUNT_ID]) {
      return
    }

    try {
      const { data: profileData } = await sdk.horizon.account.get()
      commit(userMutations.SET_PROFILE, profileData)

      await dispatch(userActions.LOAD_PLATFORM)
    } catch (err) {
      showError(i18n.t('TOASTS.LOAD_PROFILE_ERROR'))
    }
  },
  async [userActions.SIGN_OUT] ({ commit, getters }) {
    sdk.ejectToken()
    localStorage.removeItem('token')
    disableTracking(getters[userGetters.PLATFORM_ID])
    commit(userMutations.RESET)

    router.push('/login')
  },
  async [userActions.TRY_REFRESH_TOKEN] ({ getters, dispatch }) {
    if (!getters[userGetters.ACCOUNT_ID]) {
      return
    }

    try {
      const newToken = await sdk.auth.refreshToken()
      sdk.useToken(newToken)
    } catch (err) {
      showError(i18n.t('TOASTS.REFRESH_TOKEN_ERROR'))
      dispatch(userActions.SIGN_OUT)
    }
  },

  async [userActions.START_TOKEN_REFRESHER] ({ dispatch }) {
    setInterval(
      () => dispatch(userActions.TRY_REFRESH_TOKEN),
      JWT_REFRESH_INTERVAL,
    )
  },

  async [userActions.LOAD_PLATFORM] ({ commit, getters }) {
    const { data: platform } = await sdk.platformer.getById(
      getters[userGetters.PLATFORM_ID]
    )
    commit(userMutations.SET_PLATFORM, platform)

    const amplitudeKey = get(platform, 'frontFeatureFlags.amplitudeKey')
    if (amplitudeKey) {
      enableTracking(amplitudeKey)
    }
  },

  async [userActions.CHANGE_LOCALE] ({ getters, dispatch }, locale) {
    i18n.setLocale(locale)
    rootDispatch(`entities/enums/${enumsActions.FETCH_TRANSLATIONS}`, locale)

    const isSameLocale = get(getters[userGetters.PROFILE], 'locale', '') === locale
    if (!getters[userGetters.IS_LOGGED_IN] || isSameLocale) {
      return
    }

    await sdk.horizon.account.update({ locale })
  },

  async [userActions.UPDATE_PROFILE] ({ commit, dispatch }, opts) {
    // TODO: can just commit an update instead of loading profile.
    await sdk.horizon.account.update(opts)
    await dispatch(userActions.TRY_GET_PROFILE_DATA)
  },

  async [userActions.CHANGE_AVATAR] ({ getters, commit }, imgFile) {
    const IMG_WIDTH = 160 // px
    const IMG_HEIGHT = 160 // px
    const IMG_JPEG_QUALITY = 100

    const croppedImage = await cropImage(imgFile, {
      width: IMG_WIDTH,
      height: IMG_HEIGHT,
      jpegQuality: IMG_JPEG_QUALITY
    })

    const { data: { s3Link: avatarLink } } = await sdk.files.upload(
      croppedImage,
      {
        isPublic: true,
        owners: [getters[userGetters.ACCOUNT_ID]]
      })

    await sdk.horizon.account.update({ avatarLink })

    commit(userMutations.SET_AVATAR_LINK, avatarLink)
  },

  async [userActions.REMOVE_AVATAR] ({ commit }) {
    await sdk.horizon.account.update({ avatarLink: '' })
    commit(userMutations.SET_AVATAR_LINK, '')
  },

  [userActions.UPDATE_PASSWORD] (_, { oldPassword, newPassword }) {
    return sdk.auth.changePassword(oldPassword, newPassword)
  }
}

function serializeToken (token) {
  localStorage.setItem('token', token.rawJWT)
}

function assertAdminAccountType (token) {
  const allowedAccountTypes = [accountTypes.admin, accountTypes.broker]
  if (!allowedAccountTypes.includes(token.accountType)) {
    throw new errors.LoginUserInvalidCredentialsError()
  }
}
