<template>
  <button
    @click="togglePriority"
    class="lead-priority-mark"
    :class="{ 'lead-priority-mark_high': lead.isPriorityHigh }"
  >
    <ui-icon
      class="lead-priority-mark__ico"
      icon="favourite"
    />
  </button>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { Lead } from 'Models/Lead'
import { mapActions } from 'vuex'
import { actions } from '../store/types'

export default {
  name: 'lead-priority-select',

  components: {
    UiIcon
  },

  props: {
    lead: { type: Lead, required: true }
  },

  methods: {
    ...mapActions('ui/leads', {
      updateLead: actions.UPDATE_LEAD
    }),

    togglePriority () {
      this.updateLead({
        id: this.lead.id,
        priority: this.lead.isPriorityHigh
          ? Lead.prioritiesEnum.low
          : Lead.prioritiesEnum.high
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lead-priority-mark {
  background: $color-light-grey;
  color: $color-dark-grey;
  border: 2px solid $color-white;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    color: $color-sys-info;
  }

  &_high {
    background: $color-sys-info;
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  &__ico {
    display: block;
    margin-top: -0.1em;
  }
}
</style>
