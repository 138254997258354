<template>
  <div class="leads">
    <page-subnav class="leads__subnav">
      <ui-button @click="openCreateLeadModal">
        {{ $t('CREATE_LEAD_BTN') }}
        <ui-icon icon="plus" />
      </ui-button>

      <leads-list-params
        class="leads__subnav-action"
        :value="{
          sort: $route.query.sort || '',
          channel: $route.query.channel || '',
          broker: $route.query.broker || '',
          location: $route.query.location || '',
          isVerified: String($route.query.isVerified) === 'true',
          isUnverified: String($route.query.isUnverified) === 'true',
          isPrioritized: String($route.query.isPrioritized) === 'true',
          isFinanced: String($route.query.isFinanced) === 'true',
          isProCandidate: String($route.query.isProCandidate) === 'true',
        }"
        @input="onListParamsChange"
      />

      <subnav-search
        class="leads__subnav-action"
        :value="$route.query.search"
        @input="onSearch"
      />

      <template v-if="$can($USER_CLAIMS.BROKER_ASSIGNMENT)">
        <div class="leads__subnav-group">
          <leads-bulk-assistant class="leads__subnav-action" />
          <leads-bulk-assigner class="leads__subnav-action" />
        </div>
      </template>

      <template slot="right">
        <router-link
          class="leads__tab leads__tab_waiting"
          active-class="leads__tab_active"
          :to="{
            params: { tab: LEADS_URL_PARAM_TABS.waiting },
            query: $route.query
          }"
        >
          <ui-icon
            icon="circle"
            class="leads__tab-circle"
          />
          {{ $t('WAITING_TAB') }}
          <ui-icon
            icon="circle-full"
            class="leads__tab-notify"
          />
        </router-link>

        <router-link
          class="leads__tab leads__tab_hold"
          active-class="leads__tab_active"
          :to="{
            params: { tab: LEADS_URL_PARAM_TABS.hold },
            query: $route.query
          }"
        >
          <ui-icon
            icon="circle"
            class="leads__tab-circle"
          />
          {{ $t('HOLD_TAB') }}
          <ui-icon
            icon="circle-full"
            class="leads__tab-notify"
          />
        </router-link>

        <router-link
          class="leads__tab leads__tab_looking"
          active-class="leads__tab_active"
          :to="{
            params: { tab: LEADS_URL_PARAM_TABS.looking },
            query: $route.query
          }"
        >
          <ui-icon
            icon="circle"
            class="leads__tab-circle"
          />
          {{ $t('LOOKING_TAB') }}
          <ui-icon
            icon="circle-full"
            class="leads__tab-notify"
          />
        </router-link>

        <router-link
          class="leads__tab leads__tab_offered"
          active-class="leads__tab_active"
          :to="{
            params: { tab: LEADS_URL_PARAM_TABS.offered },
            query: $route.query
          }"
        >
          <ui-icon
            icon="circle"
            class="leads__tab-circle"
          />
          {{ $t('OFFERED_TAB') }}
          <ui-icon
            icon="circle-full"
            class="leads__tab-notify"
          />
        </router-link>
      </template>
    </page-subnav>

    <leads-list
      class="leads__list-container"
      :leads="leadsListFiltered"
      :is-loading="isLoading"
      :group-by="groupingAlgorithm"
      @update-list-ask="loadLeads"
    />

    <lead-overview
      class="leads__details"
      :leads-list="leadsListFiltered"
      :is-leads-loading="isLoading"
      :lead-id="$route.params.leadId"
      :lead-tab="$route.params.leadTab"
      @update:leadId="$router.push({
        params: { leadId: $event },
        query: $route.query,
      })"
      @update:leadTab="$router.push({
        params: { leadTab: $event },
        query: $route.query,
      })"
      @close="$router.push({
        params: { leadId: null, leadTab: null },
        query: $route.query,
      })"
    />

    <lead-create
      class="leads__create-modal"
      v-if="showCreateLeadModal"
      @close="showCreateLeadModal = false"
      @lead-created="loadLeads"
      auto-redirect
    />
  </div>
</template>

<script>
import { UiButton, UiIcon } from '@shelf.network/ui-kit'
import PageSubnav from 'Common/PageSubnav'
import SubnavSearch from 'Common/SubnavSearch'
import LeadsList from './components/LeadsList'
import LeadOverview from './components/LeadOverview'
import LeadCreate from './components/LeadCreate'
import LeadsListParams from './components/LeadsListParams'
import LeadsBulkAssistant from './components/LeadsBulkAssistant'
import LeadsBulkAssigner from './components/LeadsBulkAssigner'

import { actions, mutations } from './store/types'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { Lead } from 'Models/Lead'
import isEqual from 'lodash/isEqual'
import without from 'lodash/without'
import debounce from 'lodash/debounce'

import { USER_CLAIMS } from 'Constants/userClaims'

import {
  LEADS_URL_PARAM_ASSIGNMENTS,
  LEADS_URL_PARAM_TABS,
  LEADS_QUERY_SORTS,
  LEADS_URL_PARAM_TAB_DEFAULT_SORT,
} from 'Constants/leadUrlParams'
import { showError } from 'Utils/notifications'

const TAB_NAME_TO_LEAD_STATE = {
  [LEADS_URL_PARAM_TABS.waiting]: Lead.statesEnum.waitingCall,
  [LEADS_URL_PARAM_TABS.hold]: Lead.statesEnum.onHold,
  [LEADS_URL_PARAM_TABS.looking]: Lead.statesEnum.lookingCar,
  [LEADS_URL_PARAM_TABS.offered]: Lead.statesEnum.offerSent,
}

const SORT_COMPARATORS = {
  [LEADS_QUERY_SORTS.supportRequestCreatedAt]: (leadA, leadB) =>
    leadA.lastRequestDate.getTime() - leadB.lastRequestDate.getTime(),

  [LEADS_QUERY_SORTS.supportRequestCreatedAtDesc]: (leadA, leadB) =>
    leadB.lastRequestDate.getTime() - leadA.lastRequestDate.getTime(),

  [LEADS_QUERY_SORTS.priority]: (leadA, leadB) =>
    leadA.priority - leadB.priority,

  [LEADS_QUERY_SORTS.priorityDesc]: (leadA, leadB) =>
    leadB.priority - leadA.priority,

  [LEADS_QUERY_SORTS.dueDate]: (leadA, leadB) =>
    leadA.dueDate.getTime() - leadB.dueDate.getTime(),

  [LEADS_QUERY_SORTS.dueDateDesc]: (leadA, leadB) =>
    leadB.dueDate.getTime() - leadA.dueDate.getTime(),

  [LEADS_QUERY_SORTS.lastOfferSentAt]: (leadA, leadB) =>
    leadA.lastOfferSentAt.getTime() - leadB.lastOfferSentAt.getTime(),

  [LEADS_QUERY_SORTS.lastOfferSentAtDesc]: (leadA, leadB) =>
    leadB.lastOfferSentAt.getTime() - leadA.lastOfferSentAt.getTime(),
}

const UNSELECT_FILTERED_AFTER_ACTIONS = [
  `ui/leads/${actions.BULK_PICK_BY}`,
  `ui/leads/${actions.UPDATE_LEAD}`,
  `ui/leads/${actions.UPDATE_LEADS}`,
]

const FILTER_PREDICATES = {
  assignedOnly: lead => Boolean(lead.brokerId),
  unassignedOnly: lead => !lead.brokerId,
  waitingCallOnly: lead => lead.state === Lead.statesEnum.waitingCall,
  onHoldOnly: lead => lead.state === Lead.statesEnum.onHold,
  lookingCarOnly: lead => lead.state === Lead.statesEnum.lookingCar,
  offerSentOnly: lead => lead.state === Lead.statesEnum.offerSent,
}

export default {
  name: 'leads',
  components: {
    PageSubnav,
    SubnavSearch,
    LeadsList,
    LeadOverview,
    LeadCreate,
    LeadsListParams,
    LeadsBulkAssistant,
    LeadsBulkAssigner,
    UiButton,
    UiIcon,
  },

  data () {
    return {
      showCreateLeadModal: false,
      LEADS_URL_PARAM_TABS,
      groupingAlgorithm: '',
      filteringPredicates: [],
      sortingAlgorithm: '',
      storeUnsubscriber: () => { }
    }
  },

  computed: {
    ...mapGetters('ui/leads', {
      leadsList: 'leadsList',
      isLoading: 'isLoading',
      bulkSelection: 'bulkSelection'
    }),

    leadsListFiltered () {
      /**
       * Simulate back-end’s filter and sort so user changes are applied
       * immediately to the list
       */
      return this.leadsList
        .filter(lead => this.filteringPredicates.every(fn => fn(lead)))
        .sort(SORT_COMPARATORS[this.sortingAlgorithm])
    },

    leadTabDefaultSort () {
      return LEADS_URL_PARAM_TAB_DEFAULT_SORT[this.$route.params.tab]
    },

    metaTitle () {
      const tabTitles = {
        [LEADS_URL_PARAM_TABS.waiting]: this.$t('META_TITLE_WAITING'),
        [LEADS_URL_PARAM_TABS.hold]: this.$t('META_TITLE_HOLD'),
        [LEADS_URL_PARAM_TABS.looking]: this.$t('META_TITLE_LOOKING'),
        [LEADS_URL_PARAM_TABS.offered]: this.$t('META_TITLE_OFFERED'),
      }
      const tabTitle = tabTitles[this.$route.params.tab]

      const assignmentTitles = {
        [LEADS_URL_PARAM_ASSIGNMENTS.assigned]:
          this.$t('META_TITLE_ASSIGNED'),
        [LEADS_URL_PARAM_ASSIGNMENTS.unassigned]:
          this.$t('META_TITLE_UNASSIGNED'),
      }
      const assignmentParam = this.$route.params.assignment
      const assignmentTitle = assignmentParam
        ? assignmentTitles[assignmentParam]
        : this.$t('META_TITLE_LEADS')

      return [tabTitle, assignmentTitle].join(this.$t('META_TITLE_SEPARATOR'))
    },

    leadsPayload () {
      const query = this.$route.query
      const payload = { filter: {} }

      payload.filter.state =
        TAB_NAME_TO_LEAD_STATE[this.$route.params.tab] ||
        TAB_NAME_TO_LEAD_STATE.waiting
      payload.search = query.search || ''
      payload.sort = query.sort || this.leadTabDefaultSort
      payload.filter.channel = query.channel
      payload.filter.broker = query.broker
      payload.filter.financing = query.isFinanced
      payload.filter.interestedInPro = query.isProCandidate

      if (query.location) {
        const [countryId, regionId, cityId] = query.location.split(':')
          .map(el => el === '0' ? '' : el)
        if (countryId) payload.filter['customer.location.country'] = countryId
        if (regionId) payload.filter['customer.location.region'] = regionId
        if (cityId) payload.filter['customer.location.city'] = cityId
      }

      switch ([query.isVerified, query.isUnverified].map(Boolean).join()) {
        case 'true,false':
          payload.filter['customer.verified'] = true
          break
        case 'false,true':
          payload.filter['customer.verified'] = false
          break
        default:
          break
      }

      if (query.isPrioritized) {
        payload.filter.priority = Lead.prioritiesEnum.high
      }

      const assignment = this.$route.params.assignment
      if (assignment === LEADS_URL_PARAM_ASSIGNMENTS.assigned) {
        payload.filter.assigned = 'true'
      } else if (assignment === LEADS_URL_PARAM_ASSIGNMENTS.unassigned) {
        payload.filter.assigned = 'false'
      } else {
        // leave the filter empty
      }

      return payload
    }
  },

  watch: {
    '$route.params.tab': {
      handler (value, oldValue) {
        if (!value) {
          return this.$router.push({
            params: { tab: LEADS_URL_PARAM_TABS.waiting },
            query: this.$route.query,
          })
        }

        if (value && oldValue && !isEqual(value, oldValue)) {
          const query = this.$route.query
          const newParams = { ...query, sort: undefined }
          this.onListParamsChange(newParams)
        }
      },
      immediate: true
    },

    '$route.params.assignment': {
      handler (value, oldValue) {
        if (!value && this.$can(USER_CLAIMS.BROKER_ASSIGNMENT)) {
          return this.$router.push({
            params: { assignment: LEADS_URL_PARAM_ASSIGNMENTS.unassigned },
            query: this.$route.query,
          })
        }
        if (value && !this.$can(USER_CLAIMS.BROKER_ASSIGNMENT)) {
          return this.$router.push({
            params: { assignment: null },
            query: this.$route.query,
          })
        }

        if (value && oldValue && !isEqual(value, oldValue)) {
          this.onListParamsChange({}) // reset list params and reload
        }
      },
      immediate: true
    },
  },

  created () {
    this.storeUnsubscriber = this.$store.subscribeAction({
      after: (action, state) => {
        if (UNSELECT_FILTERED_AFTER_ACTIONS.includes(action.type)) {
          this.bulkUnselectFilteredOut()
        }
      }
    })

    this.loadLeads()
  },

  beforeDestroy () {
    this.storeUnsubscriber()
  },

  methods: {
    ...mapActions('ui/leads', {
      loadLeadsAction: actions.LOAD_LEADS_LIST,
    }),
    ...mapMutations('ui/leads', {
      setIsLoading: mutations.SET_IS_LOADING,
      bulkUnselectAll: mutations.BULK_UNSELECT_ALL,
      bulkUnselect: mutations.BULK_UNSELECT,
    }),

    openCreateLeadModal () {
      this.showCreateLeadModal = true
    },

    onSearch (str) {
      str = str.trim()
      if ((this.$route.query.search || '') === str) return
      this.setIsLoading(true)
      this.applySearchDebounced(str)
    },

    applySearchDebounced: debounce(async function (search) {
      await this.applySearch(search)
    }, 1000),

    applySearch (str) {
      const query = { ...this.$route.query }

      if ((query.search || '') === str) return
      query.search = str || undefined

      this.$router.push({ query })
      this.loadLeads()
    },

    onListParamsChange (input) {
      const query = { ...this.$route.query }

      query.sort = input.sort === this.leadTabDefaultSort
        ? undefined
        : input.sort || undefined
      query.channel = input.channel || undefined
      query.broker = input.broker || undefined
      query.location = input.location || undefined
      query.isVerified = input.isVerified || undefined
      query.isUnverified = input.isUnverified || undefined
      query.isPrioritized = input.isPrioritized || undefined
      query.isFinanced = input.isFinanced || undefined
      query.isProCandidate = input.isProCandidate || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }
      this.loadLeads()
    },

    bulkUnselectFilteredOut () {
      if (!this.bulkSelection.length) return
      const diff = without(
        this.bulkSelection,
        ...this.leadsListFiltered.map(ld => ld.id),
      )
      this.bulkUnselect(diff)
    },

    async loadLeads () {
      this.setIsLoading(true)

      try {
        await this.loadLeadsAction(this.leadsPayload)
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
      this.bulkUnselectAll()

      const sort = this.$route.query.sort || this.leadTabDefaultSort || ''
      if (sort.includes(LEADS_QUERY_SORTS.dueDate)) {
        this.groupingAlgorithm = 'dueDate'
      } else if (sort.includes(LEADS_QUERY_SORTS.lastOfferSentAt)) {
        this.groupingAlgorithm = 'lastOfferSentAt'
      } else {
        this.groupingAlgorithm = ''
      }

      const filteringAlgorithmSelection = []
      const { assignment, tab } = this.$route.params
      if (assignment === LEADS_URL_PARAM_ASSIGNMENTS.assigned) {
        filteringAlgorithmSelection.push(FILTER_PREDICATES.assignedOnly)
      } else if (assignment === LEADS_URL_PARAM_ASSIGNMENTS.unassigned) {
        filteringAlgorithmSelection.push(FILTER_PREDICATES.unassignedOnly)
      }
      if (tab === LEADS_URL_PARAM_TABS.waiting) {
        filteringAlgorithmSelection.push(FILTER_PREDICATES.waitingCallOnly)
      } else if (tab === LEADS_URL_PARAM_TABS.hold) {
        filteringAlgorithmSelection.push(FILTER_PREDICATES.onHoldOnly)
      } else if (tab === LEADS_URL_PARAM_TABS.looking) {
        filteringAlgorithmSelection.push(FILTER_PREDICATES.lookingCarOnly)
      } else if (tab === LEADS_URL_PARAM_TABS.offered) {
        filteringAlgorithmSelection.push(FILTER_PREDICATES.offerSentOnly)
      }
      this.filteringPredicates = filteringAlgorithmSelection
      this.sortingAlgorithm = sort
    },
  },

  metaInfo () {
    return {
      title: this.metaTitle,
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style scoped lang="scss">
.leads {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__subnav-group {
    display: flex;
  }

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &-circle {
      vertical-align: sub;
      font-size: 1.1em;
      margin-left: -1.1em;
      margin-right: 0.2em;
    }

    &-notify {
      color: $color-sys-warning;
      transform: translateY(-0.4em) translateX(-0.2em);
      display: inline-block;
      vertical-align: text-top;
      font-size: 0.8em;
      visibility: hidden;
      width: 0;
    }

    &_notify &-notify {
      visibility: visible;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &_active &-circle::before {
      content: map-get($icons, circle-full);
    }

    &_waiting &-circle {
      color: $color-status-waiting;
    }

    &_hold &-circle {
      color: $color-status-hold;
    }

    &_looking &-circle {
      color: $color-status-looking;
    }

    &_offered &-circle {
      color: $color-status-offered;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CREATE_LEAD_BTN": "Create Lead",
    "WAITING_TAB": "WAITING FOR CALL",
    "HOLD_TAB": "ON-HOLD",
    "LOOKING_TAB": "LOOKING FOR A CAR",
    "OFFERED_TAB": "OFFER SENT",
    "META_TITLE_LEADS": "Leads",
    "META_TITLE_ASSIGNED": "Assigned Leads",
    "META_TITLE_UNASSIGNED": "Unassigned Leads",
    "META_TITLE_WAITING": "Waiting for call",
    "META_TITLE_HOLD": "On Hold",
    "META_TITLE_LOOKING": "Looking For a Car",
    "META_TITLE_OFFERED": "Offer Sent",
    "META_TITLE_SEPARATOR": " | ",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the leads list. Please try again later or contact the system owner."
  },
  "ka": {
    "CREATE_LEAD_BTN": "ლიდის შექმნა",
    "WAITING_TAB": "ᲓᲐᲡᲐᲠᲔᲙᲘ",
    "HOLD_TAB": "ᲝᲓᲔᲡᲛᲔ",
    "LOOKING_TAB": "ᲫᲘᲔᲑᲐ",
    "OFFERED_TAB": "ᲒᲐᲒᲖᲐᲕᲜᲘᲚᲘ",
    "META_TITLE_LEADS": "ლიდები",
    "META_TITLE_ASSIGNED": "გაფილტრული",
    "META_TITLE_UNASSIGNED": "გაუფილტრავი",
    "META_TITLE_WAITING": "დასარეკი",
    "META_TITLE_HOLD": "ოდესმე",
    "META_TITLE_LOOKING": "ძიება",
    "META_TITLE_OFFERED": "გაგზავნილი",
    "META_TITLE_SEPARATOR": " | ",
    "LIST_FETCH_FAILED_NOTIFY": "ლიდების სია ვერ გაიხსნა. სცადეთ მოგვიანებით ან დაუკავშირდით ადმინისტრატორს."
  },
  "ru": {
    "CREATE_LEAD_BTN": "Создать Лид",
    "WAITING_TAB": "В ОЖИДАНИИ ЗВОНКА",
    "HOLD_TAB": "НА УДЕРЖАНИИ",
    "LOOKING_TAB": "ПОИСК АВТО",
    "OFFERED_TAB": "ОТПРАВЛЕНО",
    "META_TITLE_LEADS": "Лиды",
    "META_TITLE_ASSIGNED": "Назначенные лиды",
    "META_TITLE_UNASSIGNED": "Неназначенные лиды",
    "META_TITLE_WAITING": "В ожидании звонка",
    "META_TITLE_HOLD": "НА УДЕРЖАНИИ",
    "META_TITLE_LOOKING": "Поиск авто",
    "META_TITLE_OFFERED": "Отправлено",
    "META_TITLE_SEPARATOR": " | ",
    "LIST_FETCH_FAILED_NOTIFY": "Не удалось загрузить список Лидов. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "CREATE_LEAD_BTN": "Створити Лід",
    "WAITING_TAB": "В ОЧІКУВАННІ ДЗВІНКА",
    "HOLD_TAB": "НА УТРИМАННІ",
    "LOOKING_TAB": "ПОШУК АВТОМОБІЛЯ",
    "OFFERED_TAB": "ВІДПРАВЛЕНО",
    "META_TITLE_LEADS": "Ліди",
    "META_TITLE_ASSIGNED": "Призначені ліди",
    "META_TITLE_UNASSIGNED": "Непризначені ліди",
    "META_TITLE_WAITING": "В очікуванні дзвінка",
    "META_TITLE_HOLD": "На утриманні",
    "META_TITLE_LOOKING": "Пошук автомобіля",
    "META_TITLE_OFFERED": "Відправлено",
    "META_TITLE_SEPARATOR": " | ",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдалось завантажити список Лідів. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
