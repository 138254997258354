import { ModelBase } from './ModelBase'

export class Campaign extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.campaign = this._str(raw.campaign)
    this.medium = this._str(raw.medium)
    this.source = this._str(raw.source)
    this.content = this._str(raw.content)
    this.term = this._str(raw.term)
  }
}
