const actions = {
  LOGIN_USER: 'LOGIN_USER',
  GET_USER_DATA: 'GET_USER_DATA',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  TRY_RESTORE_SESSION: 'TRY_RESTORE_SESSION',
  TRY_GET_PROFILE_DATA: 'TRY_GET_PROFILE_DATA',
  SIGN_OUT: 'SIGN_OUT',
  TRY_REFRESH_TOKEN: 'TRY_REFRESH_TOKEN',
  START_TOKEN_REFRESHER: 'START_TOKEN_REFRESHER',
  LOAD_PLATFORM: 'LOAD_PLATFORM',
  CHANGE_LOCALE: 'CHANGE_LOCALE',
  CHANGE_AVATAR: 'CHANGE_AVATAR',
  REMOVE_AVATAR: 'REMOVE_AVATAR',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
}

const mutations = {
  SET_ACCOUNT_ID: 'SET_ACCOUNT_ID',
  SET_PROFILE: 'SET_PROFILE',
  SET_PLATFORM: 'SET_PLATFORM',
  SET_AVATAR_LINK: 'SET_AVATAR_LINK',
  RESET: 'RESET'
}

const getters = {
  IS_LOGGED_IN: 'IS_LOGGED_IN',
  ACCOUNT_ID: 'ACCOUNT_ID',
  PLATFORM: 'PLATFORM',
  PROFILE: 'PROFILE',
  PLATFORM_ID: 'PLATFORM_ID',
  TRUSTING_PLATFORMS: 'TRUSTING_PLATFORMS',
  PLATFORMS: 'PLATFORMS',
  CLAIMS: 'CLAIMS',
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  IS_BROKER: 'IS_BROKER',
  IS_ADMIN: 'IS_ADMIN',
  IS_CARFAX_RESELLER: 'IS_CARFAX_RESELLER',
}

export {
  actions as userActions,
  mutations as userMutations,
  getters as userGetters,
}
