<template>
  <div class="carfax-skeleton">
    <div class="carfax-skeleton__head">
      &nbsp;
    </div>

    <carfax-list-head />
    <carfax-list-skeleton class="carfax-skeleton__list" />
  </div>
</template>

<script>
import CarfaxListHead from './CarfaxListHead'
import CarfaxListSkeleton from './CarfaxListSkeleton'

export default {
  name: 'carfax-skeleton',
  components: {
    CarfaxListHead,
    CarfaxListSkeleton
  }
}
</script>

<style lang="scss" scoped>
.carfax-skeleton {
  &__head {
    background-color: $color-light-grey;
    margin: -1em -2em 2em;
    height: 5em;

    @include respond-below(sm) {
      margin: -1em -2em 2em;
    }
  }

  &__list {
    margin-top: 2em;
  }
}
</style>
