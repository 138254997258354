<template>
  <div
    class="lot-finder"
    :class="{ 'lot-finder_single-line': singleLine }"
  >
    <div
      class="lot-finder__msg"
      :class="{
        'lot-finder__msg_found': isReady,
        'lot-finder__msg_error': !isValidInput || isInvalidLot,
      }"
    >
      <template v-if="isReady">
        {{ $t('LOT_FOUND_MSG') }}
        <span class="lot-finder__msg-lot">
          {{ $t('FOUND_LOT_FMT', lot) }}
        </span>
      </template>
      <template v-else-if="isInvalidLot">
        {{ $t('UNABLE_TO_FIND_LOT_MSG') }}
      </template>
      <template v-else-if="!isValidInput">
        {{ $t('INVALID_LOT_LINK_MSG') }}
      </template>
      <template v-else>
        {{ $t('ADD_LOT_LBL') }}
      </template>
    </div>

    <div class="lot-finder__form-wrap">
      <ui-text
        @input="debounceGuess"
        v-model="userInput"
        look="secondary"
        type="text"
        class="lot-finder__link"
        :is-error="!isValidInput"
      >
        <ui-spinner
          type="pills"
          slot="dock-right"
          v-if="isGuessing"
        />
        <ui-icon
          v-else
          icon="link"
          slot="dock-right"
          class="lot-finder__link-icon"
        />
      </ui-text>

      <ui-button
        :is-disabled="!isReady"
        @click="selectLotId"
        class="lot-finder__button"
      >
        <ui-icon
          class="lot-finder__button-icon"
          icon="plus"
        />

        {{ btnLabel || $t('ADD_LOT_BTN') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { UiIcon, UiText, UiSpinner, UiButton } from '@shelf.network/ui-kit'
import debounce from 'lodash/debounce'
import { validate as validateVin } from 'vin-validator'
import { sdk } from 'Services/shelfNetworkSdk'
import get from 'lodash/get'
import { LotFrontOffice } from 'Models/LotFrontOffice'

const COPART_LOT_REGEX = /copart.com.+(\d{8})/
const IAAI_LOT_REGEX = /iaai.com.+(\d{8})/
const LOT_ID_REGEX = /\d+(?=\?|$)/

export default {
  name: 'lot-finder',
  components: { UiSpinner, UiButton, UiIcon, UiText },
  props: {
    btnLabel: { type: String, default: '' },
    include: { type: Array, default: null },
    singleLine: { type: Boolean, default: false }
  },
  data () {
    return {
      userInput: '',
      isValidInput: true,
      extractedValue: '',
      extractedValueType: '',
      isLoading: false,
      isGuessing: false,
      foundRawLot: null
    }
  },
  computed: {
    lot () {
      return new LotFrontOffice(this.foundRawLot)
    },

    isReady () {
      return Boolean(this.foundRawLot)
    },

    isInvalidLot () {
      return Boolean(
        this.extractedValue && !this.isReady && !this.isGuessing
      )
    }

  },
  created () {
    this.userInput = ''
    this.debounceGuess()
  },
  methods: {
    debounceGuess: debounce(async function () {
      if (this.isGuessing) return
      this.isGuessing = true

      this.guessWhatUserWant()
      if (this.extractedValue) await this.findLot()

      this.isGuessing = false
    }, 550),

    selectLotId () {
      if (this.isReady) this.$emit('select-lot', this.lot)
    },

    resetForm (withUserInput = false) {
      this.extractedValue = ''
      this.extractedValueType = ''
      this.foundRawLot = null
      this.isValidInput = true
      if (withUserInput) this.userInput = ''
    },
    matchRegex (value, regex) {
      return value.match(regex) || []
    },

    guessWhatUserWant () {
      this.resetForm()
      const value = this.userInput.trim() || ''
      if (!value) return

      const copartId = this.matchRegex(value, COPART_LOT_REGEX)[1]
      const iaaiId = this.matchRegex(value, IAAI_LOT_REGEX)[1]
      const lotId = this.matchRegex(value, LOT_ID_REGEX)[0]
      const vinNum = validateVin(value) ? value.toUpperCase() : ''

      this.extractedValue = vinNum || copartId || iaaiId || lotId || ''

      this.isValidInput = Boolean(this.extractedValue)
    },

    async findLot () {
      try {
        const response = await sdk.frontOffice.getLotsPage({
          filter: { search: this.extractedValue },
          page: { limit: 1 },
          include: this.include || undefined
        })
        if (response.data.length) {
          this.foundRawLot = get(response, 'data[0]', null)
        } else {
          this.isValidInput = false
        }
      } catch (err) {
        this.isValidInput = false
        console.error('Unable to find lot', err)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.lot-finder {
  &__msg {
    font-weight: bold;
    margin-bottom: 1em;

    &-lot {
      color: $color-sys-info;
    }

    &_error {
      color: $color-sys-warning;
    }

    &_found {
      color: $color-black;
    }
  }

  &__link {
    max-width: 40em;
    flex: 1;

    &-icon {
      font-size: 1.8em;
      color: $color-grey;
    }

    & /deep/ .ui-text__dock {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__button {
    margin-top: 1.3em;
    min-width: 13em;

    &-icon {
      margin-right: 0.5em;
    }
  }

  &_single-line &__form-wrap {
    display: flex;
  }

  &_single-line &__button {
    margin-top: 0;
    margin-left: 1.3em;
  }

  @include respond-below(sm) {
    &__msg {
      text-align: center;
    }

    &__link {
      font-size: 16px;
    }

    &__link,
    &__button {
      margin-top: 1.3em;
      margin-left: 0;
      width: 100%;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "ADD_LOT_LBL": "ADD LOT ID, VIN OR CAR LINK FROM THE WEBSITE",
    "ADD_LOT_BTN": "Add Car",
    "LOT_FOUND_MSG": "LOT FOUND —",
    "FOUND_LOT_FMT": "#{id}, {name}",
    "INVALID_LOT_LINK_MSG": "Invalid Lot link or ID. Please, check and try again.",
    "UNABLE_TO_FIND_LOT_MSG": "Unable to find lot - auction is over or lot does not exist"
  },
  "ka": {
    "ADD_LOT_LBL": "ᲨᲔᲘᲧᲕᲐᲜᲔᲗ ᲚᲝᲢᲘᲡ ID, VIN ᲐᲜ ᲐᲕᲢᲝᲛᲝᲑᲘᲚᲘᲡ ᲑᲛᲣᲚᲘ",
    "ADD_LOT_BTN": "დამატება",
    "LOT_FOUND_MSG": "ᲜᲐᲞᲝᲕᲜᲘᲐ —",
    "FOUND_LOT_FMT": "#{id}, {name}",
    "INVALID_LOT_LINK_MSG": "არასწორი ლოტის ID, კიდევ სცადეთ.",
    "UNABLE_TO_FIND_LOT_MSG": "ლოტი არ იძებნება - აუქციონი დასრულდა ან არ იყო რეგისტრირებული"
  },
  "ru": {
    "ADD_LOT_LBL": "ДОБАВЬТЕ ID, VIN ЛОТА ИЛИ ССЫЛКУ АВТОМОБИЛЯ С ВЕБ-САЙТА",
    "ADD_LOT_BTN": "Добавить",
    "LOT_FOUND_MSG": "ЛОТ НАЙДЕН —",
    "FOUND_LOT_FMT": "#{id}, {name}",
    "INVALID_LOT_LINK_MSG": "Неверная ссылка лота или ID. Пожалуйста, проверьте и попробуйте снова.",
    "UNABLE_TO_FIND_LOT_MSG": "Не удалось найти лот - торги окончены или лот не существует."
  },
  "uk": {
    "ADD_LOT_LBL": "ДОДАЙТЕ ID, VIN ЛОТА АБО ПОСИЛАННЯ НА АВТОМОБІЛЬ З ВЕБ-САЙТУ",
    "ADD_LOT_BTN": "Додати автомобіль",
    "LOT_FOUND_MSG": "ЛОТ ЗНАЙДЕНО —",
    "FOUND_LOT_FMT": "#{id}, {name}",
    "INVALID_LOT_LINK_MSG": "Неправильне посилання або ID. Будь ласка, перевірте і спробуйте знову.",
    "UNABLE_TO_FIND_LOT_MSG": "Не вдалось знайти лот - торги скінчились або лот не існує."
  }
}
</i18n>
