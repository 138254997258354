import { ModelBase } from './ModelBase'
import { NotificationLot } from './NotificationLot'
import { NotificationActor } from './NotificationActor'

import { TOPICS } from 'Constants/notificationTopics'
import { i18n } from 'I18n/instance'

import Vue from 'vue'
import get from 'lodash/get'

export class Notification extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.topic = this._num(raw.topic)
    this.createdAt = this._date(raw.createdAt)
    this.isRead = Boolean(raw.isRead)
    this.cargo = this._payloadToCargo(raw.payload)
  }

  get customerName () {
    return get(this.cargo, 'customer.name') || i18n.t('COMMON.UNNAMED_USER_PH')
  }

  get actorName () {
    return get(this.cargo, 'actor.name') ||
      this.cargo.dealerFullName ||
      i18n.t('COMMON.UNNAMED_USER_PH')
  }

  get formattedAmount () {
    return Vue.prototype.$fc(
      this.cargo.amount,
      this.cargo.amountCurrency,
    )
  }

  _payloadToCargo (payload = {}) {
    const res = {}

    if (payload.rawAmount && payload.currency) {
      res.amount = this._str(payload.rawAmount)
      res.amountCurrency = this._str(payload.currency)
    } else if (payload.amount) {
      const [, amount, currency] = /([\d.]+)\s([A-Z]+)/.exec(payload.amount)
      res.amount = this._str(amount)
      res.amountCurrency = this._str(currency)
    }

    if (payload.depositId) res.depositId = payload.depositId
    if (payload.previousStatus) res.oldState = payload.previousStatus
    if (payload.newStatus) res.newState = payload.newStatus
    if (payload.lotId) res.lotId = payload.lotId
    if (payload.dealerFullName) res.dealerFullName = payload.dealerFullName

    if (payload.bank) {
      res.bank = new NotificationActor({
        name: payload.bank,
        avatarLink: payload.bankLogo,
      })
    }

    if (payload.lot) {
      res.lot = new NotificationLot(payload.lot)
    }

    if (payload.customer) {
      res.customer = new NotificationActor(payload.customer)
    }

    if (payload.actor || payload.broker) {
      res.actor = new NotificationActor(payload.actor || payload.broker)
    }

    return res
  }

  translate () {
    const payload = {
      customer: this.customerName,
      lot: get(this.cargo, 'lot.name', ''),
      lotId: this.cargo.lotId,
      amount: this.formattedAmount,
      bank: get(this.cargo, 'bank.name', ''),
      actor: this.actorName,
      oldState: this.cargo.oldState,
      newState: this.cargo.newState
    }

    switch (this.topic) {
      case TOPICS.userRequestedBuyNow:
        return i18n.t(
          'NOTIFICATION_MESSAGES.USER_REQUESTED_BUY_NOW_MSG',
          payload
        )

      case TOPICS.userRequestedWithdrawal:
        return i18n.t(
          'NOTIFICATION_MESSAGES.USER_REQUESTED_WITHDRAWAL_MSG',
          payload
        )

      case TOPICS.userMadeDeposit:
        return i18n.t(
          'NOTIFICATION_MESSAGES.USER_MADE_DEPOSIT_MSG',
          payload
        )

      case TOPICS.userRequestedFinancing:
        return i18n.t(
          'NOTIFICATION_MESSAGES.USER_REQUESTED_FINANCING_MSG',
          payload
        )

      case TOPICS.userFinancingApproved:
        return i18n.t(
          'NOTIFICATION_MESSAGES.USER_FINANCING_APPROVED_MSG',
          payload
        )

      case TOPICS.brokerLeadDueDateExpired:
        return i18n.t(
          'NOTIFICATION_MESSAGES.BROKER_LEAD_DUE_DATE_EXPIRED_MSG',
          payload
        )

      case TOPICS.brokerLeadStateChanged:
        return i18n.t(
          'NOTIFICATION_MESSAGES.BROKER_LEAD_STATE_CHANGED_MSG',
          payload
        )

      case TOPICS.userVerified:
        return i18n.t(
          'NOTIFICATION_MESSAGES.USER_VERIFIED_MSG',
          payload
        )

      case TOPICS.brokerUserMadeDeposit:
        return i18n.t(
          'NOTIFICATION_MESSAGES.BROKER_USER_MADE_DEPOSIT_MSG',
          payload
        )

      case TOPICS.brokerUserLotWon:
        return i18n.t(
          'NOTIFICATION_MESSAGES.BROKER_USER_LOT_WON_MSG',
          payload
        )

      case TOPICS.brokerUserLotLost:
        return i18n.t(
          'NOTIFICATION_MESSAGES.BROKER_USER_LOT_LOST_MSG',
          payload
        )

      case TOPICS.userLotWon:
        return i18n.t(
          'NOTIFICATION_MESSAGES.USER_LOT_WON_MSG',
          payload
        )

      case TOPICS.userLotLost:
        return i18n.t(
          'NOTIFICATION_MESSAGES.USER_LOT_LOST_MSG',
          payload
        )

      case TOPICS.leadAssigned:
        return i18n.t(
          'NOTIFICATION_MESSAGES.LEAD_ASSIGNED_MSG',
          payload
        )

      case TOPICS.leadReassigned:
        return i18n.t(
          'NOTIFICATION_MESSAGES.LEAD_REASSIGNED_MSG',
          payload
        )

      case TOPICS.leadCommented:
        return i18n.t(
          'NOTIFICATION_MESSAGES.LEAD_COMMENTED_MSG',
          payload
        )

      case TOPICS.claimRequestCreated:
        return i18n.t(
          'NOTIFICATION_MESSAGES.CLAIM_REQUEST_CREATED_MSG',
          payload
        )
    }

    return ''
  }
}
