<template>
  <div class="users-skeleton">
    <page-subnav class="users-skeleton__subnav" />

    <div class="users-skeleton__list">
      <user-item-skeleton />
      <user-item-skeleton />
      <user-item-skeleton />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import UserItemSkeleton from './UserItemSkeleton'

export default {
  name: 'users-skeleton',
  components: {
    PageSubnav,
    UserItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
@import "../styles/users";

.users-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 0 $list-padding-side;
  }
}
</style>
