import LogRocket from 'logrocket'
import { config } from '@/config'
import { APP_VERSION } from 'Constants/appVersion'
class Logger {
  constructor () {
    this.initialized = false
    this.loggerInterface = console
    if (config.LOG_ROCKET_APP_ID) {
      this.initialized = true
      this.loggerInterface = LogRocket
      LogRocket.init(config.LOG_ROCKET_APP_ID)
      this.info('logger init', { APP_VERSION })
    }
  }

  log () {
    this.loggerInterface.log(...arguments)
  }

  error () {
    this.loggerInterface.error(...arguments)
  }

  warn () {
    this.loggerInterface.warn(...arguments)
  }

  info () {
    this.loggerInterface.info(...arguments)
  }

  identify (uid, traits) {
    if (this.initialized) {
      LogRocket.identify(uid, traits)
    }
  }
}

export default new Logger()
