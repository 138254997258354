import { ModelBase } from './ModelBase'
import get from 'lodash/get'

export class Location extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    const country = get(raw, 'relationships.country', {})
    this.countryId = this._str(country.id)
    this.countryName = this._str(country.name)
    this.countryAlpha3Code = this._str(country.alpha3Code)

    const region = get(raw, 'relationships.region', {})
    this.regionId = this._str(region.id)
    this.regionName = this._str(region.name)

    const city = get(raw, 'relationships.city', {})
    this.cityId = this._str(city.id)
    this.cityName = this._str(city.name)
  }

  static fromString (string = '') {
    const matched = /^(\d+):(\d+):(\d+)$/.exec(string) || []
    const [, countryId, regionId, cityId] = matched
    return new Location({
      relationships: {
        city: { id: cityId },
        country: { id: countryId },
        region: { id: regionId },
      },
    })
  }

  toString () {
    return `${this.countryId || 0}:${this.regionId || 0}:${this.cityId || 0}`
      .replace('0:0:0', '')
  }
}
