<template>
  <div class="subnav-search">
    <template v-if="!isSearchActive && !form.search">
      <ui-button
        fill="none"
        look="secondary"
        class="subnav-search__activate-btn"
        @click="activate"
      >
        <ui-icon
          class="subnav-search__activate-btn-ico"
          icon="search"
        />
      </ui-button>
    </template>

    <transition name="subnav-search__input">
      <template v-if="isSearchActive || form.search">
        <ui-text
          ref="input"
          v-model="form.search"
          class="subnav-search__input"
          :class="{ 'subnav-search__input_active': active }"
          name="subnav-search"
          :placeholder="placeholder || $t('SEARCH_PH')"
          @input="onInput"
          @blur="onBlur"
        >
          <ui-icon
            v-if="active"
            class="subnav-search__input-ico"
            icon="search"
            slot="dock-left"
          />

          <button
            v-if="isActive || form.search"
            class="subnav-search__clear-btn"
            @click="onClear"
            slot="dock-right"
          >
            <ui-icon icon="close" />
          </button>
        </ui-text>
      </template>
    </transition>
  </div>
</template>

<script>
import { UiButton, UiIcon, UiText } from '@shelf.network/ui-kit'

const EVENTS = {
  input: 'input',
}

export default {
  name: 'subnav-search',
  components: {
    UiButton,
    UiIcon,
    UiText
  },

  props: {
    value: { type: String, default: '' },
    active: { type: Boolean, default: false },
    placeholder: { type: String, default: '' }
  },

  data () {
    return {
      form: {
        search: '',
      },
      isActive: false,
    }
  },

  computed: {
    isSearchActive () {
      return this.active || this.isActive
    }
  },

  watch: {
    value: {
      immediate: true,
      handler () {
        this.form.search = this.value
      }
    }
  },

  methods: {
    async activate () {
      this.isActive = true
      await this.$nextTick()
      this.$refs['input'].$el.querySelector('.ui-text__input').focus()
    },

    onInput (value) {
      this.$emit(EVENTS.input, value)
    },

    onBlur () {
      if (!this.form.search) {
        this.onClear()
      }
    },

    onClear () {
      this.form.search = ''
      this.$emit(EVENTS.input, '')
      this.isActive = false
    }
  },
}
</script>

<style lang="scss" scoped>
.subnav-search {
  display: flex;
  align-items: center;
  position: relative;

  &__activate-btn {
    & /deep/ .ui-button__button {
      padding: 0.75em;
    }

    &-ico {
      font-size: 1.2em;
      line-height: 1.3;
      vertical-align: middle;
    }
  }

  &__clear-btn {
    background: none;
    border: none;
    color: $color-ui-secondary;
    padding: 0.25em;
  }

  &__input {
    transition: opacity 0.15s;

    /deep/ .ui-text {
      &__input {
        border-radius: 1em;
        padding-left: 1em;
        width: 15em;

        &:not(:focus) {
          border-color: $color-ui-secondary;
        }
      }

      &__dock_left,
      &__dock_right {
        display: inline-grid;
        place-items: center;
      }

      &__dock_left {
        width: 2.4em;
      }
    }

    &_active {
      /deep/ .ui-text__input {
        padding-left: 2.4em;
      }
    }

    &-ico {
      color: $color-ui-secondary;
    }

    &-enter {
      opacity: 0;
    }

    &-leave-active {
      position: absolute;
      opacity: 0;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SEARCH_PH": "Type to search"
  },
  "ka": {
    "SEARCH_PH": "ძიება"
  },
  "ru": {
    "SEARCH_PH": "Поиск"
  },
  "uk": {
    "SEARCH_PH": "Пошук"
  }
}
</i18n>
