import Imgproxy from '@shelf.network/imgproxy'
import { breakpoints } from '@shelf.network/ui-kit'
import { checkWebPSupport } from 'supports-webp-sync'
import { config } from '@/config'

const DEFAULT_PLACEHOLDER_BLUR = 5 // percents
const DEFAULT_RESIZE_MODE = 'fill'

const imgproxy = config.IMGPROXY_URL
  ? new Imgproxy({ baseUrl: config.IMGPROXY_URL })
  : null

const webPSupported = checkWebPSupport()

/**
 * Generates props for UiImg.
 * Makes use of imgproxy when it's available.
 *
 * @param {string} src Image source.
 * @param {string} [alt] Alt caption.
 * @param {object[]} [srcSetOptions] Desired source sizes https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-srcset.
 * @param {number} srcSetOptions[].width Width in pixels.
 * @param {number} srcSetOptions[].height Height in pixels.
 * @param {string} [srcSetOptions[].mode] Resize mode: fit, fill or auto.
 * @param {string} [sizes] A set of source sizes. See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-sizes
 * @param {number} [progressive] Show a low-res placeholder while img is loaded.
 * @param {object} [placeholderOptions] Placeholder options for progressive img.
 * @param {number} [placeholderOptions.width] Width in pixels.
 * @param {number} [placeholderOptions.height] Width in pixels.
 * @param {string} [placeholderOptions.mode] Resize mode: fit, fill or auto.
 *
 * @returns {object} UiImg props.
 */
export function generateImageProps ({
  src,
  alt,
  srcSetOptions = [],
  sizes,
  progressive = true,
  placeholderOptions,
}) {
  if (!imgproxy) {
    return { src, alt }
  }

  const optimalExtension = webPSupported ? 'webp' : 'jpg'

  const wrappedSrc = imgproxy
    .builder()
    .resize('fit')
    .generateUrl(src, optimalExtension)

  const srcset = srcSetOptions
    .map(x => {
      const url = imgproxy
        .builder()
        .resize(
          x.mode || DEFAULT_RESIZE_MODE,
          x.width,
          x.height
        )
        .generateUrl(src, optimalExtension)

      return `${url} ${x.width}w`
    })
    .join(', ')

  let srcPlaceholder
  if (progressive) {
    srcPlaceholder = imgproxy
      .builder()
      .blur(placeholderOptions.blur || DEFAULT_PLACEHOLDER_BLUR)
      .resize(
        placeholderOptions.mode || DEFAULT_RESIZE_MODE,
        placeholderOptions.width,
        placeholderOptions.height
      )
      .generateUrl(src, 'jpg')
  }

  return {
    src: wrappedSrc,
    alt,
    srcset,
    sizes,
    srcPlaceholder
  }
}

/**
 * Shortcut for generateImageProps for tiny images like
 * avatars, small lot imgs, etc
 *
 * @param {string} src
 * @param {boolean} [progressive] Show a low-res placeholder while img is loaded
 *
 * @returns {object} UiImg props.
 */
export function lazyTinyImg (src, progressive = false) {
  return generateImageProps({
    src: src,
    srcSetOptions: [
      { width: 24, height: 24 },
      { width: 48, height: 48 },
      { width: 64, height: 64 },
      { width: 128, height: 128 },
    ],
    sizes: [
      `(max-width: ${breakpoints.XS}px) 33vw`,
      `(max-width: ${breakpoints.SM}px) 25vw`,
      '15vw',
    ].join(', '),
    progressive,
    placeholderOptions: {
      width: 128,
      height: 128,
    },
  })
}

export function lazySmallImg (src = '', alt = '') {
  return generateImageProps({
    src,
    alt,
    srcSetOptions: [
      { width: 240, height: 180 },
      { width: 320, height: 240 },
      { width: 480, height: 360 },
      { width: 640, height: 480 }
    ],
    sizes: [
      `(max-width: ${breakpoints.XS}px) 30vw`,
      `(max-width: ${breakpoints.SM}px) 20vw`,
      '8vw',
    ].join(', '),
    placeholderOptions: { width: 32, height: 24 },
  })
}
