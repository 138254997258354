import { brokersActions, brokersMutations, brokersGetters } from './types'
import { accountTypes } from '@shelf.network/js-sdk'
import { sdk } from 'Services/shelfNetworkSdk'
import { i18n } from 'I18n/instance'
import set from 'lodash/set'
import { userGetters } from 'Store/entities/User/types'
import { showError } from 'Utils/notifications'

export default {
  async [brokersActions.LOAD_BROKERS] ({ commit, rootGetters }, payload = {}) {
    set(payload, 'filter.platform', rootGetters[`entities/user/${userGetters.PLATFORM_ID}`])

    const { data } = await sdk.backOffice.v2.getBrokers(payload)

    commit(brokersMutations.SET_BROKERS, data)
  },

  async [brokersActions.FETCH_BROKER] (
    { commit, dispatch, getters, rootGetters },
    cityId
  ) {
    if (!getters[brokersGetters.NEED_TO_FETCH_BROKER](cityId)) {
      return
    }

    let response

    try {
      response = await sdk.horizon.brokers.getPage({
        'filter[platform_id]': rootGetters[`entities/user/${userGetters.PLATFORM_ID}`],
        'filter[city_id]': cityId
      })
    } catch (error) {
      showError(i18n.t('TOASTS.LOAD_BROKERS_ERROR'))
      return
    }

    const brokers = response.data
      .filter(x => x.accountType === accountTypes.broker)

    commit(brokersMutations.SET_BROKER, { cityId, broker: brokers[0] || null })
  },

  async [brokersActions.RESET] ({ commit }) {
    commit(brokersMutations.RESET)
  },
}
