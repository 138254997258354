import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import ClaimsSkeleton from './components/ClaimsSkeleton'

const moduleName = 'extension-claims'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: ClaimsSkeleton,
  importComponent: () => import('./Claims.vue'),
  async createDependencies () {
    const { default: claims } = await import('./store')
    store.registerModule(['ui', moduleName], claims)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
