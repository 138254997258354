<template>
  <div class="archive-skeleton">
    <page-subnav class="archive-skeleton__subnav" />

    <div class="archive-skeleton__list">
      <archive-item-skeleton />
      <archive-item-skeleton />
      <archive-item-skeleton />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import ArchiveItemSkeleton from './ArchiveItemSkeleton'

export default {
  name: 'archive-skeleton',
  components: {
    PageSubnav,
    ArchiveItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/archive";

.archive-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 2em $list-padding-side;
  }
}
</style>
