<template>
  <div class="list-params-sort list-params-row">
    <label class="list-params-row__label">
      {{ label || $t('SORT_LBL') }}
    </label>

    <ui-select
      class="list-params-row__field"
      fill="frame"
      look="secondary"
      :options="options"
      :placeholder="placeholder || $t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
      :value="value || defaultValue"
      @input="onInput"
    />
  </div>
</template>

<script>
import { UiSelect } from '@shelf.network/ui-kit'

export default {
  name: 'list-params-sort',
  components: { UiSelect },

  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    defaultValue: { type: String, default: '' },
    placeholder: { type: String, default: '' },

    options: {
      type: Array,
      required: true,
      validate: (array = []) => array.every(item => {
        return item.hasOwnProperty('label') && typeof item.label === 'string' &&
          item.hasOwnProperty('value') && typeof item.value === 'string'
      }),
    },
  },

  methods: {
    onInput (value) {
      this.$emit('input', value === this.defaultValue ? '' : value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./styles/list-params-row.scss";
</style>

<i18n>
{
  "en": {
    "SORT_LBL": "ORDER BY"
  },
  "ka": {
    "SORT_LBL": "ᲡᲝᲠᲢᲘᲠᲔᲑᲐ"
  },
  "ru": {
    "SORT_LBL": "УПОРЯДОЧИТЬ ПО"
  },
  "uk": {
    "SORT_LBL": "ВПОРЯДКУВАТИ ЗА"
  }
}
</i18n>
