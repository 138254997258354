<template>
  <ui-portal to="notifications">
    <notifications
      group="default"
      position="bottom left"
      :duration="10000"
      class="notification-item"
    >
      <div
        class="notification-item__body"
        slot="body"
        slot-scope="props"
      >
        <div
          class="notification-item__icon-wrp"
          :class="'notification-item__icon-wrp_' + props.item.type"
        >
          <ui-icon
            class="notification-item__icon"
            :class="'notification-item__icon_' + props.item.type"
            :icon="{
              success: 'success',
              error: 'close',
              info: 'info',
            }[props.item.type]"
          />
        </div>

        <p class="notification-item__text">
          {{ props.item.text }}
        </p>

        <div class="notification-item__actions">
          <template v-if="(props.item.data || {}).route">
            <router-link
              class="notification-item__action-btn"
              :to="props.item.data.route"
              @click.native="props.close()"
            >
              {{ $t('VIEW_BTN') }}
            </router-link>
          </template>

          <template v-if="(props.item.data || {}).undoAction">
            <button
              class="notification-item__action-btn"
              @click="undo(props)"
              fill="none"
            >
              {{ $t('UNDO_BTN') }}
            </button>
          </template>

          <button
            class="notification-item__close-btn"
            @click="props.close"
          >
            <ui-icon icon="close" />
          </button>
        </div>
      </div>
    </notifications>
  </ui-portal>
</template>

<script>
import { UiIcon, UiPortal } from '@shelf.network/ui-kit'

export default {
  name: 'status-message',
  components: {
    UiIcon,
    UiPortal,
  },
  methods: {
    undo (props) {
      if (props.item.data.$isUndone) {
        return
      }

      props.item.data.$isUndone = true
      props.item.data.undoAction()
      props.close()
    },
  },
}
</script>

<style lang="scss" scoped>
$notification-item-margin: 1em;

.notification-item,
.notifications.notification-item {
  background: none;
  max-width: 375px;
  z-index: z-index(modal);
  // override defaults of vue-notification
  width: calc(100vw - #{$notification-item-margin}) !important;

  & /deep/ .notification-wrapper {
    overflow: visible;

    &.vn-fade-leave-active,
    &.vn-fade-leave-to,
    &.vn-fade-enter-active,
    &.vn-fade-enter-to {
      // override css cuz `speed` prop seems to work only for the last item
      transition: all 220ms !important;
    }
  }

  &__body {
    @include respond-font-size(15px);

    border-radius: 1em;
    background: $color-light;
    display: flex;
    margin: 0 0 $notification-item-margin 1em;
    align-items: center;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }

  &__icon-wrp {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;

    &_success {
      background: $color-flag-is-success;
    }

    &_error {
      background: $color-flag-is-error;
    }

    &_info {
      background: $color-sys-info;
    }
  }

  &__icon {
    background: $color-light;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::before {
      font-size: 1.2em;
      font-weight: 700;
    }

    &_success {
      color: $color-flag-is-success;
    }

    &_error {
      color: $color-flag-is-error;
    }

    &_info {
      color: $color-sys-info;
    }
  }

  &__text {
    color: $color-dark;
    padding: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 1em 0.5em;
  }

  &__action-btn {
    padding: 0 0.5em;
    color: $color-dark;
    font-weight: 600;
  }

  &__close-btn {
    padding: 0 0.5em;
    color: $color-grey;

    /deep/ .ui-icon {
      font-size: 1.75em;
    }
  }

  &__action-btn,
  &__close-btn {
    background: none;
    border: none;
    margin-left: auto;
    transition: opacity 150ms;
    text-decoration: none;
    display: inline-block;

    &:not(:first-child) {
      margin-left: 0.5em;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "UNDO_BTN": "UNDO",
    "VIEW_BTN": "VIEW"
  },
  "ka": {
    "UNDO_BTN": "ᲒᲐᲣᲥᲛᲔᲑᲐ",
    "VIEW_BTN": "ᲜᲐᲮᲕᲐ"
  },
  "ru": {
    "UNDO_BTN": "ОТМЕНИТЬ",
    "VIEW_BTN": "ПОСМОТРЕТЬ"
  },
  "uk": {
    "UNDO_BTN": "СКАСУВАТИ",
    "VIEW_BTN": "ПОДИВИТИСЯ"
  }
}
</i18n>
