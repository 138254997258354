export const USER_CLAIMS = Object.freeze({
  MY_TRADES: 'myTrades',
  CARFAX: 'carfax',
  UPLOAD: 'upload',
  BROKERS: 'brokers',
  TBC_PAYMENTS: 'tbcPayments',
  LEADS: 'leads',
  ANALYTICS: 'analytics',
  BROKER_ASSIGNMENT: 'brokerAssignment',
  DEPOSITS_VIEW: 'depositsView',
  DEPOSITS_MANAGEMENT: 'depositsManagement',
  ACTIVE_TRADES: 'activeTrades',
  REJECT_PARTICIPANT: 'rejectParticipant',
  WINNER_MANAGEMENT: 'winnerManagement',
  NOTIFICATIONS: 'notifications',
  DIRECT_SALE_MODERATION: 'directSaleModeration',
  W8_TRANSPORTATION: 'w8Transportation',
  ARCHIVE: 'archive',
  EDIT_PROFILE: 'editProfile',
  WON: 'won',
  LEAD_FOLLOW: 'leadFollow',
  USER_MANAGEMENT: 'userManagement',
  SHELF_AP_FEATURES: 'shelfApFeatures',
  BID_LIMIT_MANAGEMENT: 'bidLimitManagement',
  CLOSE_PLAYING_LOTS: 'closePlayingLots',
  SEARCH: 'search',

  // Extension
  EXTENSION_FEES: 'tranportationFees',
  EXTENSION_DEALERS: 'extensionUsers',
  EXTENSION_WON: 'extensionWon',
  EXTENSION_ACCOUNTS: 'extensionAccounts',
  EXTENSION_ANALYTICS: 'extensionAnalytics',
  EXTENSION_CLAIMS: 'extensionClaims',
  EXTENSION_NOTIFICATIONS: 'extensionNotifications',

  IMPACT_EXTENSION_DEALERS: 'impactExtensionUsers',
  IMPACT_EXTENSION_WON: 'impactExtensionWon',
  IMPACT_EXTENSION_ACCOUNTS: 'impactExtensionAccounts',

  // Legacy features
  LOCATION_BASED_BROKERS: 'locationBasedBrokers'
})
