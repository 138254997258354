<template>
  <div class="cell-customer cells-cmn__cell">
    <!-- TODO: apply to other items -->

    <lazy-ava-img
      class="cells-cmn__ava"
      :alt="customer.fullName"
      :title="customer.fullName"
      :src="customer.avatarLink"
    />

    <button
      class="cells-cmn__btn"
      @click="isOverviewShown = true"
      :disabled="!customer.leadId"
    >
      <span
        class="cells-cmn__str cells-cmn__str_nb"
        :title="customer.fullName"
      >
        {{ customer.fullName || $t('COMMON.UNNAMED_USER_PH') }}
      </span>

      <template v-if="isPro">
        <span class="cells-cmn__str cell-customer__pro-mark">
          {{ $t('PRO_TXT') }}
        </span>
      </template>
    </button>

    <template v-if="isOverviewShown">
      <lead-overview
        class="cell-customer__lead-overview"
        :lead-id="customer.leadId"
        @close="isOverviewShown = false"
      />
    </template>
  </div>
</template>

<script>
import LazyAvaImg from 'Common/LazyAvaImg'
import { Customer } from 'Models/Customer'
import { Identity } from 'Models/Identity'
import LeadOverview from 'Common/LeadOverview'

export default {
  name: 'cell-customer',

  components: {
    LazyAvaImg,
    LeadOverview,
  },

  props: {
    customer: {
      type: Customer,
      required: true,
    },
  },

  data () {
    return {
      isOverviewShown: false,
    }
  },

  computed: {
    isPro () {
      return this.customer.identityType === Identity.typesEnum.business
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./cells.scss";

.cell-customer {
  &__pro-mark {
    color: $color-user-pro;
  }
}
</style>

<i18n>
{
  "en": {
    "PRO_TXT": "PRO"
  },
  "ka": {
    "PRO_TXT": "PRO"
  },
  "ru": {
    "PRO_TXT": "PRO"
  },
  "uk": {
    "PRO_TXT": "PRO"
  }
}
</i18n>
