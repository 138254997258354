import { ModelBase } from 'Models/ModelBase'
import { sdk } from 'Services/shelfNetworkSdk'
import get from 'lodash/get'

export class Attachment extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.blobId = get(raw, 'relationships.blob.id', '')
    this.attachedAt = new Date(raw.attachedAt)
    this.contentType = raw.contentType || 'application/octet-stream'
    this.isImage = this.contentType.startsWith('image/')
    this.filename = raw.originalFilename

    this.src = this.blobId
      ? sdk.backOffice.v2.getBlobUrl(this.blobId)
      : ''
  }
}
