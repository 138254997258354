import { ShelfNetwork } from '@shelf.network/js-sdk'
import { config } from '../config'

/** @type {ShelfNetwork} */
export let sdk

export function init () {
  sdk = new ShelfNetwork({
    gatewayUrl: config.GATEWAY_URL,
    allowHttp: true
  })
}
