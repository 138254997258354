<template>
  <ui-portal>
    <ui-modal
      class="lead-create"
      :title="$t('CREATE_LEAD_TITLE')"
      :show-close-btn="!isLoading"
      @close="$emit('close')"
    >
      <div
        class="lead-create__form"
        :class="{ 'lead-create__form_loading': isLoading }"
      >
        <div
          v-if="errMessage"
          class="lead-create__result-message"
          :class="{'lead-create__result-message_error': !isDeadEnd}">
          {{ errMessage }}
        </div>

        <template v-if="!isDeadEnd">
          <div class="lead-create__field">
            {{ $t('USER_CHANNEL_LBL') }}
            <ui-dropdown
              class="lead-create__field-input lead-create__field-channel"
              select
              fill="frame"
            >
              <template v-if="formData.channel.value">
                <channel-icon
                  :channel="formData.channel.value"
                  class="lead-create__field-channel-icon" />
                {{ channels[formData.channel.value] }}
              </template>
              <template v-else>
                {{ $t('USER_CHANNEL_LBL') }}
              </template>

              <div slot="content">
                <ui-dropdown-item
                  v-for="(label, val) in channels"
                  :key="val"
                  interactive
                  :active="val === formData.channel.value"
                  @click="selectChannel(val)"
                  auto-close
                >
                  <channel-icon
                    :channel="val"
                    class="lead-create__field-channel-icon" />
                  {{ label }}
                </ui-dropdown-item>
              </div>
            </ui-dropdown>
          </div>

          <div class="lead-create__field">
            {{ $t('USER_NAME_LBL') }}
            <ui-text
              class="lead-create__field-input"
              :is-error="!formData.fullName.valid"
              v-model.trim="formData.fullName.value"
              @input="formData.fullName.overrided = false"
            >
              <ui-icon
                icon="user"
                slot="dock-left" />
            </ui-text>
          </div>

          <div class="lead-create__field">
            {{ $t('USER_PHONE_LBL') }}
            <ui-phone
              class="lead-create__field-input"
              :is-error="!formData.phoneNumber.valid"
              v-model="formData.phoneNumber.value"
              placeholder=""
              :pinned-countries="phoneCountries"
              filter-countries
            />
          </div>

          <div class="lead-create__buttons">
            <ui-button
              @click="createLead"
              class="lead-create__btn">
              {{ $t('CREATE_NEW_LEAD_BTN') }}
            </ui-button>

            <ui-button
              @click="close"
              class="lead-create__btn"
              look="secondary"
              fill="frame-hover">
              {{ $t('CANCEL_BTN') }}
            </ui-button>
          </div>
        </template>

        <template v-else>
          <div>
            <ui-button
              @click="close"
              class="lead-create__btn"
              look="secondary">
              {{ $t('CANCEL_BTN') }}
            </ui-button>
          </div>
        </template>
      </div>
      <ui-spinner
        class="lead-create__loader"
        overlay
        type="pills"
        :show="isLoading"
      />
    </ui-modal>
  </ui-portal>
</template>

<script>
import { sdk } from 'Services/shelfNetworkSdk'
import get from 'lodash/get'
import {
  UiButton,
  UiPhone,
  UiText,
  UiSpinner,
  UiIcon,
  UiModal,
  UiDropdown,
  UiDropdownItem,
  UiPortal,
} from '@shelf.network/ui-kit'
import ChannelIcon from 'Common/ChannelIcon'
import { Lead } from 'Models/Lead'

import { PHONE_COUNTRIES } from 'Constants/phoneCountries'
import { validatePhone } from 'Utils/validators'

export default {
  name: 'lead-create',
  components: {
    UiButton,
    UiPhone,
    UiText,
    UiSpinner,
    UiIcon,
    UiModal,
    UiDropdown,
    UiDropdownItem,
    UiPortal,
    ChannelIcon,
  },
  props: {
    dataOverride: { type: Object, default: null },
    autoRedirect: { type: Boolean, default: false },
  },
  data () {
    return {
      formData: {
        fullName: { value: '', valid: true, overrided: false },
        phoneNumber: { value: '', valid: true, overrided: false },
        dialogId: { value: null, overrided: false },
        channel: { value: Lead.channelsEnum.phone, overrided: false },
      },
      isLoading: false,
      isDeadEnd: false,
      errMessage: '',
    }
  },
  computed: {
    channels () {
      return {
        [Lead.channelsEnum.phone]: this.$t('COMMON.LEAD_CHANNELS.PHONE'),
        [Lead.channelsEnum.email]: this.$t('COMMON.LEAD_CHANNELS.EMAIL'),
        [Lead.channelsEnum.website]: this.$t('COMMON.LEAD_CHANNELS.WEBSITE'),
        [Lead.channelsEnum.telegram]: this.$t('COMMON.LEAD_CHANNELS.TELEGRAM'),
        [Lead.channelsEnum.carfax]: this.$t('COMMON.LEAD_CHANNELS.CARFAX'),
        [Lead.channelsEnum.facebook]: this.$t('COMMON.LEAD_CHANNELS.FACEBOOK'),
        [Lead.channelsEnum.rajdeba]: this.$t('COMMON.LEAD_CHANNELS.RAJDEBA'),
        [Lead.channelsEnum.facebookWeb]: this.$t('COMMON.LEAD_CHANNELS.FACEBOOK_WEB'),
      }
    },
    phoneCountries: () => PHONE_COUNTRIES,
  },
  watch: {
    dataOverride: {
      immediate: true,
      handler (dataOverride) {
        if (!dataOverride) return

        if (dataOverride.dialogId) {
          this.formData.dialogId.value = dataOverride.dialogId
          this.formData.dialogId.overrided = true
        }

        if (dataOverride.fullName) {
          this.formData.fullName.value = dataOverride.fullName
          this.formData.fullName.overrided = true
        }

        if (dataOverride.channel) {
          this.formData.channel.value = dataOverride.channel
          this.formData.channel.overrided = true
        }
      }
    }
  },
  methods: {
    selectChannel (ch) {
      this.formData.channel.value = ch
    },
    validate () {
      this.formData.fullName.valid =
        Boolean(this.formData.fullName.value)

      this.formData.phoneNumber.valid =
        validatePhone(this.formData.phoneNumber.value)

      return this.formData.fullName.valid && this.formData.phoneNumber.valid
    },
    async createLead () {
      this.isDeadEnd = false
      this.errMessage = ''

      try {
        if (!this.validate()) {
          this.errMessage = this.$t('ERR.INVALID_USER_CONTACTS')
          return
        }

        this.isLoading = true
        const req = {
          fullName: this.formData.fullName.value,
          channel: this.formData.channel.value,
          phoneNumber: this.formData.phoneNumber.value,
          auxiliaryContacts: [],
          isArtificial: true,
        }

        if (this.formData.dialogId.value) {
          req.auxiliaryContacts.push({
            channel: Lead.channelsEnum.facebook,
            data: this.formData.dialogId.value
          })
        }

        const result =
          await sdk.backOffice.v2.createAnnonymousSupportRequest(req)

        const leadId = get(result, 'relationships.lead.id')

        if (leadId) {
          this.$emit('lead-created', leadId)
          this.close()

          if (this.autoRedirect) {
            await this.$nextTick()
            this.$router.push({ params: { leadId } })
          }
        }
      } catch (e) {
        if (e.httpStatus === 403) {
          this.isDeadEnd = true
          this.errMessage = this.$t('ERR.LEAD_EXISTS_MESSAGE')
        } else {
          this.errMessage = this.$t('ERR.INVALID_USER_CONTACTS')
        }
      }
      this.isLoading = false
    },
    close () {
      this.$emit('close')
    }
  },
}
</script>

<style scoped lang="scss">
.lead-create {
  &__form {
    max-width: 100%;
    width: 33em;
    padding: 2em 0.5em;

    &_loading {
      filter: blur(0.2em);
    }
  }

  &__result-message {
    text-align: center;
    font-size: 1.4em;
    padding-bottom: 1.5em;
    white-space: pre-line;

    &_error {
      font-size: 1.3em;
      color: $color-flag-is-error;
    }
  }

  &__field {
    margin-bottom: 2em;

    &-input {
      margin-top: 0.4em;
      display: flex;
    }

    &-channel {
      display: block;

      &-icon {
        font-size: 1.5em;
        margin-right: 0.4em;
        transform: scale(1.3);
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 5em;
    padding-top: 2em;
    border-top: 1px solid $color-light-grey;

    @include respond-below(sm) {
      flex-direction: column;
    }
  }

  &__btn {
    margin: 1.2em 0;
    min-width: 15em;

    @include respond-below(sm) {
      min-width: 0;
      margin: 1em 0;
    }
  }

  & /deep/ .modal__content {
    max-width: 36em;

    @include respond-below(sm) {
      max-width: unset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CREATE_NEW_LEAD_BTN": "Create new Lead",
    "CREATE_LEAD_TITLE": "Create Lead",
    "CANCEL_BTN": "Cancel",
    "USER_PHONE_LBL": "User Phone Number",
    "USER_NAME_LBL": "User Full Name",
    "USER_COMMENT_LBL": "Notes",
    "ERR": {
      "LEAD_EXISTS_MESSAGE": "Support request has been created.\nPlease, contact your administrator if you need access this lead.",
      "INVALID_USER_CONTACTS": "User data is invalid. Please, make sure you specified at least one contact."
    },
    "USER_CHANNEL_LBL": "Channel",
    "LEAD_CREATED_NOTIFICATION": "Lead created successfully"
  },
  "ka": {
    "CREATE_NEW_LEAD_BTN": "ლიდის შექმნა",
    "CREATE_LEAD_TITLE": "ლიდის შექმნა",
    "CANCEL_BTN": "დახურვა",
    "USER_PHONE_LBL": "ტელეფონის ნომერი",
    "USER_NAME_LBL": "სახელი და გვარი",
    "USER_COMMENT_LBL": "კომენტარები",
    "ERR": {
      "LEAD_EXISTS_MESSAGE": "აღნიშნული ლიდი უკვე არსებობს, წვდომისთვის გთხოვთ მიმართოთ ადმინისტრატორს.",
      "INVALID_USER_CONTACTS": "მონაცემები არასწორია, გთხოვთ გადაამოწომთ."
    },
    "USER_CHANNEL_LBL": "წყარო",
    "LEAD_CREATED_NOTIFICATION": "ლიდი შექმნილია წარმატებით"
  },
  "ru": {
    "CREATE_NEW_LEAD_BTN": "Создать новый Лид",
    "CREATE_LEAD_TITLE": "Создать Лид",
    "CANCEL_BTN": "Закрыть",
    "USER_PHONE_LBL": "Телефон пользователя",
    "USER_NAME_LBL": "Полное имя пользователя",
    "USER_COMMENT_LBL": "Примечания",
    "ERR": {
      "LEAD_EXISTS_MESSAGE": "Запрос поддержки создан.\nПожалуйста, обратитесь к администратору, если вам нужен доступ к этому Лиду.",
      "INVALID_USER_CONTACTS": "Данные пользователя недействительны. Пожалуйста, убедитесь, что вы указали хотя бы один контакт."
    },
    "USER_CHANNEL_LBL": "Канал",
    "LEAD_CREATED_NOTIFICATION": "Лид успешно создан"
  },
  "uk": {
    "CREATE_NEW_LEAD_BTN": "Створити новий Лід",
    "CREATE_LEAD_TITLE": "Створити Лід",
    "CANCEL_BTN": "Закрити",
    "USER_PHONE_LBL": "Номер телефону користувача",
    "USER_NAME_LBL": "Повне ім'я користувача",
    "USER_COMMENT_LBL": "Примітки",
    "ERR": {
      "LEAD_EXISTS_MESSAGE": "Запит підтримки створено.\nБудь ласка, зверніться до адміністратора, якщо вам потрібен доступ до цього Ліда.",
      "INVALID_USER_CONTACTS": "Дані користувача недійсні. Будь ласка, переконайтеся, що ви вказали хоча б один контакт."
    },
    "USER_CHANNEL_LBL": "Канал",
    "LEAD_CREATED_NOTIFICATION": "Лід успішно створено"
  }
}
</i18n>
