<template>
  <div class="lead-lots-carousel">
    <div
      class="lead-lots-carousel__slider"
      ref="lotsContainer"
      @scroll="debounceScroll"
    >
      <div
        v-for="(lot, i) in lots"
        :key="i"
        class="lead-lots-carousel__slider-item"
      >
        <lead-lot
          :lot="lot"
          ref="lots"
        />

        <ui-button
          class="lead-lots-carousel__slider-item-remove"
          fill="none"
          look="secondary"
          @click="detachLot(lot.id)"
        >
          <ui-icon icon="close" />
        </ui-button>
      </div>

      <lead-lot-add
        ref="addLot"
        class="
          lead-lots-carousel__slider-item
          lead-lots-carousel__slider-item_add
        "
        :lead="lead"
      />
    </div>
    <div class="lead-lots-carousel__slider-pager">
      <ui-button
        @click="scrollToLot(currIndex - 1)"
        class="
          lead-lots-carousel__slider-pager-btn
          lead-lots-carousel__slider-pager-btn_left
        "
        fill="none"
        :is-disabled="currIndex === 0"
      >
        <ui-icon icon="arrow-left" />
      </ui-button>
      <ui-button
        class="
          lead-lots-carousel__slider-pager-btn
          lead-lots-carousel__slider-pager-btn_num
        "
        fill="none"
        :look="currIndex === page.index ? 'default' : 'secondary'"
        @click="scrollToLot(page.index)"
        v-for="page in lotsPages"
        :key="page.index"
      >
        {{ page.text }}
      </ui-button>

      <ui-button
        class="
          lead-lots-carousel__slider-pager-btn
          lead-lots-carousel__slider-pager-btn_add
        "
        fill="none"
        :look="currIndex === totalLots ? 'default' : 'secondary'"
        @click="scrollToLot(totalLots)"
      >
        <ui-icon icon="plus" />
      </ui-button>

      <ui-button
        @click="scrollToLot(currIndex + 1)"
        class="
          lead-lots-carousel__slider-pager-btn
          lead-lots-carousel__slider-pager-btn_right
        "
        fill="none"
        :is-disabled="currIndex === totalLots"
      >
        <ui-icon icon="arrow-right" />
      </ui-button>
    </div>
  </div>
</template>

<script>
import { Lead } from 'Models/Lead'
import { Lot } from 'Models/Lot'
import LeadLot from './LeadLot'
import LeadLotAdd from './LeadLotAdd'
import { actions } from '../store/types'
import { mapActions } from 'vuex'
import { UiButton, UiIcon } from '@shelf.network/ui-kit'
import debounce from 'lodash/debounce'
import { showSuccess } from 'Utils/notifications'
import { IS_SAFARI_BROWSER } from 'Constants/userAgent'
import scrollIntoView from 'scroll-into-view'

const LOTS_PAGER_RANGE = 3

export default {
  name: 'lead-lots-carousel',

  components: { LeadLot, UiButton, UiIcon, LeadLotAdd },

  props: {
    lead: { type: Lead, required: true }
  },

  data () {
    return {
      LOTS_PAGER_RANGE,
      currIndex: 0,
    }
  },

  computed: {
    lotsPages () {
      const lotsPages = []
      const pageFirt = this.currIndex + LOTS_PAGER_RANGE
      const pageLast = this.currIndex - LOTS_PAGER_RANGE

      this.lead.lots.forEach((e, index) => {
        if (index <= pageFirt && index >= pageLast) {
          lotsPages.push({
            text: (index === pageFirt || index === pageLast)
              ? '...'
              : index + 1,
            index: index
          })
        }
      })
      return lotsPages
    },
    lots () {
      return this.lead.lots
    },
    totalLots () {
      return this.lead.lots.length
    }
  },

  watch: {
    totalLots: {
      immediate: true,
      handler (value) {
        this.emitCurrentLotChangedDebounced()
      }
    },

    currIndex: {
      immediate: true,
      handler (value) {
        this.emitCurrentLotChangedDebounced()
      }
    }
  },

  methods: {
    ...mapActions('ui/leads', {
      detachLeadLotAction: actions.DETACH_LEAD_LOT,
    }),

    emitCurrentLotChanged () {
      const lot = this.lots[this.currIndex] || new Lot({})
      this.$emit('current-lot-changed', lot)
    },

    emitCurrentLotChangedDebounced: debounce(function () {
      this.emitCurrentLotChanged()
    }, 500),

    async detachLot (lotId) {
      try {
        const undo = await this.detachLeadLotAction({
          leadId: this.lead.id,
          lotId,
        })
        showSuccess({
          text: this.$t('LOT_DETACHED_NOTIFICATION', { lotId }),
          undoAction: undo,
        })
      } catch (e) {
        console.error(e)
      }
    },

    debounceScroll: debounce(function () {
      const contentLeft = this.$refs.lotsContainer.getBoundingClientRect().left
      const someLotIndex = this.$refs.lots.some(({ $el }, i) => {
        if ($el.getBoundingClientRect().left - contentLeft >= 0) {
          this.currIndex = i
          return true
        }
      })
      if (!someLotIndex) {
        this.currIndex = this.totalLots
      }
    }, 50),

    scrollToLot (index) {
      const element = index < this.totalLots
        ? this.$refs.lots[index].$el
        : this.$refs.addLot.$el

      IS_SAFARI_BROWSER
        ? scrollIntoView(element, { time: 250 })
        : element.scrollIntoView({ behavior: 'smooth', block: 'end' })
    },
  }
}
</script>

<style scoped lang="scss">
$slider-neg-margin: -1.75em;

.lead-lots-carousel {
  position: relative;

  &__spinner {
    border-radius: $layout-border-radius;
    margin: 0 $slider-neg-margin;
  }

  &__slider {
    margin: 0 $slider-neg-margin;
    padding-bottom: 1em;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    display: flex;

    @include scrollbar-invisible();

    &::after,
    &::before {
      content: "";
      width: 5%;
      min-width: 5%;
      flex: 1;
    }

    &-item {
      position: relative;
      min-width: 90%;
      min-height: 19em;
      margin: 1.5%;
      white-space: normal;
      scroll-snap-align: center;
      flex: 1;
      overflow: hidden;
      border-radius: $layout-border-radius;
      background: $color-white;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &_add {
        position: relative;
      }

      &-remove {
        position: absolute;
        right: 0;
        top: 0.5em;

        & /deep/ .ui-icon {
          font-size: 2em;
        }
      }
    }

    &-pager {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-pager-btn {
      &_left {
        margin-right: auto;

        & /deep/ .ui-icon {
          font-size: 2em;
        }
      }

      &_right {
        margin-left: auto;

        & /deep/ .ui-icon {
          font-size: 2em;
        }
      }

      &_add /deep/ .ui-icon {
        font-size: 1.2em;
      }

      &_num,
      &_add {
        & /deep/ .ui-button__button {
          width: 3.1em;
        }

        & /deep/ .ui-button__button[fill="none"][look="default"] {
          background: $color-light-grey !important;
        }
      }

      & /deep/ {
        & .ui-button__button {
          height: auto;
          font-weight: bold;
          font-size: 1.1em;
          padding: 1em;
          line-height: 1;
        }
      }
    }
  }
}

@include respond-below(sm) {
  .lead-lots-carousel {
    &__slider {
      &-pager {
        &-btn {
          &_add {
            font-size: 1.5em;
            margin: 1em auto;

            & /deep/ .ui-button__button {
              background: $color-light-grey !important;
            }
          }

          &_right,
          &_left,
          &_num {
            display: none;
          }
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "LOT_DETACHED_NOTIFICATION": "Lot #{lotId} detached"
  },
  "ka": {
    "LOT_DETACHED_NOTIFICATION": "ლოტი #{lotId} წაშლილია"
  },
  "ru": {
    "LOT_DETACHED_NOTIFICATION": "Лот #{lotId} откреплен"
  },
  "uk": {
    "LOT_DETACHED_NOTIFICATION": "Лот #{lotId} відкріплений"
  }
}
</i18n>
