const actions = {
  FETCH_RATES: 'FETCH_RATES',
  START_RATES_UPDATER: 'START_RATES_UPDATER',
  STOP_RATES_UPDATER: 'STOP_RATES_UPDATER',
}

const mutations = {
  SET_EXCHANGE_RATES: 'SET_EXCHANGE_RATES',
  SET_BASE_CURRENCY: 'SET_BASE_CURRENCY',
}

const getters = {
  EXCHANGE_RATES: 'EXCHANGE_RATES',
  BASE_CURRENCY: 'BASE_CURRENCY',
}

export {
  actions as exRatesActions,
  mutations as exRatesMutations,
  getters as exRatesGetters,
}
