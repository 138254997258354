<template>
  <div class="my-trades-skeleton">
    <page-subnav class="my-trades-skeleton__subnav" />

    <div class="my-trades-skeleton__list" />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'

export default {
  name: 'my-trades-skeleton',
  components: {
    PageSubnav,
  },
}
</script>

<style scoped lang="scss">
.my-trades-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 2em;
  }
}
</style>
