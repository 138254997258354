import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import SearchSkeleton from './components/SearchSkeleton'

const moduleName = 'search'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: SearchSkeleton,
  importComponent: () => import('./Search'),
  async createDependencies () {
    const { default: searchStore } = await import('./store')
    store.registerModule(['ui', moduleName], searchStore)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
