import Vue from 'vue'
import Vuex from 'vuex'

import leads from 'Components/Leads/store'

import rootStore from './root/index'
import entities from './entities/index'

import { initRootHelpers } from './helpers/rootHelpers'

Vue.use(Vuex)

const store = new Vuex.Store({
  ...rootStore,
  modules: {
    entities,
    ui: {
      namespaced: true,
      modules: { leads }
    },
  }
})

initRootHelpers(store)

export default store
