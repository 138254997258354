<template>
  <span>{{ text }}</span>
</template>

<script>
import { format } from 'timeago.js'

const REFRESH_INTERVAL = 60 * 1000 // ms

export default {
  name: 'time-ago',
  props: {
    date: {
      type: [Date, String, Number],
      required: true
    }
  },
  data () {
    return {
      intervalId: null,
      text: ''
    }
  },
  watch: {
    date: {
      immediate: true,
      handler () {
        this.update()
      }
    }
  },
  created () {
    this.intervalId = setInterval(() => {
      this.update()
    }, REFRESH_INTERVAL)
  },
  destroyed () {
    clearInterval(this.intervalId)
  },
  methods: {
    update () {
      this.text = format(this.date, this.$i18n.locale)
    },
  }
}
</script>
