<template>
  <div class="list-params-flags list-params-row">
    <label class="list-params-row__label">
      {{ label || $t('FLAGS_LBL') }}
    </label>

    <div
      class="list-params-flags__ticks-wrp"
      :class="{
        'list-params-flags__ticks-wrp_start': valueEntries.length !== 4
      }"
    >
      <ui-check
        class="list-params-flags__tick"
        v-for="[key, { label: tLbl, value: tVal }] of valueEntries"
        :key="key"
        :value="tVal"
        @input="$emit('input', { key, value: $event })"
      >
        {{ tLbl }}
      </ui-check>
    </div>
  </div>
</template>

<script>
import { UiCheck } from '@shelf.network/ui-kit'

export default {
  name: 'list-params-flags',

  components: {
    UiCheck,
  },

  props: {
    label: {
      type: String,
      default: '',
    },

    value: {
      type: Object,
      required: true,
      validator: value => Object.values(value).every(entry => {
        return (entry && typeof entry === 'object') &&
          typeof entry.value === 'boolean' &&
          typeof entry.label === 'string'
      }),
    },
  },

  computed: {
    valueEntries () {
      return Object.entries(this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./styles/list-params-row.scss";

.list-params-flags {
  &__ticks-wrp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 0 (-1.4em);
    width: calc(100% + 1.4em);

    &_start {
      justify-content: flex-start;
    }
  }

  &__tick {
    margin-left: 1.4em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;

    &.ui-check {
      cursor: pointer;
      line-height: 1.4em;
    }

    /deep/ .ui-check__frame {
      vertical-align: middle;
      margin-right: 0.5em;
    }

    /deep/ .ui-check__label {
      vertical-align: middle;
      padding: 0;
    }

    /deep/ .ui-check__check ~ .ui-check__label {
      line-height: 1em;
      color: $color-dark;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "FLAGS_LBL": "USER GROUPS"
  },
  "ka": {
    "FLAGS_LBL": "ᲡᲢᲐᲢᲣᲡᲘ"
  },
  "ru": {
    "FLAGS_LBL": "ГРУППЫ ПОЛЬЗОВАТЕЛЕЙ"
  },
  "uk": {
    "FLAGS_LBL": "ГРУПИ КОРИСТУВАЧІВ"
  }
}
</i18n>
