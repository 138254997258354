<template>
  <ui-img
    class="lazy-ava-img"
    v-bind="lazySrc"
  />
</template>

<script>
import { UiImg } from '@shelf.network/ui-kit'
import { lazyTinyImg } from 'Utils/generateImageProps'
import avaPh32Path from 'Assets/icons/ava-ph-32.svg'

export default {
  name: 'lazy-ava-img',

  components: {
    UiImg,
  },

  props: {
    src: {
      type: String,
      default: '',
    },

    placeholderPath: {
      type: String,
      default: avaPh32Path,
    },
  },

  computed: {
    lazySrc () {
      if (this.src) {
        return lazyTinyImg(this.src)
      }

      return { src: this.placeholderPath }
    }
  },
}
</script>

<style lang="scss" scoped>
.lazy-ava-img {
  --ui-img-border-radius: 50%;
}
</style>
