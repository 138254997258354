<template>
  <div class="page-subnav">
    <div class="page-subnav__content">
      <div class="page-subnav__content-main">
        <slot />
      </div>

      <div class="page-subnav__content-right">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-subnav',
}
</script>

<style lang="scss" scoped>
.page-subnav {
  @include scrollbar-invisible();

  overflow-x: auto;
  overflow-y: hidden;

  &:hover,
  &:focus-within {
    // hack to prevent cut of child (in slot) dropdowns
    padding-bottom: 100vh;
    margin-bottom: -100vh;
  }

  &__content {
    display: grid;
    grid: auto / auto-flow auto;
    height: 5em;
    min-width: 100%;
    padding: 0 3em;
    white-space: nowrap;
    background-color: $color-light-grey;

    &-main {
      display: grid;
      grid: auto / auto-flow auto;
      justify-content: start;
      align-items: center;
      gap: 0.75em;
      background-color: $color-light-grey;
    }

    &-right {
      padding-left: 1.5em;
      display: grid;
      grid: auto / auto-flow auto;
      justify-content: end;
      align-items: center;
      background-color: $color-light-grey;
    }

    /*
      TODO: should handle tabs
      pages that use PageSubnav are mostly have the same definition of tabs
      that being inserted into the 'right' slot. we should make a common
      solution for these tabs
    */
  }
}
</style>
