<template>
  <span
    class="pretty-lead-state"
    :data-state="leadStates[state].dataAttrVal"
  >
    <!-- TODO: search for the same pattern and replace with the component -->
    {{ leadStates[state].translated }}
  </span>
</template>

<script>
import { Lead } from 'Models/Lead'

export default {
  name: 'pretty-lead-state',

  props: {
    state: {
      type: String,
      required: true,
    },
  },

  computed: {
    leadStates () {
      return {
        [Lead.statesEnum.waitingCall]: {
          dataAttrVal: 'waiting',
          translated: this.$t('WAITING_STATE'),
        },
        [Lead.statesEnum.onHold]: {
          dataAttrVal: 'hold',
          translated: this.$t('HOLD_STATE'),
        },
        [Lead.statesEnum.lookingCar]: {
          dataAttrVal: 'looking',
          translated: this.$t('LOOKING_STATE'),
        },
        [Lead.statesEnum.offerSent]: {
          dataAttrVal: 'offered',
          translated: this.$t('OFFERED_STATE'),
        },
        [Lead.statesEnum.archived]: {
          dataAttrVal: 'archived',
          translated: this.$t('ARCHIVED_STATE'),
        },
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.pretty-lead-state {
  &[data-state="waiting"] {
    color: $color-status-waiting;
  }

  &[data-state="hold"] {
    color: $color-status-hold;
  }

  &[data-state="looking"] {
    color: $color-status-looking;
  }

  &[data-state="offered"] {
    color: $color-status-offered;
  }

  &[data-state="archived"] {
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "WAITING_STATE": "Waiting for call",
    "HOLD_STATE": "On-hold",
    "LOOKING_STATE": "Looking for a car",
    "OFFERED_STATE": "Offer Sent",
    "ARCHIVED_STATE": "Archived"
  },
  "ka": {
    "WAITING_STATE": "დასარეკი",
    "HOLD_STATE": "ოდესმე",
    "LOOKING_STATE": "ძიება",
    "OFFERED_STATE": "გაგზავნილი",
    "ARCHIVED_STATE": "არქივი"
  },
  "ru": {
    "WAITING_STATE": "В ожидании звонка",
    "HOLD_STATE": "Отложено",
    "LOOKING_STATE": "Поиск авто",
    "OFFERED_STATE": "Предложение отправлено",
    "ARCHIVED_STATE": "Помещен в архив"
  },
  "uk": {
    "WAITING_STATE": "В очікуванні дзвінка",
    "HOLD_STATE": "На утриманні",
    "LOOKING_STATE": "Пошук автомобіля",
    "OFFERED_STATE": "Відправлено",
    "ARCHIVED_STATE": "Поміщено в архів"
  }
}
</i18n>
