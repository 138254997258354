import { exRatesActions, exRatesMutations } from './types'
import { sdk } from 'Services/shelfNetworkSdk'

const RATES_UPDATE_INTERVAL = 3600000 // 1 hour
let updaterStopper = () => { }

export default {
  async [exRatesActions.FETCH_RATES] ({ commit }) {
    const baseCurrency = 'EUR' // currently exrates works for EUR only
    const response = await sdk.exrates.getLatestRates(baseCurrency)

    const rates = response.data
      .map(rate => ({
        currency: rate.relationships.quoteAsset.id,
        price: rate.price
      }))
      .reduce((allRates, rate) => {
        allRates[rate.currency] = rate.price
        return allRates
      }, {})

    commit(exRatesMutations.SET_EXCHANGE_RATES, rates)
    commit(exRatesMutations.SET_BASE_CURRENCY, baseCurrency)
  },

  async [exRatesActions.START_RATES_UPDATER] ({ dispatch }) {
    const update = () => {
      try {
        dispatch(exRatesActions.FETCH_RATES)
      } catch (error) {
        console.error(error)
      }
    }

    update()
    updaterStopper = setInterval(() => update(), RATES_UPDATE_INTERVAL)
  },

  async [exRatesActions.STOP_RATES_UPDATER] () {
    updaterStopper()
  },
}
