const actions = {
  INITIALIZE_APP: 'INITIALIZE_APP',
  INITIALIZE_USER: 'INITIALIZE_USER',
}

const mutations = {
  SET_APP_INITIALIZED: 'SET_APP_INITIALIZED',
}

const getters = {
  IS_APP_INITIALIZED: 'IS_APP_INITIALIZED'
}

export {
  actions as rootActions,
  mutations as rootMutations,
  getters as rootGetters,
}
