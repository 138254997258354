import get from 'lodash/get'
import { ModelBase } from './ModelBase'
import { Lead } from './Lead'
import { Identity } from './Identity'
import { UserEntry } from './UserEntry'
import { Campaign } from 'Models/Campaign'

export class LeadListEntry extends ModelBase {
  constructor (raw = {}, cache = {}) {
    if (raw.data) { // for single-fetched items
      Object.assign(raw, raw.data)
    }

    super(raw, cache)
    const r11s = raw.relationships || {}

    this.channel = this._str(raw.channel || LeadListEntry.channelsEnum.website)
    this.dueDate = this._date(raw.dueDate)
    this.financing = Boolean(raw.financing)
    this.hasActiveDeposit = Boolean(raw.hasActiveDeposit)
    this.hasActiveWithdrawl = Boolean(raw.hasActiveWithdrawl)
    this.isStrictlyAssigned = Boolean(raw.isStrictlyAssigned)
    this.isVerified = Boolean(raw.isVerified)
    this.lastOfferSentAt = this._date(raw.lastOfferSentAt)
    this.lastRequestDate = this._date(raw.lastTimeRequestedContact)
    this.lots = raw.lots
    this.fullName = raw.name
    this.priority = this._num(raw.priority || LeadListEntry.prioritiesEnum.low)
    this.isPriorityHigh = this.priority >= LeadListEntry.prioritiesEnum.high
    this.state = this._str(raw.state)
    this.identityType = this._str(raw.identityType)
    this.stateUpdatedAt = this._date(raw.stateUpdatedAt)
    this.interestedInPro = Boolean(raw.interestedInPro)
    this.isPro = [
      UserEntry.accountTypesEnum.business,
      UserEntry.accountTypesEnum.dealer
    ].includes(this.identityType)
    const broker = ModelBase.modelFromCache(
      Identity,
      get(raw, 'relationships.broker'),
      cache
    )
    this.brokerId = broker.id
    this.brokerAvatarLink = broker.avatarLink
    this.brokerFullName = broker.fullName
    this.iteration = this._num(raw.iteration)
    this.unresolvedSupportRequestsCount =
      this._num(raw.unresolvedSupportRequests)

    const contacts = (r11s.contacts || [])
      .reduce((acc, { data, channel }) => ({
        ...acc,
        [channel]: data
      }), {})

    this.contactPhone = this._str(contacts[Lead.channelsEnum.phone])
    this.contactEmail = this._str(contacts[Lead.channelsEnum.email])

    const campaign = ModelBase.modelFromCache(
      Campaign,
      get(raw, 'relationships.campaign'),
      cache
    )

    this.campaignName = campaign.campaign
    this.campaignMedium = campaign.medium
    this.campaignSource = campaign.source
    this.campaignTerm = campaign.term
    this.campaignContent = campaign.content
  }

  static get channelsEnum () {
    return Lead.channelsEnum
  }

  static get statesEnum () {
    return Lead.statesEnum
  }

  static get prioritiesEnum () {
    return Lead.prioritiesEnum
  }
}
