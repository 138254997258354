export const NOTIFICATIONS_URL_PARAM_TABS = Object.freeze({
  urgent: 'urgent',
  info: 'info',
})

export const NOTIFICATIONS_QUERY_SORTS = Object.freeze({
  createdAt: 'created_at',
  createdAtDesc: '-created_at',
})

export const NOTIFICATIONS_TAB_DEFAULT_SORT = Object.freeze({
  [NOTIFICATIONS_URL_PARAM_TABS.urgent]:
    NOTIFICATIONS_QUERY_SORTS.createdAtDesc,

  [NOTIFICATIONS_URL_PARAM_TABS.info]:
    NOTIFICATIONS_QUERY_SORTS.createdAtDesc,
})
