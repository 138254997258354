import { platformsMutations } from './types'
import Vue from 'vue'

export default {
  [platformsMutations.SET_PLATFORMS]: (state, platforms) => {
    const byId = platforms.reduce((acc, platform) => {
      acc[platform.id] = platform
      return acc
    }, {})

    state.platforms = Object.assign(state.platforms, byId)
  },

  [platformsMutations.SET_PLATFORMS] (state, platforms) {
    platforms.forEach(platform => {
      Vue.set(state.platforms, platform.id, platform)
    })
  }
}
