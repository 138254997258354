import { ModelBase } from './ModelBase'
import { contactChannels } from '@shelf.network/js-sdk'

export class Contact extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.channelAddress = this._str(raw.data)
    this.channel = this._str(raw.channel)
  }

  static get channelsEnum () {
    return {
      phone: contactChannels.phone,
      email: contactChannels.email,
      website: contactChannels.website,
      carfax: contactChannels.carfax,
      facebook: contactChannels.facebook,
      telegram: contactChannels.telegram,
      rajdeba: contactChannels.rajdeba,
      facebookWeb: contactChannels.facebookWeb,
    }
  }
}
