import { ModelBase } from './ModelBase'
import { Contact } from './Contact'
import { Identity } from './Identity'
import { Location } from './Location'

export class Customer extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)
    const r11s = raw.relationships || {}

    this.fullName = this._str(raw.fullName)
    this.isVerified = Boolean(raw.verified)

    const identity = ModelBase.modelFromCache(Identity, r11s.identity, cache)
    this.identityId = identity.id
    this.identityType = identity.type
    this.avatarLink = identity.avatarLink
    this.location = identity.location || new Location()
    this.leadId = (r11s.lead || {}).id
    this.activeDepositsTotal = identity.activeDepositsTotal
    this.activeDepositsCurrency = identity.activeDepositsCurrency
    this.activeDepositsIds = identity.activeDepositsIds
    this.activeDepositLatestCreatedAt = identity.activeDepositLatestCreatedAt

    const rawContacts = r11s.contacts || []
    const contacts = rawContacts.map(contact => {
      return ModelBase.modelFromCache(Contact, contact, cache)
    })
    this.contactPhone =
      this._findAddress(contacts, Contact.channelsEnum.phone)
    this.contactEmail =
      this._findAddress(contacts, Contact.channelsEnum.email)
    this.contactWebsite =
      this._findAddress(contacts, Contact.channelsEnum.website)
    this.contactFacebook =
      this._findAddress(contacts, Contact.channelsEnum.facebook)
  }

  _findAddress (contacts, channel) {
    const contact = contacts.find(el => el.channel === channel)
    if (contact) {
      return String(contact.channelAddress)
    }
    return ''
  }
}
