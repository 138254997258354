<template>
  <ui-text
    class="quick-search-input"
    name="quick-search"
    autocomplete="off"
    :value="value"
    :placeholder="$t('SEARCH_PH')"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <button
      class="quick-search-input__clear-btn"
      :class="{ 'quick-search-input__clear-btn_shown': value }"
      @click="$emit('clear')"
      slot="dock-right"
    >
      <ui-icon
        class="quick-search-input__clear-btn-ico"
        icon="close"
      />
    </button>
  </ui-text>
</template>

<script>
import { UiText, UiIcon } from '@shelf.network/ui-kit'

export default {
  name: 'quick-search-input',

  components: { UiText, UiIcon },

  props: {
    value: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.quick-search-input {
  &.ui-text /deep/ .ui-text__input {
    border-radius: 1em;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1em;
    width: 100%;

    &:not(:focus) {
      border-color: $color-ui-secondary;
    }
  }

  &__clear-btn {
    background: none;
    border: none;
    color: $color-ui-secondary;
    padding: 0.25em;
    transition: visibility 0.15s, opacity 0.15s;
    visibility: hidden;
    opacity: 0;

    &_shown {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SEARCH_PH": "Search for Leads"
  },
  "ka": {
    "SEARCH_PH": "ლიდის ძიება"
  },
  "ru": {
    "SEARCH_PH": "Найти Лид"
  },
  "uk": {
    "SEARCH_PH": "Знайти Лід"
  }
}
</i18n>
