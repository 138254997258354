<template>
  <div class="lead-attachments">
    <div class="lead-attachments__header">
      {{ $t('ATTACHMENTS_LBL') }}
    </div>
    <form
      ref="form"
      class="lead-attachments__container"
      :class="{
        'lead-attachments_incoming': incomingDrop,
        'lead-attachments_target-locked': isTargetLocked
      }">
      <!--
        Attached files
      -->
      <div
        v-for="att in lead.attachments"
        :key="att.id"
        class="lead-attachments__item">
        <a
          class="lead-attachments__item-link"
          :href="att.src + '?download=1'"
          target="_blank">
          <img
            v-if="att.isImage"
            class="lead-attachments__item-img"
            :src="att.src"
            :alt="att.filename">
          <div
            v-else
            class="lead-attachments__item-document">
            <ui-icon
              class="lead-attachments__item-document-icon"
              icon="document" />
            <div class="lead-attachments__item-document-filename">
              {{ att.filename }}
            </div>
          </div>
        </a>
        <button
          type="button"
          @click="removeAttachment({ leadId: lead.id, attachmentId: att.id })"
          class="lead-attachments__item-remove"
        >
          <ui-icon icon="trash-alt" />
        </button>
      </div>

      <!--
        Files in upload queue
      -->
      <div
        v-for="qFile in queue"
        :key="qFile.id"
        class="lead-attachments__item lead-attachments__item_in-queue">
        <img
          class="lead-attachments__item-img"
          v-if="qFile.isImage"
          :src="qFile.dataUrl"
          :alt="qFile.name">
        <template v-if="qFile.isProcessing">
          <div
            class="lead-attachments__item-upload-icon">
            <ui-spinner type="pills" />
          </div>
        </template>
        <template v-else>
          <ui-icon
            icon="sand-clock"
            class="lead-attachments__item-upload-icon"
          />

          <button
            type="button"
            @click="removeAttachmentFromQueue(qFile.id)"
            class="lead-attachments__item-remove"
          >
            <ui-icon icon="trash-alt" />
          </button>
        </template>

        <div class="lead-attachments__item-upload-filename">
          {{ qFile.name }}
        </div>
      </div>

      <div class="lead-attachments__item lead-attachments__files-wrap">
        <ui-icon
          icon="cloud-upload"
          class="lead-attachments__files-wrap-icon"
        />
        <input
          @dragenter.exact="lockTarget"
          @dragleave.exact="unlockTarget"
          @drop="dropped"
          class="lead-attachments__files"
          type="file"
          @change="handleFileSelect"
          tabindex="-1"
          multiple
        >
      </div>
      <div
        v-if="!queue.length && !lead.attachments.length"
        class="lead-attachments__tip">
        {{ $t('UPLOAD_TIP_TEXT') }}
      </div>
    </form>
  </div>
</template>

<script>
import { Lead } from 'Models/Lead'
import { UiIcon, UiSpinner } from '@shelf.network/ui-kit'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { actions, mutations } from '../store/types'
import { showError } from 'Utils/notifications'

export default {
  name: 'lead-attachments',
  components: { UiIcon, UiSpinner },
  props: {
    lead: { type: Lead, required: true }
  },
  data () {
    return {
      incomingDrop: false,
      isTargetLocked: false,
      isUploading: false,
    }
  },
  computed: {
    ...mapGetters('ui/leads', {
      attachmentsQueue: 'attachmentsQueue'
    }),
    queue () {
      return this.attachmentsQueue.filter(f => {
        return f.leadId === this.lead.id
      })
    }
  },
  mounted () {
    document.body.addEventListener('dragover', this.enterGlobalDragHandler)
    document.body.addEventListener('dragleave', this.leaveGlobalDragHandler)
  },
  beforeDestroy () {
    document.body.removeEventListener('dragover', this.enterGlobalDragHandler)
    document.body.removeEventListener('dragleave', this.leaveGlobalDragHandler)
  },
  methods: {
    ...mapActions('ui/leads', {
      addAttachment: actions.ADD_ATTACHMENT,
      removeAttachment: actions.REMOVE_ATTACHMENT
    }),
    ...mapMutations('ui/leads', {
      removeAttachmentFromQueue: mutations.REMOVE_ATTACHMENT_FROM_QUEUE,
    }),
    enterGlobalDragHandler () {
      if (!this.isTargetLocked) {
        this.incomingDrop = true
      }
    },
    leaveGlobalDragHandler () {
      if (!this.isTargetLocked) {
        this.incomingDrop = false
      }
    },
    lockTarget () {
      this.isTargetLocked = true
    },
    unlockTarget () {
      this.isTargetLocked = false
    },
    dropped (e) {
      this.isTargetLocked = false
      this.incomingDrop = false
    },
    handleFileSelect (e) {
      let toastShow = false
      let filesAttached = 0

      Object.values(e.target.files)
        .forEach((f) => {
          if (f.type) {
            this.addAttachment({ leadId: this.lead.id, file: f })
            filesAttached++
          } else {
            if (toastShow) {
              showError(this.$t('ATTACH_ERROR'))
              toastShow = true
            }
          }
        })

      if (e.target.files.length && !filesAttached) {
        showError(this.$t('ATTACH_ERROR'))
        toastShow = true
      }

      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    },
  }
}
</script>

<style scoped lang="scss">
.lead-attachments {
  &__header {
    margin-top: 1em;
    margin-bottom: 0.7em;
    font-weight: 500;
    display: block;
  }

  &__container {
    border: 2px dashed transparent;
    transition: border linear 200ms;
    padding: 0.6em;
    margin: 0 -0.5em -0.5em -0.5em;
    position: relative;
    flex-wrap: wrap;
    display: grid;
    border-radius: 1.5em;

    @include respond(grid-gap, 18px, 15px, 15px, 20px);
    @include respond(
      grid-template-columns,
      repeat(6, 1fr),
      repeat(6, 1fr),
      repeat(5, 1fr),
      repeat(3, 1fr)
    );
  }

  &__item {
    border: 1px solid $color-grey;
    position: relative;
    overflow: hidden;
    border-radius: 1em;
    background-color: $color-ui-bg;

    @include aspect-ratio();

    --ratio: 1;

    &:hover {
      color: $color-sys-info;
      border-color: transparentize($color-sys-info, 0.5);
      background-color: transparentize($color-sys-info, 0.9);
    }

    &_in-queue {
      border: 1px solid $color-dark-grey;
    }

    &-link {
      text-decoration: none;
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &-img {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      object-fit: cover;
    }

    &-document {
      text-decoration: none;
      color: $color-ui-secondary;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      flex-flow: column;
      justify-content: center;

      &:hover {
        color: $color-sys-info;
      }

      &-icon {
        font-size: 2.5em;
        display: block;
      }

      &-filename {
        text-align: center;
        font-size: 1em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 90%;
        margin: 0.5em auto 0 auto;
      }
    }

    &-link:hover &-document-icon {
      color: $color-sys-info;
    }

    &-upload-icon {
      color: $color-light;
      background-color: transparentize($color-dark-grey, 0.2);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5em;
      padding-bottom: 0.5em;

      --ui-spinner-color-a: #{$color-ui-primary-contrast};
      --ui-spinner-color-b: #{$color-sys-info};
      --ui-spinner-pills-width: 3px;

      & /deep/ .ui-spinner {
        font-size: 1em;
      }
    }

    &-upload-filename {
      color: $color-ui-secondary-contrast;
      text-align: center;
      font-size: 1em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 90%;
      position: absolute;
      top: 60%;
      right: 1em;
      left: 1em;
      z-index: z-index(1);
    }

    &-remove {
      visibility: hidden;
      color: $color-light;
      background: transparentize($color-dark-grey, 0.5);
      border: 0 none;
      position: absolute;
      right: 0.4em;
      top: 0.4em;
      font-size: 1.3em;
      display: flex;
      padding: 0.5em;
      align-items: center;
      justify-content: center;
      border-radius: 2em;

      &:hover {
        background: $color-sys-warning;
      }
    }

    &:hover &-remove {
      visibility: visible;
    }
  }

  &__files-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-grey;

    &-icon {
      font-size: 3em;
    }
  }

  &__files {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &__tip {
    color: $color-ui-secondary;
    white-space: nowrap;
    display: flex;
    width: 2fr;
    align-items: center;
    grid-column: 2;
    grid-row: 1;
  }

  &_incoming &__files-wrap {
    overflow: visible;
    position: static !important;
  }

  &_incoming &__files {
    opacity: 0;
  }

  &_target-locked {
    border-style: solid;
    border-color: $color-sys-info !important;
  }

  &_incoming {
    border-color: transparentize($color-sys-info, 0.5);
  }
}
</style>

<i18n>
{
  "en": {
    "ATTACHMENTS_LBL": "ATTACHMENTS",
    "ATTACH_ERROR": "Only documents and images are allowed for attachments.",
    "UPLOAD_TIP_TEXT": "Upload PNG, JPEG, PDF"
  },
  "ka": {
    "ATTACHMENTS_LBL": "ᲛᲘᲛᲐᲒᲠᲔᲑᲣᲚᲘ ᲤᲐᲘᲚᲔᲑᲘ",
    "ATTACH_ERROR": "მხოლოდ სურათის ან დოკუმენტის მიმაგრებაა შესაძლებელი",
    "UPLOAD_TIP_TEXT": "ატვირთე PNG, JPEG, PDF"
  },
  "ru": {
    "ATTACHMENTS_LBL": "ВЛОЖЕНИЯ",
    "ATTACH_ERROR": "В качестве вложений могут использоваться только документы и изображения.",
    "UPLOAD_TIP_TEXT": "Загрузить PNG, JPEG, PDF"
  },
  "uk": {
    "ATTACHMENTS_LBL": "ВКЛАДЕННЯ",
    "ATTACH_ERROR": "В якості вкладень можуть використовуватися тільки документи та зображення.",
    "UPLOAD_TIP_TEXT": "Завантажити PNG, JPEG, PDF"
  }
}
</i18n>
