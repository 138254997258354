<template>
  <span class="dph">
    <v-popover
      trigger="hover"
      placement="bottom"
      :offset="0"
      @show="fetch"
      v-if="tbcPaymentsPopoverEnabled"
      :container="getPopoverContainer()"
    >
      <span class="dph__trigger">
        <slot />
      </span>
      <div
        slot="popover"
        class="dph__content">
        <h4 class="dph__content-header">
          {{ $t('HEADER') }}
        </h4>
        <div
          class="dph__content-loading-stub"
          v-if="loading">
          {{ $t('LOADING') }}
        </div>
        <div
          class="dph__content-empty-stub"
          v-if="!loading && payments.length === 0">
          {{ $t('NO_PAYMENTS_FOUND') }}
        </div>
        <ul
          v-if="fetched && payments.length > 0"
          class="dph__items"
        >
          <li
            class="dph__item"
            v-for="(payment, i) in listItems"
            :key="i"
          >
            <div class="dph__item-data dph__item-data_dates">
              <div class="dph__item-data-cell">
                <span class="dph__item-data-lbl">
                  {{ $t('DATE_LBL') }}
                </span>
                <span class="dph__item-data-val">
                  {{ payment.createdAt }}
                </span>
              </div>

              <div
                class="dph__item-data-cell"
                v-if="payment.confirmedAt">
                <span class="dph__item-data-lbl">
                  {{ $t('DATE_CONFIRMED_LBL') }}
                </span>
                <span class="dph__item-data-val">
                  {{ payment.confirmedAt }}
                </span>
              </div>

              <div
                class="dph__item-data-cell"
                v-if="payment.reversedAt">
                <span class="dph__item-data-lbl">
                  {{ $t('DATE_RESERVED_LBL') }}
                </span>
                <span class="dph__item-data-val">
                  {{ payment.reversedAt }}
                </span>
              </div>

              <div
                class="dph__item-data-cell"
                v-if="payment.refundedAt">
                <span class="dph__item-data-lbl">
                  {{ $t('DATE_REFUNDED_LBL') }}
                </span>
                <span class="dph__item-data-val">
                  {{ payment.refundedAt }}
                </span>
              </div>
            </div>

            <div class="dph__item-data dph__item-data_amounts">
              <div class="dph__item-data-cell">
                <span class="dph__item-data-lbl">
                  {{ $t('AMOUNT_LBL') }}
                </span>
                <span class="dph__item-data-val">
                  {{ $fc(payment.amount, payment.currency) }}
                </span>
              </div>
              <div
                class="dph__item-data-cell"
                v-if="payment.chargedAmount"
              >
                <span class="dph__item-data-lbl">
                  {{ $t('CHARGED_AMOUNT_LBL') }}
                </span>
                <span class="dph__item-data-val">
                  {{ $fc(payment.chargedAmount, payment.currency) }}
                </span>
              </div>
            </div>

            <div class="dph__item-data dph__item-data_statuses">
              <div class="dph__item-data-cell">
                <span class="dph__item-data-lbl ">
                  {{ $t('STATUS_LBL') }}
                </span>
                <span
                  class="dph__item-data-val"
                  :class="{
                    'dph__item-data-val_error': payment.isError,
                    'dph__item-data-val_pending': payment.isPending,
                    'dph__item-data-val_processed': payment.isProcessed,
                  }"
                >
                  {{ payment.status }}
                </span>
              </div>

              <div class="dph__item-data-cell">
                <span class="dph__item-data-lbl">
                  {{ $t('RESULT_CODE_LBL') }}
                </span>
                <tbc-result-code
                  v-if="payment.resultCode"
                  class="dph__item-data-val"
                  :code="payment.resultCode" />
                <span
                  v-else
                  class="dph__item-data-val">
                  {{ $t('COMMON.SYMBOLS.MDASH') }}
                </span>
              </div>
            </div>

            <div class="dph__item-data dph__item-data_id">
              <div class="dph__item-data-cell">
                <span class="dph__item-data-lbl">
                  {{ $t('ID_LBL') }}
                </span>
                <span class="dph__item-data-val">
                  <ui-copy-button
                    :value="payment.id"
                    :copied-message="$t('COMMON.COPIED_MSG')"
                  >
                    <span class="dph__item-data-val-id">
                      {{ payment.id }}
                    </span>
                  </ui-copy-button>
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </v-popover>
    <template v-else>
      <slot />
    </template>
  </span>
</template>

<script>
import get from 'lodash/get'
import { formatDateTimeCompact } from 'Utils/dateHelpers'
import { VPopover } from 'v-tooltip'

import { sdk } from 'Services/shelfNetworkSdk'
import { UiCopyButton } from '@shelf.network/ui-kit'
import { showError } from 'Utils/notifications'
import { USER_CLAIMS } from 'Constants/userClaims'
import TbcResultCode from './TbcResultCode'

const paymentStatuses = Object.freeze({
  created: 'CREATED',
  pending: 'PENDING',
  ok: 'OK',
  confirmed: 'CONFIRMED',
  failed: 'FAILED',
  declined: 'DECLINED',
  reversed: 'REVERSED',
  refunded: 'REFUNDED',
  timeout: 'TIMEOUT'
})

export default {
  name: 'deposit-payments-hint',
  components: {
    TbcResultCode,
    UiCopyButton,
    VPopover
  },
  props: {
    depositId: {
      type: String,
      default: undefined
    },
    excludeTimeouts: {
      type: Boolean,
      default: true
    },
    excludePending: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      fetched: true,
      loading: false,
      payments: []
    }
  },
  computed: {
    listItems () {
      return this.payments.map(payment => {
        const paymentStatusClass = this.getPaymentStatusClass(payment.status)

        return {
          raw: payment,
          id: payment.id,
          createdAt: formatDateTimeCompact(new Date(payment.createdAt)),
          confirmedAt: payment.confirmedAt
            ? formatDateTimeCompact(new Date(payment.confirmedAt)) : null,
          reversedAt: payment.reversedAt
            ? formatDateTimeCompact(new Date(payment.reversedAt)) : null,
          refundedAt: payment.refundedAt
            ? formatDateTimeCompact(new Date(payment.refundedAt)) : null,
          amount: payment.amount,
          currency: payment.currency,
          status: payment.status,
          isError: paymentStatusClass === 'error',
          isPending: paymentStatusClass === 'pending',
          isProcessed: paymentStatusClass === 'processed',
          chargedAmount: get(payment, 'meta.chargedAmount'),
          resultCode: get(payment, 'meta.resultCode')
        }
      })
    },

    ignoredStatuses () {
      const statuses = []

      if (this.excludeTimeouts) {
        statuses.push(paymentStatuses.timeout)
      }
      if (this.excludePending) {
        statuses.push(paymentStatuses.created, paymentStatuses.pending)
      }

      return statuses
    },

    tbcPaymentsPopoverEnabled () {
      return this.$can(USER_CLAIMS.TBC_PAYMENTS)
    }
  },

  methods: {
    getPopoverContainer () {
      // When preventing body scroll body position is set to fixed,
      // so the tooltip position calculation is wrong. We should
      // use scroll element in this case (modal content).
      return document.querySelector('.lead') || document.body
    },

    async fetch () {
      if ((this.fetched && this.isLoading) || !this.depositId) {
        return
      }

      this.loading = true
      this.payments = []

      try {
        const response = await sdk.payments.getPage({
          invoiceId: this.depositId,
          purpose: 'DEPOSIT',
          sort: '-created_at'
        })

        this.payments = response.data
          .filter(payment => !this.ignoredStatuses.includes(payment.status))
      } catch (error) {
        showError(this.$t('TOASTS.FETCH_PAYMENTS_ERROR'))
        console.error(error)
      }

      this.loading = false
      this.fetched = true
    },

    getPaymentStatusClass (status) {
      const pendingStatuses = [
        paymentStatuses.created,
        paymentStatuses.pending,
        paymentStatuses.ok
      ]
      if (pendingStatuses.includes(status)) {
        return 'pending'
      }

      const processedStatuses = [
        paymentStatuses.confirmed,
        paymentStatuses.reversed,
        paymentStatuses.refunded
      ]
      if (processedStatuses.includes(status)) {
        return 'processed'
      }

      return 'error'
    }
  }
}
</script>

<style lang="scss" scoped>
.dph {
  text-align: left;

  /deep/ .popover {
    // popover inside removes these element once mounted, however, after it,
    // height of payment hint changes and causes scroll issues when being
    // rendered inside virtual list. The popover component later mounts .popover
    // div to body, so these styles won't affect the tooltip being shown.
    display: none !important;
  }

  & [role~="tooltip"][data-microtip-size="small"]::after {
    width: auto;
    max-width: 250px;
  }

  &__trigger {
    text-decoration: underline;
    text-decoration-style: dashed;
    cursor: help;
    padding: 8px 0;
  }

  &__content {
    min-width: 34em;
    width: 38em;
    max-height: 40vh;
    max-width: 90vw;
    padding: 0.5em 0 2em 0;
    overflow: auto;
    background: $color-white;
    color: black;

    &-header {
      padding: 0.75em 1.5em 1em 1.5em;
      display: block;
      border-bottom: 1px solid $color-light-grey;
    }

    &-loading-stub,
    &-empty-stub {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__items {
    display: block;
  }

  &__item {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-rows: repeat(2, auto);

    // eslint-disable-next-line
    grid-template-areas: "dates amounts statuses" "id id statuses";
    grid-gap: 0.32em 1em;
    line-height: 1.1em;
    border-bottom: 1px solid $color-light-grey;
    padding: 1em 1.5em;
    color: black;

    &:hover &-data-lbl {
      color: darken($color-dark-grey, 20%);
    }

    &:hover {
      background: $color-light;
    }

    &-data {
      &_dates {
        grid-area: dates;
      }

      &_amounts {
        grid-area: amounts;
      }

      &_statuses {
        grid-area: statuses;
      }

      &_id {
        grid-area: id;
      }

      &_dev {
        grid-area: dev;
      }

      &-cell {
        margin-bottom: 0.75em;
      }

      &-lbl {
        display: block;
        font-size: 0.8em;
        color: $color-dark-grey;
      }

      &-val {
        display: block;

        &_error {
          color: $color-sys-warning;
        }

        &_pending {
          color: $color-ui-waiting;
        }

        &_processed {
          color: $color-sys-success;
        }

        &-id {
          font-weight: normal;
          color: $color-dark-grey;
          cursor: copy;

          &:hover {
            color: $color-dark;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "NO_PAYMENTS_FOUND": "No payments found",
    "HEADER": "Payments",
    "LOADING": "Loading",
    "AMOUNT_LBL": "AMOUNT",
    "DATE_LBL": "DATE",
    "DATE_CONFIRMED_LBL": "CONFIRMED AT",
    "DATE_RESERVED_LBL": "RESERVED AT",
    "DATE_REFUNDED_LBL": "REFUNDED AT",
    "ID_LBL": "TRANSACTION ID",
    "STATUS_LBL": "STATUS",
    "CHARGED_AMOUNT_LBL": "CHARGED",
    "RESULT_CODE_LBL": "RESULT CODE"
  },
  "ka": {
    "NO_PAYMENTS_FOUND": "გადახდა არ დაფიქსირდა",
    "HEADER": "გადახდები",
    "LOADING": "იტვირთება",
    "AMOUNT_LBL": "ᲗᲐᲜᲮᲐ",
    "DATE_LBL": "ᲗᲐᲠᲘᲦᲘ",
    "DATE_CONFIRMED_LBL": "ᲓᲐᲓᲐᲡᲢᲣᲠᲓᲐ",
    "DATE_RESERVED_LBL": "ᲓᲐᲠᲔᲖᲔᲠᲕᲓᲐ",
    "DATE_REFUNDED_LBL": "ᲓᲐᲑᲠᲣᲜᲓᲐ",
    "ID_LBL": "ID",
    "STATUS_LBL": "ᲡᲢᲐᲢᲣᲡᲘ",
    "CHARGED_AMOUNT_LBL": "ᲒᲐᲓᲐᲮᲓᲘᲚᲘ",
    "RESULT_CODE_LBL": "ᲙᲝᲓᲘ"
  },
  "ru": {
    "NO_PAYMENTS_FOUND": "Платежей не найдено",
    "HEADER": "Платежи",
    "LOADING": "Загрузка",
    "ID_LBL": "ID ТРАНЗАЦИИ",
    "AMOUNT_LBL": "СУММА",
    "DATE_LBL": "ДАТА",
    "DATE_CONFIRMED_LBL": "ПОДТВЕРЖДЕНО",
    "DATE_RESERVED_LBL": "ЗАРЕЗЕРВИРОВАНО",
    "DATE_REFUNDED_LBL": "ВОЗМЕЩЕНО",
    "STATUS_LBL": "СТАТУС",
    "CHARGED_AMOUNT_LBL": "СПИСАННО",
    "RESULT_CODE_LBL": "КОД РЕЗУЛЬТАТА"
  },
  "uk": {
    "NO_PAYMENTS_FOUND": "Платежів не знайдено",
    "HEADER": "Платежі",
    "LOADING": "Завантаження",
    "AMOUNT_LBL": "СУМА",
    "DATE_LBL": "ДАТА",
    "DATE_CONFIRMED_LBL": "ПІДТВЕРДЖЕНО",
    "DATE_RESERVED_LBL": "ЗАРЕЗЕРВОВАНО",
    "DATE_REFUNDED_LBL": "ВІДШКОДОВАНО",
    "STATUS_LBL": "CТАТУС",
    "CHARGED_AMOUNT_LBL": "СПИСАНО",
    "ID_LBL": "ID ТРАНЗАКЦIЇ",
    "RESULT_CODE_LBL": "КОД РЕЗУЛЬТАТУ"
  }
}
</i18n>
