<template>
  <div
    class="list-params-broker list-params-row"
    v-if="$can($USER_CLAIMS.BROKER_ASSIGNMENT)"
  >
    <label class="list-params-row__label">
      {{ label || $t('BROKER_LBL') }}
    </label>

    <ui-select
      class="list-params-row__field"
      fill="frame"
      look="secondary"
      :options="options"
      :placeholder="placeholder || $t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
      :null-option="nullOption"
      :value="value || defaultValue"
      @input="onInput"
    >
      <template v-if="selectedBroker.value">
        <img
          class="list-params-row__select-ico"
          :src="selectedBroker.icon"
          :alt="selectedBroker.label"
          slot="dock-left"
        >
      </template>
    </ui-select>
  </div>
</template>

<script>
import { UiSelect } from '@shelf.network/ui-kit'
import { mapGetters } from 'vuex'
import { brokersGetters } from 'Store/entities/Brokers/types'

export default {
  name: 'list-params-broker',
  components: { UiSelect },

  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    defaultValue: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },

  computed: {
    ...mapGetters({
      options: `entities/brokers/${brokersGetters.BROKER_OPTIONS}`,
    }),

    selectedBroker () {
      const value = this.value || this.defaultValue
      return this.options.find(opt => opt.value === value) || {}
    },

    nullOption () {
      return {
        value: '',
        label: this.$t('COMMON.SELECT_FIELD.RESET_OPT'),
      }
    },
  },

  methods: {
    onInput (value) {
      this.$emit('input', value === this.defaultValue ? '' : value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./styles/list-params-row.scss";
</style>

<i18n>
{
  "en": {
    "BROKER_LBL": "BY BROKER"
  },
  "ka": {
    "BROKER_LBL": "ᲑᲠᲝᲙᲔᲠᲘᲡ ᲛᲘᲮᲔᲓᲕᲘᲗ"
  },
  "ru": {
    "BROKER_LBL": "ПО БРОКЕРУ"
  },
  "uk": {
    "BROKER_LBL": "ЗА БРОКЕРОМ"
  }
}
</i18n>
