export const DEPOSITS_URL_PARAM_TABS = Object.freeze({
  history: 'history',
  holding: 'holding',
})

export const DEPOSITS_QUERY_SORTS = Object.freeze({
  updatedAt: 'updated_at',
  updatedAtDesc: '-updated_at',
  depositCreatedAt: 'deposit.created_at',
  depositCreatedAtDesc: '-deposit.created_at',
})

export const DEPOSITS_QUERY_SORTS_BY_TABS = Object.freeze({
  [DEPOSITS_URL_PARAM_TABS.holding]: [
    DEPOSITS_QUERY_SORTS.updatedAt,
    DEPOSITS_QUERY_SORTS.updatedAtDesc,
  ],
  [DEPOSITS_URL_PARAM_TABS.history]: [
    DEPOSITS_QUERY_SORTS.depositCreatedAt,
    DEPOSITS_QUERY_SORTS.depositCreatedAtDesc,
  ]
})

export const DEPOSITS_TAB_DEFAULT_SORT = Object.freeze({
  [DEPOSITS_URL_PARAM_TABS.history]: DEPOSITS_QUERY_SORTS.updatedAtDesc,
  [DEPOSITS_URL_PARAM_TABS.holding]: DEPOSITS_QUERY_SORTS.depositCreatedAtDesc,
})

export const DEPOSITS_LEAD_TABS = Object.freeze([
  DEPOSITS_URL_PARAM_TABS.holding,
])

export const DEPOSITS_TO_LEADS_SORT = Object.freeze({
  [DEPOSITS_QUERY_SORTS.updatedAt]: DEPOSITS_QUERY_SORTS.depositCreatedAt,
  [DEPOSITS_QUERY_SORTS.updatedAtDesc]:
  DEPOSITS_QUERY_SORTS.depositCreatedAtDesc,
})

export const LEAD_TO_DEPOSITS_SORT = Object.freeze({
  [DEPOSITS_QUERY_SORTS.depositCreatedAt]: DEPOSITS_QUERY_SORTS.updatedAt,
  [DEPOSITS_QUERY_SORTS.depositCreatedAtDesc]:
  DEPOSITS_QUERY_SORTS.updatedAtDesc,
})
