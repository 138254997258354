<template>
  <div class="transportation-skeleton">
    <h2 class="transportation-skeleton__title">
      {{ $t('TITLE') }}
    </h2>

    <div class="transportation-skeleton__search">
      <p class="transportation-skeleton__search-lbl">
        &nbsp;
      </p>

      <div class="transportation-skeleton__search-field">
        &nbsp;
      </div>

      <div class="transportation-skeleton__search-btn">
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'transportation-skeleton'
}
</script>

<style lang="scss" scoped>
@import "@/styles/skeletons.scss";

.transportation-skeleton {
  margin: 2em 0;

  &__title {
    @extend %h2;

    text-align: center;
  }

  &__search {
    max-width: 30em;
    margin: 2em auto;
    padding: 2em;
    box-shadow: $static-box-shadow;

    &-lbl,
    &-field,
    &-btn {
      @extend %skeleton-bg;
    }

    &-lbl {
      max-width: 10em;
      line-height: 1.3em;
    }

    &-field {
      margin-top: 0.6em;
      line-height: 3em;
    }

    &-btn {
      margin-top: 2em;
      line-height: 3em;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "TITLE": "Transportation"
  },
  "ka": {
    "TITLE": "ტრანპორტირება"
  },
  "ru": {
    "TITLE": "Транспортировка"
  },
  "uk": {
    "TITLE": "Транспортування"
  }
}
</i18n>
