var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-params',{staticClass:"leads-list-params",attrs:{"initial-value":_vm.value},on:{"form-apply":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('template',{slot:"form-rows"},[_c('list-params-sort',{staticClass:"leads-list-params__row",attrs:{"options":_vm.sortOptions,"default-value":_vm.defaultSort},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}}),_vm._v(" "),_c('list-params-channel',{staticClass:"leads-list-params__row",model:{value:(_vm.form.channel),callback:function ($$v) {_vm.$set(_vm.form, "channel", $$v)},expression:"form.channel"}}),_vm._v(" "),_c('list-params-broker',{staticClass:"leads-list-params__row",model:{value:(_vm.form.broker),callback:function ($$v) {_vm.$set(_vm.form, "broker", $$v)},expression:"form.broker"}}),_vm._v(" "),_c('list-params-location',{staticClass:"leads-list-params__row",model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}}),_vm._v(" "),_c('list-params-flags',{staticClass:"leads-list-params__row",attrs:{"value":{
        isVerified: {
          label: _vm.$t('FLAG_VERIFIED_LBL'),
          value: _vm.form.isVerified,
        },
        isUnverified: {
          label: _vm.$t('FLAG_UNVERIFIED_LBL'),
          value: _vm.form.isUnverified,
        },
        isPrioritized: {
          label: _vm.$t('FLAG_PRIORITIZED_LBL'),
          value: _vm.form.isPrioritized,
        },
        isFinanced: {
          label: _vm.$t('FLAG_FINANCED_LBL'),
          value: _vm.form.isFinanced,
        },
        isProCandidate: {
          label: _vm.$t('FLAG_INTERESGTED_IN_PRO_LBL'),
          value: _vm.form.isProCandidate,
        },
      }},on:{"input":function($event){_vm.form[$event.key] = $event.value}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }