import { i18n } from 'I18n/instance'
import { IS_SAFARI_BROWSER } from 'Constants/userAgent'

const THOUSANDS_REGEX = /\d{1,3}(?=(\d{3})+(?!\d))/g
const NBSP = '\u00A0'
// Safari does not support narrow NBSP
const NARROW_NBSP = IS_SAFARI_BROWSER ? NBSP : '\u202F'

/**
 * Formats number with provided locale mask.
 *
 * @param {number|string} value Value to format.
 * @param {object} [params] Additional params.
 * @param {number} [params.precision=0] Precision to round number.
 * @param {boolean} [params.useFixedPrecision=false] Always append
 *        decimal part configured via precision.
 *        Example (precision = 2): 1000 -> 1,000.00.
 *
 * @returns {string} Formatted number.
 */
export function formatNumber (value, params = {}) {
  if (isNaN(value)) {
    return value
  }

  const { precision = 0, useFixedPrecision = false } = params

  const [wholePart, decimalPart = ''] = Number(value)
    .toFixed(precision)
    .split('.')

  const formattedWholePart = wholePart
    .replace(THOUSANDS_REGEX, `$&${i18n.t('COMMON.THOUSANDS_SEPARATOR')}`)
    .replace(NBSP, NARROW_NBSP)
  const formattedDecimalPart = useFixedPrecision
    ? decimalPart
    : decimalPart.replace(/0+$/g, '')

  return formattedDecimalPart
    ? [formattedWholePart, formattedDecimalPart]
      .join(i18n.t('COMMON.DECIMAL_SEPARATOR'))
    : formattedWholePart
}
