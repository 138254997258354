import './polyfills'
import '@/styles/reset.scss'

import Vue from 'vue'
import './devtools'

import Notifications from 'vue-notification'
import VueMeta from 'vue-meta'
import VuePortal from 'portal-vue'

import { i18n } from 'I18n/instance'

import App from 'Components/App'
import Feature from 'Common/Feature'

import router from 'Router'
import store from 'Store'
import { rootActions } from 'Store/root/types'
import 'microtip/microtip.css'

import { initTimeAgoI18n } from 'I18n/initTimeAgoI18n'
import {
  createFormatCurrencyShortcut,
  createConvertFormatCurrencyShortcut,
} from 'Utils/formatCurrency'
import { createConvertMoneyShortcut } from 'Utils/convertMoney'
import { createEnumTranslateShortcut } from 'Utils/translateEnum'
import { USER_CLAIMS } from 'Constants/userClaims'
import { can } from 'Utils/userHelpers'
import { formatDate } from 'Utils/dateHelpers'

Vue.config.productionTip = false

async function init () {
  initTimeAgoI18n()

  Vue.use(Notifications)
  Vue.use(VueMeta)
  Vue.use(VuePortal)

  Vue.component('feature', Feature)

  Vue.prototype.$cc = createConvertMoneyShortcut({ store })
  Vue.prototype.$fc = createFormatCurrencyShortcut({ store, i18n })
  Vue.prototype.$cfc = createConvertFormatCurrencyShortcut({
    formatCurrencyFunc: Vue.prototype.$fc,
    convertMoneyFunc: Vue.prototype.$cc,
  })
  Vue.prototype.$et = createEnumTranslateShortcut(store)
  Vue.prototype.$fd = formatDate

  // for easier access for the user claims
  Vue.prototype.$can = can
  Vue.prototype.$USER_CLAIMS = USER_CLAIMS

  await store.dispatch(rootActions.INITIALIZE_APP)

  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

init()
