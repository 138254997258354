import get from 'lodash/get'
import { ModelBase } from './ModelBase'
import { Customer } from './Customer'
import { Lot } from './Lot'
import { SupportRequest } from './SupportRequest'
import { Identity } from './Identity'
import { Attachment } from './Attachment'
import {
  leadStates,
  leadPriorities,
  contactChannels,
} from '@shelf.network/js-sdk'
import { getNullDate } from 'Utils/dateHelpers'
import { UserEntry } from 'Models/UserEntry'

export class Lead extends ModelBase {
  constructor (raw = {}, cache = {}) {
    if (raw.data) { // for single-fetched items
      Object.assign(raw, raw.data)
    }

    super(raw, cache)
    const r11s = raw.relationships || {}

    this.dueDate = this._date(raw.dueDate)
    this.lastOfferSentAt = this._date(raw.lastOfferSentAt)
    this.financing = Boolean(raw.financing)
    this.comment = this._str(raw.comment)
    this.state = this._str(raw.state)
    this.stateUpdatedAt = this._date(raw.stateUpdatedAt)
    this.iteration = this._num(raw.iteration)
    this.channel = this._str(raw.channel || Lead.channelsEnum.website)
    this.isStrictlyAssigned = Boolean(raw.isStrictlyAssigned)
    this.hasActiveDeposit = Boolean(raw.hasActiveDeposit)
    this.interestedInPro = Boolean(raw.interestedInPro)

    this.priority = this._num(raw.priority || Lead.prioritiesEnum.low)
    this.isPriorityHigh = this.priority >= Lead.prioritiesEnum.high

    const customer = ModelBase.modelFromCache(
      Customer,
      get(raw, 'relationships.customer'),
      cache
    )
    this.customerIdentityId = customer.identityId
    this.customerId = customer.id
    this.fullName = customer.fullName
    this.contactPhone = customer.contactPhone
    this.contactEmail = customer.contactEmail
    this.contactWebsite = customer.contactWebsite
    this.contactFacebook = customer.contactFacebook
    this.avatarLink = customer.avatarLink
    this.location = customer.location
    this.isGuest = !customer.identityId
    this.activeDepositsTotal = customer.activeDepositsTotal
    this.activeDepositsCurrency = customer.activeDepositsCurrency
    this.activeDepositsIds = customer.activeDepositsIds
    this.activeDepositLatestCreatedAt = customer.activeDepositLatestCreatedAt
    this.identityType = customer.identityType
    this.isPro = [
      UserEntry.accountTypesEnum.business,
      UserEntry.accountTypesEnum.dealer
    ].includes(this.identityType)

    const broker = ModelBase.modelFromCache(
      Identity,
      get(raw, 'relationships.broker'),
      cache
    )
    this.brokerId = broker.id
    this.brokerAvatarLink = broker.avatarLink
    this.brokerFullName = broker.fullName
    this.isAssigned = Boolean(broker.id)

    const rawAttachments = r11s.attachments || []
    this.attachments = rawAttachments.map(attachment => {
      return ModelBase.modelFromCache(Attachment, attachment, cache)
    })

    this.lots = this._arrOf(r11s.lots, Lot)

    const rawRequests = r11s.requests || []
    this.supportRequests = rawRequests
      .map(request => {
        return ModelBase.modelFromCache(SupportRequest, request, cache)
      })
      .sort((a, b) => b.createdAt - a.createdAt)

    this.unresolvedSupportRequests = this.supportRequests
      .filter(item => item.state !== SupportRequest.statesEnum.resolved)

    this.createdAt = this.supportRequests.length
      ? this.supportRequests[this.supportRequests.length - 1].createdAt
      : getNullDate()

    this.lastRequestDate = this.supportRequests[0]
      ? this.supportRequests[0].createdAt
      : this.createdAt
  }

  static get channelsEnum () {
    return {
      phone: contactChannels.phone,
      email: contactChannels.email,
      website: contactChannels.website,
      carfax: contactChannels.carfax,
      facebook: contactChannels.facebook,
      telegram: contactChannels.telegram,
      rajdeba: contactChannels.rajdeba,
      facebookWeb: contactChannels.facebookWeb,
    }
  }

  static get statesEnum () {
    return {
      waitingCall: leadStates.waitingForCall,
      onHold: leadStates.onHold,
      lookingCar: leadStates.lookingForCar,
      offerSent: leadStates.offerSent,
      archived: leadStates.archived,
    }
  }

  static get prioritiesEnum () {
    return {
      high: leadPriorities.high,
      medium: leadPriorities.medium,
      low: leadPriorities.low,
    }
  }
}
