<template>
  <div class="user-block">
    <template v-if="isNotificationsShown">
      <bell class="user-block__bell" />
    </template>
    <locale-switcher class="user-block__locale-switcher" />

    <div class="user-block__info">
      <span class="user-block__info-name">
        {{ formattedFullName }}
      </span>

      <div
        v-if="profile.email"
        class="user-block__info-email"
      >
        {{ profile.email }}
      </div>

      <router-link
        v-if="$can($USER_CLAIMS.EDIT_PROFILE)"
        to="/edit-profile"
        class="user-block__info-ava-wrp"
      >
        <img
          class="user-block__info-ava"
          :src="profile.avatarLink"
        >
      </router-link>

      <img
        v-else
        class="user-block__info-ava"
        :src="profile.avatarLink"
      >
    </div>

    <button
      class="user-block__sign-out-btn"
      @click="signOut"
    >
      {{ $t('SIGN_OUT') }}
    </button>
  </div>
</template>

<script>
import LocaleSwitcher from 'Common/LocaleSwitcher'
import Bell from './Bell'

import { mapActions, mapGetters } from 'vuex'
import { userActions, userGetters } from 'Store/entities/User/types'

import formatFullName from 'Utils/formatFullName'

export default {
  name: 'user-block',
  components: {
    LocaleSwitcher,
    Bell,
  },

  computed: {
    ...mapGetters('entities/user', {
      profile: userGetters.PROFILE,
    }),

    formattedFullName () {
      return formatFullName(this.profile)
    },

    isNotificationsShown () {
      return this.$can(this.$USER_CLAIMS.NOTIFICATIONS) ||
        this.$can(this.$USER_CLAIMS.EXTENSION_NOTIFICATIONS)
    }
  },

  methods: {
    ...mapActions('entities/user', {
      signOut: userActions.SIGN_OUT
    })
  }
}
</script>

<style lang="scss" scoped>
.user-block {
  display: grid;
  align-items: center;
  grid: auto / auto-flow auto;
  gap: 1.5em;

  &__info {
    display: grid;
    grid-template:
      'name ava' auto
      'email ava' auto
      / auto auto;
    align-items: center;
    gap: 0 1em;
    text-align: right;

    @include respond-below(xs) {
      display: none;
    }

    &-name {
      grid-area: name;
      font-size: 1.2em;
      white-space: nowrap;
    }

    &-email {
      grid-area: email;
      font-size: 0.9em;
      color: $color-ui-secondary;
      white-space: nowrap;
    }

    &-ava {
      grid-area: ava;
      width: 3em;
      height: 3em;
      border-radius: 0.7em;
      border: 1px solid #eee;
      display: block;

      &-wrp {
        grid-area: ava;
        display: block;
      }
    }
  }

  &__sign-out-btn {
    white-space: nowrap;
    border: none;
    background: none;
    color: $color-ui-secondary;
    font-size: inherit;

    &:hover {
      text-decoration: underline;
    }

    @include respond-below(sm) {
      display: none;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SIGN_OUT": "Sign out"
  },
  "ka": {
    "SIGN_OUT": "გამოსვლა"
  },
  "ru": {
    "SIGN_OUT": "Выход"
  },
  "uk": {
    "SIGN_OUT": "Вихід"
  }
}
</i18n>
