<template>
  <div
    class="lead-lot-suggestions"
    :class="{ 'lead-lot-suggestions_loading': isLoading }"
    v-if="lot.vin"
  >
    <template v-if="isEmpty">
      <p class="lead-lot-suggestions__empty-stub">
        {{ $t('NO_SUGGESTIONS_TXT') }}
      </p>
    </template>

    <template v-else-if="isLoadingFailed">
      <p class="lead-lot-suggestions__error-stub">
        {{ $t('ERROR_TXT') }}
      </p>
    </template>

    <template v-else>
      <lead-lot-prices-graph
        class="lead-lot-suggestions__prices-graph"
        :min-price="prices.min"
        :avg-price="prices.avg"
        :max-price="prices.max"
        :currency="prices.currency"
        :to-currency="lot.currency"
      />

      <lead-lot-similar
        class="lead-lot-suggestions__similar"
        :more-search-query="[
          configYear || lot.manufactureYear,
          $et('MAKER', lot.maker),
          $et('MODEL', lot.model)
        ].join($t('QUICK_SEARCH_SEPARATOR'))"
        :lots-count="lots.count"
        :lots-list="lots.list"
        :currency="lots.currency"
        :to-currency="lot.currency"
      />
    </template>
  </div>

  <div
    class="lead-lot-suggestions"
    v-else
  >
    <p class="lead-lot-suggestions__unavailable-stub">
      {{ $t('SUGGESTIONS_UNAVAILABLE_TXT') }}
    </p>
  </div>
</template>

<script>
import LeadLotPricesGraph from './LeadLotPricesGraph'
import LeadLotSimilar from './LeadLotSimilar'
import { Lot } from 'Models/Lot'
import { LotSimilar } from 'Models/LotSimilar'
import { sdk } from 'Services/shelfNetworkSdk'

import { LoneSdkCall } from 'Utils/LoneSdkCall'

const loneSdkCall = new LoneSdkCall()

export default {
  name: 'lead-lot-suggestions',

  components: {
    LeadLotPricesGraph,
    LeadLotSimilar,
  },

  props: {
    lot: {
      type: Lot,
      required: true,
    },

    configYear: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      prices: {
        min: '',
        avg: '',
        max: '',
        currency: '',
      },
      lots: {
        count: 0,
        list: [],
        currency: '',
      },
      isLoading: false,
      isEmpty: false,
      isLoadingFailed: false,
    }
  },

  created () {
    this.$watch(
      () => ([
        this.lot.vin,
        this.configYear,
      ]),
      () => {
        this.isLoading = true
        this.loadSuggestions()
      },
      { immediate: true }
    )
  },

  methods: {
    async loadSuggestions () {
      this.isLoading = true

      if (!this.lot.vin) {
        this.isEmpty = true
        this.isLoadingFailed = false
      } else {
        try {
          const { data: response } = await loneSdkCall.takeLatest(
            sdk.priceSuggester
              .getSuggestions(this.lot.vin, {
                primaryDamage: this.lot.primaryDamage,
                maker: this.lot.maker,
                model: this.lot.model,
                year: this.configYear || this.lot.manufactureYear,
                limit: 5,
              })
          )

          this.prices.min = response.belowMarket
          this.prices.avg = response.average
          this.prices.max = response.aboveMarket
          this.prices.currency = response.currency
          this.lots.list = response.lots.map(el => new LotSimilar(el))
          this.lots.count = response.count
          this.lots.currency = response.currency

          this.isEmpty = !this.lots.count
          this.isLoadingFailed = false
        } catch (error) {
          if ([404, 500, 502].includes(error.httpStatus)) {
            this.isEmpty = true
          } else if (!error.isCanceled) {
            this.isLoadingFailed = true
            console.error(error)
          }
        }
      }

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.lead-lot-suggestions {
  &_loading {
    @include loading-fog();
  }

  &__unavailable-stub,
  &__empty-stub,
  &__error-stub {
    font-weight: 700;
    text-align: center;
    color: $color-ui-secondary;
  }

  &__error-stub {
    color: $color-flag-is-error;
  }

  &__similar {
    margin-top: 1.5em;
  }
}
</style>

<i18n>
{
  "en": {
    "SUGGESTIONS_UNAVAILABLE_TXT": "No suggestions available",
    "NO_SUGGESTIONS_TXT": "No suggestions found",
    "ERROR_TXT": "Failed to load suggestions",
    "QUICK_SEARCH_SEPARATOR": " "
  },
  "ka": {
    "SUGGESTIONS_UNAVAILABLE_TXT": "მონაცემები არ მოიძებნა",
    "NO_SUGGESTIONS_TXT": "მონაცემები არ მოიძებნა",
    "ERROR_TXT": "მონაცემები არ მოიძებნა",
    "QUICK_SEARCH_SEPARATOR": " "
  },
  "ru": {
    "SUGGESTIONS_UNAVAILABLE_TXT": "Нет доступных предложений",
    "NO_SUGGESTIONS_TXT": "Предложений не найдено",
    "ERROR_TXT": "Предложения загрузить не удалось ",
    "QUICK_SEARCH_SEPARATOR": " "
  },
  "uk": {
    "SUGGESTIONS_UNAVAILABLE_TXT": "Немає доступних пропозицій",
    "NO_SUGGESTIONS_TXT": "Пропозицій не знайдено",
    "ERROR_TXT": "Пропозиції завантажити не вдалося",
    "QUICK_SEARCH_SEPARATOR": " "
  }
}
</i18n>
