<template>
  <div class="lead-lot-external-links">
    <a
      class="lead-lot-external-links__link"
      href="https://carvin.ge"
      target="_blank"
      rel="nofollow noopener"
    >
      <img
        class="lead-lot-external-links__link-service-ico"
        src="~Assets/channels/carfax.svg"
        alt="Carfax"
      >
      <span class="lead-lot-external-links__link-txt">
        {{ $t('CHECK_ON_CARFAX_BTN') }}
      </span>
      <ui-icon
        class="lead-lot-external-links__link-arrow-ico"
        icon="arrow-right"
      />
    </a>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { Lot } from 'Models/Lot'

export default {
  name: 'lead-lot-external-links',

  components: {
    UiIcon,
  },

  props: {
    lot: {
      type: Lot,
      default: new Lot({}),
    }
  },
}
</script>

<style lang="scss" scoped>
.lead-lot-external-links {
  display: grid;
  grid-template-rows: auto;
  grid-auto-columns: max-content;
  grid-gap: 1.5em 2.85em;

  @include respond(
    grid-template-columns,
    repeat(auto-fill, minmax(280px, 1fr)),
    repeat(auto-fill, minmax(280px, 1fr)),
    repeat(auto-fill, minmax(250px, 1fr)),
    repeat(auto-fill, minmax(270px, 1fr))
  );

  &__link {
    font-size: 1.071429em; // 15px when font-size: 14px;
    border-radius: 1em;
    padding: 1em;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    align-items: center;
    color: $color-dark;
    text-decoration: none;

    &-service-ico {
      width: 2em;
      height: 2em;
      margin-right: 0.333333em;
    }

    &-arrow-ico {
      margin-left: auto;
    }

    &_loading {
      @include loading-fog() {
        &::after {
          border-radius: 1em;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CHECK_ON_CARFAX_BTN": "Check on Carfax"
  },
  "ka": {
    "CHECK_ON_CARFAX_BTN": "Carfax-ის შემოწმება"
  },
  "ru": {
    "CHECK_ON_CARFAX_BTN": "Проверить на Carfax"
  },
  "uk": {
    "CHECK_ON_CARFAX_BTN": "Перевірити на Carfax"
  }
}
</i18n>
