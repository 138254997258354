import { i18n } from '@/i18n/instance'
import {
  isSameDay,
  isSameWeek,
  isSameMonth,
  isSameYear,
  isNullDate,
  formatDateRelative,
} from 'Utils/dateHelpers'
import get from 'lodash/get'

export function groupByPeriod ({
  collection = [],
  groupsConfig = {
    unset: { title: '', predicate: undefined },
    today: { title: '', predicate: undefined },
    week: { title: '', predicate: undefined },
    month: { title: '', predicate: undefined },
    year: { title: '', predicate: undefined },
    other: { title: '', predicate: undefined },
  },
  dateField = '',
}) {
  const groups = {
    unset: {
      title: get(groupsConfig, 'unset.title') ||
        i18n.t('COMMON.LIST_GROUPING.NOT_SET_DEFAULT_TITLE'),
      predicate: get(groupsConfig, 'unset.predicate') ||
        (date => isNullDate(date)),
      items: [],
    },
    today: {
      title: get(groupsConfig, 'today.title') ||
        i18n.t('COMMON.LIST_GROUPING.TODAY_DEFAULT_TITLE'),
      predicate: get(groupsConfig, 'today.predicate') ||
        ((date, today) => isSameDay(date, today)),
      items: [],
    },
    week: {
      title: get(groupsConfig, 'week.title') ||
        i18n.t('COMMON.LIST_GROUPING.WEEK_DEFAULT_TITLE'),
      predicate: get(groupsConfig, 'week.predicate') ||
        ((date, today) => isSameWeek(date, today)),
      items: [],
    },
    month: {
      title: get(groupsConfig, 'month.title') ||
        i18n.t('COMMON.LIST_GROUPING.MONTH_DEFAULT_TITLE'),
      predicate: get(groupsConfig, 'month.predicate') ||
        ((date, today) => isSameMonth(date, today)),
      items: [],
    },
    year: {
      title: get(groupsConfig, 'year.title') ||
        i18n.t('COMMON.LIST_GROUPING.YEAR_DEFAULT_TITLE'),
      predicate: get(groupsConfig, 'year.predicate') ||
        ((date, today) => isSameYear(date, today)),
      items: [],
    },
    other: {
      title: get(groupsConfig, 'other.title') ||
        i18n.t('COMMON.LIST_GROUPING.OTHER_DEFAULT_TITLE'),
      predicate: get(groupsConfig, 'other.predicate') ||
        (() => true),
      items: [],
    },
  }

  const compareOrder = [
    groups.unset,
    groups.today,
    groups.week,
    groups.month,
    groups.year,
    groups.other,
  ]

  const ordered = [
    groups.unset,
  ]

  const today = new Date()
  for (const item of collection) {
    const date = item[dateField]
    const group = compareOrder.find(item => item.predicate(date, today))
    group.items.push(item)
    if (!ordered.includes(group)) ordered.push(group)
  }

  return ordered
    .filter(group => group.items.length)
    .map(group => {
      return {
        title: i18n.t('COMMON.LIST_GROUPING.TITLE_FORMAT', {
          groupTitle: group.title,
          itemsCount: group.items.length,
        }),
        items: group.items,
      }
    })
}

export function groupByDate (collection = [], dateField = '', recordsField = '') {
  const groups = []

  for (const item of collection) {
    const date = get(item, dateField)
    const recordsCount = get(item, recordsField)

    let title
    if (date) {
      const formattedDate = formatDateRelative(date)
      title = recordsCount
        ? i18n.t('COMMON.LIST_GROUPING.TITLE_FORMAT', {
          groupTitle: formattedDate,
          itemsCount: recordsCount
        })
        : formattedDate
    } else {
      title = i18n.t('COMMON.LIST_GROUPING.NOT_SET_DEFAULT_TITLE')
    }

    const existingGroup = groups.find(el => el.title === title)
    if (existingGroup) {
      existingGroup.items.push(item)
    } else {
      groups.push({ title, items: [item] })
    }
  }

  return groups
}

export function groupByFirstLetter (collection = [], strField = '') {
  const groups = []

  for (const item of collection) {
    const str = item[strField] || ''
    const title = str.charAt(0).toUpperCase().trim() || '#'

    const existingGroup = groups.find(el => el.title === title)
    if (existingGroup) {
      existingGroup.items.push(item)
    } else {
      groups.push({ title, items: [item] })
    }
  }

  return groups
}
