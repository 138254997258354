import store from 'Store'
import { userActions } from 'Store/entities/User/types'

export function subscribeSignOutAfter (handler) {
  return store.subscribeAction({
    after: (action) => {
      if (action.type === `entities/user/${userActions.SIGN_OUT}`) {
        handler()
      }
    }
  })
}
