import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import ActiveTradesSkeleton from './components/ActiveTradesSkeleton'

const moduleName = 'activeTrades'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: ActiveTradesSkeleton,
  importComponent: () => import('./ActiveTrades'),
  async createDependencies () {
    const { default: activeTrades } = await import('./store')
    store.registerModule(['ui', moduleName], activeTrades)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
