<template>
  <div class="won-skeleton">
    <page-subnav class="won-skeleton__subnav" />

    <div class="won-skeleton__list">
      <won-item-skeleton />
      <won-item-skeleton />
      <won-item-skeleton />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import WonItemSkeleton from './WonListItemSkeleton'

export default {
  name: 'won-skeleton',
  components: {
    PageSubnav,
    WonItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
.won-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 2em;
  }
}
</style>
