import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'
import store from 'Store/index'

const moduleName = 'add-car'

export default createLazyLoadWrapper({
  name: moduleName,
  importComponent: () => import('./AddCar'),
  async createDependencies () {
    const { default: module } = await import('./store')
    store.registerModule(['ui', moduleName], module)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
