<template>
  <div class="list-params-period list-params-row">
    <label class="list-params-row__label">
      {{ label || $t('PERIOD_LBL') }}
    </label>

    <div class="list-params-period__date-fields">
      <calendar-field
        class="list-params-period__date-field"
        :placeholder="$t('SELECT_START_DATE_PH')"
        :value="strToDate(startDate)"
        :max-date="endDate ? strToDate(endDate) : undefined"
        :show-icon="showIcon"
        :auto-close="autoClose"
        @input="onInput('startDate', $event)"
      />

      <calendar-field
        class="list-params-period__date-field"
        :placeholder="$t('SELECT_END_DATE_PH')"
        :value="strToDate(endDate)"
        :min-date="startDate ? strToDate(startDate) : undefined"
        :is-day-end="true"
        :show-icon="showIcon"
        :auto-close="autoClose"
        @input="onInput('endDate', $event)"
      />
    </div>
  </div>
</template>

<script>
import CalendarField from 'Common/CalendarField'

export default {
  name: 'list-params-period',

  components: {
    CalendarField,
  },

  props: {
    label: {
      type: String,
      default: '',
    },

    startDate: {
      type: String,
      default: '',
    },

    endDate: {
      type: String,
      default: '',
    },

    showIcon: {
      type: Boolean,
      default: false,
    },

    autoClose: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    strToDate (str) {
      if (!str) return
      return new Date(str)
    },

    onInput (propName, value) {
      const emitVal = value instanceof Date ? value.toISOString() : ''
      this.$emit(`update:${propName}`, emitVal)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./styles/list-params-row.scss";

.list-params-period {
  &__date-fields {
    display: grid;
    grid: auto / repeat(auto-fit, minmax(16.5em, 1fr));
    gap: 1em;
  }
}
</style>

<i18n>
{
  "en": {
    "PERIOD_LBL": "BY PERIOD",
    "SELECT_START_DATE_PH": "Select start date",
    "SELECT_END_DATE_PH": "Select end date"
  },
  "ka": {
    "PERIOD_LBL": "ᲞᲔᲠᲘᲝᲓᲘᲡ ᲛᲘᲮᲔᲓᲕᲘᲗ",
    "SELECT_START_DATE_PH": "საწყისი თარიღი",
    "SELECT_END_DATE_PH": "ბოლო თარიღი"
  },
  "ru": {
    "PERIOD_LBL": "ПО ПЕРИОДУ",
    "SELECT_START_DATE_PH": "Выберите начальную дату",
    "SELECT_END_DATE_PH": "Выберите конечную дату"
  },
  "uk": {
    "PERIOD_LBL": "ЗА ПЕРІОДОМ",
    "SELECT_START_DATE_PH": "Оберіть початкову дату",
    "SELECT_END_DATE_PH": "Оберіть кінцеву дату"
  }
}
</i18n>
