<template>
  <div class="list-params">
    <ui-button
      class="list-params__btn"
      :class="{ 'list-params__btn_highlighted': !isActive }"
      fill="none"
      look="secondary"
      @click="openModal"
    >
      <ui-icon
        class="list-params__btn-ico"
        :icon="isActive ? 'filter-alt' : 'sort'"
      />

      <span class="list-params__btn-txt">
        <slot name="btn-txt">
          {{ $t('OPEN_BTN') }}
        </slot>
      </span>
    </ui-button>

    <div
      class="list-params-modal"
      v-if="isModalOpen"
    >
      <a
        href="javascript:void(0)"
        class="list-params-modal__close-btn"
        @click="closeModal"
      >
        <ui-icon icon="close" />
      </a>

      <div class="list-params-modal__content">
        <form
          class="list-params-form"
          @submit.prevent="applyForm"
          @reset.prevent="resetForm"
        >
          <div class="list-params-form__rows">
            <slot name="form-rows" />
          </div>

          <div class="list-params-form__actions">
            <ui-button
              class="list-params-form__clear-btn"
              type="reset"
              fill="none"
            >
              {{ $t('RESET_BTN') }}
            </ui-button>

            <ui-button
              class="list-params-form__apply-btn"
              type="submit"
            >
              {{ $t('APPLY_BTN') }}
            </ui-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  UiButton,
  UiIcon,
  BodyScrollPreventer
} from '@shelf.network/ui-kit'

const EVENTS = {
  formApply: 'form-apply',
  input: 'input',
}

export default {
  name: 'list-params',
  components: {
    UiButton,
    UiIcon,
  },

  props: {
    value: { type: Object, required: true },
    initialValue: { type: Object, required: true },
  },

  data () {
    return {
      isModalOpen: false,
    }
  },

  computed: {
    isActive () {
      return Object.values(this.value).every(el => !el)
    },
  },

  watch: {
    initialValue: {
      immediate: true,
      handler () {
        this.updateForm(this.initialValue)
      },
    },
  },

  methods: {
    openModal () {
      this.isModalOpen = true
      BodyScrollPreventer.on()
    },

    closeModal () {
      this.isModalOpen = false
      BodyScrollPreventer.off()
      this.updateForm(this.initialValue)
    },

    updateForm (value = {}) {
      const form = { ...this.value }

      for (const key of Object.keys(form)) {
        switch (typeof form[key]) {
          case 'string': form[key] = value[key] || ''; break
          case 'boolean': form[key] = value[key] || false; break
          default: break
        }
      }

      this.$emit(EVENTS.input, form)
    },

    applyForm () {
      this.$emit(EVENTS.formApply)
      this.closeModal()
    },

    resetForm () {
      this.updateForm()
      this.applyForm()
    }
  },
}
</script>

<style lang="scss" scoped>
.list-params {
  &__btn {
    &_highlighted {
      /deep/ .ui-button__button[fill][look] {
        color: $color-dark;
      }
    }

    /deep/ .ui-button__button {
      padding: 0.75em;
    }

    &-ico {
      font-size: 1.5em;
      vertical-align: middle;
    }

    &-txt {
      vertical-align: middle;
    }
  }
}

.list-params-modal {
  align-items: center;
  position: fixed;
  z-index: z-index(modal);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  overflow: auto;

  @include respond-below(sm) {
    padding: 0;
    border-radius: 0;
    display: block;
  }

  &__content {
    width: 60%;
    max-width: 42em;
    background: #fff;
    margin: 7em auto;
    border-radius: $layout-border-radius;
    position: relative;
    padding: 2.85em;

    @include respond-below(sm) {
      width: 100%;
      min-height: 100vh;
      border-radius: 0;
      margin: 0;
    }
  }

  &__close-btn {
    position: fixed;
    color: $color-dark-grey;
    text-decoration: none;
    right: 1em;
    top: 1em;
    bottom: 1em;
    left: 1em;
    box-sizing: border-box;
    font-size: 3em;
    text-align: right;

    &:hover {
      color: $color-light;
    }

    @include respond-below(sm) {
      font-size: 2em;
      position: absolute;
      bottom: auto;
      left: auto;
      z-index: z-index(1);
      top: 0.75em;

      &:hover {
        color: $color-dark;
      }
    }
  }
}

.list-params-form {
  &__rows,
  &__actions {
    display: grid;
    grid: auto-flow / minmax(0, auto);
  }

  &__rows {
    gap: 2.5em;
  }

  &__actions {
    margin-top: 3.5em;
    gap: 0.5em;
  }

  &__apply-btn,
  &__clear-btn {
    /deep/ .ui-button__button {
      border-radius: 1em;
    }
  }

  &__clear-btn {
    /deep/ .ui-button__button[fill][look] {
      color: $color-flag-is-error;

      &:hover {
        background: rgba($color-flag-is-error, 0.12);
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "OPEN_BTN": "Sort By",
    "RESET_BTN": "Reset all options",
    "APPLY_BTN": "Set options"
  },
  "ka": {
    "OPEN_BTN": "სორტირება",
    "RESET_BTN": "ფილტრების წაშლა",
    "APPLY_BTN": "გაფილტვრა"
  },
  "ru": {
    "OPEN_BTN": "Сортировать по",
    "RESET_BTN": "Сбросить все параметры",
    "APPLY_BTN": "Установить параметры"
  },
  "uk": {
    "OPEN_BTN": "Сортувати за",
    "RESET_BTN": "Скинути всі параметри",
    "APPLY_BTN": "Встановити параметри"
  }
}
</i18n>
