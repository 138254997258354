var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leads"},[_c('page-subnav',{staticClass:"leads__subnav"},[_c('ui-button',{on:{"click":_vm.openCreateLeadModal}},[_vm._v("\n      "+_vm._s(_vm.$t('CREATE_LEAD_BTN'))+"\n      "),_c('ui-icon',{attrs:{"icon":"plus"}})],1),_vm._v(" "),_c('leads-list-params',{staticClass:"leads__subnav-action",attrs:{"value":{
        sort: _vm.$route.query.sort || '',
        channel: _vm.$route.query.channel || '',
        broker: _vm.$route.query.broker || '',
        location: _vm.$route.query.location || '',
        isVerified: String(_vm.$route.query.isVerified) === 'true',
        isUnverified: String(_vm.$route.query.isUnverified) === 'true',
        isPrioritized: String(_vm.$route.query.isPrioritized) === 'true',
        isFinanced: String(_vm.$route.query.isFinanced) === 'true',
        isProCandidate: String(_vm.$route.query.isProCandidate) === 'true',
      }},on:{"input":_vm.onListParamsChange}}),_vm._v(" "),_c('subnav-search',{staticClass:"leads__subnav-action",attrs:{"value":_vm.$route.query.search},on:{"input":_vm.onSearch}}),_vm._v(" "),(_vm.$can(_vm.$USER_CLAIMS.BROKER_ASSIGNMENT))?[_c('div',{staticClass:"leads__subnav-group"},[_c('leads-bulk-assistant',{staticClass:"leads__subnav-action"}),_vm._v(" "),_c('leads-bulk-assigner',{staticClass:"leads__subnav-action"})],1)]:_vm._e(),_vm._v(" "),_c('template',{slot:"right"},[_c('router-link',{staticClass:"leads__tab leads__tab_waiting",attrs:{"active-class":"leads__tab_active","to":{
          params: { tab: _vm.LEADS_URL_PARAM_TABS.waiting },
          query: _vm.$route.query
        }}},[_c('ui-icon',{staticClass:"leads__tab-circle",attrs:{"icon":"circle"}}),_vm._v("\n        "+_vm._s(_vm.$t('WAITING_TAB'))+"\n        "),_c('ui-icon',{staticClass:"leads__tab-notify",attrs:{"icon":"circle-full"}})],1),_vm._v(" "),_c('router-link',{staticClass:"leads__tab leads__tab_hold",attrs:{"active-class":"leads__tab_active","to":{
          params: { tab: _vm.LEADS_URL_PARAM_TABS.hold },
          query: _vm.$route.query
        }}},[_c('ui-icon',{staticClass:"leads__tab-circle",attrs:{"icon":"circle"}}),_vm._v("\n        "+_vm._s(_vm.$t('HOLD_TAB'))+"\n        "),_c('ui-icon',{staticClass:"leads__tab-notify",attrs:{"icon":"circle-full"}})],1),_vm._v(" "),_c('router-link',{staticClass:"leads__tab leads__tab_looking",attrs:{"active-class":"leads__tab_active","to":{
          params: { tab: _vm.LEADS_URL_PARAM_TABS.looking },
          query: _vm.$route.query
        }}},[_c('ui-icon',{staticClass:"leads__tab-circle",attrs:{"icon":"circle"}}),_vm._v("\n        "+_vm._s(_vm.$t('LOOKING_TAB'))+"\n        "),_c('ui-icon',{staticClass:"leads__tab-notify",attrs:{"icon":"circle-full"}})],1),_vm._v(" "),_c('router-link',{staticClass:"leads__tab leads__tab_offered",attrs:{"active-class":"leads__tab_active","to":{
          params: { tab: _vm.LEADS_URL_PARAM_TABS.offered },
          query: _vm.$route.query
        }}},[_c('ui-icon',{staticClass:"leads__tab-circle",attrs:{"icon":"circle"}}),_vm._v("\n        "+_vm._s(_vm.$t('OFFERED_TAB'))+"\n        "),_c('ui-icon',{staticClass:"leads__tab-notify",attrs:{"icon":"circle-full"}})],1)],1)],2),_vm._v(" "),_c('leads-list',{staticClass:"leads__list-container",attrs:{"leads":_vm.leadsListFiltered,"is-loading":_vm.isLoading,"group-by":_vm.groupingAlgorithm},on:{"update-list-ask":_vm.loadLeads}}),_vm._v(" "),_c('lead-overview',{staticClass:"leads__details",attrs:{"leads-list":_vm.leadsListFiltered,"is-leads-loading":_vm.isLoading,"lead-id":_vm.$route.params.leadId,"lead-tab":_vm.$route.params.leadTab},on:{"update:leadId":function($event){return _vm.$router.push({
      params: { leadId: $event },
      query: _vm.$route.query,
    })},"update:leadTab":function($event){return _vm.$router.push({
      params: { leadTab: $event },
      query: _vm.$route.query,
    })},"close":function($event){return _vm.$router.push({
      params: { leadId: null, leadTab: null },
      query: _vm.$route.query,
    })}}}),_vm._v(" "),(_vm.showCreateLeadModal)?_c('lead-create',{staticClass:"leads__create-modal",attrs:{"auto-redirect":""},on:{"close":function($event){_vm.showCreateLeadModal = false},"lead-created":_vm.loadLeads}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }