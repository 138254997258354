import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import NotificationsSkeleton from './components/NotificationsSkeleton'

const moduleName = 'notifications'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: NotificationsSkeleton,
  importComponent: () => import('./Notifications'),
  async createDependencies () {
    const { default: notifications } = await import('./store')
    store.registerModule(['ui', moduleName], notifications)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
