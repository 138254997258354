import Vue from 'vue'

function showNotification ({
  group = 'default',
  type, text,
  undoAction,
  route,
}) {
  const notifyPayload = {
    ...(undoAction ? { undoAction } : {}),
    ...(route ? { route } : {}),
  }

  Vue.notify({
    group,
    type,
    text,
    ...(Object.keys(notifyPayload).length ? { data: notifyPayload } : {}),
  })
}

function parseParams (value) {
  if (typeof value === 'string') {
    return { text: value }
  }

  return {
    text: value.text,
    undoAction: value.undoAction,
    route: value.route,
  }
}

export function showError (params) {
  showNotification({ type: 'error', ...parseParams(params) })
}

export function showInfo (params) {
  showNotification({ type: 'info', ...parseParams(params) })
}

export function showSuccess (params) {
  showNotification({ type: 'success', ...parseParams(params) })
}
