import { ModelBase } from './ModelBase'

export class LotSimilar extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.maker = this._str(raw.maker)
    this.model = this._str(raw.model)
    this.photoUrl = this._str(raw.photo)
    this.year = this._str(raw.year)
    this.soldPrice = this._str(raw.price)
    this.currency = this._str(raw.currency)
  }
}
