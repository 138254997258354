const actions = {
  FETCH_ENUMS: 'FETCH_ENUMS',
  FETCH_TRANSLATIONS: 'FETCH_TRANSLATIONS',
}

const mutations = {
  SET_FUELS: 'SET_FUELS',
  SET_MAKER_NAMES: 'SET_MAKER_NAMES',
  SET_MODEL_NAMES: 'SET_MODEL_NAMES',
  SET_MAKERS_TO_MODELS: 'SET_MAKERS_TO_MODELS',
  SET_WHEELS: 'SET_WHEELS',
  SET_TRANSMISSIONS: 'SET_TRANSMISSIONS',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
}

const getters = {
  FUELS: 'FUELS',
  FUELS_AS_OPTIONS: 'FUELS_AS_OPTIONS',

  YEARS_AS_OPTIONS: 'YEARS_AS_OPTIONS',
  CURRENCIES_AS_OPTIONS: 'CURRENCIES_AS_OPTIONS',
  ENGINE_VOLUMES_AS_OPTIONS: 'ENGINE_VOLUMES_AS_OPTIONS',
  COLORS_AS_OPTIONS: 'COLORS_AS_OPTIONS',
  MILEAGE_UNITS_AS_OPTIONS: 'MILEAGE_UNITS_AS_OPTIONS',
  SALE_PERIODS_AS_OPTIONS: 'SALE_PERIODS_AS_OPTIONS',

  MAKERS_AS_OPTIONS: 'MAKERS_AS_OPTIONS',
  WHEELS_AS_OPTIONS: 'WHEELS_AS_OPTIONS',
  MODELS_AS_OPTIONS: 'MODELS_AS_OPTIONS',
  TRANSMISSIONS_AS_OPTIONS: 'TRANSMISSIONS_AS_OPTIONS',

  TRANSLATIONS: 'TRANSLATIONS',
}

export {
  actions as enumsActions,
  mutations as enumsMutations,
  getters as enumsGetters,
}
