<template>
  <button
    class="leads-export"
    @click="exportLeads"
  >
    {{ $t('EXPORT_BTN') }}
  </button>
</template>

<script>
import { LeadListEntry } from 'Models/LeadListEntry'
import { validateArrayOf } from 'Models/modelUtils'

import { downloadCsvFile } from 'Utils/downloadCsvFile'
import { showError } from 'Utils/notifications'
import { isNullDate, formatDate } from 'Utils/dateHelpers'

export default {
  name: 'leads-export',

  props: {
    leads: {
      type: Array,
      required: true,
      validator: validateArrayOf(LeadListEntry),
    },
  },

  computed: {
    leadsBlob () {
      const leadRows = this.leads.map(lead => {
        return [
          lead.fullName,
          lead.contactEmail,
          lead.contactPhone,
          isNullDate(lead.dueDate)
            ? ''
            : formatDate(lead.dueDate, 'yyyy-MM-dd HH:mm:ss'),
          formatDate(lead.stateUpdatedAt, 'yyyy-MM-dd HH:mm:ss'),
          lead.campaignSource,
          lead.campaignMedium,
          lead.campaignName,
          lead.campaignContent,
          lead.campaignTerm,
        ].join(',')
      })

      return [this.$t('EXPORT_TABLE_HEAD'), ...leadRows].join('\n')
    }
  },

  methods: {
    exportLeads () {
      try {
        const filename = [
          formatDate(new Date(), 'yyyy-MM-dd'),
          'leads',
          this.$route.params.assignment,
          this.$route.params.tab,
        ].join('-')

        downloadCsvFile(this.leadsBlob, filename)
      } catch (e) {
        showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.leads-export {
  background: none;
  border: none;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.5;
  color: $color-sys-info;
  transition: color 150ms;

  &:hover {
    color: rgba($color-sys-info, 0.8);
  }
}
</style>

<i18n>
{
  "en": {
    "EXPORT_BTN": "Export contacts",
    "EXPORT_TABLE_HEAD": "Full name,Email,Phone number,Due Date,State Updated,Source,Medium,Campaign,Content,Term"
  },
  "ka": {
    "EXPORT_BTN": "ლიდების გადმოწერა",
    "EXPORT_TABLE_HEAD": "სახელი და გვარი,ელ.ფოსტა,ტელეფონის ნომერი,თარიღი,სტატუსი განახლდა,წყარო,მედიუმი,კამპანია,კონტენტი,ტერმინალი"
  },
  "ru": {
    "EXPORT_BTN": "Экспорт контактов",
    "EXPORT_TABLE_HEAD": "Имя,Email,Номер телефона,Срок,Статус обновлен,Источник,Посредник,Кампания,Содержание,Срок"
  },
  "uk": {
    "EXPORT_BTN": "Експорт контактів",
    "EXPORT_TABLE_HEAD": "Ім'я,Email,Номер телефону,Термін,Статус оновлено,Джерело,Посередник,Кампанія,Зміст,Термін"
  }
}
</i18n>
