<template>
  <div class="lead">
    <div class="lead-details__head">
      <div class="lead-details__head-avatar">
        <ui-img
          class="lead-details__head-avatar-img"
          v-bind="customerAvatar"
        />
        <lead-priority-mark
          :lead="lead"
          class="lead-details__head-avatar-priority"
        />
      </div>
      <div class="lead-details__head-user">
        <div class="lead-details__head-user-name-wrp">
          <channel-icon
            class="lead-details__head-user-channel"
            :channel="lead.channel"
          />
          <span class="lead-details__head-user-name">
            {{ lead.fullName || $t('COMMON.UNNAMED_USER_PH') }}
          </span>
          <template v-if="lead.isPro">
            <span class="lead-details__head-user-pro-mark">
              {{ $t('PRO_TXT') }}
            </span>
          </template>
          <template v-if="lead.hasActiveDeposit">
            <div
              class="lead-details__head-user-deposit-wrp"
              :aria-label="$t('HAS_DEPOSIT_HINT')"
              role="tooltip"
              data-microtip-position="bottom-left"
              data-microtip-size="medium"
            >
              <ui-icon
                class="lead-details__head-user-deposit"
                icon="money-stack"
              />
            </div>
          </template>
        </div>
        <div class="lead-details__head-user-phone">
          <ui-icon icon="call" />
          {{ formattedLeadPhone }}
        </div>
      </div>

      <div class="lead-details__head-actions">
        <template v-if="showDueDate">
          <lead-due-date-dropdown
            class="lead-details__head-due-date"
            :lead="lead"
          />
        </template>
        <template v-else>
          <div class="lead-details__head-last-request">
            <ui-icon
              icon="timer-alt"
              class="lead-details__head-last-request-icon"
            />
            <time-ago :date="lead.lastRequestDate" />
          </div>
        </template>

        <lead-state-select
          class="lead-details__head-status"
          :lead="lead"
        />
      </div>
    </div>
    <div class="lead-details__subnav">
      <div class="lead-details__subnav-container">
        <div class="lead-details__subnav-tabs">
          <button
            v-for="tab in availableLeadTabs"
            :key="tab.name"
            class="lead-details__tab"
            :class="{ 'lead-details__tab_active': leadTabLocal === tab.name }"
            @click="setLeadTab(tab.name)"
          >
            {{ tab.label }}
            <ui-icon
              icon="circle-full"
              class="lead-details__tab-notify"
              :class="{
                'lead-details__tab-notify_show': tab.notifications
              }"
            />
          </button>
        </div>
        <ui-button
          class="lead-details__clear-notifications"
          look="waiting"
          @click="markAllRequestsResolved"
          v-if="lead.unresolvedSupportRequests.length"
        >
          <ui-icon
            icon="success"
            class="lead-details__clear-notifications-icon"
          />
          {{ $t('CLEAR_NOTIFICATIONS_BTN') }}
        </ui-button>
      </div>
    </div>

    <div class="lead-details__tab-wrap">
      <component
        class="lead-details__tab-component"
        :is="currentComponent"
        :lead="lead"
      />
    </div>
    <div class="lead-details__comment">
      <lead-comments
        class="lead-details__comment-comp"
        :lead-id="lead.id" />
    </div>
    <div class="lead-details__footer">
      <ui-row class="lead-details__footer-row">
        <ui-col
          class="lead-details__footer-left"
          lg="7"
          xs="12"
        >
          <lead-broker-select
            class="lead-details__footer-broker-select"
            v-if="$can($USER_CLAIMS.BROKER_ASSIGNMENT)"
            :lead="lead"
          />
          <div class="lead-details__footer-created-at">
            {{ $t('CREATED_TXT', { date: leadCreatedAt }) }}
          </div>
        </ui-col>

        <ui-col
          class="lead-details__footer-right"
          lg="5"
          xs="12"
          xs-offset="0"
        >
          <ui-button
            v-if="$can($USER_CLAIMS.LEAD_FOLLOW)"
            @click="toggleFollow"
            :is-disabled="isFollowing"
            class="lead-details__footer-action"
            look="default"
            fill="none"
          >
            <ui-icon
              class="lead-details__footer-action-icon"
              icon="bell"
            />

            <template v-if="!isFollowed">
              {{ $t('FOLLOW_LEAD_BTN') }}
            </template>

            <template v-else>
              {{ $t('UNFOLLOW_LEAD_BTN') }}
            </template>

            <ui-spinner
              class="lead-details__footer-action-icon"
              type="pills"
              overlay
              v-if="isFollowing"
            />
          </ui-button>

          <ui-button
            @click="archiveLead"
            :is-disabled="isArchived && !isArchiving"
            class="lead-details__footer-action"
            look="default"
            fill="none"
          >
            <ui-icon
              class="lead-details__footer-action-icon"
              icon="archive"
            />

            <template v-if="!isArchived || isArchiving">
              {{ $t('ARCHIVE_LEAD_BTN') }}
            </template>

            <template v-else>
              {{ $t('ARCHIVED_LEAD_BTN') }}
            </template>

            <ui-spinner
              class="lead-details__footer-action-icon"
              type="pills"
              overlay
              v-if="isArchiving"
            />
          </ui-button>
        </ui-col>
      </ui-row>
    </div>
  </div>
</template>

<script>
import {
  UiCol,
  UiRow,
  UiIcon,
  UiButton,
  UiImg,
  UiSpinner,
  breakpoints,
  formatPhone
} from '@shelf.network/ui-kit'
import LeadTabHistory from './LeadTabHistory'
import LeadTabLots from './LeadTabLots'
import LeadTabUser from './LeadTabUser'
import LeadStateSelect from './LeadStateSelect'
import LeadPriorityMark from './LeadPriorityMark'
import LeadBrokerSelect from './LeadBrokerSelect'
import LeadDueDateDropdown from './LeadDueDateDropdown'
import LeadComments from './LeadComments'
import TimeAgo from 'Common/TimeAgo'
import ChannelIcon from 'Common/ChannelIcon'

import { Lead } from 'Models/Lead'
import { mapActions, mapGetters } from 'vuex'
import { actions } from '../store/types'
import avaPh64Path from 'Assets/icons/ava-ph-64.svg'
import { generateImageProps } from 'Utils/generateImageProps'
import { showSuccess, showError } from 'Utils/notifications'
import { LEADS_URL_PARAM_LEAD_TABS } from 'Constants/leadUrlParams'
import { USER_CLAIMS } from 'Constants/userClaims'
import { formatDateTimeRelative } from 'Utils/dateHelpers'
import { userGetters } from 'Store/entities/User/types'
import { sdk } from 'Services/shelfNetworkSdk'

const DEFAULT_LEAD_TAB = LEADS_URL_PARAM_LEAD_TABS.user

export default {
  name: 'lead-details',

  components: {
    UiIcon,
    UiButton,
    UiImg,
    UiCol,
    UiRow,
    UiSpinner,

    TimeAgo,
    ChannelIcon,

    LeadDueDateDropdown,
    LeadTabHistory,
    LeadTabLots,
    LeadTabUser,
    LeadStateSelect,
    LeadPriorityMark,
    LeadBrokerSelect,
    LeadComments,
  },

  props: {
    lead: {
      type: Lead,
      required: true
    },

    leadTab: {
      type: String,
      default: DEFAULT_LEAD_TAB
    },
  },

  data () {
    return {
      isArchiving: false,
      isFollowed: false,
      isFollowing: false,
      leadTabLocal: DEFAULT_LEAD_TAB,
    }
  },

  computed: {
    ...mapGetters('entities/user', {
      accountId: userGetters.ACCOUNT_ID,
    }),

    formattedLeadPhone () {
      return formatPhone(this.lead.contactPhone)
    },

    leadTabs () {
      // TODO: some notifications is dummy data yet

      return [
        {
          name: LEADS_URL_PARAM_LEAD_TABS.user,
          component: 'lead-tab-user',
          label: this.$t('USER_DETAILS_TAB'),
          notifications: 0
        },
        {
          name: LEADS_URL_PARAM_LEAD_TABS.cars,
          component: 'lead-tab-lots',
          label: this.$t('CARS_AND_CALCULATOR_TAB'),
          notifications: 0
        },
        {
          name: LEADS_URL_PARAM_LEAD_TABS.history,
          component: 'lead-tab-history',
          label: this.$t('HISTORY_TAB'),
          notifications: 0
        }
      ]
    },
    showDueDate () {
      return [
        Lead.statesEnum.lookingCar,
        Lead.statesEnum.onHold,
        Lead.statesEnum.offerSent,
      ].includes(this.lead.state)
    },
    leadCreatedAt () {
      return formatDateTimeRelative(this.lead.createdAt)
    },
    currentComponent () {
      let tab = this.leadTabs.find(t => this.leadTabLocal === t.name)
      return tab ? tab.component : this.leadTabs[0].component
    },
    customerAvatar () {
      if (this.lead.avatarLink) {
        return generateImageProps({
          alt: this.lead.firstName,
          src: this.lead.avatarLink,
          srcSetOptions: [
            { width: 64, height: 64 },
            { width: 92, height: 92 },
          ],
          sizes: [
            `(max-width: ${breakpoints.SM}px) 10vw`,
            '2vw',
          ].join(', '),
          progressive: false,
        })
      } else {
        return { src: avaPh64Path }
      }
    },

    isArchived () {
      return this.lead.state === Lead.statesEnum.archived
    },

    availableLeadTabs () {
      return this.leadTabs.filter(tab => !tab.claim || this.$can(tab.claim))
    },
  },

  watch: {
    leadTab: {
      handler (value, oldValue) {
        if (!value) {
          return this.setLeadTab(DEFAULT_LEAD_TAB)
        }

        const tab = this.leadTabs.find(x => x.name === value)
        if (tab.claim && !this.$can(tab.claim)) {
          this.setLeadTab(DEFAULT_LEAD_TAB)
        }
      },
      immediate: true
    },
  },

  created () {
    if (this.$can(USER_CLAIMS.LEAD_FOLLOW)) {
      this.checkIsFollowed()
    }
  },

  methods: {
    ...mapActions('ui/leads', {
      updateLead: actions.UPDATE_LEAD,
      markLeadRequestsResolved: actions.MARK_LEAD_REQUESTS_RESOLVED,
      followLead: actions.FOLLOW_LEAD,
      unfollowLead: actions.UNFOLLOW_LEAD,
    }),

    async toggleFollow () {
      if (this.isFollowing) return

      this.isFollowing = true

      let errorTxt
      try {
        if (this.isFollowed) {
          errorTxt = this.$t('UNFOLLOW_ERROR_NOTIFY')
          await this.unfollowLead({ leadId: this.lead.id })
          this.isFollowed = false
        } else {
          errorTxt = this.$t('FOLLOW_ERROR_NOTIFY')
          await this.followLead({ leadId: this.lead.id })
          this.isFollowed = true
        }
      } catch (error) {
        showError(errorTxt)
      }

      this.isFollowing = false
    },

    async archiveLead () {
      if (this.isArchiving || this.isArchived) return

      this.isArchiving = true
      try {
        const text = this.$t('ARCHIVED_NOTIFY')
        const undo = await this.updateLead(({
          id: this.lead.id,
          state: Lead.statesEnum.archived,
        }))
        showSuccess({ text, undoAction: undo })
        this.$emit('lead-dismiss')
      } catch (e) {
        console.error(e)
      }
      this.isArchiving = false
    },

    async checkIsFollowed () {
      const params = { lead: this.lead.id, follower: this.accountId }
      try {
        const { data } = await sdk.backOffice.v2.getLeadFollower(params)
        this.isFollowed = data && data.id
      } catch (error) {
        if (error.httpStatus === 404) {
          this.isFollowed = false
        } else {
          console.error(error)
        }
      }
    },

    markAllRequestsResolved () {
      this.markLeadRequestsResolved(this.lead.id)
    },

    setLeadTab (name) {
      this.leadTabLocal = name
      this.$emit('update:leadTab', name)
    },
  },

  metaInfo () {
    return {
      title: this.lead.fullName || this.$t('COMMON.UNNAMED_USER_PH')
    }
  }
}
</script>

<style scoped lang="scss">
$ava-size: 4em;

.lead-details {
  @extend %p;

  margin: 0;

  &__head {
    display: grid;
    grid: auto / auto minmax(0, 1fr) auto;
    align-items: center;
    padding: 2em 2.75em;
    max-width: 100%;
    gap: 1em;

    &-avatar {
      position: relative;
      border-radius: 0.5em;
      width: $ava-size;
      height: $ava-size;
      background: $color-light-grey;

      @include aspect-ratio();

      --ratio: calc(1 / 1);

      &-img {
        --ui-img-border-radius: 0.5em;

        width: $ava-size;
        height: $ava-size;
        overflow: hidden;

        @include aspect-ratio();

        --ratio: calc(1 / 1);
      }

      &-priority {
        font-size: 1.4em;
        position: absolute;
        left: -0.7em;
        top: -0.7em;
      }
    }

    &-user {
      flex: auto;
      line-height: 2em;

      &-name-wrp {
        display: flex;
        align-items: center;
      }

      &-channel {
        flex-shrink: 0;
        font-size: 1.8em;
        display: flex;
        justify-items: center;
        margin-right: 0.15em;
      }

      &-name,
      &-pro-mark {
        font-size: 1.4em;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-pro-mark {
        color: $color-user-pro;
        margin-left: 0.2em;
      }

      &-deposit-wrp {
        width: 1.8em;
        height: 1.8em;
        border-radius: 50%;
        margin-left: 1ch;
      }

      &-deposit {
        display: flex;
        width: 1.8em;
        height: 1.8em;
        background: $color-light-grey;
        border-radius: 50%;
        color: $color-sys-success;
        align-items: center;
        justify-content: center;

        &::before {
          font-size: 1.3em;
        }
      }

      &-phone {
        font-size: 1.2em;
      }
    }

    &-actions {
      display: grid;
      grid: auto / auto-flow auto;
      align-items: center;
      justify-items: end;
      justify-content: end;
      gap: 1em;
    }

    &-last-request {
      display: flex;
      align-items: center;
      color: $color-dark-grey;
      white-space: nowrap;

      &-icon {
        font-size: 1.3em;
        margin-right: 0.25em;
      }
    }
  }

  &__subnav {
    // needs to be a component or mixin
    background-color: #f0f2f5;
    max-width: 100%;

    &-tabs {
      margin-right: auto;
      margin-left: 0;
      display: flex;
      height: 100%;
    }

    &-container {
      display: flex;
      height: 5em;
      align-items: center;
      padding: 0 2.75em;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;

      @include scrollbar-invisible();
    }
  }

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 2em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;
    background: none;
    border: none;

    &-circle {
      vertical-align: sub;
      font-size: 1.1em;
    }

    &-notify {
      color: $color-sys-warning;
      transform: translateY(-0.4em) translateX(-0.2em);
      display: inline-block;
      vertical-align: text-top;
      font-size: 0.8em;
      visibility: hidden;
      width: 0;

      &_show {
        visibility: visible;
      }
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &_active &-circle::before {
      content: map-get($icons, circle-full);
    }
  }

  &__tab-wrap {
    padding: 2em 1.75em 3em 1.75em;
  }

  &__comment {
    padding: 0;
    box-shadow: 0 -0.5em 0.5em rgba(0, 0, 0, 0.05);
  }

  &__footer {
    border-top: 1px solid $color-grey;
    padding: 2em 1.75em;

    &-action {
      & /deep/ .ui-button__button {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        position: relative;
      }

      &-icon {
        padding-right: 0.4em;
        font-size: 1.5em;
      }
    }

    &-broker-select {
      width: 16em;
      margin-right: 2em;

      @include respond-below(sm) {
        margin-right: 0;
        margin-bottom: 2em;
        width: 80vw;
      }
    }

    &-created-at {
      color: $color-ui-secondary;
      display: flex;
      align-items: center;
    }

    &-left {
      text-align: left;
      display: flex;

      @include respond-below(sm) {
        display: block;
      }
    }

    &-right {
      text-align: right;
    }
  }

  &__clear-notifications {
    & /deep/ .ui-button__button {
      display: flex;
      align-items: center;
      align-content: center;
      background-color: $color-sys-warning !important;
      border-color: transparent !important;
      font-size: 0.95em;

      &:hover {
        background-color: darken($color-sys-warning, 10%) !important;
      }
    }

    &-icon {
      font-size: 1.4em;
      margin-right: 0.3em;
    }
  }

  @include respond-below(sm) {
    &__head {
      margin-top: 2.9em;
      grid: unset;
      grid-template:
        "ava user" "actions actions"
        / $ava-size minmax(0, 1fr);
      justify-content: start;

      &-avatar {
        grid-area: ava;
      }

      &-user {
        grid-area: user;
      }

      &-actions {
        grid-area: actions;
        justify-items: start;
        justify-content: start;
      }
    }
  }

  @include respond-below(xs) {
    &__head {
      &-user {
        &-name {
          font-size: 1.2em;
        }
      }

      &-actions {
        grid: auto-flow auto / 1fr;
        justify-items: stretch;
        justify-content: stretch;

        /deep/ .ui-dropdown__trigger {
          justify-content: unset;
        }
      }
    }

    &__clear-notifications {
      padding: 0 2.1em 0 1em;
    }

    &__footer {
      &-action {
        margin-top: 2em;
        width: 100%;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "USER_DETAILS_TAB": "USER DETAILS",
    "CARS_AND_CALCULATOR_TAB": "CARS & CALCULATOR",
    "MESSAGES_TAB": "MESSAGES",
    "HISTORY_TAB": "HISTORY",
    "CLEAR_NOTIFICATIONS_BTN": "RESOLVE",
    "ARCHIVE_LEAD_BTN": "Archive",
    "ARCHIVED_LEAD_BTN": "ARCHIVED",
    "ARCHIVED_NOTIFY": "Lead archived",
    "FOLLOW_LEAD_BTN": "Follow",
    "UNFOLLOW_LEAD_BTN": "Unfollow",
    "FOLLOW_ERROR_NOTIFY": "Cannot follow the lead",
    "UNFOLLOW_ERROR_NOTIFY": "Cannot unfollow the lead",
    "CREATED_TXT": "Created: {date}",
    "HAS_DEPOSIT_HINT": "Has deposit",
    "PRO_TXT": "PRO"
  },
  "ka": {
    "USER_DETAILS_TAB": "ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲚᲘᲡ ᲘᲜᲤᲝ",
    "CARS_AND_CALCULATOR_TAB": "ᲐᲕᲢᲝᲛᲝᲑᲘᲚᲘ",
    "MESSAGES_TAB": "ᲛᲔᲢᲧᲝᲑᲘᲜᲔᲑᲔᲑᲘ",
    "HISTORY_TAB": "ᲘᲡᲢᲝᲠᲘᲐ",
    "CLEAR_NOTIFICATIONS_BTN": "ᲩᲐᲥᲠᲝᲑᲐ",
    "ARCHIVE_LEAD_BTN": "დაარქივება",
    "ARCHIVED_LEAD_BTN": "ᲓᲐᲐᲠᲥᲘᲕᲔᲑᲣᲚᲘ",
    "ARCHIVED_NOTIFY": "დაარქივება",
    "FOLLOW_LEAD_BTN": "გამოწერა",
    "UNFOLLOW_LEAD_BTN": "გამოწერის გაუქმება",
    "FOLLOW_ERROR_NOTIFY": "ლიდის დაკვირვება შეუძლებელია",
    "UNFOLLOW_ERROR_NOTIFY": "დაკვირვების გაუქმება შეუძლებელია",
    "CREATED_TXT": "შექმნილია: {date}",
    "HAS_DEPOSIT_HINT": "დეპოზიტი",
    "PRO_TXT": "PRO"
  },
  "ru": {
    "USER_DETAILS_TAB": "ПОЛЬЗОВАТЕЛЬ",
    "CARS_AND_CALCULATOR_TAB": "АВТОМОБИЛИ И КАЛЬКУЛЯТОР",
    "MESSAGES_TAB": "СООБЩЕНИЯ",
    "HISTORY_TAB": "ИСТОРИЯ",
    "CLEAR_NOTIFICATIONS_BTN": "ОБРАБОТАНО",
    "ARCHIVE_LEAD_BTN": "Архивировать",
    "ARCHIVED_LEAD_BTN": "Помещен в архив",
    "ARCHIVED_NOTIFY": "Лид помещен в архив",
    "FOLLOW_LEAD_BTN": "Следить",
    "UNFOLLOW_LEAD_BTN": "Не следить",
    "FOLLOW_ERROR_NOTIFY": "Не удалось начать следить за лидом",
    "UNFOLLOW_ERROR_NOTIFY": "Не удалось перестать следить за лидом",
    "CREATED_TXT": "Создан: {date}",
    "HAS_DEPOSIT_HINT": "Есть депозит",
    "PRO_TXT": "PRO"
  },
  "uk": {
    "USER_DETAILS_TAB": "ДАНІ КОРИСТУВАЧА",
    "CARS_AND_CALCULATOR_TAB": "АВТОМОБІЛІ І КАЛЬКУЛЯТОР",
    "MESSAGES_TAB": "ПОВІДОМЛЕННЯ",
    "HISTORY_TAB": "ІСТОРІЯ",
    "CLEAR_NOTIFICATIONS_BTN": "ОБРОБИТИ",
    "ARCHIVE_LEAD_BTN": "Архівувати",
    "ARCHIVED_LEAD_BTN": "Поміщено в архів",
    "ARCHIVED_NOTIFY": "Лід поміщено в архів",
    "FOLLOW_LEAD_BTN": "Стежити",
    "UNFOLLOW_LEAD_BTN": "Не стежити",
    "FOLLOW_ERROR_NOTIFY": "Не вдалось почати стежити за лідом",
    "UNFOLLOW_ERROR_NOTIFY": "Не вдалось припинити стежити за лідом",
    "CREATED_TXT": "Створений: {date}",
    "HAS_DEPOSIT_HINT": "Є депозит",
    "PRO_TXT": "PRO"
  }
}
</i18n>
