<template>
  <div class="carfax-list-skeleton">
    <div class="carfax-list-skeleton__date">
      <p class="carfax-list-skeleton__line">
        &nbsp;
      </p>
    </div>

    <carfax-item-skeleton
      v-for="i in 3"
      :key="i"
    />
  </div>
</template>

<script>
import CarfaxItemSkeleton from './CarfaxItemSkeleton'

export default {
  name: 'carfax-list-skeleton',
  components: { CarfaxItemSkeleton }
}
</script>

<style lang="scss" scoped>
@import "@/styles/skeletons.scss";

.carfax-list-skeleton {
  &:not(:first-child) {
    margin-top: 2.4em;
  }

  &__line {
    @extend %skeleton-bg;

    border-radius: 3em;
    max-width: 20em;
  }

  &__date {
    padding: 0 1em 1em;
    font-size: 1.4em;

    @include respond-below(sm) {
      padding: 0.6em 0;
    }

    .carfax-list-skeleton__line {
      max-width: 10em;
    }
  }
}
</style>
