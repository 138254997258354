import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import DepositsSkeleton from './components/DepositsSkeleton'

const moduleName = 'deposits'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: DepositsSkeleton,
  importComponent: () => import('./Deposits'),
  async createDependencies () {
    const { default: deposits } = await import('./store')
    store.registerModule(['ui', moduleName], deposits)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
