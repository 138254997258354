import amplitude from 'amplitude-js'
import { sha256 } from 'crypto-hash'
import memoize from 'lodash/memoize'

const sha256Memoized = memoize(sha256)

/**
 * Init Amplitude SDK for a particular platform if the API key is provide
 *
 * @param {string} amplitudeKey Amplitude API key for that platform.
 */
export function enableTracking (amplitudeKey) {
  try {
    amplitude.getInstance().init(amplitudeKey, null, {
      platform: 'Admin Panel'
    })
    amplitude.getInstance().setOptOut(false)
  } catch (err) {
    console.error(err)
  }
}

/**
 * Disable Amplitude tracking.
 */
export function disableTracking () {
  try {
    amplitude.getInstance().setOptOut(true)
  } catch (err) {
    console.error(err)
  }
}

/**
 * Send user interaction event to Amplitude.
 *
 * @param {string} phoneNumber User's phone number.
 * @param {string} category The category that was interacted with. E.g. player
 * @param {string} action The type of interaction (e.g. 'clicked play')
 * @param {object} [meta] Meta info about event.
 */
export async function trackUserEvent (phoneNumber, event, details = {}) {
  try {
    const instance = await getUserScopedInstance(phoneNumber)
    instance.logEvent(event, details)
  } catch (err) {
    console.error(err)
  }
}

/**
 * Set custom user properties.
 *
 * @param {string} phoneNumber User's phone number.
 * @param {object} properties Custom user properties.
 */
export async function setUserProperties (phoneNumber, properties) {
  try {
    const instance = await getUserScopedInstance(phoneNumber)
    instance.setUserProperties(properties)
  } catch (err) {
    console.error(err)
  }
}

async function getUserScopedInstance (phoneNumber) {
  const rawHash = await sha256Memoized(phoneNumber)
  const hash = rawHash.slice(0, 16)

  amplitude.getInstance().setDeviceId('ADMIN:' + hash)
  amplitude.getInstance().setUserId(hash)

  return amplitude.getInstance()
}
