import { ModelBase } from './ModelBase'

export class NotificationLot extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.platformId = this._str(raw.platform)
    this.images = [this._str(raw.imageLink)]
    this.name = this._str(raw.name)
    this.link = this._str(raw.link)
    this.endTime = this._date(raw.endTime).getTime()
  }
}
