const actions = {
  LOAD_BROKERS: 'LOAD_BROKERS',
  FETCH_BROKER: 'FETCH_BROKER',
  RESET: 'RESET',
}

const mutations = {
  SET_BROKERS: 'SET_BROKERS',
  SET_BROKER: 'SET_BROKER',
  RESET: 'RESET',
}

const getters = {
  BROKERS: 'BROKERS',
  BROKERS_RAW: 'BROKERS_RAW',
  BROKER_OPTIONS: 'BROKER_OPTIONS',
  BROKER: 'BROKER',
  NEED_TO_FETCH_BROKER: 'NEED_TO_FETCH_BROKER',
}

export {
  actions as brokersActions,
  mutations as brokersMutations,
  getters as brokersGetters,
}
