import { config } from '@/config'
import { WSClient, WS_CLIENT_EVENTS } from '@shelf.network/js-sdk'

const NOTIFICATIONS_CLIENT_EVENTS = Object.freeze({
  NOTIFICATION: 'NOTIFICATION',
})

class NotificationsClient extends WSClient {
  constructor () {
    super({ wsUrl: config.RINGER_WSS })

    this.on(WS_CLIENT_EVENTS.MESSAGE, this._onMessage)
  }

  _onMessage (data) {
    if (!data.resourceType || data.resourceType !== 'notifications') return
    this.emit(NOTIFICATIONS_CLIENT_EVENTS.NOTIFICATION, data)
  }
}

const notificationsClient = new NotificationsClient()
export { notificationsClient, NOTIFICATIONS_CLIENT_EVENTS }
