<template>
  <div class="notification-item-skeleton notifications-cmn__row">
    <div class="notification-item-skeleton__cell">
      <div class="notification-item-skeleton__ava" />

      <span
        class="notification-item-skeleton__line"
        style="width: 13em;"
      >&nbsp;</span>
    </div>

    <div
      class="notification-item-skeleton__cell"
      :class="'notification-item-skeleton__cell_end'"
    >
      <div class="notification-item-skeleton__btn" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'notification-item-skeleton',
}
</script>

<style scoped lang="scss">
@import "../styles/notifications";
@import "@/styles/skeletons.scss";

.notification-item-skeleton {
  padding: 2.1em 0;
  background: $color-light;
  border: 1px solid transparent;
  box-sizing: border-box;

  &__cell {
    min-width: 0;
    min-height: 0;
    display: grid;
    grid: auto / auto-flow auto;
    justify-content: start;
    align-items: center;
    grid-gap: 0.5em;

    &_end {
      justify-self: end;
    }
  }

  &__ava {
    @extend %skeleton-bg;

    max-width: 2.2em;
    min-width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
  }

  &__line {
    @extend %skeleton-bg;

    border-radius: 50px;
    display: inline-block;
  }

  &__btn {
    @extend %skeleton-bg;

    width: 3.55em;
    height: 3.55em;
    border-radius: 50%;
  }
}
</style>
