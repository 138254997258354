<template>
  <div
    class="layout"
    :class="{ 'layout_hide-ui': !isLoggedIn }">
    <sidebar
      @close="hideSidebar"
      class="layout__sidebar"
      :class="{ 'layout__sidebar_is-open': isSidebarOpen }"
    />
    <div class="layout__wrap">
      <div class="layout__wrap-header">
        <ui-button
          class="layout__btn-menu"
          @click="showSidebar"
          fill="none"
        >
          <ui-icon icon="menu" />
        </ui-button>
        <header-component class="layout__wrap-header-component" />
      </div>
      <router-view class="layout__wrap-view" />
    </div>

    <div class="layout__modal">
      <ui-portal-target />
      <ui-portal-target name="notifications" />
    </div>

    <status-message />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Header from 'Components/Header'
import Sidebar from './components/Sidebar.vue'
import { UiIcon, UiButton, UiPortalTarget } from '@shelf.network/ui-kit'
import StatusMessage from 'Common/StatusMessage'
import { userGetters } from 'Store/entities/User/types'

export default {
  name: 'layout',
  components: {
    StatusMessage,
    Sidebar,
    UiButton,
    UiIcon,
    UiPortalTarget,
    HeaderComponent: Header.rootComponent
  },
  data () {
    return { showUi: false, isSidebarOpen: false }
  },
  computed: {
    ...mapGetters('entities/user', {
      isLoggedIn: userGetters.IS_LOGGED_IN,
    })
  },
  methods: {
    showSidebar () {
      this.isSidebarOpen = true
    },
    hideSidebar () {
      this.isSidebarOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
$sidebar-width: 20em;

%lt-layout-transition {
  transition: all ease-out 280ms;
}

.layout {
  @extend %lt-layout-transition;

  padding-left: $sidebar-width;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__sidebar {
    @extend %lt-layout-transition;

    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: $sidebar-width;
    height: 100vh;
    box-sizing: border-box;
  }

  &__btn-menu {
    display: none;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    flex: 1;

    &-header {
      @extend %lt-layout-transition;

      position: sticky;
      top: 0;
      z-index: z-index(10);
      display: flex;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
      background: #fff;
      border-bottom: 1px solid $color-light;

      &-component {
        flex: 1;
        z-index: z-index(10);
      }
    }

    &-view {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  &__modal {
    z-index: z-index(modal);
  }

  &_hide-ui {
    transform: translateX(-$sidebar-width/2);
  }

  &_hide-ui &__wrap-header {
    transform: translateY(-100%);
    visibility: hidden;
    height: 0;
  }

  &_hide-ui &__sidebar {
    transform: translateX(-100%);
    visibility: hidden;
  }

  @include respond-below(sm) {
    padding-left: 0;

    &__sidebar {
      max-width: 90vw;
      transform: translateX(-100%);
      z-index: z-index(100);

      &_is-open {
        transform: translateX(0);
      }
    }

    &__btn-menu {
      display: flex;
      font-size: 2em;
      align-items: center;

      &::after {
        content: "";
        width: 1px;
        height: 1.5em;
        display: block;
        background: $color-grey;
      }

      & /deep/ .ui-button__button {
        padding: 0 0.7em;
        display: flex;
        align-items: center;
      }
    }

    &_hide-ui {
      transform: none;
    }
  }
}
</style>
