<template>
  <div class="dealers-skeleton">
    <page-subnav class="dealers-skeleton__subnav" />

    <div class="dealers-skeleton__list">
      <dealer-item-skeleton />
      <dealer-item-skeleton />
      <dealer-item-skeleton />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import DealerItemSkeleton from './DealerItemSkeleton'

export default {
  name: 'dealers-skeleton',
  components: {
    PageSubnav,
    DealerItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
@import "../styles/dealers";

.dealers-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 0 $list-padding-side;
  }
}
</style>
