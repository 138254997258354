import snakeCase from 'lodash/snakeCase'
import toUpper from 'lodash/toUpper'
import startCase from 'lodash/startCase'
import flow from 'lodash/flow'

export function constantCase (str = '') {
  return flow(snakeCase, toUpper)(str)
}

export function titleCase (str = '') {
  return flow(snakeCase, startCase)(str)
}
