export const WON_URL_PARAMS_TABS = Object.freeze({
  invoice: 'invoice',
  inTransit: 'in-transit',
  done: 'done',
})

export const WON_QUERY_SORTS = Object.freeze({
  lotEndTime: 'lot.end_time',
  lotEndTimeDesc: '-lot.end_time',
  winnerDeliveredAt: 'winner.delivered_at',
  winnerDeliveredAtDesc: '-winner.delivered_at',
})

export const WON_TAB_DEFAULT_SORT = Object.freeze({
  [WON_URL_PARAMS_TABS.invoice]: WON_QUERY_SORTS.lotEndTimeDesc,
  [WON_URL_PARAMS_TABS.inTransit]: WON_QUERY_SORTS.lotEndTimeDesc,
  [WON_URL_PARAMS_TABS.done]: WON_QUERY_SORTS.winnerDeliveredAtDesc,
})

export const WON_QUERY_SORTS_BY_TAB = Object.freeze({
  [WON_URL_PARAMS_TABS.invoice]: [
    WON_QUERY_SORTS.lotEndTime,
    WON_QUERY_SORTS.lotEndTimeDesc,
  ],
  [WON_URL_PARAMS_TABS.inTransit]: [
    WON_QUERY_SORTS.lotEndTime,
    WON_QUERY_SORTS.lotEndTimeDesc,
  ],
  [WON_URL_PARAMS_TABS.done]: [
    WON_QUERY_SORTS.lotEndTime,
    WON_QUERY_SORTS.lotEndTimeDesc,
    WON_QUERY_SORTS.winnerDeliveredAt,
    WON_QUERY_SORTS.winnerDeliveredAtDesc,
  ],
})
