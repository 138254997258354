import { enums } from '@shelf.network/js-sdk'

import { ModelBase } from './ModelBase'
import { formatLocation } from 'Utils/formatLocation'

import youtubeRegex from 'youtube-regex'
import get from 'lodash/get'

export class LotFrontOffice extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.createdAt = this._date(raw.createdAt).getTime()
    this.endTime = this._date(raw.endTime).getTime()
    this.endedAt = this._date(raw.endedAt).getTime()
    this.startTime = this._date(raw.startTime).getTime()
    this.duration = this._num(raw.duration)
    this.timeElapsed = Boolean(raw.timeElapsed)

    this.type = this._str(raw.lotType)
    this.typeI = this._num(raw.lotTypeI)
    this.state = this._str(raw.state)
    this.externalId = this._str(raw.externalId)

    this.highestBid = this._num(raw.highestBid)
    this.buyNowPrice = this._num(raw.buyNowPrice) ||
      this._num(raw.customerTotalPrice)
    this.startPrice = this._num(raw.startPrice)
    this.customerTotalPrice = this._num(raw.customerTotalPrice)

    this.minStep = this._num(raw.minBidStep)
    this.maxStep = this._num(raw.maxBidStep)

    const vehicle = get(raw, 'relationships.vehicle') || {}

    this.isOnApproval = Boolean(vehicle.onApproval)
    this.isOnRoad = Boolean(vehicle.onRoad)
    this.isExchangeAllowed = Boolean(vehicle.exchangeAllowed)
    this.isPremium = Boolean(raw.isPremium)

    this.city = this._str(vehicle.city)
    this.country = this._str(vehicle.country)
    this.region = this._str(vehicle.region)
    this.location = formatLocation(this.country, this.city, this.region)

    this._externalAuctionId = this._str(vehicle.externalId)

    this.name = this._str(raw.name)
    this.maker = this._str(vehicle.maker)
    this.model = this._str(vehicle.model)

    this.vinCode = this._str(vehicle.vinCode)
    this.year = this._num(vehicle.year)
    this.currency = this._str(raw.currency)

    this.images = vehicle.images || []
    this.hdImages = vehicle.imagesHd || []

    this.itemNumber = this._num(raw.itemNumber)
    this.primaryDamage = this._str(vehicle.primaryDamage)
    this.secondaryDamage = this._str(vehicle.secondaryDamage)
    this.damageLevel = this._str(vehicle.damageLevel)

    this.fuelType = this._str(vehicle.fuelType)
    this.transmission = this._str(vehicle.transmission)
    this.isRegistered = Boolean(vehicle.registered)
    this.color = this._str(vehicle.color)
    this.driveType = this._str(vehicle.driveType)
    this.odometerValueKm = this._num(vehicle.odometerValueKm)
    this.odometerValueState = vehicle.odometerValueState
    this.isBidFinal = Boolean(raw.isBidFinal)
    this.isSubLot = Boolean(raw.isSubLot)

    this.saleId = this._str(get(raw, 'relationships.sale.id'))

    this.keyFob = this._str(vehicle.keyFob)
    this.wheel = this._str(vehicle.wheelPosition)
    this.zip = this._str(vehicle.zip)
    this._rawYoutubeLink = this._str(vehicle.youtubeLink)

    this.description = this._str(vehicle.notes)
    this._rawDescriptionLocalized = this._str(vehicle.notesLocalized)

    this.engineVolumeCm = this._str(vehicle.engineVolumeCm)
    this.engineVolume = this.engineVolumeCm / 1000

    this.vehicleState = this._str(vehicle.vehicleState)
    this.documentType = this._str(vehicle.ownershipDocumentType)
    this.ownershipDocumentGrade = this._str(vehicle.ownershipDocumentGrade)

    this.platformId = this._str(get(raw, 'relationships.platform.id'))
  }

  get isDirectSale () {
    return this.type === enums.lotTypes.sale
  }

  get isBnp () {
    return this.type === enums.lotTypes.bnp
  }

  get isSale () {
    const isBnpSale = this.isBnp && Boolean(this.buyNowPrice)
    return this.isOnRoad || this.isDirectSale || isBnpSale
  }

  get isCopartAuction () {
    return this.type === enums.lotTypes.copartAuction
  }

  get isIaaiAuction () {
    return this.type === enums.lotTypes.iaaiAuction
  }

  get externalAuctionId () {
    if (!this._externalAuctionId) return ''

    if (this.isCopartAuction) {
      const matched = this._externalAuctionId.match(/copart-(\d{6,8})/)
      return matched ? matched[1] : ''
    }

    if (this.isIaaiAuction) {
      const matched = this._externalAuctionId.match(/^.+-(\d{6,8})$/)
      return matched ? matched[1] : ''
    }

    return this._externalAuctionId
  }

  get isAuction () {
    return this.type === enums.lotTypes.shelfAuction ||
      this.isCopartAuction ||
      this.isIaaiAuction
  }

  get price () {
    return this.isDirectSale || (this.isBnp && this.buyNowPrice)
      ? this.buyNowPrice
      : this.highestBid || this.startPrice
  }

  get descriptionLocalized () {
    try {
      return JSON.parse(this._rawDescriptionLocalized) || {}
    } catch (e) {
      return {}
    }
  }

  get youtubeLink () {
    if (!this._rawYoutubeLink) {
      return ''
    }

    const youtubeId = youtubeRegex().exec(this._rawYoutubeLink)[1]
    return youtubeId ? `https://www.youtube.com/embed/${youtubeId}` : ''
  }
}
