<template>
  <span
    class="lead-item-type"
    :class="{
      'lead-item-type_deposit': lead.hasActiveDeposit,
      'lead-item-type_onboard': lead.isVerified,
      'lead-item-type_guest': !lead.isVerified,
    }"
  >
    <template v-if="lead.hasActiveDeposit">
      {{ $t('DEPOSIT_LEAD_TYPE') }}
    </template>

    <template v-else-if="lead.isVerified">
      {{ $t('ONBOARD_LEAD_TYPE') }}
    </template>

    <template v-else-if="!lead.isVerified">
      {{ $t('GUEST_LEAD_TYPE') }}
    </template>
  </span>
</template>

<script>
import { Lead } from 'Models/Lead'
import { LeadListEntry } from 'Models/LeadListEntry'

export default {
  name: 'lead-item-type',

  props: {
    lead: {
      type: [Lead, LeadListEntry],
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.lead-item-type {
  font-size: 0.7em;
  font-weight: 500;
  color: $color-ui-secondary;

  &_guest {
    color: $color-sys-attention;
  }

  &_onboard {
    color: $color-sys-success;
  }

  &_deposit {
    color: $color-sys-info;
  }
}
</style>

<i18n>
{
  "en": {
    "GUEST_LEAD_TYPE": "GUEST",
    "ONBOARD_LEAD_TYPE": "VERIFIED",
    "DEPOSIT_LEAD_TYPE": "DEPOSIT"
  },
  "ka": {
    "GUEST_LEAD_TYPE": "ᲣᲪᲜᲝᲑᲘ",
    "ONBOARD_LEAD_TYPE": "ᲕᲔᲠᲘᲤᲘᲪᲘᲠᲔᲑᲣᲚᲘ",
    "DEPOSIT_LEAD_TYPE": "ᲓᲔᲞᲝᲖᲘᲢᲘ"
  },
  "ru": {
    "GUEST_LEAD_TYPE": "ГОСТЬ",
    "ONBOARD_LEAD_TYPE": "ПОЛЬЗОВАТЕЛЬ",
    "DEPOSIT_LEAD_TYPE": "ДЕПОЗИТ"
  },
  "uk": {
    "GUEST_LEAD_TYPE": "ГІСТЬ",
    "ONBOARD_LEAD_TYPE": "ПІДТВЕРДЖЕНИЙ",
    "DEPOSIT_LEAD_TYPE": "ДЕПОЗИТ"
  }
}
</i18n>
