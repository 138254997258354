import { Lead } from 'Models/Lead'
import { LeadListEntry } from 'Models/LeadListEntry'
import { LeadComment } from 'Models/LeadComment'

export default {
  isLoading: state => state.isLoading,
  leadsList: state => state.leadsList.map(item => new LeadListEntry(item)),
  leadListEntryById: state => id =>
    new LeadListEntry(state.leadsList.find(item => item.id === id)),
  leadById: state => id => new Lead(state.leadsById[id]),
  leadsByIdRaw: state => state.leadsById,
  bulkSelection: state => state.bulkSelection,
  attachmentsQueue: state => state.attachmentsQueue,
  isAttachmentsUploading: state => state.isAttachmentsUploading,
  getCommentsByLeadId: state => (leadId) => {
    return state.commentsByLeadId[leadId] ? {
      isLoading: state.commentsByLeadId[leadId].isLoading,
      isSaving: state.commentsByLeadId[leadId].isSaving,
      hasError: state.commentsByLeadId[leadId].hasError,
      fetchNext: state.commentsByLeadId[leadId].fetchNext,
      totalItems: state.commentsByLeadId[leadId].totalItems,
      items: state.commentsByLeadId[leadId].items
        .map(item => new LeadComment(item))
    } : {}
  },
}
