export default {
  translations: {},

  fuels: [],
  wheels: [],
  transmissions: [],

  makerNames: [],
  modelNames: [],
  makersToModels: [],
}
