import user from './User'
import platforms from './Platforms'
import brokers from './Brokers'
import enums from './Enums'
import exRates from './ExRates'
import bell from './Bell'

export default {
  namespaced: true,
  modules: {
    user,
    platforms,
    brokers,
    enums,
    exRates,
    bell,
  }
}
