<template>
  <div class="sidebar">
    <img
      v-if="platformLogo"
      :src="platformLogo"
      class="sidebar__platform-logo"
    >
    <template v-if="isLoggedIn">
      <div class="sidebar__side-top">
        <ui-button
          @click="$emit('close')"
          class="sidebar__btn-close"
          fill="none"
          look="secondary"
        >
          <ui-icon icon="close" />
        </ui-button>

        <router-link
          @click.native="$emit('close')"
          v-if="$can($USER_CLAIMS.LEADS)"
          to="/leads"
          active-class="sidebar__link_active"
          class="sidebar__link"
          :class="{
            'sidebar__link_active': $route.name === 'lead',
          }"
        >
          <ui-icon
            icon="call"
            class="sidebar__link-icon" />
          {{ $t('LEADS_LINK') }}
        </router-link>

        <template
          v-if="$route.name === 'leads' && $can($USER_CLAIMS.BROKER_ASSIGNMENT)"
        >
          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.LEADS)"
            :to="{
              name: 'leads',
              params: { assignment: LEADS_URL_PARAM_ASSIGNMENTS.unassigned },
              query: $route.query,
            }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            {{ $t('LEADS_UNASSIGNED_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.LEADS)"
            :to="{
              name: 'leads',
              params: { assignment: LEADS_URL_PARAM_ASSIGNMENTS.assigned },
              query: $route.query,
            }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            {{ $t('LEADS_ASSIGNED_LINK') }}
          </router-link>
        </template>

        <router-link
          @click.native="$emit('close')"
          v-if="$can($USER_CLAIMS.ACTIVE_TRADES)"
          :to="{ name: 'activeTrades.entry' }"
          active-class="sidebar__link_active"
          class="sidebar__link"
        >
          <ui-icon
            icon="refresh"
            class="sidebar__link-icon" />
          {{ $t('ACTIVE_TRADES_LINK') }}
        </router-link>

        <router-link
          @click.native="$emit('close')"
          v-if="$can($USER_CLAIMS.WON)"
          :to="{ name: 'won.entry' }"
          active-class="sidebar__link_active"
          class="sidebar__link"
        >
          <ui-icon
            icon="money-sign"
            class="sidebar__link-icon" />
          {{ $t('WON_LINK') }}
        </router-link>

        <router-link
          @click.native="$emit('close')"
          v-if="$can($USER_CLAIMS.DEPOSITS_VIEW)"
          :to="{ name: 'deposits.entry' }"
          active-class="sidebar__link_active"
          class="sidebar__link"
        >
          <ui-icon
            icon="send-invoice"
            class="sidebar__link-icon" />
          {{ $t('DEPOSITS_LINK') }}
        </router-link>

        <router-link
          @click.native="$emit('close')"
          v-if="$can($USER_CLAIMS.ARCHIVE)"
          :to="{ name: 'archive.entry' }"
          active-class="sidebar__link_active"
          class="sidebar__link"
        >
          <ui-icon
            icon="archive"
            class="sidebar__link-icon" />
          {{ $t('ARCHIVE_LINK') }}
        </router-link>

        <router-link
          @click.native="$emit('close')"
          v-if="$can($USER_CLAIMS.SEARCH)"
          to="/search"
          active-class="sidebar__link_active"
          class="sidebar__link"
        >
          <ui-icon
            icon="search"
            class="sidebar__link-icon" />
          {{ $t('SEARCH_LINK') }}
        </router-link>

        <router-link
          @click.native="$emit('close')"
          v-if="
            $can($USER_CLAIMS.MY_TRADES) ||
              $can($USER_CLAIMS.UPLOAD)
          "
          active-class="sidebar__link_active"
          :to="{ name: 'platformSales' }"
          :class="{
            'sidebar__link': true,
            'sidebar__link_active': $route.path.includes('/platform-sales/'),
          }"
        >
          <ui-icon
            icon="make-a-bid-alt"
            class="sidebar__link-icon"
          />
          {{ $t('PLATFORM_SALES_LINK') }}
        </router-link>

        <template v-if="$route.path.includes('/platform-sales/')">
          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.MY_TRADES)"
            :to="{ name: 'myTrades.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="refresh"
              class="sidebar__sub-link-icon"
            />
            {{ $t('MY_TRADES_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.UPLOAD)"
            :to="{ name: 'addCar.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="plus"
              class="sidebar__sub-link-icon"
            />
            {{ $t('ADD_A_CAR_LINK') }}
          </router-link>
        </template>

        <router-link
          @click.native="$emit('close')"
          v-if="$can($USER_CLAIMS.CARFAX) || isTransportationEnabled"
          active-class="sidebar__link_active"
          :to="{ name: 'services' }"
          :class="{
            'sidebar__link': true,
            'sidebar__link_active': $route.path.includes('/services/'),
          }"
        >
          <ui-icon
            icon="gear"
            class="sidebar__link-icon"
          />
          {{ $t('SERVICES_LINK') }}
        </router-link>

        <template v-if="$route.path.includes('/services/')">
          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.CARFAX)"
            :to="{ name: 'carfax' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="car-check"
              class="sidebar__sub-link-icon"
            />
            {{ $t('CARFAX_LINK') }}
          </router-link>
          <router-link
            @click.native="$emit('close')"
            v-if="isTransportationEnabled"
            :to="{ name: 'transportation' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="calculator"
              class="sidebar__sub-link-icon"
            />
            {{ $t('TRANSPORTATION_LINK') }}
          </router-link>
        </template>

        <router-link
          @click.native="$emit('close')"
          v-if="$can($USER_CLAIMS.ANALYTICS)"
          to="/analytics"
          active-class="sidebar__link_active"
          class="sidebar__link"
          :class="{
            'sidebar__link_active': $route.name === 'analytics',
          }"
        >
          <ui-icon
            icon="eye"
            class="sidebar__link-icon" />
          {{ $t('ANALYTICS') }}
        </router-link>

        <router-link
          @click.native="$emit('close')"
          v-if="$can($USER_CLAIMS.NOTIFICATIONS)"
          :to="{ name: 'notifications.entry' }"
          active-class="sidebar__link_active"
          class="sidebar__link"
        >
          <ui-icon
            icon="bell-fill"
            class="sidebar__link-icon" />
          {{ $t('NOTIFICATIONS_LINK') }}
        </router-link>

        <router-link
          @click.native="$emit('close')"
          v-if="$can($USER_CLAIMS.USER_MANAGEMENT)"
          :to="{ name: 'users.entry' }"
          active-class="sidebar__link_active"
          class="sidebar__link"
        >
          <ui-icon
            icon="user-fill"
            class="sidebar__link-icon" />
          {{ $t('USERS_LINK') }}
        </router-link>

        <router-link
          @click.native="$emit('close')"
          v-if="
            $can($USER_CLAIMS.EXTENSION_DEALERS) ||
              $can($USER_CLAIMS.EXTENSION_FEES)
          "
          :to="{ name: 'extension.copart' }"
          class="sidebar__link"
          active-class="sidebar__link_active"
          :class="{ 'sidebar__link_active': isCopartActive }"
        >
          <ui-icon
            icon="car-side-fill"
            class="sidebar__link-icon"
          />
          {{ $t('COPART_EXTENSION_LINK') }}
        </router-link>

        <template v-if="isCopartActive">
          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.EXTENSION_DEALERS)"
            :to="{ name: 'extension.copart.dealers.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="user-fill"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_DEALERS_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.EXTENSION_ACCOUNTS)"
            :to="{ name: 'extension.copart.accounts.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="id-card"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_ACCOUNTS_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.EXTENSION_FEES)"
            :to="{ name: 'extension.copart.fees.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="ship"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_FEES_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.EXTENSION_WON)"
            :to="{ name: 'extension.copart.won.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="money-sign"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_WON_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.EXTENSION_CLAIMS)"
            :to="{ name: 'extension.copart.claims.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="car-inspect"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_CLAIMS_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.EXTENSION_NOTIFICATIONS)"
            :to="{ name: 'extension.copart.notifications.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="bell-fill"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_NOTIFICATIONS_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            :to="{ name: 'search' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="search"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_SEARCH_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.EXTENSION_ANALYTICS)"
            :to="{ name: 'extension.copart.analytics.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="eye"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_ANALYTICS_LINK') }}
          </router-link>
        </template>

        <router-link
          @click.native="$emit('close')"
          v-if="
            $can($USER_CLAIMS.IMPACT_EXTENSION_DEALERS) ||
              $can($USER_CLAIMS.IMPACT_EXTENSION_ACCOUNTS)
          "
          :to="{ name: 'extension.impact' }"
          class="sidebar__link"
          active-class="sidebar__link_active"
          :class="{ 'sidebar__link_active': isImpactActive }"
        >
          <ui-icon
            icon="car"
            class="sidebar__link-icon"
          />
          {{ $t('IMPACT_EXTENSION_LINK') }}
        </router-link>

        <template v-if="isImpactActive">
          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.IMPACT_EXTENSION_DEALERS)"
            :to="{ name: 'extension.impact.dealers.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="user-fill"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_DEALERS_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.IMPACT_EXTENSION_ACCOUNTS)"
            :to="{ name: 'extension.impact.accounts.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="id-card"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_ACCOUNTS_LINK') }}
          </router-link>

          <router-link
            @click.native="$emit('close')"
            v-if="$can($USER_CLAIMS.IMPACT_EXTENSION_WON)"
            :to="{ name: 'extension.impact.won.entry' }"
            active-class="sidebar__sub-link_active"
            class="sidebar__sub-link"
          >
            <ui-icon
              icon="money-sign"
              class="sidebar__link-icon" />
            {{ $t('EXTENSION_WON_LINK') }}
          </router-link>
        </template>
      </div>
      <div class="sidebar__side-bottom">
        <button
          @click="signOut"
          class="sidebar__sign-out-btn sidebar__link">
          <ui-icon
            icon="sign-out"
            class="sidebar__link-icon" />
          {{ $t('SIGN_OUT_BTN') }}
        </button>

        <p class="sidebar__version">
          {{ $t('VERSION_TXT', { version: APP_VERSION }) }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import get from 'lodash/get'

import { UiIcon, UiButton } from '@shelf.network/ui-kit'
import { config } from '@/config'
import { userActions, userGetters } from 'Store/entities/User/types'
import { LEADS_URL_PARAM_ASSIGNMENTS } from 'Constants/leadUrlParams'

import { APP_VERSION } from 'Constants/appVersion'

export default {
  name: 'sidebar',

  components: {
    UiIcon,
    UiButton,
  },

  data () {
    return {
      featureFlags: config.featureFlags,
      APP_VERSION,
      LEADS_URL_PARAM_ASSIGNMENTS,
    }
  },

  computed: {
    ...mapGetters('entities/user', {
      isLoggedIn: userGetters.IS_LOGGED_IN,
      platform: userGetters.PLATFORM,
    }),

    platformLogo () {
      return get(this.platform, 'logos.light')
    },

    isCopartActive () {
      return this.$route.path.includes('/extension/copart/') ||
        (this.$route.name === 'search' && !this.$can(this.$USER_CLAIMS.SEARCH))
    },

    isImpactActive () {
      return this.$route.path.includes('/extension/impact/')
    },

    isTransportationEnabled () {
      return this.$can(this.$USER_CLAIMS.W8_TRANSPORTATION) &&
        config.featureFlags.TRANSPORTATION_FEES
    }
  },

  methods: {
    ...mapActions('entities/user', {
      signOut: userActions.SIGN_OUT
    }),
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  margin: 0;
  background: #0d0d0e;
  color: #5b6066;
  padding: 2.5em 2.25em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;

  &__side-bottom {
    margin-top: auto;
    padding-top: 1.4em;
  }

  &__side-top {
    margin-bottom: auto;
  }

  &__platform-logo {
    width: 80%;
    height: 2.5em;
    object-fit: contain;
    display: block;
    object-position: 0 50%;
    margin-bottom: 3em;
  }

  &__version {
    margin-top: 0.8em;
  }

  &__btn-close {
    display: none;

    @include respond-below(sm) {
      display: block;
      position: absolute;
      right: 0;
      top: 0.35em;
      font-size: 1.5em;

      & /deep/ .ui-button__button {
        padding: 1.5em 1.2em;
      }
    }
  }

  &__link {
    border: 0 none;
    background: transparent;
    font-size: 1em;
    padding: 1em;
    border-radius: $layout-border-radius;
    text-decoration: none;
    color: #5b6066;
    font-weight: 500;
    display: block;
    margin: 0.5em 0;
    text-align: left;
    white-space: nowrap;

    @include respond-below(sm) {
      font-size: 0.8em;
    }

    &-icon {
      font-size: 1.55em;
      margin-right: 0.5em;
      vertical-align: sub;
      width: 1.2em;
      display: inline-block;
    }

    &:hover {
      background: #1d1d23;
      color: #898e94;
    }

    &_active,
    &_active:hover {
      background: #1d1d23;
      color: #fff;
    }

    & /deep/ .badge__indicator {
      font-size: 10px;
      color: transparent;
      min-width: 0.5em;
      min-height: 0.5em;
      width: 0.5em;
      height: 0.5em;
      border-radius: 2em;
      line-height: 0.5em;
      position: relative;
      top: -0.65em;
      right: -0.1em;
    }
  }

  &__sub-link {
    font-size: 1em;
    padding: 0.5em;
    text-decoration: none;
    color: #5b6066;
    font-weight: 400;
    display: block;
    margin: 0.65em 0 0.65em 2.9em;

    &:hover {
      color: #898e94;
    }

    &_active,
    &_active:hover {
      color: #fff;
    }

    &-icon {
      font-size: 1.15em;
      margin-right: 0.25em;
      vertical-align: middle;
      width: 1em;
      display: inline-block;
    }
  }

  &__sign-out-btn {
    display: none;

    @include respond-below(sm) {
      display: block;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "VERSION_TXT": "v.{version}",
    "ACTIVE_TRADES_LINK": "ACTIVE TRADES",
    "DEPOSITS_LINK": "DEPOSITS",
    "WON_LINK": "WON",
    "SEARCH_LINK": "SEARCH",
    "LEADS_LINK": "LEADS",
    "LEADS_ASSIGNED_LINK": "Assigned",
    "LEADS_UNASSIGNED_LINK": "Unassigned",
    "SIGN_OUT_BTN": "Sign out",
    "ARCHIVE_LINK": "ARCHIVE",
    "EDIT_PROFILE_LINK": "EDIT PROFILE",
    "ANALYTICS": "ANALYTICS",
    "PLATFORM_SALES_LINK": "PLATFORM SALES",
    "ADD_A_CAR_LINK": "Add a car",
    "MY_TRADES_LINK": "My trades",
    "SERVICES_LINK": "SERVICES",
    "CARFAX_LINK": "Carfax",
    "TRANSPORTATION_LINK": "Transportation",
    "NOTIFICATIONS_LINK": "NOTIFICATIONS",
    "USERS_LINK": "USERS",
    "COPART_EXTENSION_LINK": "COPART EXTENSION",
    "IMPACT_EXTENSION_LINK": "IMPACT EXTENSION",
    "EXTENSION_DEALERS_LINK": "Dealers",
    "EXTENSION_ACCOUNTS_LINK": "Accounts",
    "EXTENSION_FEES_LINK": "Fees",
    "EXTENSION_WON_LINK": "Won",
    "EXTENSION_SEARCH_LINK": "Search",
    "EXTENSION_ANALYTICS_LINK": "Analytics",
    "EXTENSION_CLAIMS_LINK": "Claims",
    "EXTENSION_NOTIFICATIONS_LINK": "Notifications"
  },
  "ka": {
    "VERSION_TXT": "v.{version}",
    "ACTIVE_TRADES_LINK": "ᲐᲣᲥᲪᲘᲝᲜᲔᲑᲘ",
    "SEARCH_LINK": "ᲫᲘᲔᲑᲐ",
    "LEADS_LINK": "ᲚᲘᲓᲔᲑᲘ",
    "LEADS_ASSIGNED_LINK": "გაფილტრული",
    "LEADS_UNASSIGNED_LINK": "გაუფილტრავი",
    "SIGN_OUT_BTN": "გამოსვლა",
    "DEPOSITS_LINK": "ᲓᲔᲞᲝᲖᲘᲢᲔᲑᲘ",
    "WON_LINK": "ᲛᲝᲒᲔᲑᲣᲚᲘ",
    "ARCHIVE_LINK": "ᲐᲠᲥᲘᲕᲘ",
    "EDIT_PROFILE_LINK": "პროფილის რედაქტირება",
    "ANALYTICS": "ᲐᲜᲐᲚᲘᲢᲘᲙᲐ",
    "PLATFORM_SALES_LINK": "ᲒᲐᲧᲘᲓᲕᲔᲑᲘ",
    "ADD_A_CAR_LINK": "ატვირთვა",
    "MY_TRADES_LINK": "გაყიდვები",
    "SERVICES_LINK": "ᲡᲔᲠᲕᲘᲡᲔᲑᲘ",
    "CARFAX_LINK": "Carfax",
    "TRANSPORTATION_LINK": "ტრანსპორტირება",
    "NOTIFICATIONS_LINK": "ᲨᲔᲢᲧᲝᲑᲘᲜᲔᲑᲔᲑᲘ",
    "USERS_LINK": "ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲚᲔᲑᲘ",
    "EXTENSION_LINK": "ᲐᲞᲚᲘᲙᲐᲪᲘᲐ",
    "COPART_EXTENSION_LINK": "COPART ᲐᲞᲚᲘᲙᲐᲪᲘᲐ",
    "IMPACT_EXTENSION_LINK": "IMPACT ᲐᲞᲚᲘᲙᲐᲪᲘᲐ",
    "EXTENSION_DEALERS_LINK": "დილერი",
    "EXTENSION_ACCOUNTS_LINK": "ექაუნთები",
    "EXTENSION_FEES_LINK": "ტარიფი",
    "EXTENSION_WON_LINK": "მოგებული",
    "EXTENSION_SEARCH_LINK": "ძიება",
    "EXTENSION_ANALYTICS_LINK": "ანალიტიკა",
    "EXTENSION_CLAIMS_LINK": "მოთხოვნა",
    "EXTENSION_NOTIFICATIONS_LINK": "შეტყობინებები"
  },
  "ru": {
    "VERSION_TXT": "v.{version}",
    "ACTIVE_TRADES_LINK": "АКТИВНЫЕ ЛОТЫ",
    "SEARCH_LINK": "ПОИСК",
    "LEADS_LINK": "ЛИДЫ",
    "LEADS_ASSIGNED_LINK": "Назначенные",
    "LEADS_UNASSIGNED_LINK": "Неназначенные",
    "SIGN_OUT_BTN": "Выход",
    "DEPOSITS_LINK": "ДЕПОЗИТЫ",
    "WON_LINK": "ВЫИГРАННЫЕ",
    "ARCHIVE_LINK": "АРХИВ",
    "EDIT_PROFILE_LINK": "РЕДАКТИРОВАТЬ ПРОФИЛЬ",
    "ANALYTICS": "АНАЛИТИКА",
    "PLATFORM_SALES_LINK": "ПРОДАЖИ",
    "ADD_A_CAR_LINK": "Добавить авто",
    "MY_TRADES_LINK": "Мои сделки",
    "SERVICES_LINK": "УСЛУГИ",
    "CARFAX_LINK": "Carfax",
    "TRANSPORTATION_LINK": "Транспортировка",
    "NOTIFICATIONS_LINK": "ОПОВЕЩЕНИЯ",
    "USERS_LINK": "ПОЛЬЗОВАТЕЛИ",
    "COPART_EXTENSION_LINK": "РАСШИРЕНИЕ COPART",
    "IMPACT_EXTENSION_LINK": "РАСШИРЕНИЕ IMPACT",
    "EXTENSION_DEALERS_LINK": "Дилеры",
    "EXTENSION_ACCOUNTS_LINK": "Аккаунты",
    "EXTENSION_FEES_LINK": "Комиссии",
    "EXTENSION_WON_LINK": "Выигранные",
    "EXTENSION_SEARCH_LINK": "Поиск",
    "EXTENSION_ANALYTICS_LINK": "Аналитика",
    "EXTENSION_CLAIMS_LINK": "Запросы",
    "EXTENSION_NOTIFICATIONS_LINK": "Оповещения"
  },
  "uk": {
    "VERSION_TXT": "v.{version}",
    "ACTIVE_TRADES_LINK": "АКТИВНІ ЛОТИ",
    "SEARCH_LINK": "ПОШУК",
    "LEADS_LINK": "ЛІДИ",
    "LEADS_ASSIGNED_LINK": "Призначені",
    "LEADS_UNASSIGNED_LINK": "Непризначені",
    "SIGN_OUT_BTN": "Вихід",
    "DEPOSITS_LINK": "ДЕПОЗИТИ",
    "WON_LINK": "ВИГРАНІ",
    "ARCHIVE_LINK": "АРХІВ",
    "EDIT_PROFILE_LINK": "РЕДАГУВАТИ ПРОФІЛЬ",
    "ANALYTICS": "АНАЛІТИКА",
    "PLATFORM_SALES_LINK": "ПРОДАЖІ",
    "ADD_A_CAR_LINK": "Додати авто",
    "MY_TRADES_LINK": "Мої угоди",
    "SERVICES_LINK": "ПОСЛУГИ",
    "CARFAX_LINK": "Carfax",
    "TRANSPORTATION_LINK": "Транспортування",
    "NOTIFICATIONS_LINK": "СПОВІЩЕННЯ",
    "USERS_LINK": "КОРИСТУВАЧІ",
    "COPART_EXTENSION_LINK": "РОЗШИРЕННЯ COPART",
    "IMPACT_EXTENSION_LINK": "РОЗШИРЕННЯ IMPACT",
    "EXTENSION_DEALERS_LINK": "Дилери",
    "EXTENSION_ACCOUNTS_LINK": "Акаунти",
    "EXTENSION_FEES_LINK": "Комісії",
    "EXTENSION_WON_LINK": "Виграні",
    "EXTENSION_SEARCH_LINK": "Пошук",
    "EXTENSION_ANALYTICS_LINK": "Аналітика",
    "EXTENSION_CLAIMS_LINK": "Запити",
    "EXTENSION_NOTIFICATIONS_LINK": "Сповіщення"
  }
}
</i18n>
