<template>
  <ui-portal>
    <div
      ref="leadOverviewWrap"
      class="lead-overview"
      :class="'lead-overview_' + (isAnimated ? 'animate' : 'before-animate')"
      :style="{ '--lead-animation': 'appear-animation-' + animationDirection }"
      v-if="isOpen"
    >
      <button
        class="lead-overview__close-btn"
        @click="$emit('close')"
      >
        <ui-icon icon="close" />
      </button>

      <div class="lead-overview__pager lead-overview__pager_prev">
        <button
          class="lead-overview__pager-link"
          v-if="prevLead.id"
          @click="goPrevLead"
        >
          <ui-icon
            icon="arrow-left"
            class="lead-overview__pager-icon"
          />
          <span class="lead-overview__pager-name">
            {{ prevLead.fullName || $t('COMMON.UNNAMED_USER_PH') }}
          </span>
        </button>
      </div>

      <div class="lead-overview__pager lead-overview__pager_next">
        <button
          class="lead-overview__pager-link"
          v-if="nextLead.id"
          @click="goNextLead"
        >
          <span class="lead-overview__pager-name">
            {{ nextLead.fullName || $t('COMMON.UNNAMED_USER_PH') }}
          </span>
          <ui-icon
            icon="arrow-right"
            class="lead-overview__pager-icon"
          />
        </button>
      </div>

      <div class="lead-overview__content-wrap">
        <template v-if="currentLead.id">
          <lead-details
            @lead-dismiss="handleLeadDismiss"
            :lead="currentLead"
            :lead-tab="leadTab"
            :next-lead-id="nextLead.id"
            @update:leadTab="$emit('update:leadTab', $event)"
          />
        </template>

        <template v-else-if="isLoading">
          <lead-details-skeleton />
        </template>

        <template v-else-if="isNotFoundCurrentLead">
          <p class="lead-overview__not-found-err">
            {{ $t('LEAD_LOAD_FAILED_MSG') }}
          </p>
        </template>
      </div>
    </div>
  </ui-portal>
</template>

<script>
import { UiIcon, UiPortal, BodyScrollPreventer } from '@shelf.network/ui-kit'
import LeadDetails from './LeadDetails'
import LeadDetailsSkeleton from './LeadDetailsSkeleton'
import { LeadListEntry } from 'Models/LeadListEntry'
import { validateArrayOf } from 'Models/modelUtils'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { mutations, actions } from '../store/types'
import { wait } from 'Utils/wait'

export default {
  name: 'lead-overview',
  components: {
    UiIcon,
    UiPortal,
    LeadDetails,
    LeadDetailsSkeleton,
  },

  props: {
    leadsList: {
      type: Array,
      validator: validateArrayOf(LeadListEntry),
      default: () => ([]),
    },

    isLeadsLoading: {
      type: Boolean,
      default: false,
    },

    leadId: {
      type: String,
      default: '',
    },

    leadTab: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      isLoadingCurrentLead: false,
      isNotFoundCurrentLead: false,
      isAnimated: true,
      animationDirection: 'up',
      storeUnsubscriber: () => {
      },
    }
  },

  computed: {
    ...mapGetters('ui/leads', {
      leadById: 'leadById',
    }),

    currentLead () {
      return this.leadById(this.leadId)
    },

    isOpen () {
      return Boolean(this.leadId)
    },

    isLoading () {
      return this.isLeadsLoading || this.isLoadingCurrentLead
    },

    prevLead () {
      if (!this.currentLead.id) return {}
      const index = this.leadsList.findIndex(l => l.id === this.currentLead.id)
      if (index < 0) return {}
      return this.leadsList[index - 1] || {}
    },

    nextLead () {
      if (!this.currentLead.id) return {}
      const index = this.leadsList.findIndex(l => l.id === this.currentLead.id)
      if (index < 0) return {}
      return this.leadsList[index + 1] || {}
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler (newV, oldV) {
        if (newV && !oldV) this.animationDirection = 'up'
      }
    }
  },
  created () {
    this.$watch(
      () => ([this.leadId, this.leadsList, this.isLeadsLoading]),
      async () => {
        if (this.leadId && !this.isLoading && !this.currentLead.id) {
          await this.loadCurrentLead()
        }
      },
      { immediate: true }
    )

    this.$watch(
      'isOpen',
      (newV, oldV) => newV
        ? BodyScrollPreventer.on()
        : oldV && BodyScrollPreventer.off(),
      { immediate: true },
    )

    this.storeUnsubscriber = this.$store.subscribe(async (mutation, state) => {
      if (mutation.type === `ui/leads/${mutations.UPDATE_LEAD}`) {
        const { id } = mutation.payload
        if (!this.leadId || this.leadId !== id || !this.leadsList.length) return

        const nextLeadId = this.nextLead.id
        const prevLeadId = this.prevLead.id
        const isWasPresent = this.leadsList.find(el => el.id === this.leadId)
        await this.$nextTick()
        const isNowPresent = this.leadsList.find(el => el.id === this.leadId)

        if (isWasPresent && !isNowPresent) {
          const newLeadId = nextLeadId || prevLeadId
          if (newLeadId) {
            if (nextLeadId) {
              this.animate('forward')
            } else if (prevLeadId) {
              this.animate('backward')
            }
            this.$emit('update:leadId', newLeadId)
          } else {
            this.$emit('close')
          }
        }
      }
    })
  },

  beforeDestroy () {
    if (this.isOpen) BodyScrollPreventer.off()
    this.storeUnsubscriber()
  },

  methods: {
    ...mapActions('ui/leads', {
      loadLeadAction: actions.LOAD_LEAD,
    }),

    ...mapMutations('ui/leads', {
      deleteLead: mutations.DELETE_LEAD,
    }),

    async animate (direction) {
      this.isAnimated = false

      // wait for vdom -> dom
      await this.$nextTick()

      // and then wait a bit more (hack)
      await wait(40)

      this.isAnimated = true
      this.animationDirection = direction
    },
    goNextLead () {
      this.animate('forward')
      this.$emit('update:leadId', this.nextLead.id)
    },
    goPrevLead () {
      this.animate('backward')
      this.$emit('update:leadId', this.prevLead.id)
    },
    handleLeadDismiss () {
      if (!this.nextLead.id && !this.prevLead.id) {
        return this.$emit('close')
      }
    },

    async loadCurrentLead () {
      this.isLoadingCurrentLead = true
      await this.loadLead(this.leadId)
      if (!this.currentLead.id) {
        this.isNotFoundCurrentLead = true
      }
      this.isLoadingCurrentLead = false
    },

    async loadLead (leadId) {
      try {
        await this.loadLeadAction({ leadId })
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
@keyframes appear-animation-backward {
  from {
    opacity: 0;
    transform: translateX(-3em);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes appear-animation-forward {
  from {
    opacity: 0;
    transform: translateX(3em);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes appear-animation-up {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>

<style scoped lang="scss">

.lead-overview {
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include scrollbar-invisible();

  &_before-animate /deep/ {
    .lead-details__head,
    .lead-details__subnav-container,
    .lead-details__tab-component,
    .lead-details__comment-comp,
    .lead-details__footer-row {
      opacity: 0;
    }
  }

  &_animate /deep/ {
    .lead-details__head,
    .lead-details__subnav-container,
    .lead-details__tab-component,
    .lead-details__comment-comp,
    .lead-details__footer-row {
      animation-name: var(--lead-animation, appear-animation-up);
      animation-duration: 0.6s;
      animation-iteration-count: 1;
    }
  }

  &__content-wrap {
    min-height: 30vh;
    width: 60%;
    max-width: 70em;
    background: #fff;
    margin: 7em auto;
    border-radius: $layout-border-radius;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__pager {
    width: 15%;
    height: 10%;
    position: fixed;
    top: 45%;
    min-height: 5em;

    &-link {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      height: 100%;
      width: 100%;
      color: $color-dark-grey;
      text-decoration: none;
      background: none;
      border: none;

      &:hover {
        color: $color-light;
      }
    }

    &-name {
      font-size: 1.2em;
      display: block;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-link:hover &-name {
      text-decoration: underline;
    }

    &-icon {
      font-size: 2em;
      padding: 0 1em;
      display: block;
    }

    &_next {
      text-align: right;
      right: 1em;
    }

    &_prev {
      text-align: left;
      left: 1em;
    }
  }

  &__close-btn {
    position: fixed;
    color: $color-dark-grey;
    top: 1em;
    bottom: 1em;
    left: 1em;
    box-sizing: border-box;
    font-size: 3em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: calc(100vw - 2em);
    background: none;
    border: none;

    &:hover {
      color: $color-light;
    }
  }

  &__not-found-err {
    color: $color-flag-is-error;
    text-align: center;
    padding: 1em;
    height: 100%;
    margin: auto;
    font-weight: 700;
  }

  @include respond-below(sm) {
    padding: 0;
    border-radius: 0;
    display: block;

    &__pager {
      display: none;
    }

    &__content-wrap {
      width: 100%;
      min-height: 100vh;
      border-radius: 0;
      margin: 0;
    }

    &__close-btn {
      font-size: 2em;
      width: calc(100vw - 1em);
      position: absolute;
      bottom: auto;
      left: auto;
      z-index: z-index(1);
    }
  }
}
</style>

<i18n>
{
  "en": {
    "LEAD_LOAD_FAILED_MSG": "Could not load the provided lead. Please ensure you are using the valid link or contact the system owner."
  },
  "ka": {
    "LEAD_LOAD_FAILED_MSG": "ლიდის გახსნა ვერ მოხერხდა. შეამოწმეთ ლინკი ან დაუკავშირდით ადმინისტრატორს."
  },
  "ru": {
    "LEAD_LOAD_FAILED_MSG": "Не удалось отобразить лида. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "LEAD_LOAD_FAILED_MSG": "Не вдалось показати ліда. Please ensure you are using the valid link or contact the system owner."
  }
}
</i18n>
