<template>
  <div class="lead-lot-similar">
    <div class="lead-lot-similar__list">
      <div
        class="lead-lot-similar__lot"
        v-for="(lot, index) in lotsList.slice(0, 3)"
        :key="index"
      >
        <img
          class="lead-lot-similar__lot-img"
          :src="lot.photoUrl"
          :alt="`${$et('MAKER', lot.maker)} ${$et('MODEL', lot.model)}`"
        >

        <div class="lead-lot-similar__lot-price-wrp">
          <p class="lead-lot-similar__lot-price">
            <span class="lead-lot-similar__lot-price-prefix">
              {{ $t('PRICE_PREFIX') }}
            </span>
            <span class="lead-lot-similar__lot-price-value">
              &nbsp;{{ $cfc(lot.soldPrice, currency, { to: toCurrency }) }}
            </span>
          </p>
        </div>
      </div>

      <router-link
        class="lead-lot-similar__more-btn"
        :to="{ name: 'search', query: { q: moreSearchQuery } }"
      >
        <label class="lead-lot-similar__more-btn-lbl">
          {{ $tc('MORE_BTN', lotsCount, { count: lotsCount }) }}
        </label>

        <ui-icon
          class="lead-lot-similar__more-btn-ico"
          icon="arrow-right"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { LotSimilar } from 'Models/LotSimilar'
import { validateArrayOf } from 'Models/modelUtils'

export default {
  name: 'lead-lot-similar',

  components: {
    UiIcon,
  },

  props: {
    moreSearchQuery: {
      type: String,
      default: '',
    },

    lotsCount: {
      type: Number,
      default: 0,
    },

    lotsList: {
      type: Array,
      validator: validateArrayOf(LotSimilar),
      default: () => ([])
    },

    currency: {
      type: String,
      default: '',
    },

    toCurrency: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.lead-lot-similar {
  overflow-x: auto;
  overflow-y: hidden;

  @include scrollbar-invisible();

  &__list {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2.85em;
    padding-bottom: 4em;
    margin-bottom: -4em;

    @include respond-below(sm) {
      min-width: 720px;
    }
  }

  &__lot {
    border-radius: 1em;
    position: relative;
    overflow: hidden;

    &-img {
      display: block;
      width: 100%;
      max-height: 8.5em;
      object-fit: cover;
    }

    &-price {
      color: white;
      height: 100%;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      &-value {
        color: $color-sys-emphasis;
      }

      &-wrp {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2.75em;
        padding-top: 0.75em;
        background:
          linear-gradient(
            0deg,
            rgba(13, 13, 14, 0.4) 0%,
            rgba(13, 13, 14, 0) 100%
          ),
          linear-gradient(
            0deg,
            rgba(130, 130, 130, 1) 0%,
            rgba(13, 13, 14, 0) 100%
          );
      }
    }
  }

  &__more-btn {
    text-decoration: none;
    border: 1px solid $color-grey;
    background: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.75em 1.75em 1.4em;

    &-lbl {
      color: $color-dark;
      line-height: 1.8;
      pointer-events: none;
    }

    &-ico {
      width: 2.15em;
      height: 2.15em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $color-light-grey;
      color: $color-dark-grey;
      pointer-events: none;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "PRICE_PREFIX": "Sold price:",
    "MORE_BTN": "{count} similar car | {count} similar cars"
  },
  "ka": {
    "PRICE_PREFIX": "გაყიდული ფასი",
    "MORE_BTN": "{count} მსგავსი ავტომობილი | {count} მსგავსი ავტომობილი"
  },
  "ru": {
    "PRICE_PREFIX": "Продано по цене:",
    "MORE_BTN": "- | {count} похожее авто | {count} похожих авто"
  },
  "uk": {
    "PRICE_PREFIX": "Продано за ціною:",
    "MORE_BTN": "- | {count} схоже авто | {count} схожих авто"
  }
}
</i18n>
