<template>
  <div class="active-trades-skeleton">
    <page-subnav class="active-trades-skeleton__subnav" />

    <div class="active-trades-skeleton__list">
      <active-trade-item-skeleton />
      <active-trade-item-skeleton />
      <active-trade-item-skeleton />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import ActiveTradeItemSkeleton from './ActiveTradeItemSkeleton'

export default {
  name: 'active-trades-skeleton',
  components: {
    PageSubnav,
    ActiveTradeItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
.active-trades-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 2em;
  }
}
</style>
