import { USER_CLAIMS } from 'Constants/userClaims'
import { can } from 'Utils/userHelpers'
import { bellMutations } from './types'

export default {
  [bellMutations.SET_URGENT_NOTIFICATIONS]: (state, value) => {
    if (!can(USER_CLAIMS.NOTIFICATIONS)) return
    state.urgentNotifications = value
  },

  [bellMutations.SET_INFO_NOTIFICATIONS]: (state, value) => {
    if (!can(USER_CLAIMS.NOTIFICATIONS)) return
    state.infoNotifications = value
  },

  [bellMutations.SET_EXTENSION_NOTIFICATIONS]: (state, value) => {
    if (!can(USER_CLAIMS.EXTENSION_NOTIFICATIONS)) return
    state.extensionNotifications = value
  },

  [bellMutations.UNSHIFT_URGENT_NOTIFICATIONS]: (state, value) => {
    state.urgentNotifications.splice(0, 0, value)
  },

  [bellMutations.UNSHIFT_INFO_NOTIFICATIONS]: (state, value) => {
    state.infoNotifications.splice(0, 0, value)
  },

  [bellMutations.UNSHIFT_EXTENSION_NOTIFICATIONS]: (state, value) => {
    state.extensionNotifications.splice(0, 0, value)
  },

  [bellMutations.REMOVE_URGENT_NOTIFICATION]: (state, value) => {
    const index = state.urgentNotifications.findIndex(item => item.id === value)
    if (index === -1) return

    state.urgentNotifications.splice(index, 1)
  },

  [bellMutations.REMOVE_INFO_NOTIFICATION]: (state, value) => {
    const index = state.infoNotifications.findIndex(item => item.id === value)
    if (index === -1) return

    state.infoNotifications.splice(index, 1)
  },

  [bellMutations.REMOVE_EXTESION_NOTIFICATION]: (state, value) => {
    const index = state.extensionNotifications
      .findIndex(item => item.id === value)
    if (index === -1) return

    state.extensionNotifications.splice(index, 1)
  },

  [bellMutations.SET_URGENT_UNREAD_COUNT]: (state, value) => {
    if (!can(USER_CLAIMS.NOTIFICATIONS)) return
    state.urgentUnreadCount = value
  },

  [bellMutations.SET_INFO_UNREAD_COUNT]: (state, value) => {
    if (!can(USER_CLAIMS.NOTIFICATIONS)) return
    state.infoUnreadCount = value
  },

  [bellMutations.SET_EXTENSION_UNREAD_COUNT]: (state, value) => {
    if (!can(USER_CLAIMS.EXTENSION_NOTIFICATIONS)) return
    state.extensionUnreadCount = value
  },
}
