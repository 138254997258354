<template>
  <!-- eslint-disable max-len vue/multiline-html-element-content-newline -->

  <div class="lead-item-skeleton">
    <div class="lead-item-skeleton__lots">
      <div class="lead-item-skeleton__lot" />
      <div class="lead-item-skeleton__lot" />
      <div class="lead-item-skeleton__lot" />
    </div>

    <div class="lead-item-skeleton__link">
      <div class="lead-item-skeleton__user-type">
        <span
          class="lead-item-skeleton__line"
          style="width: 8em;"
        >&nbsp;</span>
      </div>

      <h4 class="lead-item-skeleton__user-name">
        <span
          class="lead-item-skeleton__line"
          style="width: 8em;"
        >&nbsp;</span>
      </h4>

      <div class="lead-item-skeleton__time-ago">
        <span
          class="lead-item-skeleton__line"
          style="width: 8em;"
        >&nbsp;</span>
      </div>

      <div class="lead-item-skeleton__phone">
        <span
          class="lead-item-skeleton__line"
          style="width: 8em;"
        >&nbsp;</span>
      </div>
    </div>

    <div class="lead-item-skeleton__btn" />
  </div>
</template>

<script>
export default {
  name: 'lead-item-skeleton',
}
</script>

<style scoped lang="scss">
@import "@/styles/skeletons.scss";

.lead-item-skeleton {
  background: $color-light;
  border: 1px solid $color-grey;
  box-sizing: border-box;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  padding: 1.75em;
  transition: all linear 180ms;

  &__lots {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid $color-grey;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
  }

  &__lot {
    @extend %skeleton-bg;

    display: inline-block;
    vertical-align: middle;
    border-radius: 0.5em;
    height: 100%;

    --ratio: calc(1 / 1);

    @include aspect-ratio();
  }

  &__line {
    @extend %skeleton-bg;

    border-radius: 50px;
    display: inline-block;
  }

  &__btn {
    @extend %skeleton-bg;

    border-radius: 50px;
    display: inline-block;
    width: 100%;
    height: calc(2.25em);
    margin-top: 0.4em;
  }

  &__user-type {
    font-size: 0.8em;
    font-weight: bold;
  }

  &__user-name {
    @extend %h4;

    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__time-ago {
    @extend %p;
  }

  &__phone {
    margin: 1.5em 0 1em 0;
    display: flex;
    align-items: center;
    align-content: space-between;
    font-size: 1.2em;
  }
}
</style>
<i18n>
{
  "en": {
    "GUEST_LEAD_TYPE": "GUEST",
    "ONBOARD_LEAD_TYPE": "VERIFIED"
  },
  "ka": {
    "GUEST_LEAD_TYPE": "ᲣᲪᲜᲝᲑᲘ",
    "ONBOARD_LEAD_TYPE": "ᲕᲔᲠᲘᲤᲘᲪᲘᲠᲔᲑᲣᲚᲘ"
  },
  "ru": {
    "GUEST_LEAD_TYPE": "ГОСТЬ",
    "ONBOARD_LEAD_TYPE": "ПОЛЬЗОВАТЕЛЬ"
  },
  "uk": {
    "GUEST_LEAD_TYPE": "ГІСТЬ",
    "ONBOARD_LEAD_TYPE": "ПІДТВЕРДЖЕНИЙ"
  }
}
</i18n>
