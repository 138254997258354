import { mutations } from './types'
import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'
import isObject from 'lodash/isObject'
import Vue from 'vue'
import get from 'lodash/get'
import { genLeadsState } from './state'
import { SupportRequest } from 'Models/SupportRequest'

export default {
  [mutations.SET_IS_LOADING]: (state, value) => { state.isLoading = value },
  [mutations.SET_LEADS_LIST]: (state, leads) => {
    Vue.set(state, 'leadsList', leads)
  },

  [mutations.SET_LEAD]: (state, lead) => {
    Vue.set(state.leadsById, lead.id, { ...lead })
  },

  [mutations.DELETE_LEAD]: (state, lead) => {
    Vue.delete(state.leadsById, lead.id)
  },
  [mutations.ADD_LEAD_LOT]: (state, { leadId, lot }) => {
    if (state.leadsById[leadId]) {
      const lots = get(state.leadsById[leadId], 'relationships.lots', [])
      if (lots.findIndex(l => l.id === lot.id) < 0) {
        state.leadsById[leadId].relationships.lots.push(lot)
      }
    }

    const listEntry = state.leadsList.find(lead => lead.id === leadId)
    if (listEntry && lot.images[0]) {
      listEntry.lots.push(lot.images[0])
    }
  },
  [mutations.UPDATE_LEAD]: (state, payload) => {
    const lead = state.leadsById[payload.id] || {}
    const leadListEntry = state.leadsList
      .find(entry => entry.id === payload.id) || {}

    lead.relationships = lead.relationships || {}
    leadListEntry.relationships = leadListEntry.relationships || {}

    if (payload.iteration) {
      lead.iteration = leadListEntry.iteration = payload.iteration
    }

    if (payload.state) {
      lead.state = leadListEntry.state = payload.state
    }

    if (payload.channel) {
      lead.channel = leadListEntry.channel = payload.channel
    }

    if (isNumber(payload.priority)) {
      lead.priority = leadListEntry.priority = payload.priority
    }

    if (isBoolean(payload.financing)) {
      lead.financing = leadListEntry.financing = payload.financing
    }

    if (isBoolean(payload.interestedInPro)) {
      lead.interestedInPro =
        leadListEntry.interestedInPro = payload.interestedInPro
    }

    if (payload.dueDate) {
      lead.dueDate = leadListEntry.dueDate = payload.dueDate
    }

    if (payload.broker) {
      const isSameBroker = isObject(payload.broker) &&
        isObject(lead.relationships.broker) &&
        payload.broker.id === lead.relationships.broker.id
      if (!isSameBroker) {
        lead.relationships.broker = payload.broker
        leadListEntry.relationships.broker = payload.broker
        lead.isStrictlyAssigned = false
      }
    }

    if (payload.requests && lead.relationships.requests) {
      payload.requests.forEach(plReq => {
        const req = lead.relationships.requests.find(r => r.id === plReq.id)
        if (!req) return
        Object.assign(req, { state: plReq.state })
      })

      leadListEntry.unresolvedSupportRequests =
        lead.relationships.requests.filter(
          sr => sr.state !== SupportRequest.statesEnum.resolved
        ).length
    }

    if (
      Array.isArray(payload.detachedLotsIds) && payload.detachedLotsIds.length
    ) {
      lead.relationships.lots = (lead.relationships.lots || [])
        .filter(el => !payload.detachedLotsIds.includes(el.id))

      leadListEntry.lots =
        lead.relationships.lots.map(lot => get(lot, 'images[0]', ''))
    }

    if (Array.isArray(payload.attachedLots) && payload.attachedLots.length) {
      lead.relationships.lots = lead.relationships.lots || []
      lead.relationships.lots.push(...payload.attachedLots)
    }

    if (lead.id) Vue.set(state.leadsById, payload.id, { ...lead })

    if (leadListEntry.id) {
      const foundIndex =
        state.leadsList.findIndex(entry => entry.id === leadListEntry.id)
      if (foundIndex > -1) {
        state.leadsList.splice(foundIndex, 1)
        state.leadsList.push(leadListEntry)
      }
    }
  },

  [mutations.ADD_LEAD_ATTACHMENT]:
    (state, payload) => {
      const lead = state.leadsById[payload.leadId]
      if (lead) {
        lead.relationships.attachments.push(payload.attachment)
      }
    },
  [mutations.REMOVE_LEAD_ATTACHMENT]:
    (state, payload) => {
      const lead = state.leadsById[payload.leadId]
      if (lead) {
        const attachments = get(lead, 'relationships.attachments', [])
        const attachmentIndex = attachments
          .findIndex(att => att.id === payload.attachmentId)
        if (attachmentIndex >= 0) {
          attachments.splice(attachmentIndex, 1)
        }
      }
    },
  [mutations.BULK_SELECT]:
    (state, ids = []) => {
      if (!Array.isArray(ids)) ids = [ids]
      state.bulkSelection = state.bulkSelection.concat(ids)
    },
  [mutations.BULK_UNSELECT]:
    (state, ids = []) => {
      if (!Array.isArray(ids)) ids = [ids]
      state.bulkSelection = state.bulkSelection.filter(el => !ids.includes(el))
    },
  [mutations.BULK_UNSELECT_ALL]:
    (state) => {
      state.bulkSelection = []
    },
  [mutations.ADD_ATTACHMENT_TO_QUEUE]:
    (state, file) => {
      state.attachmentsQueue.push(file)
    },
  [mutations.REMOVE_ATTACHMENT_FROM_QUEUE]:
    (state, fileId) => {
      const index = state.attachmentsQueue.findIndex(f => f.id === fileId)
      if (index >= 0) {
        state.attachmentsQueue.splice(index, 1)
      }
    },
  [mutations.SET_IS_QUEUE_UPLOADING]:
    (state, value) => {
      state.isAttachmentsUploading = value
    },
  [mutations.SET_QUEUE_ITEM_IS_PROCESSING]: (state, itemId) => {
    state.attachmentsQueue.forEach((f) => { f.isProcessing = f.id === itemId })
  },
  [mutations.PUSH_COMMENTS]:
    (state, { leadId, comments }) => {
      if (!state.commentsByLeadId[leadId]) {
        Vue.set(state.commentsByLeadId, leadId, {
          items: [],
          isLoading: false,
          isSaving: false,
          hasError: false,
          fetchNext: null,
        })
      }

      if (!comments.length) return

      const items = state.commentsByLeadId[leadId].items
      comments.forEach(c => {
        const indexFound = items.findIndex(cc => cc.id === c.id)
        if (indexFound > -1) items.splice(indexFound, 1)
        items.push(c)
      })

      items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    },
  [mutations.DELETE_COMMENT]:
    (state, { leadId, commentId }) => {
      const items = state.commentsByLeadId[leadId]
        ? state.commentsByLeadId[leadId].items
        : null

      if (!items || !items.length) return
      const indexFound = items.findIndex(c => c.id === commentId)
      if (indexFound > -1) {
        items.splice(indexFound, 1)
        state.commentsByLeadId[leadId].totalItems--
      }
    },
  [mutations.UPDATE_COMMENT_DATA]:
    (state, { leadId, commentId, data }) => {
      const comments = state.commentsByLeadId[leadId]
        ? state.commentsByLeadId[leadId].items
        : null

      if (!comments || !comments.length) return
      const indexFound = comments.findIndex(c => c.id === commentId)
      if (indexFound > -1) {
        comments[indexFound].data = data
        comments[indexFound].updatedAt = new Date().toISOString()
      }
    },
  [mutations.SET_IS_COMMENTS_LOADING]:
    (state, { leadId, isLoading }) => {
      if (!state.commentsByLeadId[leadId]) return
      state.commentsByLeadId[leadId].isLoading = isLoading
    },
  [mutations.SET_COMMENTS_LOADING_ERROR]:
    (state, { leadId, hasError }) => {
      if (!state.commentsByLeadId[leadId]) return
      state.commentsByLeadId[leadId].hasError = hasError
    },
  [mutations.SET_COMMENTS_FETCH_NEXT] (
    state, { leadId, fetchNext, totalItems }
  ) {
    if (!state.commentsByLeadId[leadId]) return
    state.commentsByLeadId[leadId].fetchNext = fetchNext
    state.commentsByLeadId[leadId].totalItems = totalItems
  },
  [mutations.RESET]:
    (state) => {
      Object.assign(state, genLeadsState())
    },
}
