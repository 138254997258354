<template>
  <div class="header">
    <quick-search
      v-if="!hideSearch"
      class="header__quick-search"
    />

    <template v-if="isLoggedIn">
      <user-block class="header__user-block" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserBlock from './components/UserBlock'
import QuickSearch from './components/QuickSearch'
import { userGetters } from 'Store/entities/User/types'

export default {
  name: 'header-component',
  components: {
    UserBlock,
    QuickSearch,
  },

  props: {
    hideSearch: { type: Boolean, default: false }
  },

  computed: {
    ...mapGetters('entities/user', {
      isLoggedIn: userGetters.IS_LOGGED_IN,
    })
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 6em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;

  @include respond-below(sm) {
    padding: 0 1em;
    height: 70px;
  }

  &__user-block {
    margin-left: auto;
    margin-right: 0;
  }
}
</style>
