import format from 'date-fns/format'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import isValid from 'date-fns/isValid'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import isTomorrow from 'date-fns/isTomorrow'
import isThisYear from 'date-fns/isThisYear'
import isSameDay from 'date-fns/isSameDay'
import isSameWeek from 'date-fns/isSameWeek'
import isSameMonth from 'date-fns/isSameMonth'
import isSameYear from 'date-fns/isSameYear'
import endOfDay from 'date-fns/endOfDay'
import { i18n } from 'I18n/instance'

export {
  isBefore,
  isAfter,
  isSameDay,
  isSameWeek,
  isSameMonth,
  isSameYear,
  endOfDay,
}

export function formatDate (date, fmt) {
  if (!isValid(date)) return date

  const locale = getLocale(i18n.locale)
  return format(date, fmt, { locale })
}

export function formatDateRelative (date) {
  if (!isValid(date)) return date

  let res
  const fmt = getLocalizedFormatter(date, i18n.locale)
  switch (true) {
    case isToday(date):
      res = fmt(i18n.t('DATE_FORMATS.TODAY')); break
    case isYesterday(date):
      res = fmt(i18n.t('DATE_FORMATS.YESTERDAY')); break
    case isTomorrow(date):
      res = fmt(i18n.t('DATE_FORMATS.TOMORROW')); break
    case isThisYear(date):
      res = fmt(i18n.t('DATE_FORMATS.MONTH_DAY')); break
    default:
      res = fmt(i18n.t('DATE_FORMATS.MONTH_DAY_YEAR')); break
  }
  return res
}

export function formatDateNumeralRelative (date) {
  if (!isValid(date)) return date

  let res
  const fmt = getLocalizedFormatter(date, i18n.locale)
  switch (true) {
    case isToday(date):
      res = fmt(i18n.t('DATE_FORMATS.TODAY')); break
    case isYesterday(date):
      res = fmt(i18n.t('DATE_FORMATS.YESTERDAY')); break
    case isTomorrow(date):
      res = fmt(i18n.t('DATE_FORMATS.TOMORROW')); break
    case isThisYear(date):
      res = fmt(i18n.t('DATE_FORMATS.MONTH_DAY_NUMERAL')); break
    default:
      res = fmt(i18n.t('DATE_FORMATS.MONTH_DAY_YEAR_NUMERAL')); break
  }
  return res
}

export function formatDateTimeRelative (date) {
  if (!isValid(date)) return date

  let res
  const fmt = getLocalizedFormatter(date, i18n.locale)
  switch (true) {
    case isToday(date):
      res = fmt(i18n.t('DATE_FORMATS.TODAY_HOURS_MINUTES')); break
    case isYesterday(date):
      res = fmt(i18n.t('DATE_FORMATS.YESTERDAY_HOURS_MINUTES')); break
    case isTomorrow(date):
      res = fmt(i18n.t('DATE_FORMATS.TOMORROW_HOURS_MINUTES')); break
    case isThisYear(date):
      res = fmt(i18n.t('DATE_FORMATS.MONTH_DAY_HOURS_MINUTES')); break
    default:
      res = fmt(i18n.t('DATE_FORMATS.MONTH_DAY_YEAR_HOURS_MINUTES')); break
  }
  return res
}

export function formatDateTimeNumeralRelative (date) {
  if (!isValid(date)) return date

  let res
  const fmt = getLocalizedFormatter(date, i18n.locale)
  switch (true) {
    case isToday(date):
      res = fmt(i18n.t('DATE_FORMATS.TODAY_HOURS_MINUTES')); break
    case isYesterday(date):
      res = fmt(i18n.t('DATE_FORMATS.YESTERDAY_HOURS_MINUTES')); break
    case isTomorrow(date):
      res = fmt(i18n.t('DATE_FORMATS.TOMORROW_HOURS_MINUTES')); break
    case isThisYear(date):
      res = fmt(i18n.t('DATE_FORMATS.MONTH_DAY_HOURS_MINUTES_NUMERAL')); break
    default:
      res = fmt(i18n.t('DATE_FORMATS.MONTH_DAY_YEAR_HOURS_MINUTES_NUMERAL')); break
  }
  return res
}

export function formatTime (date) {
  if (!isValid(date)) return date
  return format(date, i18n.t('DATE_FORMATS.HOURS_MINUTES'))
}

export function formatDistanceFromSeconds (seconds) {
  const now = Date.now()
  const options = { locale: getLocale(i18n.locale) }
  return formatDistanceStrict(now, now - seconds * 1000, options)
}

export function unixTimestamp (date) {
  return Number(((date || Date.now()) / 1000).toFixed())
}

const NULL_DATE = getNullDate()
export function getNullDate () {
  return new Date(0)
}

export function getISODate (date = new Date()) {
  return (new Date(date)).toISOString()
}

export function isNullDate (date = new Date()) {
  return date.getTime() === NULL_DATE.getTime()
}

function getLocale (i18nLocaleCode) {
  const locale = i18nLocaleCode === 'en' ? 'en-US' : i18nLocaleCode
  return require(`date-fns/locale/${locale}/index.js`)
}

function getLocalizedFormatter (date, locale) {
  return formatStr => {
    const options = { locale: getLocale(locale) }
    return format(date, formatStr, options)
  }
}

export function formatDateTimeCompact (date) {
  if (!isValid(date)) return date
  if (!isThisYear(date)) {
    return formatDate(date, i18n.t('DATE_FORMATS.MONTH_DAY_YEAR_HOURS_MINUTES_SHORT'))
  }

  return formatDate(date, i18n.t('DATE_FORMATS.MONTH_DAY_HOURS_MINUTES_SHORT'))
}

export function getDayStartDate (value) {
  const date = value !== undefined
    ? new Date(value)
    : new Date()

  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}
