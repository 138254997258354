import set from 'lodash/set'
import { actions, mutations } from './types'
import { sdk } from 'Services/shelfNetworkSdk'
import { SupportRequest } from 'Models/SupportRequest'
import { Lead } from 'Models/Lead'
import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'
import omitBy from 'lodash/omitBy'
import uuid from 'uuid/v4'
import { wait } from 'Utils/wait'
import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { userGetters } from 'Store/entities/User/types'
import { COMMENTS_PER_PAGE } from './constants'
import formatFullName from 'Utils/formatFullName'
import get from 'lodash/get'
import { brokersGetters } from 'Store/entities/Brokers/types'
import { rootGet } from 'Store/helpers/rootHelpers'
import { trackUserEvent, setUserProperties } from 'Services/analytics'

const DETACH_BROKER_MAGIC_VALUE = 'GAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAWHF'

const LEAD_END_STATES = [
  Lead.statesEnum.archived,
]

const loneSdkCall = new LoneSdkCall()

export default {
  async [actions.LOAD_LEADS_LIST] ({ commit, rootGetters }, payload) {
    commit(mutations.SET_IS_LOADING, true)

    const extendedPayload = extendLeadsPayload(payload, rootGetters)
    const { data } = await loneSdkCall.takeLatest(
      sdk.backOffice.v2.getLeadsBasics(extendedPayload)
    )

    commit(mutations.SET_LEADS_LIST, data)
    commit(mutations.SET_IS_LOADING, false)
  },

  async [actions.UPDATE_LEAD] ({ commit, getters }, payload) {
    const {
      forward: mutationPayload,
      backward: backwardMutationPayload,
    } = createUpdateLeadMutationPayload(payload)
    const {
      forward: backOfficePayload,
      backward: backwardBackOfficePayload
    } = createUpdateLeadBackOfficePayload(payload)

    commit(mutations.UPDATE_LEAD, mutationPayload)
    await sdk.backOffice.v2.updateLeads([backOfficePayload])
    trackLeadUpdate(
      getters.leadById(payload.id),
      payload
    )

    return async function undo () {
      commit(mutations.UPDATE_LEAD, backwardMutationPayload)
      await sdk.backOffice.v2.updateLeads([backwardBackOfficePayload])
    }
  },

  async [actions.UPDATE_LEADS] ({ commit, getters }, payload) {
    payload = payload || []
    const mutationPayloads = payload.map(createUpdateLeadMutationPayload)
    const backOfficePayloads = payload.map(createUpdateLeadBackOfficePayload)

    mutationPayloads
      .forEach(item => commit(mutations.UPDATE_LEAD, item.forward))
    await sdk.backOffice.v2
      .updateLeads(backOfficePayloads.map(item => item.forward))

    payload.forEach(pl => trackLeadUpdate(
      getters.leadById(pl.id),
      pl
    ))

    return async function undo () {
      mutationPayloads
        .forEach(item => commit(mutations.UPDATE_LEAD, item.backward))
      await sdk.backOffice.v2.updateLeads(
        backOfficePayloads.map(item => item.backward)
      )
    }
  },

  async [actions.LOAD_LEAD] ({ commit }, { leadId }) {
    const response = await sdk.backOffice.v2.getLead(leadId)
    commit(mutations.SET_LEAD, {
      ...response.data,
      relationships: response.relationships,
    })
  },

  async [actions.MARK_LEAD_REQUESTS_RESOLVED] ({ commit, getters }, leadId) {
    const requests = getters.leadById(leadId).supportRequests
      .filter(item => !item.isStatusResolved)
      .map(({ id }) => ({ id, state: SupportRequest.statesEnum.resolved }))

    commit(mutations.UPDATE_LEAD, { id: leadId, requests })
    await sdk.backOffice.v2.updateSupportRequests({ leadId, requests })
  },

  async [actions.BULK_PICK_BY] ({ commit, getters }, predicate) {
    const toSelect = getters.leadsList.filter(predicate).map(item => item.id)

    commit(mutations.BULK_UNSELECT_ALL)
    commit(mutations.BULK_SELECT, toSelect)
  },

  async [actions.ADD_ATTACHMENT] ({ commit, dispatch }, payload) {
    const isImage = payload.file.type.startsWith('image/')

    const queueFile = {
      id: uuid(),
      leadId: payload.leadId,
      name: payload.file.name,
      type: payload.file.type,
      dataUrl: '',
      file: payload.file,
      isProcessing: false,
      isImage,
    }
    if (isImage) {
      const reader = new FileReader()
      reader.onload = (f) => {
        queueFile.dataUrl = f.target.result
        commit(mutations.ADD_ATTACHMENT_TO_QUEUE, queueFile)
        dispatch(actions.ATTACHMENTS_UPLOAD)
      }
      reader.readAsDataURL(payload.file)
    } else {
      commit(mutations.ADD_ATTACHMENT_TO_QUEUE, queueFile)
      dispatch(actions.ATTACHMENTS_UPLOAD)
    }
  },

  async [actions.ATTACHMENTS_UPLOAD] ({ commit, getters }) {
    if (!getters.isAttachmentsUploading) {
      commit(mutations.SET_IS_QUEUE_UPLOADING, true)

      while (getters.attachmentsQueue.length > 0) {
        const currFile = getters.attachmentsQueue[0]

        commit(mutations.SET_QUEUE_ITEM_IS_PROCESSING, currFile.id)

        try {
          const newAttachment = await sdk.backOffice.v2.uploadLeadAttachment(
            currFile.leadId, currFile.file
          )
          commit(mutations.ADD_LEAD_ATTACHMENT, {
            leadId: currFile.leadId,
            attachment: {
              ...newAttachment.data,
              relationships: newAttachment.relationships
            }
          })
          commit(mutations.REMOVE_ATTACHMENT_FROM_QUEUE, currFile.id)
        } catch (err) {
          console.error(err, 'Retry in 5 sec...')
          await wait(5000)
        }
      }
      commit(mutations.SET_IS_QUEUE_UPLOADING, false)
    }
  },

  async [actions.REMOVE_ATTACHMENT] ({ commit }, payload) {
    try {
      commit(mutations.REMOVE_LEAD_ATTACHMENT, payload)
      await sdk.backOffice.v2.removeLeadAttachment(
        payload.leadId, payload.attachmentId
      )
    } catch (err) { console.error(err) }
  },

  async [actions.ATTACH_LEAD_LOT] (
    { commit, dispatch },
    { leadId, lotId },
  ) {
    const {
      // optimistic interface is not available here, fetch changes instead
      forward: backwardMutationPayload,
    } = createUpdateLeadMutationPayload({
      id: leadId,
      detachedLotsIds: [lotId],
    })

    const response = await sdk.backOffice.v2.attachLeadLot(leadId, lotId)

    commit(mutations.ADD_LEAD_LOT, { leadId, lot: response.data })

    return async function undo () {
      commit(mutations.UPDATE_LEAD, backwardMutationPayload)
      await dispatch(actions.DETACH_LEAD_LOT, { leadId, lotId })
    }
  },

  async [actions.DETACH_LEAD_LOT] (
    { commit, dispatch },
    { leadId, lotId }
  ) {
    const {
      forward: mutationPayload,
      backward: backwardMutationPayload,
    } = createUpdateLeadMutationPayload({
      id: leadId,
      detachedLotsIds: [lotId],
    })

    commit(mutations.UPDATE_LEAD, mutationPayload)
    await sdk.backOffice.v2.detachLeadLot(leadId, lotId)

    return async function undo () {
      commit(mutations.UPDATE_LEAD, backwardMutationPayload)
      await dispatch(actions.ATTACH_LEAD_LOT, { leadId, lotId })
    }
  },

  async [actions.LOAD_COMMENTS] ({ commit }, leadId) {
    commit(mutations.SET_IS_COMMENTS_LOADING, { leadId, isLoading: true })
    commit(mutations.SET_COMMENTS_LOADING_ERROR, { leadId, hasError: false })

    try {
      const response = await sdk.backOffice.v2.getLeadComments(
        leadId,
        {
          page: {
            order: 'desc',
            limit: COMMENTS_PER_PAGE,
          },
          include: ['author', 'identity.basics'],
        }
      )

      const { data, fetchNext, meta } = response

      commit(mutations.PUSH_COMMENTS, { leadId, comments: data })
      commit(mutations.SET_COMMENTS_FETCH_NEXT, {
        leadId,
        fetchNext: data.length >= COMMENTS_PER_PAGE ? fetchNext : null,
        totalItems: meta.totalCount,
      })
    } catch (err) {
      commit(mutations.SET_COMMENTS_LOADING_ERROR, { leadId, hasError: true })
      console.error(err)
    }

    commit(mutations.SET_IS_COMMENTS_LOADING, { leadId, isLoading: false })
  },

  async [actions.LOAD_MORE_COMMENTS] ({ commit, getters }, leadId) {
    const comments = getters.getCommentsByLeadId(leadId)

    if (!comments.fetchNext) return

    commit(mutations.SET_IS_COMMENTS_LOADING, { leadId, isLoading: true })
    commit(mutations.SET_COMMENTS_LOADING_ERROR, { leadId, hasError: false })

    try {
      const { data, fetchNext: newFetchNext, meta } = await comments.fetchNext()
      commit(mutations.PUSH_COMMENTS, { leadId, comments: data })
      commit(mutations.SET_COMMENTS_FETCH_NEXT, {
        leadId,
        fetchNext: data.length >= COMMENTS_PER_PAGE ? newFetchNext : null,
        totalItems: meta.totalCount,
      })
    } catch (err) {
      commit(mutations.SET_COMMENTS_LOADING_ERROR, { leadId, hasError: true })
      console.error(err)
    }

    commit(mutations.SET_IS_COMMENTS_LOADING, { leadId, isLoading: false })
  },

  async [actions.POST_COMMENT] ({ commit, rootGetters }, { leadId, data }) {
    try {
      const result = await sdk.backOffice.v2.createLeadComment(leadId, data)

      const newComment = result.data

      set(newComment, 'relationships.lead.id',
        leadId)

      set(newComment, 'relationships.author.id',
        rootGetters[`entities/user/${userGetters.PROFILE}`].id)

      set(newComment, 'relationships.author.relationships.basic.fullName',
        formatFullName(rootGetters[`entities/user/${userGetters.PROFILE}`]))

      set(newComment, 'relationships.author.relationships.basic.avatarLink',
        rootGetters[`entities/user/${userGetters.PROFILE}`].avatarLink)

      commit(mutations.PUSH_COMMENTS, { leadId, comments: [newComment] })
    } catch (err) {
      console.error(err)
    }
  },

  async [actions.UPDATE_COMMENT] ({ commit }, { leadId, commentId, data }) {
    try {
      await sdk.backOffice.v2.updateLeadComment(leadId, commentId, data)
      commit(mutations.UPDATE_COMMENT_DATA, { leadId, commentId, data })
    } catch (err) {
      console.error(err)
    }
  },

  async [actions.DELETE_COMMENT] (
    { commit, dispatch, getters },
    { leadId, commentId }
  ) {
    try {
      await sdk.backOffice.v2.deleteLeadComment(leadId, commentId)
      commit(mutations.DELETE_COMMENT, { leadId, commentId })

      if (!get(getters.getCommentsByLeadId(leadId), 'items.length', null)) {
        await dispatch(actions.LOAD_COMMENTS, leadId)
      }
    } catch (err) {
      console.error(err)
    }
  },

  async [actions.FOLLOW_LEAD] ({ rootGetters }, { leadId }) {
    const follower = rootGetters[`entities/user/${userGetters.ACCOUNT_ID}`]
    await sdk.backOffice.v2.followLead({ lead: leadId, follower })
  },

  async [actions.UNFOLLOW_LEAD] ({ rootGetters }, { leadId }) {
    const follower = rootGetters[`entities/user/${userGetters.ACCOUNT_ID}`]
    await sdk.backOffice.v2.unfollowLead({ lead: leadId, follower })
  },

  async [actions.RESET] ({ commit }) {
    commit(mutations.RESET)
  },
}

function getLeadData (id) {
  let lead = rootGet('ui/leads/leadListEntryById')(id)
  if (lead.id) return lead

  lead = rootGet('ui/leads/leadById')(id)
  return lead.id ? lead : null
}

function createUpdateLeadBackOfficePayload (obj = {}) {
  const curLead = getLeadData(obj.id)

  const forward = {
    id: obj.id,
    type: 'leads',
    attributes: {},
    relationships: {},
  }
  const backward = {
    id: obj.id,
    type: 'leads',
    attributes: {},
    relationships: {},
  }

  if (obj.state) {
    forward.attributes.state = obj.state
    backward.attributes.state = curLead.state
  }
  if (obj.channel) {
    forward.attributes.channel = obj.channel
    backward.attributes.channel = curLead.channel
  }
  if (isNumber(obj.priority)) {
    forward.attributes.priority = obj.priority
    backward.attributes.priority = curLead.priority
  }
  if (isBoolean(obj.financing)) {
    forward.attributes.financing = obj.financing
    backward.attributes.financing = curLead.financing
  }
  if (isBoolean(obj.interestedInPro)) {
    forward.attributes.interestedInPro = obj.interestedInPro
    backward.attributes.interestedInPro = curLead.interestedInPro
  }
  if (obj.dueDate) {
    forward.attributes.due_date = obj.dueDate
    backward.attributes.due_date = curLead.dueDate.toISOString()
  }
  if (obj.hasOwnProperty('brokerId')) {
    forward.relationships.broker = {
      data: {
        id: obj.brokerId || DETACH_BROKER_MAGIC_VALUE,
        type: 'identities',
      }
    }
    backward.relationships.broker = {
      data: {
        id: curLead.brokerId || DETACH_BROKER_MAGIC_VALUE,
        type: 'identities',
      }
    }
  }

  return { forward, backward }
}

function createUpdateLeadMutationPayload (obj = {}) {
  const forward = { id: obj.id }
  const backward = { id: obj.id }
  const curLead = rootGet('ui/leads/leadById')(obj.id)

  if (obj.state) {
    forward.state = obj.state
    backward.state = curLead.state
    if (LEAD_END_STATES.includes(curLead.state) &&
      !LEAD_END_STATES.includes(obj.state)
    ) {
      forward.iteration = curLead.iteration + 1
    }
  }
  if (obj.channel) {
    forward.channel = obj.channel
    backward.channel = curLead.channel
  }
  if (isNumber(obj.priority)) {
    forward.priority = obj.priority
    backward.priority = Number(curLead.priority)
  }
  if (isBoolean(obj.financing)) {
    forward.financing = obj.financing
    backward.financing = curLead.financing
  }
  if (isBoolean(obj.interestedInPro)) {
    forward.interestedInPro = obj.interestedInPro
    backward.interestedInPro = curLead.interestedInPro
  }
  if (obj.dueDate) {
    forward.dueDate = obj.dueDate
    backward.dueDate = curLead.dueDate.toISOString()
  }
  if (obj.hasOwnProperty('brokerId')) {
    const brokersRaw = rootGet(`entities/brokers/${brokersGetters.BROKERS_RAW}`) || []
    forward.broker = brokersRaw
      .find(item => item.id === obj.brokerId) || {}
    backward.broker = brokersRaw
      .find(item => item.id === curLead.brokerId) || {}
  }
  if (Array.isArray(obj.detachedLotsIds) && obj.detachedLotsIds.length) {
    const curLeadRaw = rootGet('ui/leads/leadsByIdRaw')[obj.id] || {}
    forward.detachedLotsIds = []
    backward.attachedLots = []
    for (const lotId of obj.detachedLotsIds) {
      const lot = ((curLeadRaw.relationships || {}).lots || [])
        .find(el => el.id === lotId)
      if (lot) {
        forward.detachedLotsIds.push(lot.id)
        backward.attachedLots.push(lot)
      } else {
        forward.detachedLotsIds.push(lotId)
      }
    }
  }

  return {
    forward,
    backward,
  }
}

function extendLeadsPayload (payload, rootGetters) {
  set(payload, 'filter.platform', rootGetters[`entities/user/${userGetters.PLATFORM_ID}`])
  if (rootGetters[`entities/user/${userGetters.IS_BROKER}`]) {
    set(payload, 'filter.broker', rootGetters[`entities/user/${userGetters.ACCOUNT_ID}`])
  }

  return omitBy(payload, val => !val)
}

function trackLeadUpdate (lead, updatePayload) {
  const wasAssigned = updatePayload.brokerId &&
    updatePayload.brokerId !== DETACH_BROKER_MAGIC_VALUE

  if (wasAssigned) {
    setUserProperties(lead.contactPhone, { qualified: true })
    trackUserEvent(lead.contactPhone, 'Assigned to a broker')
  }

  if (updatePayload.financing) {
    trackUserEvent(lead.contactPhone, 'Requested financing')
  }

  const requestedFinancing = updatePayload.financing || lead.financing
  if (requestedFinancing && (lead.isAssigned || wasAssigned)) {
    trackUserEvent(lead.contactPhone, 'Was qualified for financing')
    setUserProperties(lead.contactPhone, { qualifiedForFinancing: true })
  }

  if (updatePayload.state === Lead.statesEnum.onHold) {
    setUserProperties(lead.contactPhone, { onHold: true })
    trackUserEvent(lead.contactPhone, 'Was put on hold')
  }
}
