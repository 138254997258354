<template>
  <div class="impact-won-skeleton">
    <page-subnav class="impact-won-skeleton__subnav" />

    <div class="impact-won-skeleton__list">
      <won-list-item-skeleton />
      <won-list-item-skeleton />
      <won-list-item-skeleton />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import WonListItemSkeleton from './WonListItemSkeleton'

export default {
  name: 'impact-won-skeleton',
  components: {
    PageSubnav,
    WonListItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
@import "../styles/impact-won";

.impact-won-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 0 $list-padding-side;
  }
}
</style>
