export function genLeadsState () {
  return {
    isLoading: true,
    leadsById: {},
    leadsList: [],
    bulkSelection: [], // ids only
    attachmentsQueue: [],
    isAttachmentsUploading: false,
    commentsByLeadId: {},
  }
}

export default genLeadsState()
