const actions = {
  FETCH_PLATFORMS: 'FETCH_PLATFORMS',
}

const mutations = {
  SET_PLATFORMS: 'SET_PLATFORMS',
}

const getters = {
  PLATFORM: 'PLATFORM',
  PLATFORMS: 'PLATFORMS',
  PLATFORM_NAME: 'PLATFORM_NAME',
  PLATFORM_ICON: 'PLATFORM_ICON',
}

export {
  actions as platformsActions,
  mutations as platformsMutations,
  getters as platformsGetters,
}
