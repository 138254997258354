import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'
import store from 'Store/index'

import WonSkeleton from './components/WonSkeleton'

const moduleName = 'won'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: WonSkeleton,
  importComponent: () => import('./Won'),
  async createDependencies () {
    const { default: module } = await import('./store')
    store.registerModule(['ui', moduleName], module)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
