import phone from 'phone'

// Non-existing Georgian operator for autotests
export const GEO_PHONE_CODE = '+995'
const GEO_TEST_OPERATOR = '123'
const GEO_PHONE_LENGTH = 13

export function validatePhone (phoneStr = '') {
  return phone(phoneStr).length > 0 || validateAutotestPhone(phoneStr)
}

export function validateAutotestPhone (phoneStr = '') {
  return phoneStr.length === GEO_PHONE_LENGTH &&
    phoneStr.startsWith(`${GEO_PHONE_CODE}${GEO_TEST_OPERATOR}`)
}
