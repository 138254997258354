import { platformsGetters } from './types'
import get from 'lodash/get'

const DEFAULT_PLATFORM_NAME = 'Shelf'

export default {
  [platformsGetters.PLATFORM]: state => id => state.platforms[id],

  [platformsGetters.PLATFORMS]: state => state.platforms,

  [platformsGetters.PLATFORM_NAME]: (state, getters) => id => {
    const platform = getters[platformsGetters.PLATFORM](id)
    return get(platform, 'name', DEFAULT_PLATFORM_NAME)
  },

  [platformsGetters.PLATFORM_ICON]: (state, getters) => id => {
    const platform = getters[platformsGetters.PLATFORM](id)
    return get(platform, 'logos.icon', '')
  },
}
