var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-params-flags list-params-row"},[_c('label',{staticClass:"list-params-row__label"},[_vm._v("\n    "+_vm._s(_vm.label || _vm.$t('FLAGS_LBL'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"list-params-flags__ticks-wrp",class:{
      'list-params-flags__ticks-wrp_start': _vm.valueEntries.length !== 4
    }},_vm._l((_vm.valueEntries),function(ref){
    var key = ref[0];
    var ref_1 = ref[1];
    var tLbl = ref_1.label;
    var tVal = ref_1.value;
return _c('ui-check',{key:key,staticClass:"list-params-flags__tick",attrs:{"value":tVal},on:{"input":function($event){return _vm.$emit('input', { key: key, value: $event })}}},[_vm._v("\n      "+_vm._s(tLbl)+"\n    ")])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }