<template>
  <div class="lead-tab-lots">
    <lead-lots-carousel
      :lead="lead"
      @current-lot-changed="currentCarouselLot = $event"
    />
    <div class="lead-tab-lots__container">
      <template v-if="currentCarouselLot.id">
        <lead-lot-calculator
          class="lead-tab-lots__container-item"
          :lot="currentCarouselLot"
          @year-changed="calculatorConfigYear = $event"
        />

        <template v-if="isExternalResourcesAvailable">
          <lead-lot-external-links
            class="lead-tab-lots__container-item"
            :class="'lead-tab-lots__container-item_external-links'"
            :lot="currentCarouselLot"
          />

          <lead-lot-suggestions
            class="lead-tab-lots__container-item"
            :lot="currentCarouselLot"
            :config-year="calculatorConfigYear"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import LeadLotsCarousel from './LeadLotsCarousel'
import LeadLotSuggestions from './LeadLotSuggestions'
import LeadLotCalculator from './LeadLotCalculator'
import LeadLotExternalLinks from './LeadLotExternalLinks'
import { Lead } from 'Models/Lead'
import { Lot } from 'Models/Lot'

export default {
  name: 'lead-tab-lots',

  components: {
    LeadLotsCarousel,
    LeadLotSuggestions,
    LeadLotCalculator,
    LeadLotExternalLinks,
  },

  props: {
    lead: { type: Lead, required: true },
  },

  data () {
    return {
      currentCarouselLot: new Lot(),
      calculatorConfigYear: '',
    }
  },

  computed: {
    isExternalResourcesAvailable () {
      return [Lot.typesEnum.iaaiAuction, Lot.typesEnum.copartAuction]
        .includes(this.currentCarouselLot.type)
    }
  },
}
</script>

<style scoped lang="scss">
.lead-tab-lots {
  &__container {
    padding: 0 1em;

    &-item {
      margin-top: 4.3em;

      &_external-links {
        margin-top: 2.85em;
      }
    }

    @include respond-below(xs) {
      padding: 0;
    }
  }
}
</style>
