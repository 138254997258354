import get from 'lodash/get'
import { ModelBase } from './ModelBase'
import { formatLocation } from 'Utils/formatLocation'
import { enums } from '@shelf.network/js-sdk'

export class Lot extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.createdAt = this._date(raw.createdAt).getTime()
    this.endTime = this._date(raw.endTime).getTime()
    this.endedAt = this._date(raw.endedAt).getTime()
    this.startTime = this._date(raw.startTime).getTime()
    this.awaitingSince = this._date(raw.awaitingSince).getTime()
    this.typeI = this._num(raw.typeI)
    this.state = this._str(raw.state)
    this.city = this._str(raw.city)
    this.country = this._str(raw.country)
    this.location = formatLocation(this.country, this.city)
    this.name = this._str(raw.name)
    this.images = raw.images || []
    this.maker = this._str(raw.maker)
    this.model = this._str(raw.model)
    this.vin = this._str(raw.vin)
    this.manufactureYear = this._num(raw.manufactureYear)
    this.primaryDamage = this._str(raw.primaryDamage)
    this.wheelPosition = this._str(raw.wheelPosition)
    this.fuelType = this._str(raw.fuelType)
    this.isRegistered = Boolean(raw.registered)
    this.isOnApproval = Boolean(raw.onApproval)
    this.zip = this._str(raw.zip)
    this.currency = this._str(raw.currency)
    this.externalId = this._str(raw.externalId)
    this.engineVolumeCm = this._str(raw.engineVolumeCm)
    this.engineVolumeL = (this.engineVolumeCm / 1000).toFixed(1)
    this.organizerId = this._str(raw.organizerId)
    this.ownershipDocType = this._str(raw.ownershipDocType)
    this.highestBid = this._strFiat(raw.highestBid)
    this.buyNowPrice = this._strFiat(raw.buyNowPrice)
    this.customerTotalPrice = this._strFiat(raw.customerTotalPrice)
    this.startPrice = this._strFiat(raw.startPrice)
    this.bidStep = this._strFiat(raw.minBidStep)
    this.price = this._getPrice()
    this.isBidFinal = Boolean(raw.isBidFinal)
    this.isSubLot = Boolean(raw.isSubLot)
    this.region = String(raw.region)

    this.type = this._str(raw.type)
    this.isAuction = this.type !== Lot.typesEnum.sale

    this.platformId = get(raw, 'relationships.platform.id', '')
  }

  static get typesEnum () {
    return {
      sale: enums.lotTypes.sale,
      shelfAuction: enums.lotTypes.shelfAuction,
      copartAuction: enums.lotTypes.copartAuction,
      iaaiAuction: enums.lotTypes.iaaiAuction,
      bnp: enums.lotTypes.bnp,
    }
  }

  static get statesEnum () {
    return {
      pending: enums.lotStates.pending,
      sold: enums.lotStates.sold,
      finished: enums.lotStates.finished,
    }
  }

  _getPrice () {
    if (Number(this.customerTotalPrice)) {
      return this.customerTotalPrice
    } else if (Number(this.highestBid)) {
      return this.highestBid
    } else if (Number(this.buyNowPrice)) {
      return this.buyNowPrice
    } else if (Number(this.startPrice)) {
      return this.startPrice
    } else {
      return '0.00'
    }
  }
}
