import { Identity } from 'Models/Identity'
import { brokersGetters } from './types'

import avaPh32Path from 'Assets/icons/ava-ph-32.svg'
import { i18n } from 'I18n/instance'
import { compareAlphabetically } from 'Utils/arrayHelpers'

export default {
  [brokersGetters.BROKERS]: state =>
    state.brokers.map(item => new Identity(item)),
  [brokersGetters.BROKER_OPTIONS]: (_, getters) =>
    getters[brokersGetters.BROKERS]
      .map(broker => ({
        value: broker.id,
        label: broker.fullName || i18n.t('COMMON.UNNAMED_USER_PH'),
        icon: broker.avatarLink || avaPh32Path,
      }))
      .sort((a, b) => compareAlphabetically(a.label, b.label)),

  [brokersGetters.BROKERS_RAW]: state => state.brokers,

  [brokersGetters.BROKER]: state => cityId => state.brokersByCityId[cityId],

  [brokersGetters.NEED_TO_FETCH_BROKER]: (state, getters) =>
    cityId => {
      return getters[brokersGetters.BROKER](cityId) === undefined
    },
}
