import Vue from 'vue'
import { exRatesMutations } from './types'

export default {
  [exRatesMutations.SET_EXCHANGE_RATES]:
    (state, value) => { Vue.set(state, 'exchangeRates', value) },

  [exRatesMutations.SET_BASE_CURRENCY]:
    (state, value) => { state.baseCurrency = value },
}
