<template>
  <div class="leads-subnav-select">
    <ui-button
      class="leads-subnav-select__button"
      fill="none"
      look="secondary"
      @click.self="toggleDropdown"
    >
      <div
        class="leads-subnav-select__slot"
        :class="{
          'leads-subnav-select__slot_skip-clicks': isClicksSkipped,
        }"
      >
        <slot />
      </div>

      <ui-icon
        class="leads-subnav-select__dropdown-ico"
        icon="dropdown"
      />
    </ui-button>

    <ui-select
      class="leads-subnav-select__dropdown"
      ref="dropdown"
      :options="options"
      :value="value"
      :clickaway="isClickawayEnabled"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { UiButton, UiSelect, UiIcon } from '@shelf.network/ui-kit'
import { validateArrayOf } from 'Models/modelUtils'

export default {
  name: 'leads-subnav-select',

  components: {
    UiButton,
    UiSelect,
    UiIcon,
  },

  props: {
    options: {
      validator: validateArrayOf(Object),
      default: Array,
    },

    value: {
      type: String,
      default: '',
    },

    // Set to false to not skip clicks on slot’s transclusion
    isClicksSkipped: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      isClickawayEnabled: true,
    }
  },

  methods: {
    async toggleDropdown () {
      this.isClickawayEnabled = false
      this.$refs.dropdown.toggle()
      setTimeout(() => { this.isClickawayEnabled = true }, 100)
    },
  },
}
</script>

<style lang="scss" scoped>
.leads-subnav-select {
  &__button {
    & /deep/ .ui-button__button {
      padding: 0.75em;
      display: flex;
      align-items: center;
    }
  }

  &__slot {
    &_skip-clicks {
      pointer-events: none;
    }
  }

  &__dropdown {
    & /deep/ .ui-select__button {
      display: none;
    }

    & /deep/ .ui-select__dropdown {
      border-radius: 1em;
      top: 0;
      min-width: 14em;
    }

    & /deep/ .ui-items__list-item {
      border-radius: 1em;
      color: $color-dark;
    }
  }

  &__dropdown-ico {
    display: inline-block;
    margin-left: 0.25em;
    transform: translateY(0.1em);
    pointer-events: none;
  }
}
</style>
