<template>
  <div class="carfax-item-skeleton">
    <div class="carfax-item-skeleton__name">
      <span class="carfax-item-skeleton__icon carfax-item-skeleton__icon_name">
        &nbsp;
      </span>

      <span class="carfax-item-skeleton__line">
        &nbsp;
      </span>
    </div>

    <div class="carfax-item-skeleton__vin">
      <span class="carfax-item-skeleton__icon carfax-item-skeleton__icon_vin">
        &nbsp;
      </span>

      <span class="carfax-item-skeleton__line">
        &nbsp;
      </span>
    </div>

    <div class="carfax-item-skeleton__email">
      <span class="carfax-item-skeleton__icon">
        &nbsp;
      </span>

      <span class="carfax-item-skeleton__line">
        &nbsp;
      </span>
    </div>

    <div class="carfax-item-skeleton__phone">
      <div class="carfax-item-skeleton__icon">
        <span>&nbsp;</span>
      </div>

      <span class="carfax-item-skeleton__line">
        &nbsp;
      </span>
    </div>

    <div class="carfax-item-skeleton__time">
      <div class="carfax-item-skeleton__icon">
        <span>&nbsp;</span>
      </div>

      <span class="carfax-item-skeleton__line">
        &nbsp;
      </span>
    </div>

    <div class="carfax-item-skeleton__report">
      <div class="carfax-item-skeleton__icon">
        <span>&nbsp;</span>
      </div>

      <span class="carfax-item-skeleton__line">
        &nbsp;
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'carfax-item-skeleton'
}
</script>

<style lang="scss" scoped>
@import "@/styles/skeletons.scss";

.carfax-item-skeleton {
  position: relative;
  display: grid;
  padding: 1.8em 0;
  border-radius: 15px;
  align-items: center;
  grid-template-columns: 25% 15% 20% 15% 7.5% 7.5%;
  grid-gap: 2%;

  &:not(:last-child)::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    border-bottom: 1px solid $color-grey;
    transition: all 0ms;
  }

  &:not(:first-child) {
    margin-top: -1.5px; // cover bottom border of the previous element
  }

  @include respond-above(sm) {
    padding: 1.8em 1em;

    &::after {
      left: 1em;
      right: 1em;
    }
  }

  &__line,
  &__lbl,
  &__icon {
    @extend %skeleton-bg;

    border-radius: 3em;
    width: 8em;
  }

  &__icon {
    font-size: 1.2em;
    margin-right: 0.4em;
    width: 1.1em;

    &_name {
      width: 2.2em;
      padding: 0.5em;
      font-size: 1.4em;
      border-radius: 5px;
      margin-right: 0.8em;
    }

    &_vin {
      width: 2.4em;
      border-radius: 3px;
      font-size: 0.7em;
      margin-right: 0.9em;
      padding: 0.4em 0.4em 0.2em;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 1.1em;
    display: flex;
    align-items: center;

    .carfax-item-skeleton__line {
      width: 12em;
    }
  }

  &__vin,
  &__email,
  &__phone,
  &__time,
  &__report {
    display: flex;
    align-items: center;
  }

  &__time,
  &__report {
    .carfax-item-skeleton__line {
      width: 3em;
    }
  }
}
</style>
