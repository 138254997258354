import { i18n } from 'I18n/instance'

/**
 * Returns formatted and localized full name. Truncates nullable values
 * @param {object} opts If an object provided it should contain
 * @param {string} [opts.firstName]
 * @param {string} [opts.lastName]
 * @param {string} [opts.middleName]
 */
export default function formatFullName (opts = {}) {
  const { firstName, lastName, middleName } = opts

  if (!firstName) {
    return lastName
  }

  if (!lastName) {
    return firstName
  }

  if (lastName && firstName && middleName) {
    return i18n.t(
      'COMMON.FULL_NAME_FORMAT_V2',
      { firstName, lastName, middleName },
    )
  }

  return i18n.t('COMMON.FULL_NAME_FORMAT', { firstName, lastName })
}
