import { config } from '@/config'
import { getNullDate } from 'Utils/dateHelpers'

export class ModelBase {
  constructor (raw = {}, cache = {}) {
    if (config.ENABLE_DEVTOOLS) {
      this._raw = raw
    }

    this.id = String(raw.id || '')
    this._resourceType = String(raw.resourceType || '')

    if (raw.id && raw.resourceType) {
      const cacheKey = raw.id + '-' + raw.resourceType
      if (!cache[cacheKey]) {
        cache[cacheKey] = this
      }
    }
  }

  _str (value) {
    return String(value || '')
  }

  _arrOf (value, Constructor) {
    return Array.from(value || [], el => new Constructor(el))
  }

  _strFiat (value) {
    if (typeof value === 'number') return value.toFixed(2)
    return String(value || '0.00')
  }

  _num (value) {
    return Number(value || 0)
  }

  _date (value) {
    return value ? new Date(value) : getNullDate()
  }

  static modelFromCache (Model, raw = {}, cache) {
    if (raw.id && raw.resourceType) {
      const cacheKey = raw.id + '-' + raw.resourceType
      if (!cache[cacheKey]) {
        cache[cacheKey] = new Model(raw, cache)
      }
      return cache[cacheKey]
    }
    return new Model(raw, cache)
  }
}
