<template>
  <div class="carfax-list-head">
    <p class="carfax-list-head__item">
      {{ $t('VEHICLE_HEADER') }}
    </p>

    <p class="carfax-list-head__item">
      {{ $t('VIN_HEADER') }}
    </p>

    <p class="carfax-list-head__item">
      {{ $t('EMAIL_HEADER') }}
    </p>

    <p class="carfax-list-head__item">
      {{ $t('PHONE_NUMBER_HEADER') }}
    </p>

    <p class="carfax-list-head__item">
      {{ $t('TIME_HEADER') }}
    </p>

    <p class="carfax-list-head__item">
      {{ $t('REPORT_HEADER') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'carfax-list-head'
}
</script>

<style lang="scss" scoped>
.carfax-list-head {
  padding: 0 1em;
  display: grid;
  grid-template-columns: 25% 15% 20% 15% 7.5% 7.5%;
  grid-gap: 2%;

  @include respond-below(sm) {
    display: none;
  }

  &__item {
    color: $color-ui-secondary;
  }
}
</style>

<i18n>
{
  "en": {
    "VEHICLE_HEADER": "VEHICLE",
    "VIN_HEADER": "VIN",
    "EMAIL_HEADER": "EMAIL",
    "PHONE_NUMBER_HEADER": "PHONE NUMBER",
    "TIME_HEADER": "TIME",
    "REPORT_HEADER": "REPORT"
  },
  "ka": {
    "VEHICLE_HEADER": "ავტომობილი",
    "VIN_HEADER": "ვინკოდი",
    "EMAIL_HEADER": "ელ.ფოსტა",
    "PHONE_NUMBER_HEADER": "ტელეფონი",
    "TIME_HEADER": "დრო",
    "REPORT_HEADER": "რეპორტი"
  },
  "ru": {
    "VEHICLE_HEADER": "АВТО",
    "VIN_HEADER": "VIN",
    "EMAIL_HEADER": "ЭЛ. ПОЧТА",
    "PHONE_NUMBER_HEADER": "НОМЕР ТЕЛЕФОНА",
    "TIME_HEADER": "ВРЕМЯ",
    "REPORT_HEADER": "ОТЧЕТ"
  },
  "uk": {
    "VEHICLE_HEADER": "АВТО",
    "VIN_HEADER": "VIN",
    "EMAIL_HEADER": "ЕЛ. ПОШТА",
    "PHONE_NUMBER_HEADER": "НОМЕР ТЕЛЕФОНУ",
    "TIME_HEADER": "ЧАС",
    "REPORT_HEADER": "ЗВІТ"
  }
}
</i18n>
