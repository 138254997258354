import { LOCALES } from 'Constants/locales'
import { register as timeAgoRegister } from 'timeago.js'
import timeAgoKaLocale from 'timeago.js/lib/lang/ka.js'
import timeAgoRuLocale from 'timeago.js/lib/lang/ru.js'
import timeAgoUkLocale from 'timeago.js/lib/lang/uk.js'

export function initTimeAgoI18n () {
  timeAgoRegister(LOCALES.ru.isoCode, timeAgoRuLocale)
  timeAgoRegister(LOCALES.uk.isoCode, timeAgoUkLocale)
  timeAgoRegister(LOCALES.ka.isoCode, timeAgoKaLocale)
}
