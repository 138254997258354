<template>
  <div class="lead-audit-event">
    <div class="lead-audit-event__actor">
      {{ actor }}
    </div>

    <div class="lead-audit-event__action">
      {{ value }}
    </div>

    <div class="lead-audit-event__time">
      {{ time }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Lead } from 'Models/Lead'
import { AuditEvent } from 'Models/AuditEvent'
import { userGetters } from 'Store/entities/User/types'
import { brokersGetters } from 'Store/entities/Brokers/types'
import { formatTime, formatDateTimeCompact } from 'Utils/dateHelpers'

const ACTS_ENUM = AuditEvent.actionEnums

export default {

  name: 'lead-audit-event',
  props: {
    event: { type: AuditEvent, required: true }
  },
  computed: {
    ...mapGetters('entities/brokers', {
      brokers: brokersGetters.BROKERS,
    }),

    ...mapGetters('entities/user', {
      profile: userGetters.PROFILE,
      accountId: userGetters.ACCOUNT_ID,
      isAdmin: userGetters.IS_ADMIN,
    }),

    actor () {
      return this.event.actor.fullName || this.$t('DEFAULT_USER')
    },

    time () {
      return formatTime(this.event.date)
    },

    value () {
      const val = this.event.value

      switch (this.event.action) {
        case ACTS_ENUM.brokerAssigned:

          const brokerId = val.broker
          let brokerName = this.$t('UNKNOWN_BROKER')

          if (this.isAdmin) {
            const broker = this.brokers.find(b => b.id === val.broker)
            if (broker) brokerName = broker.fullName
          }

          if (this.accountId === brokerId) {
            brokerName = this.$t('COMMON.FULL_NAME_FORMAT', {
              firstName: this.profile.firstName,
              lastName: this.profile.lastName,
            })
          }

          return this.$t('ACTION_BROKER_ASSIGNED', { value: brokerName })

        case ACTS_ENUM.brokerUnassigned:
          return this.$t('ACTION_BROKER_UNASSIGNED')

        case ACTS_ENUM.dueDateUpdated:
          if (!val.dueDate) {
            return this.$t('ACTION_DUE_DATE_REMOVED')
          }

          return this.$t('ACTION_DUE_DATE_UPDATED', {
            value: this.$fd(
              new Date(Date.parse(val.dueDate)),
              this.$t('DATE_FORMATS.MONTH_DAY_HOURS_MINUTES'),
            )
          })

        case ACTS_ENUM.channelUpdated:
          return this.$t('ACTION_CHANNEL_UPDATED', {
            value: this.$t(this.channelLiteral[val.channel])
          })
        case ACTS_ENUM.commentUpdated:
          return this.$t('ACTION_COMMENT_UPDATED')

        case ACTS_ENUM.financingUpdated:
          return val.financing
            ? this.$t('ACTION_FINANCING_ENABLED')
            : this.$t('ACTION_FINANCING_DISABLED')

        case ACTS_ENUM.priorityUpdated:
          return val.priority
            ? this.$t('ACTION_PRIORITY_CHANGED_HI')
            : this.$t('ACTION_PRIORITY_CHANGED_LOW')

        case ACTS_ENUM.stateUpdated:
          return this.$t('ACTION_STATE_UPDATED', {
            value: this.$t(this.stateLiteral[val.state])
          })
        case ACTS_ENUM.leadLost:
          return this.$t('ACTION_LEAD_LOST', {
            value: val.lotId
          })
        case ACTS_ENUM.leadWon:
          return this.$t('ACTION_LEAD_WON', {
            value: val.lotId
          })
        case ACTS_ENUM.leadCreated:
          return this.$t('ACTION_LEAD_CREATED')
        case ACTS_ENUM.dueDateExpired:
          return this.$t('ACTION_DUE_DATE_EXPIRED', {
            value: formatDateTimeCompact(new Date(val.dueDate))
          })
        default:
          // is some new events will appear - we'll see it anyway
          return this.event.action + ': ' + JSON.stringify(val)
      }
    },

    stateLiteral () {
      return {
        [Lead.statesEnum.waitingCall]: 'WAITING_STATE',
        [Lead.statesEnum.onHold]: 'HOLD_STATE',
        [Lead.statesEnum.lookingCar]: 'LOOKING_STATE',
        [Lead.statesEnum.offerSent]: 'OFFERED_STATE',
        [Lead.statesEnum.archived]: 'ARCHIVED_STATE',
      }
    },

    channelLiteral () {
      return {
        [Lead.channelsEnum.phone]: 'COMMON.LEAD_CHANNELS.PHONE',
        [Lead.channelsEnum.email]: 'COMMON.LEAD_CHANNELS.EMAIL',
        [Lead.channelsEnum.website]: 'COMMON.LEAD_CHANNELS.WEBSITE',
        [Lead.channelsEnum.facebook]: 'COMMON.LEAD_CHANNELS.FACEBOOK',
        [Lead.channelsEnum.telegram]: 'COMMON.LEAD_CHANNELS.TELEGRAM',
        [Lead.channelsEnum.carfax]: 'COMMON.LEAD_CHANNELS.CARFAX',
        [Lead.channelsEnum.facebookWeb]: 'COMMON.LEAD_CHANNELS.FACEBOOK_WEB',
        [Lead.channelsEnum.rajdeba]: 'COMMON.LEAD_CHANNELS.RAJDEBA',
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.lead-audit-event {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  font-size: 0.95em;

  &__actor {
    color: darken($color-dark-grey, 20%);
    font-weight: 500;
    white-space: nowrap;
  }

  &__action {
    font-weight: 400;
    margin: 0 1.2em 0 0.35em;
    color: darken($color-grey, 10%);
  }

  &__time {
    color: $color-dark-grey;
    font-weight: 500;
  }

  @include respond-below(sm) {
    flex-wrap: wrap;

    &__actor {
      margin-right: auto;
    }

    &__action {
      order: 3;
      width: 100%;
      flex-wrap: wrap;
      margin-left: 0;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DEFAULT_USER": "Admin",
    "UNKNOWN_BROKER": "Another Broker",
    "ACTION_BROKER_ASSIGNED": "assigned to {value}",
    "ACTION_BROKER_UNASSIGNED": "unassigned broker",
    "ACTION_DUE_DATE_UPDATED": "changed due date to {value}",
    "ACTION_DUE_DATE_REMOVED": "removed due date",
    "ACTION_DUE_DATE_EXPIRED": "due date {value} expired",
    "ACTION_CHANNEL_UPDATED": "channel set to {value}",
    "ACTION_COMMENT_UPDATED": "updated comment",
    "ACTION_FINANCING_ENABLED": "enabled financing",
    "ACTION_FINANCING_DISABLED": "disabled financing",
    "ACTION_PRIORITY_CHANGED_HI": "marked as high priority",
    "ACTION_PRIORITY_CHANGED_LOW": "marked as low priority",
    "ACTION_STATE_UPDATED": "changed state to {value}",
    "ACTION_LEAD_LOST": "Lost in trade. Lot: {value}",
    "ACTION_LEAD_WON": "Won in trade. Lot: {value}",
    "ACTION_LEAD_CREATED": "Сreated",
    "WAITING_STATE": "Waiting for call",
    "HOLD_STATE": "On-hold",
    "LOOKING_STATE": "Looking for a car",
    "OFFERED_STATE": "Offer Sent",
    "ARCHIVED_STATE": "Archived"
  },
  "ka": {
    "DEFAULT_USER": "ადმინი",
    "UNKNOWN_BROKER": "სხვა ბროკერი",
    "ACTION_BROKER_ASSIGNED": "მიამაგრა {value}",
    "ACTION_BROKER_UNASSIGNED": "ბროკერის წაშლა",
    "ACTION_DUE_DATE_UPDATED": "შეცვლილი თარიღი ",
    "ACTION_DUE_DATE_REMOVED": "თარიღის გაუქმება",
    "ACTION_DUE_DATE_EXPIRED": "დრო {value} ამოიწურა",
    "ACTION_CHANNEL_UPDATED": "წყარო შეიცვალა {value}",
    "ACTION_COMMENT_UPDATED": "განახლებული კომენტარი",
    "ACTION_FINANCING_ENABLED": "დაფინანსებით",
    "ACTION_FINANCING_DISABLED": "დაფინანსების გარეშე",
    "ACTION_PRIORITY_CHANGED_HI": "მაღალი პრიორიტეტი ",
    "ACTION_PRIORITY_CHANGED_LOW": "დაბალი პრიორიტეტი",
    "ACTION_STATE_UPDATED": "სტატუსი განახლდა {value}",
    "ACTION_LEAD_LOST": "წაგებულია ლოტი: {value}",
    "ACTION_LEAD_WON": "მოგებულია ლოტი: {value}",
    "ACTION_LEAD_CREATED": "შექმნილია",
    "WAITING_STATE": "დასარეკია",
    "HOLD_STATE": "ოდესმე",
    "LOOKING_STATE": "ძიება",
    "OFFERED_STATE": "გაგზავნილი",
    "ARCHIVED_STATE": "არქივი"
  },
  "ru": {
    "DEFAULT_USER": "Администратор",
    "UNKNOWN_BROKER": "Другой Брокер",
    "ACTION_BROKER_ASSIGNED": "назначен на {value}",
    "ACTION_BROKER_UNASSIGNED": "неназначенный брокер",
    "ACTION_DUE_DATE_UPDATED": "дата изменена на {value}",
    "ACTION_DUE_DATE_REMOVED": "удалены установленные сроки",
    "ACTION_DUE_DATE_EXPIRED": "назначеное время звонка {value} прошло",
    "ACTION_CHANNEL_UPDATED": "канал установлен на {value}",
    "ACTION_COMMENT_UPDATED": "обновленный комментарий",
    "ACTION_FINANCING_ENABLED": "финансирование включено",
    "ACTION_FINANCING_DISABLED": "финансирование отключено",
    "ACTION_PRIORITY_CHANGED_HI": "отмечен как высокоприоритетный",
    "ACTION_PRIORITY_CHANGED_LOW": "отмечен как низкоприоритетный",
    "ACTION_STATE_UPDATED": "состояние изменено на {value}",
    "ACTION_LEAD_LOST": "проиграл в торгах. Лот: {value}",
    "ACTION_LEAD_WON": "выиграл в торгах. Лот: {value}",
    "ACTION_LEAD_CREATED": "создан",
    "WAITING_STATE": "В ожидании звонка",
    "HOLD_STATE": "На удержании",
    "LOOKING_STATE": "Поиск авто",
    "OFFERED_STATE": "Предложение отправлено",
    "ARCHIVED_STATE": "Помещен в архив"
  },
  "uk": {
    "DEFAULT_USER": "Адміністратор",
    "UNKNOWN_BROKER": "Інший Брокер",
    "ACTION_BROKER_ASSIGNED": "призначений на {value}",
    "ACTION_BROKER_UNASSIGNED": "непризначений брокер",
    "ACTION_DUE_DATE_UPDATED": "дата змінена на {value}",
    "ACTION_DUE_DATE_REMOVED": "видалені встановлені сроки",
    "ACTION_DUE_DATE_EXPIRED": "назначений час дзвінка {value} пройшов",
    "ACTION_CHANNEL_UPDATED": "канал встановлений на {value}",
    "ACTION_COMMENT_UPDATED": "оновлений коментар",
    "ACTION_FINANCING_ENABLED": "фінансування включено",
    "ACTION_FINANCING_DISABLED": "фінансування відключено",
    "ACTION_PRIORITY_CHANGED_HI": "відзначений як високопріоритетний",
    "ACTION_PRIORITY_CHANGED_LOW": "відзначений як низькопріоритетний",
    "ACTION_STATE_UPDATED": "стан змінено на {value}",
    "ACTION_LEAD_LOST": "програв торги. Лот: {value}",
    "ACTION_LEAD_WON": "виграв торги. Лот: {value}",
    "ACTION_LEAD_CREATED": "створено",
    "WAITING_STATE": "В очікуванні дзвінка",
    "HOLD_STATE": "На утриманні",
    "LOOKING_STATE": "Пошук автомобіля",
    "OFFERED_STATE": "Відправлено",
    "ARCHIVED_STATE": "Поміщено в архів"
  }
}
</i18n>
