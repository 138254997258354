<template>
  <div
    class="
      claim-item-skeleton-skeleton
      claims-cmn__row
      claims-cmn__row_won
  "
  >
    <div class="claim-item-skeleton-skeleton__cell">
      <div class="claim-item-skeleton-skeleton__img" />

      <div class="claim-item-skeleton-skeleton__won-cell">
        <p
          v-for="j in 3"
          :key="j"
          class="claim-item-skeleton-skeleton__cell"
        >
          <span
            class="claim-item-skeleton-skeleton__line"
            style="width: 9em;"
          >&nbsp;</span>
        </p>
      </div>
    </div>

    <div class="claim-item-skeleton-skeleton__won-cell">
      <p
        v-for="j in 2"
        :key="j"
        class="claim-item-skeleton-skeleton__cell"
      >
        <span
          class="claim-item-skeleton-skeleton__line"
          style="width: 9em;"
        >&nbsp;</span>
      </p>
    </div>

    <div
      v-for="i in 3"
      :key="i"
      class="claim-item-skeleton-skeleton__won-cell"
    >
      <span
        class="claim-item-skeleton-skeleton__line"
        style="width: 8em;"
      >&nbsp;</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'claim-item-skeleton-skeleton',
}
</script>

<style scoped lang="scss">
@import "../styles/claims.scss";
@import "@/styles/skeletons.scss";

.claim-item-skeleton-skeleton {
  padding: 2.1em 0;
  background: $color-light;
  border: 1px solid transparent;
  box-sizing: border-box;

  &__cell {
    min-width: 0;
    min-height: 0;
    display: grid;
    grid: auto / auto-flow auto;
    justify-content: start;
    align-items: center;
    grid-gap: 0.5em;
  }

  &__won-cell {
    display: grid;
    row-gap: 0.8em;
  }

  &__img {
    @extend %skeleton-bg;

    height: 5.4em;
    max-width: 6.5em;
    min-width: 6.5em;
    margin-right: 0.9em;

    --ratio: calc(1 / 1);

    @include aspect-ratio();
  }

  &__line {
    @extend %skeleton-bg;

    border-radius: 50px;
    display: inline-block;
  }
}
</style>
