import { notificationTypes as TOPICS } from '@shelf.network/js-sdk'

export { TOPICS }

export const URGENT_TOPICS = [
  TOPICS.userRequestedBuyNow,
  TOPICS.userRequestedWithdrawal,
  TOPICS.userMadeDeposit,
  TOPICS.leadCommented,
].sort()

export const INFO_TOPICS = [
  TOPICS.userRequestedFinancing,
  TOPICS.userFinancingApproved,
  TOPICS.brokerLeadDueDateExpired,
  TOPICS.brokerLeadStateChanged,
  TOPICS.userVerified,
  TOPICS.brokerUserMadeDeposit,
  TOPICS.brokerUserLotWon,
  TOPICS.brokerUserLotLost,
  TOPICS.userLotWon,
  TOPICS.userLotLost,
  TOPICS.leadAssigned,
  TOPICS.leadReassigned,
].sort()

export const EXTENSION_TOPICS = [
  TOPICS.claimRequestCreated,
].sort()
