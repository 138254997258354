<template>
  <div class="list-params-channel list-params-row">
    <label class="list-params-row__label">
      {{ label || $t('CHANNEL_LBL') }}
    </label>

    <ui-select
      class="list-params-row__field"
      fill="frame"
      look="secondary"
      :options="options"
      :placeholder="placeholder || $t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
      :null-option="nullOption"
      :value="value || defaultValue"
      @input="onInput"
    />
  </div>
</template>

<script>
import { UiSelect } from '@shelf.network/ui-kit'
import { getChannelOptions } from 'Utils/channelOptions'

export default {
  name: 'list-params-channel',
  components: { UiSelect },

  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    defaultValue: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },

  computed: {
    options: () => {
      return getChannelOptions()
    },

    nullOption () {
      return {
        value: '',
        label: this.$t('COMMON.SELECT_FIELD.RESET_OPT'),
      }
    },
  },

  methods: {
    onInput (value) {
      this.$emit('input', value === this.defaultValue ? '' : value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./styles/list-params-row.scss";
</style>

<i18n>
{
  "en": {
    "CHANNEL_LBL": "BY CHANNEL"
  },
  "ka": {
    "CHANNEL_LBL": "ᲚᲘᲓᲘᲡ ᲬᲧᲐᲠᲝ"
  },
  "ru": {
    "CHANNEL_LBL": "ПО КАНАЛУ"
  },
  "uk": {
    "CHANNEL_LBL": "ЗА КАНАЛОМ"
  }
}
</i18n>
