export const bellActions = {
  LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
  LOAD_UNREAD_COUNT: 'LOAD_UNREAD_COUNT',
  MARK_READ: 'MARK_READ',
  CONNECT_CLIENT: 'CONNECT_CLIENT',
  DISCONNECT_CLIENT: 'DISCONNECT_CLIENT',
  UPDATE_URGENT_NOTIFICATION: 'UPDATE_URGENT_NOTIFICATION',
  UPDATE_INFO_NOTIFICATION: 'UPDATE_INFO_NOTIFICATION',
  UPDATE_EXTENSION_NOTIFICATION: 'UPDATE_EXTENSION_NOTIFICATION',
}

export const bellMutations = {
  SET_URGENT_NOTIFICATIONS: 'SET_URGENT_NOTIFICATIONS',
  SET_INFO_NOTIFICATIONS: 'SET_INFO_NOTIFICATIONS',
  SET_URGENT_UNREAD_COUNT: 'SET_URGENT_UNREAD_COUNT',
  SET_INFO_UNREAD_COUNT: 'SET_INFO_UNREAD_COUNT',
  SET_EXTENSION_NOTIFICATIONS: 'SET_EXTENSION_NOTIFICATIONS',
  SET_EXTENSION_UNREAD_COUNT: 'SET_EXTENSION_UNREAD_COUNT',
  UNSHIFT_URGENT_NOTIFICATIONS: 'UNSHIFT_URGENT_NOTIFICATIONS',
  UNSHIFT_INFO_NOTIFICATIONS: 'UNSHIFT_INFO_NOTIFICATIONS',
  UNSHIFT_EXTENSION_NOTIFICATIONS: 'UNSHIFT_EXTENSION_NOTIFICATIONS',
  REMOVE_URGENT_NOTIFICATION: 'REMOVE_URGENT_NOTIFICATION',
  REMOVE_INFO_NOTIFICATION: 'REMOVE_INFO_NOTIFICATION',
  REMOVE_EXTESION_NOTIFICATION: 'REMOVE_EXTESION_NOTIFICATION',
}

export const bellGetters = {
  URGENT_NOTIFICATIONS: 'URGENT_NOTIFICATIONS',
  INFO_NOTIFICATIONS: 'INFO_NOTIFICATIONS',
  EXTENSION_NOTIFICATIONS: 'EXTENSION_NOTIFICATIONS',
  URGENT_UNREAD_COUNT: 'URGENT_UNREAD_COUNT',
  INFO_UNREAD_COUNT: 'INFO_UNREAD_COUNT',
  EXTENSION_UNREAD_COUNT: 'EXTENSION_UNREAD_COUNT',
}
