// The app is configured via environment variables in .env files
// Use prefix "VUE_APP_" for config lines.
// Use prefix "VUE_APP_FEATURE_FLAG_" for feature flags.

export const config = {
  featureFlags: {
    SHELF_AP_FEATURES:
      toBoolean(process.env.VUE_APP_FEATURE_FLAG_SHELF_AP_FEATURES),
    TRANSPORTATION_FEES:
      toBoolean(process.env.VUE_APP_FEATURE_FLAG_TRANSPORTATION_FEES),
  },

  getLocalized (key, localeCode) {
    if (!key || !localeCode) {
      return null
    }

    const result = this[`${key}_${localeCode.toUpperCase()}`]

    return result || this[key] || null
  },

  LOG_ROCKET_APP_ID: process.env.VUE_APP_LOG_ROCKET_APP_ID,
  DISABLE_CRAWLERS: toBoolean(process.env.VUE_APP_DISABLE_CRAWLERS),
  ENABLE_DEVTOOLS: toBoolean(process.env.VUE_APP_ENABLE_DEVTOOLS),
  GATEWAY_URL: process.env.VUE_APP_GATEWAY_URL,
  IMGPROXY_URL: process.env.VUE_APP_IMGPROXY_URL,
  DEFAULT_LOCALE: process.env.VUE_APP_DEFAULT_LOCALE,
  SUPPORTED_LOCALES: process.env.VUE_APP_SUPPORTED_LOCALES,
  DEFAULT_CURRENCY: process.env.VUE_APP_DEFAULT_CURRENCY,
  BUNDLE_ANALYZER: toBoolean(process.env.VUE_APP_BUNDLE_ANALYZER),
  PHONE_COUNTRIES: process.env.VUE_APP_PHONE_COUNTRIES,
  COPART_PLATFORM_ID: process.env.VUE_APP_COPART_PLATFORM_ID,
  RINGER_WSS: process.env.VUE_APP_RINGER_WSS,
}

function toBoolean (value) {
  switch (value) {
    case 'true':
      return true
    case 'false':
    case undefined:
      return false
    default:
      throw new Error(`expected true or false, got ${value} `)
  }
}
