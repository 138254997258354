export function genBellState () {
  return {
    urgentNotifications: [],
    infoNotifications: [],
    extensionNotifications: [],

    urgentUnreadCount: 0,
    infoUnreadCount: 0,
    extensionUnreadCount: 0,
  }
}

export default genBellState()
