import { translateEnum } from 'Utils/translateEnum'
import { enumsGetters } from './types'
import { currencySymbols } from 'Utils/currencySymbols'
import { i18n } from 'I18n/instance'
import range from 'lodash/range'

export default {
  [enumsGetters.TRANSLATIONS]: state => state.translations,

  [enumsGetters.FUELS]: state => state.fuels,
  [enumsGetters.FUELS_AS_OPTIONS]: state =>
    Object.keys(state.fuels).map(item => ({
      label: translateEnum({
        name: 'FUEL_TYPE',
        value: item,
        translations: state.translations
      }),
      value: item,
    })),

  [enumsGetters.MAKERS_AS_OPTIONS]: state => Object
    .values(state.makerNames)
    .map(item => ({
      label: translateEnum({
        name: 'MAKER',
        value: item,
        translations: state.translations,
      }),
      value: item,
    })),
  [enumsGetters.MODELS_AS_OPTIONS]: (state) => Object
    .entries(state.makersToModels)
    .reduce((result, [makerId, modelIds]) => {
      const makerName = state.makerNames[makerId]
      const models = modelIds
        .map(id => state.modelNames[id])
        .map(modelName => ({
          value: modelName,
          label: translateEnum({
            name: 'MODEL',
            value: modelName,
            translations: state.translations,
          })
        }))

      result[makerName] = models
      return result
    }, {}),
  [enumsGetters.TRANSMISSIONS_AS_OPTIONS]: state =>
    Object.keys(state.transmissions)
      .map(item => ({
        label: translateEnum({
          name: 'TRANSMISSION_TYPE',
          value: item,
          translations: state.translations,
        }),
        value: item,
      })),
  [enumsGetters.WHEELS_AS_OPTIONS]: state =>
    Object.keys(state.wheels)
      .map(item => ({
        label: translateEnum({
          name: 'WHEEL_POSITION',
          value: item,
          translations: state.translations,
        }),
        value: item,
      })),
  // static (doesn't require API):
  [enumsGetters.YEARS_AS_OPTIONS]: _ => range(new Date().getFullYear(), 1919)
    .map(String)
    .map(el => ({ label: el, value: el })),

  [enumsGetters.ENGINE_VOLUMES_AS_OPTIONS]: _ => range(5, 71)
    .map(el => String((el / 10).toFixed(1)))
    .map(el => ({ label: el, value: el })),

  [enumsGetters.CURRENCIES_AS_OPTIONS]: _ => Object
    .keys(currencySymbols)
    .map(code => ({ label: code, value: code })),

  [enumsGetters.MILEAGE_UNITS_AS_OPTIONS]: _ => [
    { label: i18n.t('ENUMS.MILEAGE_UNITS.KILOMETER'), value: 'km' },
    { label: i18n.t('ENUMS.MILEAGE_UNITS.MILE'), value: 'mi' }
  ],
  [enumsGetters.COLORS_AS_OPTIONS]: _ => [
    { label: i18n.t('ENUMS.COLORS.BEIGE'), value: 'beige' },
    { label: i18n.t('ENUMS.COLORS.BLACK'), value: 'black' },
    { label: i18n.t('ENUMS.COLORS.BLUE'), value: 'blue' },
    { label: i18n.t('ENUMS.COLORS.BROWN'), value: 'brown' },
    { label: i18n.t('ENUMS.COLORS.GOLD'), value: 'gold' },
    { label: i18n.t('ENUMS.COLORS.GREEN'), value: 'green' },
    { label: i18n.t('ENUMS.COLORS.GRAY'), value: 'gray' },
    { label: i18n.t('ENUMS.COLORS.ORANGE'), value: 'orange' },
    { label: i18n.t('ENUMS.COLORS.PURPLE'), value: 'purple' },
    { label: i18n.t('ENUMS.COLORS.RED'), value: 'red' },
    { label: i18n.t('ENUMS.COLORS.SILVER'), value: 'silver' },
    { label: i18n.t('ENUMS.COLORS.WHITE'), value: 'white' },
    { label: i18n.t('ENUMS.COLORS.YELLOW'), value: 'yellow' },
    { label: i18n.t('ENUMS.COLORS.OTHER'), value: 'other' }
  ],
  [enumsGetters.SALE_PERIODS_AS_OPTIONS]: _ => [
    { label: i18n.t('ENUMS.SALE_PERIODS.28_DAYS'), value: '28' },
    { label: i18n.t('ENUMS.SALE_PERIODS.14_DAYS'), value: '14' },
    { label: i18n.t('ENUMS.SALE_PERIODS.7_DAYS'), value: '7' }
  ],
}
