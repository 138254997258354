<template>
  <div class="lead-lot-prices-graph">
    <h2 class="lead-lot-prices-graph__heading">
      {{ $t('HEADING') }}
    </h2>

    <div class="lead-lot-prices-graph__pins">
      <img
        class="lead-lot-prices-graph__bg"
        src="~Assets/images/price-graph.svg"
        alt="Prices graph"
      >

      <div class="lead-lot-prices-graph__pin lead-lot-prices-graph__pin_min">
        <div class="lead-lot-prices-graph__pin-cloud">
          <span class="lead-lot-prices-graph__pin-value">
            {{ $cfc(minPrice, currency, { to: toCurrency }) }}
          </span>

          <span class="lead-lot-prices-graph__pin-label">
            {{ $t('MIN_PRICE_LBL') }}
          </span>
        </div>

        <div class="lead-lot-prices-graph__pin-dot" />
      </div>

      <div class="lead-lot-prices-graph__pin lead-lot-prices-graph__pin_avg">
        <div class="lead-lot-prices-graph__pin-cloud">
          <span class="lead-lot-prices-graph__pin-value">
            {{ $cfc(avgPrice, currency, { to: toCurrency }) }}
          </span>

          <span class="lead-lot-prices-graph__pin-label">
            {{ $t('AVG_PRICE_LBL') }}
          </span>
        </div>

        <div class="lead-lot-prices-graph__pin-dot" />
      </div>

      <div class="lead-lot-prices-graph__pin lead-lot-prices-graph__pin_max">
        <div class="lead-lot-prices-graph__pin-cloud">
          <span class="lead-lot-prices-graph__pin-value">
            {{ $cfc(maxPrice, currency, { to: toCurrency }) }}
          </span>

          <span class="lead-lot-prices-graph__pin-label">
            {{ $t('MAX_PRICE_LBL') }}
          </span>
        </div>

        <div class="lead-lot-prices-graph__pin-dot" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'lead-lot-prices-graph',

  props: {
    minPrice: {
      type: String,
      default: '',
    },

    avgPrice: {
      type: String,
      default: '',
    },

    maxPrice: {
      type: String,
      default: '',
    },

    currency: {
      type: String,
      default: '',
    },

    toCurrency: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.lead-lot-prices-graph {
  &__bg {
    width: 100%;
    margin-top: 6em;
    height: 14em;
    pointer-events: none;
  }

  &__heading {
    font-size: 1.285714em; // 18px when font-size: 14px
    line-height: 1.5;
    font-weight: 600;
  }

  &__pins {
    position: relative;
  }

  &__pin {
    position: absolute;
    display: inline-block;
    width: auto;

    &_min {
      left: -1.25em;
      top: 28.5%;
    }

    &_avg {
      top: 20.5%;
      left: 50%;
      transform: translateX(-50%);
    }

    &_max {
      right: -1.25em;
      top: 2%;
    }

    &-cloud {
      padding: 0.75em 2em;
      border-radius: 1em;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      background: white;
      display: inline-flex;
      flex-direction: column;
      width: auto;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 1px;
        border: 0.75em solid;
        border-color: transparent white white transparent;
        width: 0;
        height: 0;
        bottom: -0.4em;
      }

      .lead-lot-prices-graph__pin_min > &::after {
        left: 1.6em;
        transform: translateX(-50%) rotate(45deg);
      }

      .lead-lot-prices-graph__pin_avg > &::after {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      .lead-lot-prices-graph__pin_max > &::after {
        right: 1.6em;
        transform: translateX(50%) rotate(45deg);
      }
    }

    &-value {
      text-align: center;
      color: $color-dark;
      line-height: 1.5;
    }

    &-label {
      text-align: center;
      color: $color-dark-grey;
      font-size: 0.86em;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      display: inline-block;
    }

    &-dot {
      width: 1.6em;
      height: 1.6em;
      background: white;
      border: 1px solid $color-sys-info;
      border-radius: 50%;
      position: absolute;
      bottom: -2.85em;

      .lead-lot-prices-graph__pin_min > & {
        left: 1.6em;
        transform: translateX(-50%);
      }

      .lead-lot-prices-graph__pin_avg > & {
        left: 50%;
        transform: translateX(-50%);
      }

      .lead-lot-prices-graph__pin_max > & {
        right: 1.5em;
        transform: translateX(50%);
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "HEADING": "Average Price Globally",
    "MIN_PRICE_LBL": "min price",
    "AVG_PRICE_LBL": "average price",
    "MAX_PRICE_LBL": "max price"
  },
  "ka": {
    "HEADING": "ფასების სტატისტიკა",
    "MIN_PRICE_LBL": "მინიმუმი",
    "AVG_PRICE_LBL": "საშუალო",
    "MAX_PRICE_LBL": "მაქსიმუმი"
  },
  "ru": {
    "HEADING": "Средняя рыночная цена",
    "MIN_PRICE_LBL": "минимальная цена",
    "AVG_PRICE_LBL": "средняя цена",
    "MAX_PRICE_LBL": "максимальная цена"
  },
  "uk": {
    "HEADING": "Середня ринкова ціна",
    "MIN_PRICE_LBL": "мінімальна ціна",
    "AVG_PRICE_LBL": "середня ціна",
    "MAX_PRICE_LBL": "максимальна ціна"
  }
}
</i18n>
