<template>
  <div
    class="lead-item"
    :class="{
      'lead-item_overdue': isDueDateShown && isOverdue,
      'lead-item_selected': isInBulkSelection,
      'lead-item_high-priority': lead.isPriorityHigh,
      'lead-item_interested-in-pro': lead.interestedInPro && !lead.isPro,
      'lead-item_financing': lead.financing,
    }">
    <button
      v-if="$can($USER_CLAIMS.BROKER_ASSIGNMENT)"
      class="lead-item__bulk-toggler"
      :class="{
        'lead-item__bulk-toggler_overdue': isDueDateShown && isOverdue,
        'lead-item__bulk-toggler_selected': isInBulkSelection,
        'lead-item__bulk-toggler_high-priority': lead.isPriorityHigh,
      }"
      @click="bulkToggle"
    >
      <ui-icon
        class="lead-item__bulk-toggler-ico"
        icon="success"
      />
    </button>
    <router-link
      :to="{ params: { leadId: lead.id }, query: $route.query }"
      class="lead-item__link"
    >
      <div class="lead-item__lots">
        <template v-if="lazyLotImagesToShow.length">
          <div
            class="lead-item__lot"
            v-for="(lazyLotImg, i) in lazyLotImagesToShow"
            :key="i">
            <ui-img
              v-if="lazyLotImg"
              class="lead-item__lot-img"
              v-bind="lazyLotImg"
            />
            <ui-icon
              icon="photo-camera"
              class="lead-item__lot-img"
              v-else />
          </div>
          <div
            class="lead-item__lot lead-item__lot_more"
            v-if="lead.lots.length > MAX_LOTS_PER_LEAD"
          >
            <div class="lead-item__lot-img">
              {{ $t('MORE_IMAGES_TXT', {
                count: lead.lots.length - (MAX_LOTS_PER_LEAD - 1)
              }) }}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="lead-item__lot lead-item__lot_no-lots">
            <div class="lead-item__lot-img">
              <ui-icon icon="no-car" />
            </div>
          </div>
        </template>
      </div>
      <div>
        <lead-item-type
          class="lead-item__user-type"
          :lead="lead"
        />

        <h4 class="lead-item__user-name">
          <channel-icon
            :channel="lead.channel"
            class="lead-item__user-channel-icon" />
          <span class="lead-item__user-name-text">
            {{
              lead.fullName || $t('COMMON.UNNAMED_USER_PH')
            }}
          </span>
          <span
            class="lead-item__user-name-pro-mark"
            v-if="lead.isPro"
          >
            {{ $t('PRO_MARK') }}
          </span>
        </h4>

        <div
          v-if="isDueDateShown"
          class="lead-item__date"
          :class="{ 'lead-item__date_warning' : isOverdue }">
          <ui-icon
            icon="sand-clock"
            class="lead-item__date-icon"
          />
          <span
            class="lead-item__date-val"
            :title="isDueDateSet ? $t('DUE_DATE_HINT') : ''"
          >
            {{ displayDueDate }}
          </span>
        </div>
        <div
          v-else
          class="lead-item__date"
        >
          <ui-icon
            icon="timer-alt"
            class="lead-item__date-icon"
          />
          <time-ago
            class="lead-item__date-val"
            :date="eventDate.value"
            :title="eventDate.hint"
          />
        </div>
      </div>
      <div class="lead-item__phone">
        <ui-icon
          icon="call"
          class="lead-item__phone-icon" />
        <span class="lead-item__phone-number">
          {{ formattedLeadPhone }}
        </span>

        <ui-icon
          v-if="!lead.unresolvedSupportRequestsCount"
          icon="message-alt"
          class="lead-item__phone-message-icon"
        />
        <span
          v-else
          class="lead-item__phone-missed"
        >
          <div class="lead-item__phone-missed-num">
            {{ lead.unresolvedSupportRequestsCount }}
          </div>
        </span>
      </div>
    </router-link>

    <template v-if="$can($USER_CLAIMS.BROKER_ASSIGNMENT)">
      <lead-broker-select
        class="lead-item__dropdown"
        :bulk-selection="bulkSelection"
        :lead="lead"
      />
    </template>

    <template v-else>
      <lead-state-select
        class="lead-item__dropdown"
        :bulk-selection="bulkSelection"
        :lead="lead"
      />
    </template>
  </div>
</template>

<script>
import TimeAgo from 'Common/TimeAgo'
import { UiIcon, UiImg, breakpoints, formatPhone } from '@shelf.network/ui-kit'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { actions, mutations } from '../store/types'
import { LeadListEntry } from 'Models/LeadListEntry'
import LeadStateSelect from './LeadStateSelect'
import LeadBrokerSelect from './LeadBrokerSelect'
import LeadItemType from './LeadItemType'
import ChannelIcon from 'Common/ChannelIcon'
import { generateImageProps } from 'Utils/generateImageProps'
import { isNullDate, formatDateTimeCompact } from 'Utils/dateHelpers'

const MAX_LOTS_PER_LEAD = 4

export default {
  name: 'lead-item',

  components: {
    TimeAgo,
    UiIcon,
    UiImg,
    LeadStateSelect,
    LeadBrokerSelect,
    LeadItemType,
    ChannelIcon
  },

  props: {
    lead: { type: LeadListEntry, required: true },
  },

  data () {
    return {
      MAX_LOTS_PER_LEAD,
    }
  },

  computed: {
    ...mapGetters('ui/leads', [
      'bulkSelection'
    ]),

    formattedLeadPhone () {
      return formatPhone(this.lead.contactPhone)
    },

    isOverdue () {
      return this.lead.dueDate < (new Date())
    },

    isDueDateShown () {
      return [
        LeadListEntry.statesEnum.lookingCar,
        LeadListEntry.statesEnum.onHold
      ].includes(this.lead.state) || (
        this.lead.state ===
        LeadListEntry.statesEnum.offerSent && this.isDueDateSet
      )
    },

    eventDate () {
      if (
        this.lead.state ===
        LeadListEntry.statesEnum.offerSent && !this.isDueDateSet
      ) {
        return {
          value: this.lead.lastOfferSentAt,
          hint: this.$t('LAST_OFFERED_DATE_HINT'),
        }
      }

      return {
        value: this.lead.lastRequestDate,
        hint: this.$t('LAST_REQUEST_DATE_HINT'),
      }
    },

    isDueDateSet () {
      return !isNullDate(this.lead.dueDate)
    },

    displayDueDate () {
      if (!this.isDueDateSet) {
        return this.$t('DUE_DATE_PH')
      }

      return formatDateTimeCompact(this.lead.dueDate)
    },

    lazyLotImagesToShow () {
      const lotsToShow = this.lead.lots.length > 4 ? 3 : this.lead.lots.length
      return this.lead.lots.slice(0, lotsToShow)
        .filter(Boolean)
        .map((img, index) => {
          if (!img) return null

          return generateImageProps({
            src: img,
            srcSetOptions: [
              { width: 24, height: 24 },
              { width: 48, height: 48 },
              { width: 64, height: 64 },
              { width: 128, height: 128 },
            ],
            sizes: [
              `(max-width: ${breakpoints.XS}px) 33vw`,
              `(max-width: ${breakpoints.SM}px) 25vw`,
              '15vw',
            ].join(', '),
            progressive: false,
          })
        })
    },

    isInBulkSelection () {
      return this.bulkSelection.includes(this.lead.id)
    }
  },

  methods: {
    ...mapActions('ui/leads', {
      updateLead: actions.UPDATE_LEAD,
    }),

    ...mapMutations('ui/leads', {
      bulkSelect: mutations.BULK_SELECT,
      bulkUnselect: mutations.BULK_UNSELECT,
    }),

    bulkToggle () {
      if (this.isInBulkSelection) {
        this.bulkUnselect(this.lead.id)
      } else {
        this.bulkSelect(this.lead.id)
      }
    },
  }
}
</script>

<style scoped lang="scss">
@mixin lead-state($color) {
  border-color: $color;
  box-shadow: 0 4px 20px transparentize($color, 0.9);

  &:hover {
    box-shadow: 0 10px 10px transparentize($color, 0.8);
  }

  &.lead-item_selected {
    box-shadow:
      0 4px 20px transparentize($color, 0.9),
      inset 0 0 0 2px $color;

    &:hover {
      box-shadow:
        0 10px 10px transparentize($color, 0.8),
        inset 0 0 0 2px $color;
    }
  }

  & .lead-item__bulk-toggler {
    border-color: $color;

    &_selected {
      background: $color;
      border-color: $color;
    }
  }
}

.lead-item {
  background: $color-light;
  border: 1px solid $color-grey;
  box-sizing: border-box;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  padding: 0 0 0;
  transition: all linear 180ms;
  position: relative;

  &:hover {
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
  }

  &__bulk-toggler {
    width: 2em;
    height: 2em;
    display: block;
    position: absolute;
    top: -0.65em;
    left: -0.65em;
    border-radius: 0.6em;
    background: $color-light;
    border: 1px solid $color-grey;
    transition: all linear 180ms;

    &_selected {
      background: $color-dark;
      border-color: $color-dark;
    }

    &-ico {
      color: $color-light;
      position: relative;
      font-size: 1.5em;
      top: 0.05em;
    }
  }

  &_selected {
    @include lead-state($color-dark);
  }

  &_overdue {
    @include lead-state($color-sys-warning);
  }

  &_high-priority {
    @include lead-state($color-sys-info);
  }

  &_financing {
    @include lead-state($color-sys-success);
  }

  &_interested-in-pro {
    @include lead-state($color-user-pro);
  }

  &__lots {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid $color-grey;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
  }

  &__lot {
    @include aspect-ratio();

    --ratio: calc(1 / 1);

    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &_more {
      font-size: 1em;
    }

    &_no-lots {
      font-size: 1.75em;
    }

    &-img {
      border-radius: 0.5em;
      background: $color-light-grey;
      color: darken($color-grey, 20%);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        font-size: 2em;
      }
    }
  }

  &__user {
    &-name {
      margin: 0.3em 0;
      display: flex;
      max-width: 100%;
      align-content: center;

      &-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        font-size: 1.2em;
      }

      &-pro-mark {
        color: $color-user-pro;
        font-weight: 500;
        border: 1px solid $color-user-pro;
        border-radius: 3px;
        padding: 0.2em 0.3em 0 0.3em;
        margin-left: 0.3em;
        font-size: 0.8em;
        display: flex;
        align-items: center;
        height: auto;
      }
    }

    &-channel-icon {
      font-size: 1.4em;
      min-width: 1.1em;
    }

    &-type {
      display: block;
    }
  }

  &__date {
    color: $color-ui-secondary;
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0.6em 0 0 0;
    font-weight: 400;
    line-height: 1em;

    &_warning {
      color: $color-sys-warning;
    }

    &-icon {
      margin-right: 0.2em;
      font-size: 1.4em;
    }
  }

  &__phone {
    margin: 1.7em 0 0 0;
    display: flex;
    align-items: center;
    align-content: space-between;
    height: 1.7em;

    &-icon {
      font-size: 1.2em;
    }

    &-number {
      margin-left: 0.5em;
      margin-right: auto;
      line-height: 1.35;
      letter-spacing: 0.02em;
      font-weight: 500;
    }

    &-missed {
      color: #fff;
      font-size: 9px;
      background: $color-sys-warning;
      border-radius: 1em;
      width: 2em;
      height: 2em;
      position: relative;

      &-num {
        position: absolute;
        margin-top: 1px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-message-icon {
      font-size: 1.2em;
      color: $color-ui-secondary;
    }
  }

  &__link {
    text-decoration: none;
    color: $color-dark;
    display: block;
    padding: 1.4em 1.4em 0 1.4em;
  }

  &__link:hover &__user-name-text {
    text-decoration: underline;
  }

  &__dropdown {
    padding: 1.4em;
    display: block;
  }
}
</style>
<i18n>
{
  "en": {
    "DUE_DATE_PH": "No due date",
    "LAST_REQUEST_DATE_HINT": "Last request date",
    "LAST_OFFERED_DATE_HINT": "Last offered date",
    "DUE_DATE_HINT": "Due date",
    "MORE_IMAGES_TXT": "+{count}",
    "PRO_MARK": "PRO"
  },
  "ka": {
    "DUE_DATE_PH": "თარიღის გარეშე",
    "LAST_REQUEST_DATE_HINT": "ბოლო მომართვის მიხედვით",
    "LAST_OFFERED_DATE_HINT": "ბოლოს შეთავაზებული",
    "DUE_DATE_HINT": "თარიღი",
    "MORE_IMAGES_TXT": "+{count}",
    "PRO_MARK": "PRO"
  },
  "ru": {
    "DUE_DATE_PH": "Дата не установлена",
    "LAST_REQUEST_DATE_HINT": "Дата последнего запроса",
    "LAST_OFFERED_DATE_HINT": "Дата последнего предложения",
    "DUE_DATE_HINT": "Дата",
    "MORE_IMAGES_TXT": "+{count}",
    "PRO_MARK": "PRO"
  },
  "uk": {
    "DUE_DATE_PH": "Дата не встановлена",
    "LAST_REQUEST_DATE_HINT": "Дата останнього запиту",
    "LAST_OFFERED_DATE_HINT": "Дата останньої пропозиції",
    "DUE_DATE_HINT": "Дата",
    "MORE_IMAGES_TXT": "+{count}",
    "PRO_MARK": "PRO"
  }
}
</i18n>
