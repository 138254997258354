import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import EditProfileSkeleton from './components/EditProfileSkeleton'

const moduleName = 'edit-profile'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: EditProfileSkeleton,
  importComponent: () => import('./EditProfile'),
})
