<template>
  <div class="lead-tab-history">
    <div
      class="lead-tab-history__refresh"
      v-if="leadSeemsChanged">
      <p>{{ $t('LEAD_SEEMS_CHANGED') }}</p>

      <ui-button
        @click="initHistory"
        class="lead-tab-history__refresh-btn">
        <ui-icon
          icon="refresh"
          class="lead-tab-history__refresh-icon" />
        {{ $t('RELOAD_BTN') }}
      </ui-button>
    </div>
    <div
      class="lead-tab-history__group"
      v-for="(day, i) in historyByDays"
      :key="i"
    >
      <div class="lead-tab-history__day">
        {{ day.date }}
      </div>
      <lead-audit-event
        v-for="event in day.events"
        :key="event.id"
        :event="event"
        class="lead-tab-history__event"
      />
    </div>

    <ui-spinner
      type="pills"
      v-if="isLoading"
      class="lead-tab-history__spinner"
    />

    <ui-button
      class="lead-tab-history__load-more"
      look="waiting"
      fill="none"
      @click="loadMore"
      v-else-if="fetchNext"
    >
      {{ $t('LOAD_MORE') }}
      <ui-icon
        class="lead-tab-history__load-more-icon"
        icon="arrow-up" />
    </ui-button>

    <div
      v-else-if="history.length"
      class="lead-tab-history__event-very-beginning">
      {{ $t('FIRST_EVENT') }}
    </div>

    <div
      v-else
      class="lead-tab-history__event-very-beginning">
      {{ $t('NO_EVENTS_YET') }}
    </div>
  </div>
</template>

<script>
import { sdk } from 'Services/shelfNetworkSdk'
import { Lead } from 'Models/Lead'
import { AuditEvent } from 'Models/AuditEvent'
import { UiSpinner, UiButton, UiIcon } from '@shelf.network/ui-kit'
import leadAuditEvent from './LeadAuditEvent'
import get from 'lodash/get'

const CURRENT_YEAR = new Date().getFullYear()
const EVENTS_PER_PAGE = 32

export default {
  name: 'lead-tab-history',
  components: { UiSpinner, UiButton, UiIcon, leadAuditEvent },
  props: {
    lead: { type: Lead, required: true }
  },
  data () {
    return {
      isLoading: true,
      history: [],
      fetchNext: null,
      rawData: null,
      leadSeemsChanged: false
    }
  },
  computed: {
    historyByDays () {
      const eventsByDays = []
      let cDayIndex = 0
      let currDate = null

      this.history.forEach(event => {
        const eventYear = event.date.getFullYear()
        const dateFormat = eventYear === CURRENT_YEAR
          ? this.$t('DATE_FORMATS.MONTH_DAY')
          : this.$t('DATE_FORMATS.MONTH_DAY_YEAR')

        const date = this.$fd(event.date, dateFormat)

        if (date !== currDate) {
          cDayIndex = eventsByDays.push({ date, events: [] }) - 1
          currDate = date
        }

        eventsByDays[cDayIndex].events.push(event)
      })

      return eventsByDays
    },
  },
  watch: {
    lead (curr, prev) {
      if (curr.id !== prev.id) {
        this.initHistory()
      } else {
        this.leadSeemsChanged = true
      }
    }
  },
  created () {
    this.initHistory()
  },
  methods: {
    async initHistory () {
      this.isLoading = true
      this.leadSeemsChanged = false

      try {
        const leadHistory = await sdk.backOffice.v2.getLeadHistory(
          this.lead.id,
          { 'page[limit]': EVENTS_PER_PAGE }
        )
        this.history.splice(0)

        this.fetchNext =
          get(leadHistory, 'data.length', 0) < EVENTS_PER_PAGE
            ? null
            : leadHistory.fetchNext

        this.appendEvents(leadHistory.data || [])
      } catch (err) {
        console.error(err)
      }

      this.isLoading = false
    },
    async loadMore () {
      if (!this.fetchNext) return
      this.isLoading = true

      try {
        const leadHistory = await this.fetchNext()

        this.fetchNext =
          get(leadHistory, 'data.length', 0) < EVENTS_PER_PAGE
            ? null
            : leadHistory.fetchNext

        this.appendEvents(leadHistory.data || [])
      } catch (err) {
        console.error(err)
      }

      this.isLoading = false
    },
    appendEvents (rawActions = []) {
      if (rawActions.length) {
        rawActions.forEach(ev => {
          this.history.push(new AuditEvent(ev))
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.lead-tab-history {
  position: relative;
  padding: 2em 1em 5em 1em;
  height: 30em;
  overflow-y: auto;
  margin: -2em 0 -3em;

  @include scrollbar-awesome();

  &__group {
    @extend %p;

    margin-bottom: 2em;
  }

  &__day {
    font-size: 1.1em;
    margin-bottom: 1.1em;
    color: $color-black;
    font-weight: 500;
  }

  &__spinner {
    padding: 2em 0;
  }

  &__refresh {
    padding: 0 0 1em 0;
    text-align: center;

    &-btn {
      margin: 1em 0;
    }

    &-icon {
      font-size: 1.2em;
      margin-right: 0.5em;
    }
  }

  &__load-more {
    & /deep/ .ui-button__button {
      padding-left: 0;
      padding-right: 0;
      font-weight: 400;
    }

    &-icon {
      transform: rotate(180deg);
      margin-left: 0.5em;
    }
  }

  &__event {
    margin: 1em 0;

    &-very-beginning {
      color: $color-grey;
    }
  }

  & /deep/ .ui-button__button {
    display: flex;
    align-items: center;
  }

  @include respond-below(sm) {
    height: unset;
  }
}
</style>

<i18n>
{
  "en": {
    "LOAD_MORE": "Load more",
    "FIRST_EVENT": "Beginning of the lead history",
    "NO_EVENTS_YET": "No events yet",
    "LEAD_SEEMS_CHANGED": "Lead seems changed, your history might not be complete",
    "RELOAD_BTN": "Refresh History"
  },
  "ka": {
    "LOAD_MORE": "იტვირთება",
    "FIRST_EVENT": "ისტორიის დასაწყისი",
    "NO_EVENTS_YET": "ჩანაწერი არ ფიქსირდება",
    "LEAD_SEEMS_CHANGED": "მოხდა ლიდში ცვლილება",
    "RELOAD_BTN": "ისტორიის განახლება"
  },
  "ru": {
    "LOAD_MORE": "Загрузить еще",
    "FIRST_EVENT": "Начало истории Лида",
    "NO_EVENTS_YET": "Никаких событий еще не было",
    "LEAD_SEEMS_CHANGED": "Лид изменен, история может быть неполной",
    "RELOAD_BTN": "Обновить историю"
  },
  "uk": {
    "LOAD_MORE": "Завантажити ще",
    "FIRST_EVENT": "Початок історії Ліда",
    "NO_EVENTS_YET": "Ніяких подій ще не було",
    "LEAD_SEEMS_CHANGED": "Лід змінено, історія може бути неповною",
    "RELOAD_BTN": "Оновити історію"
  }
}
</i18n>
