export function localeToFontFamily (locale) {
  switch (locale) {
    case 'en':
      return `lat`
    case 'ka':
      return `ka`
    case 'ru':
    case 'uk':
      return `cyr`
    default:
      return `fallback`
  }
}
