<template>
  <div class="sign-in-skeleton">
    <ui-container class="sign-in-skeleton__container">
      <div class="sign-in-skeleton__form">
        <div class="sign-in-skeleton__form-title">
          <span class="sign-in-skeleton__line">
            &nbsp;
          </span>
        </div>

        <div class="sign-in-skeleton__form-field">
          <span class="sign-in-skeleton__line">
            &nbsp;
          </span>
        </div>

        <div class="sign-in-skeleton__form-field">
          <span class="sign-in-skeleton__line">
            &nbsp;
          </span>
        </div>

        <div class="sign-in-skeleton__form-field">
          <span class="sign-in-skeleton__line">
            &nbsp;
          </span>
        </div>

        <div class="sign-in-skeleton__form-btn">
          <span class="sign-in-skeleton__line">
            &nbsp;
          </span>
        </div>
      </div>
    </ui-container>
  </div>
</template>

<script>
import { UiContainer } from '@shelf.network/ui-kit'

export default {
  name: 'sign-in-skeleton',
  components: {
    UiContainer
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/skeletons.scss";

.sign-in-skeleton {
  display: flex;
  align-items: center;
  height: 100vh;
  margin: 0;
  width: 100%;

  &__container {
    align-self: center;
    display: flex;
    flex: 1;
    align-items: center;
  }

  &__line {
    display: block;
    position: relative;

    &::after {
      content: "";

      @extend %skeleton-bg;

      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
      bottom: 10%;
      border-radius: 2em;
      display: block;
      background-attachment: unset;
    }
  }

  &__form {
    background-color: $color-white;
    padding: 3em;
    width: 30em;
    margin: 0 auto;
    box-shadow: $static-box-shadow;
    border-radius: 10px;

    @include respond-below(xs) {
      box-shadow: none;
      width: 100%;
      padding: 1.6em;
    }

    &-title {
      .sign-in-skeleton__line {
        height: 2em;
        width: 8em;
      }
    }

    &-field,
    &-btn {
      .sign-in-skeleton__line {
        height: 3em;
        width: 100%;
      }
    }

    &-field {
      margin-top: 1.6em;

      &:first-child {
        margin-top: 2em;
      }
    }

    &-btn {
      margin-top: 3.2em;
      width: 100%;
    }
  }
}
</style>
