<script>
import { config } from '@/config'

export default {
  name: 'feature',
  functional: true,
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  render (createElement, { props, children }) {
    if (!props.name || config.featureFlags[props.name]) {
      return children
    }

    return []
  }
}
</script>
