import { i18n } from 'I18n/instance'

// TODO: apply where possible

/**
 * Returns the provided name or translated 'COMMON.UNNAMED_USER_PH' key
 *
 * @param {string} [name]
 * @returns {String}
 */
export function safeUserName (name = '') {
  return name.trim() || i18n.t('COMMON.UNNAMED_USER_PH')
}
