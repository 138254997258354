<template>
  <ui-dropdown
    ref="dropdownComponent"
    @close="resetChanges"
    select
    class="lead-state-select"
    :class="{ 'lead-state-select_archived': isStateArchived }"
    :fill="isStateArchived ? 'solid' : 'frame'"
    :look="stateOptions[lead.state].look"
  >
    <div class="lead-state-select__trigger">
      <template v-if="!isStateArchived">
        <ui-spinner
          class="lead-state-select__trigger-spinner"
          type="pills"
          v-if="isLoading"
        />
        <ui-icon
          v-else
          class="lead-state-select__trigger-icon"
          icon="circle-full"
          slot="dock-left"
        />
      </template>
      <span class="lead-state-select__trigger-text">
        {{ isLoading ? $t('COMMON.SYMBOLS.ELLIPSIS') : leadStateLabel }}
      </span>
    </div>

    <div
      slot="content"
      class="lead-state-select__content"
    >
      <template v-if="askDueDate">
        <div class="lead-state-select__change">
          <div class="lead-state-select__change-to">
            <div>{{ $t('CHANGE_STATE_TO') }}</div>
            <div
              class="lead-state-select__change-to-state"
              :look="stateOptions[changes.state].look"
              fill="none"
            >
              {{ stateOptions[changes.state].label }}
            </div>
          </div>
          <div class="lead-state-select__change-calendar-title">
            {{ $t('SELECT_DUE_DATE') }}
          </div>
          <due-date-select
            :can-clear="canClearDueDate"
            :value="futureDueDate"
            @change="onChangeDate"
          />
        </div>
      </template>
      <template v-else>
        <ui-dropdown-item
          v-for="state in dropdownStateOptions"
          :key="state"
          interactive
          :active="state === lead.state"
          :look="stateOptions[state].look"
          :auto-close="!stateOptions[state].requiredDueDate"
          @click="onChangeState(state)"
          class="lead-state-select__item"
        >
          <ui-icon
            icon="circle-full"
            class="lead-state-select__item-icon"
            :class="{
              'lead-state-select__item-icon_active': state === lead.state
            }"
          />
          {{ stateOptions[state].label }}
        </ui-dropdown-item>
      </template>
    </div>
  </ui-dropdown>
</template>

<script>
import {
  UiIcon,
  UiDropdown,
  UiDropdownItem,
  UiSpinner
} from '@shelf.network/ui-kit'
import { Lead } from 'Models/Lead'
import { LeadListEntry } from 'Models/LeadListEntry'
import { validateArrayOfStrings } from 'Models/modelUtils'
import { mapActions } from 'vuex'
import { actions } from '../store/types'
import { showSuccess } from 'Utils/notifications'
import { getFirstWord } from 'Utils/stringHelpers'
import { getNullDate } from 'Utils/dateHelpers'
import DueDateSelect from 'Common/DueDateSelect'

export default {
  name: 'lead-state-select',

  components: {
    UiIcon,
    UiDropdown,
    UiDropdownItem,
    UiSpinner,
    DueDateSelect,
  },

  props: {
    lead: { type: [Lead, LeadListEntry], required: true },

    // String array of lead IDs. Leave empty to update only the provided lead,
    // if an array provided, the component will try to update entries also
    bulkSelection: {
      validator: validateArrayOfStrings(),
      default: Array,
    },
  },

  data () {
    return {
      isLoading: false,
      askDueDate: false,
      changes: {
        state: null,
        dueDate: null,
      }
    }
  },

  computed: {
    leadStateLabel () {
      if (!this.isStateArchived) {
        return this.stateOptions[this.lead.state].label + (
          this.lead.iteration > 1
            ? ` (${this.lead.iteration})`
            : ''
        )
      }

      return this.stateOptions[this.lead.state].label
    },
    futureDueDate () {
      return this.lead.dueDate > (new Date())
        ? this.lead.dueDate
        : getNullDate()
    },
    stateOptions () {
      return {
        [Lead.statesEnum.waitingCall]: {
          requiredDueDate: false,
          label: this.$t('WAITING_STATE'),
          look: 'waiting'
        },
        [Lead.statesEnum.onHold]: {
          requiredDueDate: true,
          label: this.$t('HOLD_STATE'),
          look: 'hold'
        },
        [Lead.statesEnum.lookingCar]: {
          requiredDueDate: true,
          label: this.$t('LOOKING_STATE'),
          look: 'looking'

        },
        [Lead.statesEnum.offerSent]: {
          requiredDueDate: false,
          label: this.$t('OFFERED_STATE'),
          look: 'offered'
        },
        [Lead.statesEnum.archived]: {
          requiredDueDate: false,
          label: this.$t('UNARCHIVE_STATE'),
          look: 'default'
        }
      }
    },

    dropdownStateOptions () {
      return [
        Lead.statesEnum.waitingCall,
        Lead.statesEnum.onHold,
        Lead.statesEnum.lookingCar,
        Lead.statesEnum.offerSent,
      ]
    },

    canClearDueDate () {
      return [
        Lead.statesEnum.waitingCall,
        Lead.statesEnum.offerSent,
        Lead.statesEnum.archived,
      ].includes(this.lead.state)
    },

    isStateArchived () {
      return this.lead.state === Lead.statesEnum.archived
    },
  },

  methods: {
    ...mapActions('ui/leads', {
      updateLead: actions.UPDATE_LEAD,
    }),

    resetChanges () {
      this.changes.state = null
      this.changes.dueDate = null
      this.askDueDate = false
    },

    onChangeDate (date) {
      this.changes.dueDate = date.toISOString()
      this.applyChanges()
    },

    onChangeState (state) {
      this.changes.state = state

      if (this.stateOptions[state].requiredDueDate) {
        this.askDueDate = true
      } else {
        if (
          [Lead.statesEnum.waitingCall, Lead.statesEnum.offerSent]
            .includes(this.changes.state)
        ) {
          this.changes.dueDate = getNullDate()
        }
      }

      if (this.askDueDate) return
      this.applyChanges()
    },

    async applyChanges () {
      this.$refs.dropdownComponent.$emit('close-dropdown')
      if (!this.changes.state && !this.changes.dueDate) return
      this.isLoading = true

      try {
        const params = { id: this.lead.id }

        if (this.changes.state) params.state = this.changes.state
        if (this.changes.dueDate) params.dueDate = this.changes.dueDate

        const text = this.$t('SUCCESS_NOTIFY', {
          leadName: getFirstWord(this.lead.fullName) ||
            this.$t('COMMON.UNNAMED_USER_PH')
        })
        const undo = await this.updateLead(params)
        showSuccess({ text, undoAction: undo })
      } catch (err) {
        console.error('Unable to applyChanges', err)
      }

      this.isLoading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.lead-state-select {
  min-width: 14em;

  & /deep/ .ui-dropdown__content {
    background-color: $color-white !important;
  }

  &_archived {
    /deep/ .ui-dropdown__trigger_select-like {
      padding-right: 3.5em;
    }

    /deep/ .ui-dropdown__trigger-caret {
      &::before {
        font-size: 1.4em;
        content: map-get($icons, archive);
      }
    }

    /deep/ .ui-dropdown__trigger-caret_open {
      transform: unset;
    }
  }

  &__change {
    padding: 1.25em;

    &-to {
      display: flex;
      padding: 0.5em 0;
      font-size: 1.1em;
      justify-items: center;
      justify-content: space-between;

      &-state {
        font-weight: bold;

        @include ui-can-fill(false);
      }
    }

    &-calendar-title {
      font-size: 1.1em;
      font-weight: 500;
      margin: 0.5em 0 1em 0;
      padding-top: 0.75em;
      border-top: 1px solid $color-light;
    }
  }

  &__trigger {
    white-space: nowrap;

    &-spinner {
      width: 1.1em;

      --ui-spinner-pills-width: 2px;
    }

    &-icon {
      vertical-align: middle;
      width: 1.1em;
      display: inline-block;
    }

    &-text {
      font-size: 1em;
    }
  }

  &__item {
    &-icon {
      opacity: 0;
      vertical-align: middle;
      width: 1.3em;
      display: inline-block;

      &_active {
        opacity: 1;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SELECT_DUE_DATE": "DUE DATE IS REQUIRED",
    "CHANGE_STATE_TO": "Move to:",
    "WAITING_STATE": "Waiting for call",
    "HOLD_STATE": "On-hold",
    "LOOKING_STATE": "Looking for a car",
    "OFFERED_STATE": "Offer Sent",
    "UNARCHIVE_STATE": "Unarchive",
    "SUCCESS_NOTIFY": "State for {leadName} changed",
    "SUCCESS_BULK_NOTIFY": "Leads states changed"
  },
  "ka": {
    "SELECT_DUE_DATE": "ᲓᲠᲝ ᲐᲣᲪᲘᲚᲔᲑᲔᲚᲘᲐ",
    "CHANGE_STATE_TO": "ცვლილება:",
    "WAITING_STATE": "დასარეკი",
    "HOLD_STATE": "ოდესმე",
    "LOOKING_STATE": "ძიება",
    "OFFERED_STATE": "გაგზავნილი",
    "UNARCHIVE_STATE": "აღდგენა",
    "SUCCESS_NOTIFY": "ლიდის {leadName} სტატუსი შეიცვალა",
    "SUCCESS_BULK_NOTIFY": "ლიდების სტატუსი შეიცვალა"
  },
  "ru": {
    "SELECT_DUE_DATE": "УСТАНОВИТЕ ДАТУ",
    "CHANGE_STATE_TO": "Переместить:",
    "WAITING_STATE": "В ожидании звонка",
    "HOLD_STATE": "На удержании",
    "LOOKING_STATE": "Поиск авто",
    "OFFERED_STATE": "Отправлено",
    "UNARCHIVE_STATE": "Разархивировать",
    "SUCCESS_NOTIFY": "Состояние лида {leadName} изменено",
    "SUCCESS_BULK_NOTIFY": "Состояния Лидов изменено"
  },
  "uk": {
    "SELECT_DUE_DATE": "ОБОВ'ЯЗКОВО ВСТАНОВІТЬ ДАТУ",
    "CHANGE_STATE_TO": "Перемістити:",
    "WAITING_STATE": "В очікуванні дзвінка",
    "HOLD_STATE": "На утриманні",
    "LOOKING_STATE": "Пошук автомобіля",
    "OFFERED_STATE": "Відправлено",
    "UNARCHIVE_STATE": "Розархівувати",
    "SUCCESS_NOTIFY": "Стан ліда {leadName} змінено",
    "SUCCESS_BULK_NOTIFY": "Стан Лідів змінено"
  }
}
</i18n>
