<template>
  <div class="search-item-skeleton">
    &nbsp;
  </div>
</template>

<script>
export default {
  name: 'search-item-skeleton',
}
</script>

<style lang="scss" scoped>
@import "@/styles/skeletons.scss";

.search-item-skeleton {
  @extend %skeleton-bg;

  height: 16em;

  @include respond-below(sm) {
    height: 32em;
  }
}
</style>
