import Vue from 'vue'
import { userMutations } from './types'
import { genUserState } from './state'

export default {
  [userMutations.SET_ACCOUNT_ID]: (state, pl) => { state.accountId = pl },
  [userMutations.SET_PROFILE]: (state, pl) => { state.profile = pl },
  [userMutations.SET_PLATFORM]: (state, pl) => { state.platform = pl },
  [userMutations.SET_AVATAR_LINK]: (state, link) => {
    Vue.set(state.profile, 'avatarLink', link)
  },
  [userMutations.RESET]: (state) => {
    Object.assign(state, genUserState())
  },
}
