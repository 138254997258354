<template>
  <div class="lead-comments">
    <div
      class="lead-comments__input lead-comments__input_post-new"
      :class="{
        'lead-comments__input_error': newComment.length > MAX_COMMENT_LENGTH
      }">
      <ui-textarea
        auto-height="always"
        :maxlength-soft="1000"
        :placeholder="$t('COMMENT_PH')"
        look="secondary"
        v-model="newComment"
        @focus="showPostButton = true"
        @blur="showPostButton = false"
        @keydown="postCommentKeydown"
        :is-disabled="isSaving"

      />
      <ui-button
        @click="postComment"
        class="lead-comments__input-post-new-btn"
        :class="{'lead-comments__input-post-btn_show':showPostButton}"
        :is-disabled="!isNewValid">
        <ui-icon icon="success" />
      </ui-button>
      <ui-spinner
        v-if="isSaving"
        type="pills"
        overlay
      />
    </div>

    <div
      class="lead-comments__error"
      v-if="hasError">
      {{ $t('COMMENTS_LOAD_ERROR') }}
    </div>

    <div
      class="lead-comments__scroll"
      v-if="comments && comments.length">
      <div
        class="lead-comments__item"
        :class="{'lead-comments__item_deleted': deleteId === c.id}"
        v-for="c in comments"
        :key="c.id">
        <div class="lead-comments__item-head">
          <div class="lead-comments__item-author">
            <ui-avatar
              class="lead-comments__item-author-avatar"
              :src="c.authorAvatarUrl"
              :alt="c.authorFullName"
            />
            <strong class="lead-comments__item-author-name">
              {{ c.authorFullName }}
            </strong>
            <span class="lead-comments__item-author-date">
              <ui-icon
                icon="circle-full"
                class="lead-comments__item-author-icon"
              />
              {{ c.isUpdated ? $t('UPDATED') : $t('CREATED') }}
              <time-ago :date="c.isUpdated ? c.updatedAt : c.createdAt" />
            </span>
          </div>

          <div
            class="lead-comments__item-actions"
            v-if="editCommentId === c.id">
            <ui-button
              class="lead-comments__item-btn lead-comments__item-btn_save"
              @click="saveComment"
              :is-disabled="isSaving || !isEditValid"
              fill="none"
              look="default"
            >
              <ui-icon icon="success" />
              <span class="lead-comments__item-btn-lbl">
                {{ $t('SAVE_BTN') }}
              </span>
            </ui-button>

            <ui-button
              class="lead-comments__item-btn lead-comments__item-btn_cancel"
              @click="toggleEdit(null)"
              :is-disabled="isSaving"
              fill="none"
              look="default"
            >
              <ui-icon icon="close" />
              <span class="lead-comments__item-btn-lbl">
                {{ $t('CANCEL_BTN') }}
              </span>
            </ui-button>
          </div>

          <div
            v-else-if="accountId === c.authorId"
            class="
              lead-comments__item-actions
              lead-comments__item-actions_hover
            "
          >
            <ui-button
              @click="toggleEdit(c)"
              fill="none"
              look="secondary"
              class="lead-comments__item-btn"
            >
              <ui-icon icon="pen" />
            </ui-button>

            <ui-button
              @click="deleteItem(c.id)"
              fill="none"
              look="secondary"
              class="lead-comments__item-btn"
            >
              <ui-icon icon="trash-alt" />
            </ui-button>
          </div>
        </div>

        <div
          class="lead-comments__input"
          :class="{
            'lead-comments__input_error':
              editCommentData.length > MAX_COMMENT_LENGTH
          }"
          v-if="editCommentId === c.id"
        >
          <ui-textarea
            ref="editTextarea"
            auto-height="always"
            @keydown="saveCommentKeydown"
            :maxlength-soft="1000"
            :placeholder="$t('COMMENT_PH')"
            look="secondary"
            v-model="editCommentData"
            :is-disabled="isSaving"
          />
        </div>
        <div
          v-else
          class="lead-comments__item-data"
          v-text="c.data"
          v-linkify
        />
      </div>
      <div
        class="lead-comments__end-of-list"
        v-if="hasMore">
        <ui-button
          class="lead-comments__load-more"
          look="waiting"
          fill="none"
          v-if="!isLoading"
          @click="loadMore"
        >
          {{ $t('LOAD_MORE') }}
          <ui-icon
            class="lead-comments__load-more-icon"
            icon="arrow-down" />
        </ui-button>
        <ui-spinner
          v-if="isLoading"
          type="pills" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  UiTextarea,
  UiButton,
  UiAvatar,
  UiSpinner,
  UiIcon,
} from '@shelf.network/ui-kit'
import { mapActions, mapGetters } from 'vuex'
import { actions } from '../store/types'
import linkify from 'vue-linkify'
import TimeAgo from 'Common/TimeAgo'
import { confirmAction } from 'Utils/confirmAction'
import { userGetters } from 'Store/entities/User/types'
import { MAX_COMMENT_LENGTH } from '../store/constants'

export default {
  name: 'lead-comments',
  components: { UiTextarea, UiAvatar, UiButton, UiSpinner, TimeAgo, UiIcon },
  directives: { linkify },
  props: { leadId: { type: String, required: true } },

  data () {
    return {
      editCommentId: '',
      editCommentData: '',
      newComment: '',
      isSaving: false,
      deleteId: '',
      showPostButton: false,
      MAX_COMMENT_LENGTH
    }
  },

  computed: {
    ...mapGetters('ui/leads', {
      getCommentsByLeadId: 'getCommentsByLeadId',
    }),

    ...mapGetters('entities/user', {
      accountId: userGetters.ACCOUNT_ID,
      isBroker: userGetters.IS_BROKER,
    }),

    isNewValid () {
      const text = this.newComment.trim()
      return Boolean(text) && text.length <= MAX_COMMENT_LENGTH
    },

    isEditValid () {
      const text = this.editCommentData.trim()
      return Boolean(text) && text.length <= MAX_COMMENT_LENGTH
    },

    hasMore () {
      const state = this.getCommentsByLeadId(this.leadId)
      return Boolean(state.fetchNext && state.totalItems > state.items.length)
    },

    hasError () {
      return Boolean(this.getCommentsByLeadId(this.leadId).hasError)
    },

    comments () {
      return this.getCommentsByLeadId(this.leadId).items
    },

    isLoading () {
      return this.getCommentsByLeadId(this.leadId).isLoading
    }
  },
  watch: {
    leadId: {
      immediate: true,
      handler (leadId, oldLeadId) {
        if (!leadId || leadId === oldLeadId || this.comments) return
        this.isSaving = false
        this.deleteId = ''
        this.showPostButton = false
        this.toggleEdit(null)
        this.loadComments(leadId)
      }
    },
  },
  methods: {
    ...mapActions('ui/leads', {
      loadComments: actions.LOAD_COMMENTS,
      loadMoreComments: actions.LOAD_MORE_COMMENTS,
      post: actions.POST_COMMENT,
      delete: actions.DELETE_COMMENT,
      update: actions.UPDATE_COMMENT,
    }),
    toggleEdit (comment) {
      this.editCommentId = comment ? comment.id : ''
      this.editCommentData = comment ? comment.data : ''
      if (comment) {
        this.$nextTick(() => {
          if (this.$refs.editTextarea[0]) {
            this.$refs.editTextarea[0].$refs.textarea.focus()
          }
        })
      }
    },
    async deleteItem (id) {
      if (!await confirmAction(this.$t('CONFIRM_COMMENT_DELETE'))) return

      this.deleteId = id
      await this.delete({ commentId: id, leadId: this.leadId })
      this.deleteId = ''
    },
    async saveCommentKeydown (e) {
      if (e.key !== 'Enter' || e.shiftKey) return
      e.preventDefault()
      e.target.blur()
      this.saveComment()
      e.target.focus()
    },
    async saveComment () {
      if (!this.isEditValid) return

      this.isSaving = true
      const { leadId, editCommentId, editCommentData } = this

      await this.update({
        leadId,
        commentId: editCommentId,
        data: editCommentData.trim()
      })

      this.editCommentId = ''
      this.editCommentData = ''
      this.isSaving = false
    },
    async postCommentKeydown (e) {
      if (!this.isNewValid) return
      if (e.key === 'Enter' && (e.shiftKey || e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        e.target.blur()
        await this.postComment()
        e.target.focus()
      }
    },
    async postComment () {
      if (!this.isNewValid) return

      this.isSaving = true
      const { leadId, newComment } = this

      await this.post({
        leadId,
        data: newComment.trim()
      })

      this.newComment = ''
      this.isSaving = false
    },
    loadMore () {
      this.loadMoreComments(this.leadId)
    }
  }
}
</script>

<style scoped lang="scss">
.lead-comments {
  padding: 1.5em 0 0 0;

  &__input {
    flex: 0 1 auto;
    display: flex;
    align-items: flex-end;
    margin-left: 3.25em;
    position: relative;

    &_error {
      & /deep/ .ui-textarea__input[look="secondary"] {
        box-shadow: 0 0 1px 1px red !important;
      }
    }

    &_post-new {
      margin: 0 2.5em 1.5em 2.5em;
      display: flex;
      align-items: stretch;
      position: relative;

      & /deep/ .ui-button {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 4.5em;

        &__button {
          border: 0 none !important;
          min-height: 100%;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }

    // todo: probably hack for ui-kit
    & /deep/ .ui-textarea__input[look="secondary"] {
      height: calc(3.2em + 2px);
    }

    & /deep/ .ui-textarea {
      flex: 1;

      &__lbl_counter {
        right: 6em;
        font-size: 1em;
        color: #fff;
        background: $color-flag-is-error;
        padding: 0.5em 1em;
        border-radius: 1em;
      }

      &__input {
        border: 0 none;
        max-height: 10em;
        height: 3.2em;
        min-height: 3.2em;
        padding: 1em 6em 1em 1.5em;
        line-height: 1.4em;
        border-radius: 1em;
        background-color: $color-light-grey !important;
        color: $color-dark !important;
      }
    }
  }

  &__scroll {
    padding: 0 2.5em;
    max-height: 75vh;
    border-top: 1px solid $color-grey;
    overflow: auto;

    @include scrollbar-awesome-bold();

    &::before {
      display: block;
      height: 2em;
      content: "";
    }
  }

  &__item {
    margin: 0 0 2em 0;

    &_deleted {
      transition: opacity ease-out 200ms;
      opacity: 0;
      visibility: hidden;
      color: red;
    }

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-author {
      margin-right: auto;
      display: flex;
      align-items: center;

      &-avatar {
        font-size: 2.5em !important;
        margin-bottom: -0.25em;
      }

      &-name {
        margin-left: 0.75em;
      }

      &-icon {
        font-size: 0.7em;
        margin: 0 0.45em;
      }

      &-date {
        font-size: 0.9em;
        color: $color-ui-secondary;
      }
    }

    &-btn {
      &-lbl {
        margin-left: 0.5em;
      }

      &:hover &-lbl {
        text-decoration: underline;
      }

      &_save /deep/ .ui-button__button {
        color: $color-sys-success !important;
      }

      &_cancel /deep/ .ui-button__button {
        color: $color-sys-warning !important;
      }

      & /deep/ .ui-button__button {
        padding: 0.5em 1em;
        min-height: 3em;
        display: flex;
        align-items: center;
      }

      & /deep/ .ui-icon {
        font-size: 1.2em;
      }
    }

    &-data {
      display: block;
      white-space: pre-line;
      word-break: break-word;
      margin-left: 3.25em;
      margin-top: 0.35em;

      & /deep/ .linkified {
        color: $color-sys-info;
      }
    }
  }

  &__end-of-list {
    display: block;
    padding: 1em 2.5em 1em 2.5em;
  }

  &__load-more {
    margin: 0 2em 0 0;

    & /deep/ .ui-button__button {
      padding-left: 0;
      padding-right: 0;
    }

    &-icon {
      transform: rotate(180deg);
      margin-left: 0.5em;
    }
  }
}

</style>

<i18n>
{
  "en": {
    "LOAD_MORE": "Load more",
    "COMMENT_PH": "Mark everything about the client",
    "CONFIRM_COMMENT_DELETE": "Are you sure that you want to delete this comment?",
    "CREATED": "Created",
    "UPDATED": "Edited",
    "SAVE_BTN": "Save Changes",
    "CANCEL_BTN": "Cancel"
  },
  "ka": {
    "LOAD_MORE": "იტვირთება",
    "COMMENT_PH": "დატოვე კლიენტზე კომენტარები",
    "CONFIRM_COMMENT_DELETE": "დატოვე კლიენტზე კომენტარები",
    "CREATED": "შექმნილია",
    "UPDATED": "შეცვლილი",
    "SAVE_BTN": "შენახვა",
    "CANCEL_BTN": "უარყოფა"
  },
  "ru": {
    "LOAD_MORE": "Загрузить еще",
    "COMMENT_PH": "Заметка о клиенте",
    "CONFIRM_COMMENT_DELETE": "Вы уверены, что хотите удалить этот комментарий?",
    "CREATED": "Создано",
    "UPDATED": "Отредактировано",
    "SAVE_BTN": "Сохранить",
    "CANCEL_BTN": "Отмена"
  },
  "uk": {
    "LOAD_MORE": "Завантажити ще",
    "COMMENT_PH": "Примітка про клієнта",
    "CONFIRM_COMMENT_DELETE": "Ви впевнені, що хочете видалити цей коментар?",
    "CREATED": "Створено",
    "UPDATED": "Відредаговано",
    "SAVE_BTN": "Зберегти",
    "CANCEL_BTN": "Скасувати"
  }
}
</i18n>
