<template>
  <div class="list-params-deposit-state list-params-row">
    <label class="list-params-row__label">
      {{ label || $t('DEPOSIT_STATE_LBL') }}
    </label>

    <ui-select
      class="list-params-row__field"
      fill="frame"
      look="secondary"
      :options="options"
      :placeholder="placeholder || $t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
      :null-option="nullOption"
      :value="value || defaultValue"
      @input="onInput"
    />
  </div>
</template>

<script>
import { UiSelect } from '@shelf.network/ui-kit'
import { Deposit } from 'Models/Deposit'

export default {
  name: 'list-params-deposit-state',
  components: { UiSelect },

  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    defaultValue: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },

  computed: {
    nullOption () {
      return {
        value: '',
        label: this.$t('COMMON.SELECT_FIELD.RESET_OPT'),
      }
    },

    options () {
      return [
        {
          value: Deposit.statesEnum.pending,
          label: this.$t('STATE_PENDING')
        },
        {
          value: Deposit.statesEnum.paid,
          label: this.$t('STATE_PAID')
        },
        {
          value: Deposit.statesEnum.returned,
          label: this.$t('STATE_RETURNED')
        },
        {
          value: Deposit.statesEnum.returning,
          label: this.$t('STATE_RETURNING')
        },
        {
          value: Deposit.statesEnum.feeCharged,
          label: this.$t('STATE_FEE_CHARGED')
        },
        {
          value: Deposit.statesEnum.failed,
          label: this.$t('STATE_FAILED')
        },
        {
          value: Deposit.statesEnum.reversed,
          label: this.$t('STATE_REVERSED')
        },
        {
          value: Deposit.statesEnum.locked,
          label: this.$t('STATE_LOCKED')
        },
        {
          value: Deposit.statesEnum.requestedWithdrawal,
          label: this.$t('STATE_REQUESTED_WITHDRAWAL')
        },
      ]
    },
  },

  methods: {
    onInput (value) {
      this.$emit('input', value === this.defaultValue ? '' : value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./styles/list-params-row.scss";
</style>

<i18n>
{
  "en": {
    "DEPOSIT_STATE_LBL": "BY DEPOSIT STATE",
    "STATE_PENDING": "Pending",
    "STATE_PAID": "Holding",
    "STATE_RETURNED": "Returned",
    "STATE_RETURNING": "Returning",
    "STATE_FEE_CHARGED": "Fee charged",
    "STATE_FAILED": "Failed",
    "STATE_REVERSED": "Reversed",
    "STATE_LOCKED": "Holding(Attached)",
    "STATE_REQUESTED_WITHDRAWAL": "Requested withdrawal"
  },
  "ka": {
    "DEPOSIT_STATE_LBL": "ᲓᲔᲞᲝᲖᲘᲢᲘᲡ ᲡᲢᲐᲢᲣᲡᲘᲗ",
    "STATE_PENDING": "მუშავდება",
    "STATE_PAID": "Holding",
    "STATE_RETURNED": "დაბრუნებული",
    "STATE_RETURNING": "მუშავდება",
    "STATE_FEE_CHARGED": "ჩამოჭრილი",
    "STATE_FAILED": "უარყოფილი",
    "STATE_REVERSED": "სრულად დაბრუნებული",
    "STATE_LOCKED": "Holding(Attached)",
    "STATE_REQUESTED_WITHDRAWAL": "გატანის მოთხოვნა"
  },
  "ru": {
    "DEPOSIT_STATE_LBL": "ПО СОСТОЯНИЮ ДЕПОЗИТА",
    "STATE_PENDING": "Ожидается",
    "STATE_PAID": "На удержании",
    "STATE_RETURNED": "Возвращен",
    "STATE_RETURNING": "Возвращается",
    "STATE_FEE_CHARGED": "Стянута комиссия",
    "STATE_FAILED": "Ошибка",
    "STATE_REVERSED": "Аннулирован",
    "STATE_LOCKED": "Прикреплен",
    "STATE_REQUESTED_WITHDRAWAL": "Запрошен вывод"
  },
  "uk": {
    "DEPOSIT_STATE_LBL": "ЗА СТАНОМ ДЕПОЗИТУ",
    "STATE_PENDING": "Очікується",
    "STATE_PAID": "На утриманні",
    "STATE_RETURNED": "Повернений",
    "STATE_RETURNING": "Повертається",
    "STATE_FEE_CHARGED": "Комісію стягнуто",
    "STATE_FAILED": "Помилка",
    "STATE_REVERSED": "Анульовано",
    "STATE_LOCKED": "Прикріплено",
    "STATE_REQUESTED_WITHDRAWAL": "Запрошено виведення"
  }
}
</i18n>
