import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'
import Skeleton from './components/Skeleton'

const moduleName = 'sign-in'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: Skeleton,
  importComponent: async () => import('./SignIn'),
})
