<template>
  <ui-dropdown
    select
    class="lead-broker-select"
    :class="{
      'lead-broker-select_strictly-assigned': lead.isStrictlyAssigned,
    }"
  >
    <div class="lead-broker-select__current">
      <template v-if="lead.brokerId">
        <img
          class="lead-broker-select__current-ava"
          v-bind="currentBrokerAvatarSrc"
        >
        <div class="lead-broker-select__current-name">
          {{ lead.brokerFullName }}
        </div>
      </template>
      <div
        v-else
        class="lead-broker-select__current-name"
      >
        {{ $t('ASSIGN_TO_PH') }}
      </div>
    </div>
    <div slot="content">
      <ui-dropdown-item class="lead-broker-select__item">
        <ui-text
          class="lead-broker-select__item-search"
          v-model="filter"
          look="secondary"
          fill="frame"
          :placeholder="$t('SEARCH_PH')"
        />
      </ui-dropdown-item>
      <ui-dropdown-item
        auto-close
        interactive
        v-for="item in mappedBrokers"
        :key="item.value"
        class="lead-broker-select__item"
        @click="onChoose(item.value)"
      >
        <img
          class="lead-broker-select__item-ava"
          v-bind="item.avatarSrc"
        >
        <div class="lead-broker-select__item-name">
          {{ item.label }}
        </div>
      </ui-dropdown-item>
    </div>
  </ui-dropdown>
</template>

<script>
import {
  UiDropdown,
  UiDropdownItem,
  UiText,
  breakpoints
} from '@shelf.network/ui-kit'
import { Lead } from 'Models/Lead'
import { LeadListEntry } from 'Models/LeadListEntry'
import { validateArrayOfStrings } from 'Models/modelUtils'
import { mapActions, mapGetters } from 'vuex'
import { actions } from '../store/types'
import unassignIcoPath from 'Assets/icons/unassign.svg'
import avaPh32Path from 'Assets/icons/ava-ph-32.svg'
import { showSuccess, showError } from 'Utils/notifications'
import { getFirstWord } from 'Utils/stringHelpers'
import { brokersGetters } from 'Store/entities/Brokers/types'
import { generateImageProps } from 'Utils/generateImageProps'
import { compareAlphabetically } from 'Utils/arrayHelpers'

export default {
  name: 'lead-broker-select',

  components: {
    UiDropdown, UiDropdownItem, UiText
  },

  props: {
    lead: { type: [Lead, LeadListEntry], required: true },

    // String array of lead IDs. Leave empty to update only the provided lead,
    // if an array provided, the component will try to update entries also
    bulkSelection: {
      validator: validateArrayOfStrings(),
      default: Array,
    },
  },

  data () {
    return {
      avaPh32Path,
      filter: ''
    }
  },

  computed: {
    ...mapGetters({
      brokers: `entities/brokers/${brokersGetters.BROKERS}`,
      leadById: 'ui/leads/leadById',
    }),
    currentBrokerAvatarSrc () {
      if (this.lead.brokerAvatarLink) {
        return generateImageProps({
          alt: this.lead.brokerFullName,
          src: this.lead.brokerAvatarLink,
          srcSetOptions: [
            { width: 32, height: 32 },
            { width: 48, height: 48 },
          ],
          sizes: [
            `(max-width: ${breakpoints.SM}px) 3vw`,
            '2vw',
          ].join(', '),
          progressive: false,
        })
      } else {
        return { src: avaPh32Path }
      }
    },
    mappedBrokers () {
      return [
        {
          value: 'UNASSIGN',
          label: this.$t('UNASSIGN_OPT'),
          selectedLabel: this.$t('ASSIGN_TO_PH'),
          avatarSrc: { src: unassignIcoPath },
        },
        ...this.brokers.map(item => {
          return {
            value: item.id,
            label: item.fullName || this.$t('COMMON.UNNAMED_USER_PH'),
            avatarSrc: item.avatarLink
              ? generateImageProps({
                alt: item.fullName,
                src: item.avatarLink,
                srcSetOptions: [
                  { width: 32, height: 32 },
                  { width: 48, height: 48 },
                ],
                sizes: [
                  `(max-width: ${breakpoints.SM}px) 3vw`,
                  '2vw',
                ].join(', '),
                progressive: false,
              })
              : { src: avaPh32Path }
          }
        })
          .filter(item => !this.filter || item.label.includes(this.filter))
          .sort((a, b) => compareAlphabetically(a.label, b.label))
      ]
    }
  },

  methods: {
    ...mapActions('ui/leads', {
      updateLead: actions.UPDATE_LEAD,
      updateLeads: actions.UPDATE_LEADS,
    }),

    onChoose (brokerId) {
      this.filter = ''

      const isBulkChoose =
        this.bulkSelection.length > 1 &&
        this.bulkSelection.includes(this.lead.id)
      if (isBulkChoose) {
        this.handleBulkChoose(brokerId)
        return
      }

      this.handleSingleChoose(brokerId)
    },

    handleSingleChoose (brokerId) {
      if (brokerId === 'UNASSIGN') {
        this.unassignBroker()
        return
      }

      this.assignBroker(brokerId)
    },

    async assignBroker (brokerId) {
      if (this.lead.brokerId === brokerId) return
      if (!this.confirmStrictViolation([this.lead.id], brokerId)) return

      const successMsg = this.$t(
        'ASSIGNED_SUCCESS_NOTIFY',
        this.getNames(brokerId, this.lead)
      )

      try {
        const undo = await this.updateLead({ id: this.lead.id, brokerId })
        showSuccess({ text: successMsg, undoAction: undo })
      } catch (error) {
        showError(this.$t('FAILED_NOTIFY'))
      }
    },

    async unassignBroker () {
      if (!this.lead.brokerId) return
      if (!this.confirmStrictViolation([this.lead.id], '')) return

      const successMsg = this.$t(
        'UNASSIGNED_SUCCESS_NOTIFY',
        this.getNames(this.lead.brokerId, this.lead)
      )

      try {
        const undo = await this.updateLead({ id: this.lead.id, brokerId: '' })
        showSuccess({ text: successMsg, undoAction: undo })
      } catch (error) {
        showError(this.$t('FAILED_NOTIFY'))
      }
    },

    async handleBulkChoose (brokerId) {
      if (!this.confirmStrictViolation(this.bulkSelection, brokerId)) return

      let successMsg
      if (brokerId === 'UNASSIGN') {
        successMsg = this.$t('UNASSIGNED_BULK_SUCCESS_NOTIFY')
        brokerId = ''
      } else {
        successMsg = this.$t('ASSIGNED_BULK_SUCCESS_NOTIFY')
      }

      try {
        const undo = await this.updateLeads(
          this.bulkSelection.map(id => ({ id, brokerId }))
        )
        showSuccess({ text: successMsg, undoAction: undo })
      } catch (error) {
        showError(this.$t('FAILED_NOTIFY'))
      }
    },

    getNames (brokerId, lead) {
      const broker = this.brokers.find(br => br.id === brokerId)
      return {
        brokerName: getFirstWord(broker.fullName) ||
          this.$t('COMMON.UNNAMED_USER_PH'),
        leadName: getFirstWord(lead.fullName) ||
          this.$t('COMMON.UNNAMED_USER_PH'),
      }
    },

    confirmStrictViolation (leadIdsToCheck, brokerIdToAssign) {
      const strictAssignedLeads = leadIdsToCheck
        .map(id => this.leadById(id))
        .filter(el => el.isStrictlyAssigned && el.brokerId !== brokerIdToAssign)

      let confirmMsg
      if (strictAssignedLeads.length === 1) {
        const name = getFirstWord(strictAssignedLeads[0].fullName)
        confirmMsg = this.$t('CONFIRM_REASSIGN_STRICT_LEAD', { name })
      } else if (strictAssignedLeads.length > 1) {
        confirmMsg = this.$t('CONFIRM_REASSIGN_STRICT_LEADS')
      }

      return confirmMsg ? confirm(confirmMsg) : true
    }
  }
}
</script>

<style lang="scss" scoped>
.lead-broker-select {
  &_strictly-assigned {
    /deep/ .ui-dropdown__trigger-caret {
      line-height: 1;

      &_open {
        transform: unset;
      }

      &::before {
        position: relative;
        top: -0.1ch;
        content: "\01F512" !important;
      }
    }
  }

  &__current {
    display: flex;
    align-items: center;
    min-width: 0;

    &-ava {
      width: 1.75em;
      height: 1.75em;
      margin-right: 0.5em;
      border-radius: 50%;
    }

    &-name {
      @include ellipsis();
    }
  }

  &__item-search {
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    min-width: 0;

    &-ava {
      width: 1.75em;
      height: 1.75em;
      margin-right: 0.5em;
      border-radius: 50%;
    }

    &-name {
      @include ellipsis();
    }
  }
}
</style>

<i18n>
{
  "en": {
    "ASSIGN_TO_PH": "Assign to",
    "ASSIGNED_SUCCESS_NOTIFY": "Assigned {brokerName} to {leadName}",
    "UNASSIGNED_SUCCESS_NOTIFY": "Unassigned {brokerName} from {leadName}",
    "ASSIGNED_BULK_SUCCESS_NOTIFY": "Brokers assigned",
    "UNASSIGNED_BULK_SUCCESS_NOTIFY": "Brokers unassigned",
    "FAILED_NOTIFY": "Cannot change broker. Please try again later or contact the system owner.",
    "CONFIRM_REASSIGN_STRICT_LEAD": "{name} has broker strictly assigned. Are you sure you want to reassign it?",
    "CONFIRM_REASSIGN_STRICT_LEADS": "Some leads have broker strictly assigned. Are you sure you want to reassign them?",
    "STRICTLY_ASSIGNED_HINT": "Broker strictly assigned",
    "UNASSIGN_OPT": "Unassign",
    "SEARCH_PH": "Search"
  },
  "ka": {
    "ASSIGN_TO_PH": "მიმაგრება",
    "ASSIGNED_SUCCESS_NOTIFY": "ბროკერი {brokerName} მიემაგრა {leadName}",
    "UNASSIGNED_SUCCESS_NOTIFY": "ბროკერი {brokerName} ჩამოშორდა {leadName}",
    "ASSIGNED_BULK_SUCCESS_NOTIFY": "ბროკერი მიმაგრებულია",
    "UNASSIGNED_BULK_SUCCESS_NOTIFY": "ბროკერი წაშლილია",
    "FAILED_NOTIFY": "ბროკერი ვერ შეიცვალა. მოგვიანებით სცადეთ ან დაუკავშირდით ადმინისტრატორს.",
    "CONFIRM_REASSIGN_STRICT_LEAD": "{name} ლიდი ავტომატურად მიმაგრებულია. გსურთ მისი გადაწერა?",
    "CONFIRM_REASSIGN_STRICT_LEADS": "რამდენიმე ლიდი ავტომატურად მიმაგრებულია. გსურთ მათი გადაწერა?",
    "STRICTLY_ASSIGNED_HINT": "ბროკერი ავტომატურად მიმაგრებულია",
    "UNASSIGN_OPT": "გასუფთავება",
    "SEARCH_PH": "ძიება"
  },
  "ru": {
    "ASSIGN_TO_PH": "Назначить на",
    "ASSIGNED_SUCCESS_NOTIFY": "{brokerName} был назначен на {leadName}",
    "UNASSIGNED_SUCCESS_NOTIFY": "Назначение {brokerName} на {leadName} отменено",
    "ASSIGNED_BULK_SUCCESS_NOTIFY": "Брокеры назначены",
    "UNASSIGNED_BULK_SUCCESS_NOTIFY": "Брокеры сняты",
    "FAILED_NOTIFY": "Невозможно сменить брокера. Пожалуйста, попробуйте позже или свяжитесь с владельцем системы.",
    "CONFIRM_REASSIGN_STRICT_LEAD": "{имя} имеет строго назначенного брокера. Вы уверены, что хотите переназначить его?",
    "CONFIRM_REASSIGN_STRICT_LEADS": "Несколько лидов имеют строго назначенных брокеров. Вы уверены, что хотите переназначить их?",
    "STRICTLY_ASSIGNED_HINT": "Брокер строго назначен",
    "UNASSIGN_OPT": "Отменить назначение",
    "SEARCH_PH": "Поиск"
  },
  "uk": {
    "ASSIGN_TO_PH": "Призначити на",
    "ASSIGNED_SUCCESS_NOTIFY": "{brokerName} був призначений на {leadName}",
    "UNASSIGNED_SUCCESS_NOTIFY": "Призначення {brokerName} на {leadName} скасовано",
    "ASSIGNED_BULK_SUCCESS_NOTIFY": "Брокери призначені",
    "UNASSIGNED_BULK_SUCCESS_NOTIFY": "Брокери зняті",
    "FAILED_NOTIFY": "Неможливо змінити брокера. Повторіть спробу пізніше або зв’яжіться з власником системи.",
    "CONFIRM_REASSIGN_STRICT_LEAD": "{name} має суворо призначеного брокера. Ви впевнені, що хочете перепризначити його?",
    "CONFIRM_REASSIGN_STRICT_LEADS": "Кілька лідів мають суворо призначених брокерів. Ви впевнені, що хочете перепризначити їх?",
    "STRICTLY_ASSIGNED_HINT": "Брокера суворо призначено",
    "UNASSIGN_OPT": "Скасувати призначення",
    "SEARCH_PH": "Пошук"
  }
}
</i18n>
