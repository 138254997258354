<template>
  <ui-dropdown
    class="locale-switcher"
    ref="dropdown"
    bubble
    caret
  >
    <img
      class="locale-switcher__placeholder-icon"
      :src="selectedLocale.icon"
    >
    <ul
      class="locale-switcher__list"
      slot="content"
    >
      <li
        class="locale-switcher__item"
        :class="{
          'locale-switcher__item_selected': locale.isoCode === $i18n.locale
        }"
        v-for="locale in Object.values(AVAILABLE_LOCALES)"
        :key="locale.text"
      >
        <a
          class="locale-switcher__link"
          @click.prevent="changeLanguage(locale.isoCode)"
        >
          <img
            class="locale-switcher__icon"
            :src="locale.icon"
          >

          <span class="locale-switcher__text">
            {{ $t(`COMMON.LOCALES.${locale.text}`) }}
          </span>
        </a>
      </li>
    </ul>
  </ui-dropdown>
</template>

<script>
import { UiDropdown } from '@shelf.network/ui-kit'

import { mapActions } from 'vuex'
import { userActions } from 'Store/entities/User/types'

import { AVAILABLE_LOCALES } from 'Constants/locales'

export default {
  name: 'locale-switcher',
  components: {
    UiDropdown
  },

  data () {
    return {
      AVAILABLE_LOCALES
    }
  },

  computed: {
    selectedLocale () {
      return AVAILABLE_LOCALES[this.$i18n.locale] || {}
    }
  },

  methods: {
    ...mapActions('entities/user', {
      changeLocale: userActions.CHANGE_LOCALE
    }),

    changeLanguage (locale) {
      this.changeLocale(locale)
      this.$refs.dropdown.closeDropdown()
    }
  }
}
</script>
<style lang="scss" scoped>
.locale-switcher {
  position: relative;
  min-height: 0;

  & /deep/ .ui-dropdown {
    &__trigger {
      position: relative;
      cursor: pointer;
      display: flex;
    }

    &__content {
      min-width: max-content;
      margin-top: 10px;
      z-index: z-index(100);

      &::before {
        left: 43%;
      }

      @include respond-below(sm) {
        left: unset;
        right: 0;
        transform: none;

        &::before {
          right: 1em;
          left: unset;
        }
      }
    }
  }

  &__placeholder-icon {
    width: 18px;
    height: 18px;
    display: block;
    border: 1px solid $color-light-grey;
  }

  &__list {
    min-width: max-content;
    list-style: none;
    margin: 5px 0;
    padding: 0;
    transform: none !important;
  }

  &__item {
    &_selected {
      background-color: transparentize($color-ui-primary, 0.8);
    }
  }

  &__link {
    padding: 5px 15px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
  }

  &__icon {
    width: 28px;
    height: 28px;
    border: 1px solid $color-light-grey;
  }

  &__icon,
  &__placeholder-icon {
    border-radius: 50%;
  }

  &__text {
    line-height: 29px;
    padding-left: 10px;
  }
}
</style>
