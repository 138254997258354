import uuid from 'uuid/v4'

let workerInstance

export default async function cropImage (
  file,
  { height, width, jpegQuality }
) {
  if (!workerInstance) {
    const { default: CropImageWorker } = await import(
      /* webpackPrefetch: true */
      'Workers/cropImage'
    )
    workerInstance = new CropImageWorker()
  }

  const id = uuid()

  // eslint-disable-next-line promise/avoid-new
  return new Promise((resolve, reject) => {
    workerInstance.onmessage = e => {
      if (e.data.id === id) {
        e.data.error
          ? reject(e.data.error)
          : resolve(e.data.blob)
      }
    }
    workerInstance.onerror = e => {
      if (e.data.id === id) {
        reject(e)
      }
    }

    workerInstance.postMessage({ id, file, width, height, jpegQuality })
  })
}
