<template>
  <div class="lead-lot-calculator-config">
    <div class="lead-lot-calculator-config__inner">
      <ui-select
        class="lead-lot-calculator-config__field"
        :options="yearOptions"
        :value="year"
        @input="$emit('update:year', $event)"
      />

      <div class="lead-lot-calculator-config__field-divider" />

      <ui-select
        class="lead-lot-calculator-config__field"
        :options="fuelTypeOptions"
        :value="fuelType"
        @input="$emit('update:fuelType', $event)"
      />

      <div class="lead-lot-calculator-config__field-divider" />

      <ui-select
        class="lead-lot-calculator-config__field"
        :options="engineVolumeOptions"
        :value="engineVolume"
        :is-disabled="fuelType === 'ELECTRIC'"
        @input="$emit('update:engineVolume', $event)"
      />

      <div class="lead-lot-calculator-config__field-divider" />

      <span
        class="lead-lot-calculator-config__field"
        :class="{
          'lead-lot-calculator-config__field_loading': isLocationsLoading
        }"
      >
        {{ formattedDeparture }}
      </span>

      <div class="lead-lot-calculator-config__field-divider" />

      <span
        class="lead-lot-calculator-config__field"
        :class="{
          'lead-lot-calculator-config__field_loading': isLocationsLoading
        }"
      >
        {{ formattedArrival }}
      </span>

      <!-- to balance justify-content alignment -->
      <div class="lead-lot-calculator-config__field-divider-terminator" />
    </div>
  </div>
</template>

<script>
import { UiSelect } from '@shelf.network/ui-kit'
import { mapGetters } from 'vuex'
import { enumsGetters } from 'Store/entities/Enums/types'
import { formatLocation } from 'Utils/formatLocation'

export default {
  name: 'lead-lot-calculator-config',

  components: {
    UiSelect,
  },

  props: {
    year: {
      type: String,
      default: '',
    },

    fuelType: {
      type: String,
      default: '',
    },

    engineVolume: {
      type: String,
      default: '',
    },

    departureLocation: {
      type: Object,
      default: () => ({ country: '', city: '' }),
      validator (value) {
        return value.hasOwnProperty('country') && value.hasOwnProperty('city')
      },
    },

    arrivalLocation: {
      type: Object,
      default: () => ({ country: '', city: '' }),
      validator (value) {
        return value.hasOwnProperty('country') && value.hasOwnProperty('city')
      },
    },

    isLocationsLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('entities/enums', {
      yearOptions: enumsGetters.YEARS_AS_OPTIONS,
      engineVolumeOptions: enumsGetters.ENGINE_VOLUMES_AS_OPTIONS,
      fuelTypeOptions: enumsGetters.FUELS_AS_OPTIONS,
    }),

    formattedDeparture () {
      return formatLocation(this.departureLocation)
    },

    formattedArrival () {
      return formatLocation(this.arrivalLocation)
    },
  },
}
</script>

<style lang="scss" scoped>
.lead-lot-calculator-config {
  border-radius: 1em;
  background: $color-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 1em 1.75em;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: hidden;
    overflow-x: auto;

    @include scrollbar-invisible();

    // hack to prevent cut of child (in slot) dropdowns
    padding-bottom: 100vh;
    margin-bottom: -100vh;
  }

  &__field {
    font-size: 0.928571em; // 13px when font-size: 14px;
    white-space: nowrap;
    line-height: 1.5;

    &_loading {
      @include loading-fog();
    }

    & /deep/ .ui-select__wrap,
    & /deep/ .ui-select__button {
      font-size: inherit;
    }

    & /deep/ .ui-select__dropdown {
      max-width: unset;
    }

    & /deep/ .ui-button__button[fill][look] {
      border: none;
      padding-right: 2em;
    }

    & /deep/ .ui-select__dock_caret {
      width: 1em;

      &::before {
        content: "\E903";
        transform: rotate(180deg);
      }
    }

    &-divider {
      height: 2.85em;
      width: 1px;
      margin: 0 1em;
      background: $color-light-grey;
      flex-shrink: 0;
    }

    &-divider-terminator {
      height: 2.85em;
      width: 1px;
      margin: 0 0 0 1em;
      background: transparent;
      flex-shrink: 0;
    }
  }
}
</style>
