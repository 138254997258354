import { ModelBase } from './ModelBase'
import { Contact } from './Contact'
import get from 'lodash/get'
import { leadRequestStates } from '@shelf.network/js-sdk'

export class SupportRequest extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.createdAt = new Date(raw.createdAt)
    this.state = this._str(raw.state || '')

    this.platformId = get(raw, 'relationships.platform.id', '')

    const contact = ModelBase.modelFromCache(
      Contact,
      get(raw, 'relationships.contact'),
      cache
    )
    this.contactPhone = contact.channel === Contact.channelsEnum.phone
      ? contact.channelAddress : ''
    this.contactEmail = contact.channel === Contact.channelsEnum.email
      ? contact.channelAddress : ''
    this.contactWebsite = contact.channel === Contact.channelsEnum.website
      ? contact.channelAddress : ''
  }

  static get statesEnum () {
    return {
      active: leadRequestStates.active,
      resolved: leadRequestStates.resolved,
    }
  }
}
