export const ACTIVE_TRADES_URL_PARAM_TABS = Object.freeze({
  playing: 'playing',
  approval: 'approval',
  lost: 'lost',
})

export const ACTIVE_TRADES_QUERY_SORTS = Object.freeze({
  createdAt: 'created_at',
  createdAtDesc: '-created_at',
  endTime: 'end_time',
  endTimeDesc: '-end_time',
  lotCreatedAt: 'lot.created_at',
  lotCreatedAtDesc: '-lot.created_at',
  lotEndTime: 'lot.end_time',
  lotEndTimeDesc: '-lot.end_time',
})

export const ACTIVE_TRADES_LOTS_TABS = Object.freeze([
  ACTIVE_TRADES_URL_PARAM_TABS.playing,
  ACTIVE_TRADES_URL_PARAM_TABS.approval,
])

export const ACTIVE_TRADES_TAB_DEFAULT_SORT = Object.freeze({
  [ACTIVE_TRADES_URL_PARAM_TABS.playing]:
    ACTIVE_TRADES_QUERY_SORTS.endTime,
  [ACTIVE_TRADES_URL_PARAM_TABS.approval]:
    ACTIVE_TRADES_QUERY_SORTS.endTimeDesc,
  [ACTIVE_TRADES_URL_PARAM_TABS.lost]:
    ACTIVE_TRADES_QUERY_SORTS.endTimeDesc,
})

export const LOT_TO_PARTICIPATION_SORT = Object.freeze({
  [ACTIVE_TRADES_QUERY_SORTS.createdAt]:
    ACTIVE_TRADES_QUERY_SORTS.lotCreatedAt,
  [ACTIVE_TRADES_QUERY_SORTS.createdAtDesc]:
    ACTIVE_TRADES_QUERY_SORTS.lotCreatedAtDesc,
  [ACTIVE_TRADES_QUERY_SORTS.endTime]:
    ACTIVE_TRADES_QUERY_SORTS.lotEndTime,
  [ACTIVE_TRADES_QUERY_SORTS.endTimeDesc]:
    ACTIVE_TRADES_QUERY_SORTS.lotEndTimeDesc,
})
