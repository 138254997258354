import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import UsersSkeleton from './components/UsersSkeleton'

const moduleName = 'users'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: UsersSkeleton,
  importComponent: () => import('./Users'),
  async createDependencies () {
    const { default: users } = await import('./store')
    store.registerModule(['ui', moduleName], users)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
