import { Notification } from 'Models/Notification'
import { TOPICS } from 'Constants/notificationTopics'
import router from 'Router'

import { ACTIVE_TRADES_URL_PARAM_TABS } from 'Constants/activeTradesUrlParams'
import { DEPOSITS_URL_PARAM_TABS } from 'Constants/depositsUrlParams'

export function buildNotificationRedirectionRouteParams (notification) {
  if (!(notification instanceof Notification)) {
    throw new TypeError('buildNotificationRedirectionRouteParams requires a Notification instance')
  }

  const route = { params: {}, query: {}, target: '_self' }
  switch (notification.topic) {
    case TOPICS.userRequestedBuyNow:
      route.name = 'activeTrades'
      route.params.tab = ACTIVE_TRADES_URL_PARAM_TABS.approval
      break

    case TOPICS.userRequestedWithdrawal:
      route.name = 'deposits'
      route.params.tab = DEPOSITS_URL_PARAM_TABS.holding
      route.query.accentLead = notification.cargo.customer.leadId
      break

    case TOPICS.userMadeDeposit:
      route.name = 'activeTrades.entry'
      break

    case TOPICS.userRequestedFinancing:
    case TOPICS.userFinancingApproved:
      route.name = 'leasings'
      break

    case TOPICS.brokerLeadDueDateExpired:
    case TOPICS.brokerLeadStateChanged:
    case TOPICS.userVerified:
    case TOPICS.brokerUserMadeDeposit:
    case TOPICS.brokerUserLotWon:
    case TOPICS.brokerUserLotLost:
    case TOPICS.leadAssigned:
    case TOPICS.leadCommented:
      route.name = 'showLead'
      route.params.leadId = notification.cargo.customer.leadId
      break

    case TOPICS.userLotWon:
      route.name = 'won'
      break

    case TOPICS.userLotLost:
      route.name = 'activeTrades'
      route.params.tab = ACTIVE_TRADES_URL_PARAM_TABS.lost
      break

    case TOPICS.leadReassigned:
      delete route.name
      break

    case TOPICS.claimRequestCreated:
      route.name = 'extension.copart.claims.entry'
      route.query.search = notification.cargo.lotId
      break
  }

  if (!route.name) return { to: '' }

  const toRoute = (router.resolve(route) || {}).route
  return { to: toRoute }
}
