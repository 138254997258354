/**
 * Create & download CSV file with provided content.
 *
 * @param {string} content File content.
 * @param {string} [filename] Result file name.
 */
export function downloadCsvFile (content, filename = 'result.csv') {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8' })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  link.style.visibility = 'hidden'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
