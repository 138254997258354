<template>
  <ui-dropdown
    class="calendar-field"
    ref="dropdownComponent"
    @close="onDropdownClose()"
    bubble
  >
    <ui-button
      class="calendar-field__trigger"
      :class="{
        'calendar-field__trigger_placeholder': !value,
      }"
      look="secondary"
      fill="frame"
    >
      <ui-icon
        v-if="showIcon"
        icon="calendar-fill"
        class="calendar-field__trigger-icon" />
      {{ valueFormatted }}
    </ui-button>

    <form
      class="calendar-field__content"
      @submit.prevent="apply()"
      @reset.prevent="resetLocalValue() & apply()"
      slot="content"
    >
      <calendar
        class="calendar-field__calendar"
        :value="localValue"
        @change="assignLocalValueDate($event[0])"
        v-bind="$attrs"
      />

      <div
        class="calendar-field__actions"
        v-if="!autoClose">
        <ui-button
          class="calendar-field__btn calendar-field__btn_reset"
          type="reset"
        >
          {{ $t('RESET_BTN') }}
        </ui-button>

        <ui-button
          class="calendar-field__btn"
          type="submit"
        >
          {{ $t('APPLY_BTN') }}
        </ui-button>
      </div>
    </form>
  </ui-dropdown>
</template>

<script>
import { UiDropdown, UiButton, UiIcon } from '@shelf.network/ui-kit'
import { formatDateRelative, endOfDay } from 'Utils/dateHelpers'
import Calendar from 'Common/Calendar'

export default {
  name: 'calendar-field',

  components: {
    UiDropdown,
    UiButton,
    Calendar,
    UiIcon
  },

  props: {
    value: {
      type: Date,
      default: null,
    },

    placeholder: {
      type: String,
      default: '',
    },

    isDayEnd: {
      type: Boolean,
      default: false,
    },

    showIcon: {
      type: Boolean,
      default: false,
    },

    autoClose: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      localValue: null,
    }
  },

  computed: {
    valueFormatted () {
      return this.value
        ? formatDateRelative(this.value)
        : this.placeholder || this.$t('SELECT_DATE_PH')
    }
  },

  watch: {
    value: {
      immediate: true,
      handler () {
        this.reassignLocalValue()
      },
    }
  },

  methods: {
    onDropdownClose () {
      this.reassignLocalValue()
    },

    reassignLocalValue () {
      if (this.value) {
        this.assignLocalValueDate(this.value)
      } else {
        this.resetLocalValue()
      }
    },

    assignLocalValueDate (newValue) {
      this.localValue = new Date(newValue)
      if (this.autoClose) this.apply()
    },

    apply () {
      let toEmit = this.isDayEnd && this.localValue
        ? endOfDay(this.localValue)
        : this.localValue
      this.$emit('input', toEmit)
      if (this.$refs.dropdownComponent) {
        this.$refs.dropdownComponent.$emit('close-dropdown')
      }
    },

    resetLocalValue () {
      this.localValue = null
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar-field {
  width: 100%;

  &__trigger {
    width: 100%;

    &-icon {
      font-size: 1.2em;
      margin-right: 0.5em;
    }

    /deep/ .ui-button__button {
      display: flex;
      align-items: center;
    }

    /deep/ .ui-button__button[fill][look] {
      text-align: start;
      color: inherit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_placeholder {
      /deep/ .ui-button__button[fill][look] {
        color: rgba($color-dark, 0.25);
      }
    }
  }

  &__content {
    padding: 1.25em;
    display: grid;
    grid: auto-flow / 1fr;
    gap: 2em;
  }

  &__actions {
    display: grid;
    grid: auto-flow / 1fr;
    gap: 0.5em;
  }

  &__btn {
    /deep/ .ui-button__button {
      border-radius: 1em;
    }

    &_reset {
      /deep/ .ui-button__button[fill][look] {
        color: $color-flag-is-error;
        background: none;
        border-color: transparent;

        &:hover {
          background: rgba($color-flag-is-error, 0.12);
        }
      }
    }
  }

  /deep/ .ui-dropdown__trigger {
    width: 100%;
  }

  /deep/ .ui-dropdown__content {
    border-radius: 1em;
    min-width: unset;
  }
}
</style>

<i18n>
{
  "en": {
    "APPLY_BTN": "Apply",
    "RESET_BTN": "Reset",
    "SELECT_DATE_PH": "Select date"
  },
  "ka": {
    "APPLY_BTN": "ცვლილება",
    "RESET_BTN": "გაწმენდა",
    "SELECT_DATE_PH": "თარიღის არჩევა"
  },
  "ru": {
    "APPLY_BTN": "Применить",
    "RESET_BTN": "Сбросить",
    "SELECT_DATE_PH": "Выберите дату"
  },
  "uk": {
    "APPLY_BTN": "Застосувати",
    "RESET_BTN": "Скинути",
    "SELECT_DATE_PH": "Оберіть дату"
  }
}
</i18n>
