export const actions = {
  LOAD_LEAD: 'LOAD_LEAD',
  LOAD_LEADS_LIST: 'LOAD_LEADS_LIST',
  UPDATE_LEAD: 'UPDATE_LEAD',
  UPDATE_LEADS: 'UPDATE_LEADS',
  MARK_LEAD_REQUESTS_RESOLVED: 'MARK_LEAD_REQUESTS_RESOLVED',
  BULK_PICK_BY: 'BULK_PICK_BY',
  ATTACHMENTS_UPLOAD: 'ATTACHMENTS_UPLOAD',
  ADD_ATTACHMENT: 'ADD_ATTACHMENT',
  REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
  DETACH_LEAD_LOT: 'DETACH_LEAD_LOT',
  ATTACH_LEAD_LOT: 'ATTACH_LEAD_LOT',
  LOAD_COMMENTS: 'LOAD_COMMENTS',
  LOAD_MORE_COMMENTS: 'LOAD_MORE_COMMENTS',
  POST_COMMENT: 'POST_COMMENT',
  UPDATE_COMMENT: 'UPDATE_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
  FOLLOW_LEAD: 'FOLLOW_LEAD',
  UNFOLLOW_LEAD: 'UNFOLLOW_LEAD',
  RESET: 'RESET',
}

export const mutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_LEADS_LIST: 'SET_LEADS_LIST',
  SET_EXPORTED_LEADS: 'SET_EXPORTED_LEADS',
  SET_LEAD: 'SET_LEAD',
  DELETE_LEAD: 'DELETE_LEAD',
  ADD_LEAD_LOT: 'ADD_LEAD_LOT',
  UPDATE_LEAD: 'UPDATE_LEAD',
  BULK_SELECT: 'BULK_SELECT',
  BULK_UNSELECT: 'BULK_UNSELECT',
  BULK_UNSELECT_ALL: 'BULK_UNSELECT_ALL',
  ADD_ATTACHMENT_TO_QUEUE: 'ADD_ATTACHMENT_TO_QUEUE',
  REMOVE_ATTACHMENT_FROM_QUEUE: 'REMOVE_ATTACHMENT_FROM_QUEUE',
  SET_IS_QUEUE_UPLOADING: 'SET_IS_QUEUE_UPLOADING',
  SET_QUEUE_ITEM_IS_PROCESSING: 'SET_QUEUE_ITEM_IS_PROCESSING',
  ADD_LEAD_ATTACHMENT: 'ADD_LEAD_ATTACHMENT',
  REMOVE_LEAD_ATTACHMENT: 'REMOVE_LEAD_ATTACHMENT',
  PUSH_COMMENTS: 'PUSH_COMMENTS',
  UPDATE_COMMENT_DATA: 'UPDATE_COMMENT_DATA',
  DELETE_COMMENT: 'DELETE_COMMENT',
  SET_IS_COMMENTS_LOADING: 'SET_IS_COMMENTS_LOADING',
  SET_COMMENTS_LOADING_ERROR: 'SET_COMMENTS_LOADING_ERROR',
  SET_COMMENTS_FETCH_NEXT: 'SET_COMMENTS_FETCH_NEXT',
  RESET: 'RESET',
}
