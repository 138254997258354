<template>
  <div class="lead-lot">
    <div class="lead-lot__main">
      <ui-img
        v-bind="lotImageProps"
        v-if="lotImageProps"
        class="lead-lot__main-img" />
      <ui-img
        src=""
        v-else
        class="lead-lot__main-img" />
      <ui-countdown
        v-if="lot.isAuction"
        :start="lot.startTime"
        :end="lot.endTime"
        :ended-at="lot.endedAt"
        :text-days="$t('COMMON.DAYS')"
        :text-hours="$t('COMMON.HOURS')"
        :text-minutes="$t('COMMON.MINUTES')"
        :locale="$i18n.locale"
        class="lead-lot__main-countdown"
      />

      <div class="lead-lot__main-prices">
        <template v-if="lot.isAuction">
          <div
            class="lead-lot__main-price"
            v-if="showCurrentBid">
            {{ $t('CURRENT_BID_LBL') }}
            <b class="lead-lot__main-price-val lead-lot__main-price-val_bid">
              {{ prices.highestBid }}
            </b>
          </div>
          <div
            class="lead-lot__main-price"
            v-if="showBuyNowPrice">
            {{ $t('BUY_NOW_LBL') }}
            <b class="lead-lot__main-price-val lead-lot__main-price-val_buy">
              {{ prices.buyNow }}
            </b>
          </div>
        </template>
        <template v-else>
          <div class="lead-lot__main-price">
            {{ $t('PRICE_LBL') }}
            <b class="lead-lot__main-price-val lead-lot__main-price-val_buy">
              {{ prices.sellPrice }}
            </b>
          </div>
        </template>
      </div>
    </div>
    <div class="lead-lot__info">
      <a
        class="lead-lot__info-name"
        :href="lotLink"
        target="_blank"
        :title="$t('LOT_LINK_MSG')"
      >
        {{ lot.name || '&mdash;' }}
      </a>
      <div class="lead-lot__info-location">
        <ui-icon icon="pin-alt" />
        {{ lot.location }}
      </div>

      <div class="lead-lot__info-row">
        <div class="lead-lot__info-block">
          <span class="lead-lot__info-lbl">
            {{ $t('LOT_ID_LBL') }}
          </span>
          <span class="lead-lot__info-val">
            {{ lot.id }}
            <ui-copy-button
              class="lead-lot__copy-btn"
              :value="lot.id"
              :copied-message="$t('COMMON.COPIED_MSG')"
            />
          </span>
        </div>

        <div
          class="lead-lot__info-block"
          v-if="external">
          <span class="lead-lot__info-lbl">
            {{ external.label }}
          </span>
          <span class="lead-lot__info-val">
            {{ external.id || '&mdash;' }}
            <ui-copy-button
              v-if="external.id"
              class="lead-lot__copy-btn"
              :value="external.id"
              :copied-message="$t('COMMON.COPIED_MSG')"
            />
          </span>
        </div>

        <div class="lead-lot__info-block">
          <span class="lead-lot__info-lbl">
            {{ $t('COMMON.VIN_CODE') }}
          </span>
          <span class="lead-lot__info-val">
            {{ lot.vin || '&mdash;' }}
            <ui-copy-button
              v-if="lot.vin"
              class="lead-lot__copy-btn"
              :value="lot.vin"
              :copied-message="$t('COMMON.COPIED_MSG')"
            />
          </span>
        </div>
      </div>

      <div class="lead-lot__info-row lead-lot__info-row_separated">
        <div class="lead-lot__info-block">
          <img
            :src="platformIcon(lot.platformId)"
            class="lead-lot__info-icon">
          {{ platformName(lot.platformId) }}
        </div>

        <div
          v-if="lot.ownershipDocType"
          class="lead-lot__info-block lead-lot__info-block-doc"
          :aria-label="lot.ownershipDocType"
          role="tooltip"
          data-microtip-position="top"
          data-microtip-size="medium"
        >
          <ui-icon
            icon="document"
            class="lead-lot__info-icon" />
          {{ $t('DOCK_TYPE_LBL') }}
        </div>
        <div
          class="lead-lot__info-block"
          v-if="lot.isOnApproval">
          <ui-icon
            icon="hands"
            class="lead-lot__info-icon" />
          {{ $t('LOT_ON_APPROVAL') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Lot } from 'Models/Lot'
import {
  UiImg,
  UiCountdown,
  UiIcon,
  UiCopyButton,
  breakpoints
} from '@shelf.network/ui-kit'
import { generateImageProps } from 'Utils/generateImageProps'
import { mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'
import { platformsGetters } from 'Store/entities/Platforms/types'

export default {
  name: 'lead-lot',
  components: { UiImg, UiCountdown, UiIcon, UiCopyButton },
  props: {
    lot: {
      type: Lot,
      required: true
    }
  },
  computed: {
    ...mapGetters('entities/platforms', {
      platformName: platformsGetters.PLATFORM_NAME,
      platformIcon: platformsGetters.PLATFORM_ICON,
    }),

    ...mapGetters('entities/user', {
      platform: userGetters.PLATFORM,
    }),

    showCurrentBid () {
      return !(this.lot.type === Lot.typesEnum.bnp && this.showBuyNowPrice)
    },

    showBuyNowPrice () {
      return Boolean(Number(this.lot.buyNowPrice))
    },

    lotLink () {
      return `${this.platform.clientUrl}/lot/${this.lot.id}`
    },

    prices () {
      return {
        buyNow:
          this.$fc(this.lot.buyNowPrice, this.lot.currency),
        highestBid:
          this.$fc(
            Math.max(this.lot.startPrice, this.lot.highestBid),
            this.lot.currency
          ),
        sellPrice:
          this.$fc(this.lot.customerTotalPrice, this.lot.currency)
      }
    },

    external () {
      switch (this.lot.type) {
        case Lot.typesEnum.iaaiAuction:
          return {
            id: this.lot.externalId,
            label: this.$t('EXTERNAL_LOT_ID_IAAI')
          }
        case Lot.typesEnum.copartAuction:
          return {
            id: this.lot.externalId,
            label: this.$t('EXTERNAL_LOT_ID_COPART')
          }
        default:
          return null
      }
    },

    lotImageProps () {
      if (!this.lot.images.length) return null

      return generateImageProps({
        src: this.lot.images[0],
        alt: this.lot.name,
        srcSetOptions: [
          { width: 240, height: 180 },
          { width: 320, height: 240 },
          { width: 480, height: 360 },
          { width: 640, height: 480 }
        ],
        sizes: [
          `(max-width: ${breakpoints.XS}px) 90vw`,
          '20vw',
        ].join(', '),
        placeholderOptions: { width: 32, height: 24 },
      })
    }
  }
}
</script>

<style scoped lang="scss">
.lead-lot {
  display: flex;
  min-height: 100%;

  &__main {
    @include aspect-ratio();

    --ratio: calc(4 / 3);

    position: relative;
    min-width: 40%;
    color: $color-white;

    &-img {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: $color-grey;
    }

    &-countdown {
      background: $color-sys-attention;
      padding: 0 1em;
      border-radius: 2.2em;
      position: absolute;
      left: 1em;
      top: 1em;
      line-height: 2.2em;
      font-size: 1.2em;
    }

    &-prices {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 2.5em 1em 1em 1em;
      display: flex;
      justify-content: space-evenly;
      justify-items: center;
      background:
        linear-gradient(
          0deg,
          rgba(35, 35, 35, 0.65) 20%,
          rgba(35, 35, 35, 0) 100%
        );
    }

    &-price {
      font-size: 1.1em;

      &-val {
        color: $color-sys-attention;

        &_bid {
          color: #95d6f3;
        }

        &_buy {
          color: #acffe7;
        }
      }
    }
  }

  &__info {
    min-width: 60%;
    padding: 1em 2em;

    &-name {
      @extend %h3;

      margin-top: 0.75em;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &-location {
      @extend %h4;

      color: $color-dark-grey;
    }

    &-row {
      display: flex;
      margin: 1.8em 0;

      &_separated {
        margin: 2em 0 0.75em 0;
        padding-top: 2em;
        border-top: 1px solid $color-grey;
      }
    }

    &-block {
      font-size: 1.1em;
      line-height: 1.5em;
      font-weight: bold;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      white-space: nowrap;
      padding-right: 1.75em;

      &-doc {
        cursor: help;
      }
    }

    &-lbl {
      color: $color-dark-grey;
      font-weight: normal;
      display: block;
      font-size: 0.9em;
      min-width: 100%;
    }

    &-icon {
      color: $color-dark-grey;
      font-weight: normal;
      font-size: 1.3em;
      margin-right: 0.25em;
      max-height: 1em;
      max-width: 3em;
    }
  }

  &__copy-btn {
    margin-left: 0.2em;
  }

  @include respond-below(sm) {
    display: block;

    &__main {
      min-width: 100%;

      --ratio: calc(16 / 9);
    }

    &__info {
      font-size: 0.85em;
      min-width: 100%;

      &-row {
        display: block;
      }

      &-block {
        margin-bottom: 1em;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DOCK_TYPE_LBL": "Dock Type",
    "EXTERNAL_LOT_ID_COPART": "Copart ID",
    "EXTERNAL_LOT_ID_IAAI": "IAAI ID",
    "LOT_ON_APPROVAL": "On Approval",
    "LOT_LINK_MSG": "Lot Link",
    "CURRENT_BID_LBL": "Current bid:",
    "BUY_NOW_LBL": "Buy Now:",
    "PRICE_LBL": "Price:",
    "LOT_ID_LBL": "Lot Id",
    "VIN_CODE": "VIN Code"
  },
  "ka": {
    "DOCK_TYPE_LBL": "დოკუმენტი",
    "EXTERNAL_LOT_ID_COPART": "Copart ID",
    "EXTERNAL_LOT_ID_IAAI": "IAAI ID",
    "LOT_ON_APPROVAL": "შეთანხმებით",
    "LOT_LINK_MSG": "ლოტის ლინკი",
    "CURRENT_BID_LBL": "მიმდინარე ფასი:",
    "BUY_NOW_LBL": "ახლავე ყიდვა:",
    "PRICE_LBL": "ფასი:",
    "LOT_ID_LBL": "ლოტის ID",
    "VIN_CODE": "VIN კოდი"
  },
  "ru": {
    "DOCK_TYPE_LBL": "Тип документа",
    "EXTERNAL_LOT_ID_COPART": "Copart ID",
    "EXTERNAL_LOT_ID_IAAI": "IAAI ID",
    "LOT_ON_APPROVAL": "По утверждению",
    "LOT_LINK_MSG": "Ссылка на лот",
    "CURRENT_BID_LBL": "Текущая ставка",
    "BUY_NOW_LBL": "Купить сейчас",
    "PRICE_LBL": "Цена",
    "LOT_ID_LBL": "Лот Id",
    "VIN_CODE": "VIN код"
  },
  "uk": {
    "DOCK_TYPE_LBL": "Тип документу",
    "EXTERNAL_LOT_ID_COPART": "Copart ID",
    "EXTERNAL_LOT_ID_IAAI": "IAAI ID",
    "LOT_ON_APPROVAL": "По затвердженню",
    "LOT_LINK_MSG": "Посилання на лот",
    "CURRENT_BID_LBL": "Поточна ставка",
    "BUY_NOW_LBL": "Купити зараз",
    "PRICE_LBL": "Ціна",
    "LOT_ID_LBL": "Лот Id",
    "VIN_CODE": "VIN код"
  }
}
</i18n>
