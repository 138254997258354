import { platformsActions, platformsMutations } from './types'

import { sdk } from 'Services/shelfNetworkSdk'
import { i18n } from 'I18n/instance'
import { showError } from 'Utils/notifications'

export default {
  async [platformsActions.FETCH_PLATFORMS] ({ commit }) {
    try {
      const { data: platforms } = await sdk.platformer.getAll()
      commit(platformsMutations.SET_PLATFORMS, platforms)
    } catch (error) {
      showError(i18n.t('TOASTS.LOAD_PLATFORMS_ERROR'))
    }
  },
}
