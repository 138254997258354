import { render, staticRenderFns } from "./UsersSkeleton.vue?vue&type=template&id=2a238c30&scoped=true&"
import script from "./UsersSkeleton.vue?vue&type=script&lang=js&"
export * from "./UsersSkeleton.vue?vue&type=script&lang=js&"
import style0 from "./UsersSkeleton.vue?vue&type=style&index=0&id=2a238c30&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a238c30",
  null
  
)

export default component.exports