<template>
  <div class="due-date-select">
    <calendar
      class="due-date-select__calendar"
      :value="form.dueDate"
      @change="onCalendarSelect($event[0])"
      :enable="calendarEnabledDays"
    />

    <div class="due-date-select__time-lbl-wrp">
      <label class="due-date-select__time-lbl">
        {{ $t('DUE_TIME_LBL') }}
      </label>
    </div>

    <ui-text
      class="due-date-select__time-input"
      v-model="form.dueTime"
      :is-error="!isDueTimeValid"
      maxlength="5"
    >
      <button
        class="due-date-select__time-input-btn"
        slot="dock-left"
        @click="form.dueDate = addMinutes(form.dueDate, -30, true)"
      >
        <ui-icon
          class="due-date-select__time-input-btn-ico"
          icon="minus"
        />
      </button>

      <button
        class="due-date-select__time-input-btn"
        slot="dock-right"
        @click="form.dueDate = addMinutes(form.dueDate, 30, true)"
      >
        <ui-icon
          class="due-date-select__time-input-btn-ico"
          icon="plus"
        />
      </button>
    </ui-text>

    <div class="due-date-select__content-footer">
      <ui-button
        class="due-date-select__btn due-date-select__btn_danger"
        fill="none"
        @click="clearDueDate"
        v-if="currentDueDate && canClear"
      >
        {{ $t('CLEAR_DUE_DATE_BTN') }}
      </ui-button>

      <ui-button
        class="due-date-select__btn"
        @click="applyDueDate(form.dueDate)"
        :is-disabled="isApplyBtnDisabled"
      >
        {{ $t('APPLY_BTN') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { UiButton, UiIcon, UiText } from '@shelf.network/ui-kit'
import {
  getNullDate,
  isNullDate,
  isSameDay,
  isAfter,
} from 'Utils/dateHelpers'
import Calendar from 'Common/Calendar'

const MINUTE = 60 * 1000
const HOURS_MINUTES_RE = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/

export default {
  name: 'due-date-select',

  components: { UiButton, UiIcon, UiText, Calendar },

  props: {
    value: { type: Date, required: true },
    canClear: { type: Boolean, default: false },
  },

  data () {
    return {
      form: {
        dueDate: new Date(),
        dueTime: new Date(),
      },
    }
  },

  computed: {
    currentDueDate () {
      return isNullDate(this.value) ? undefined : this.value
    },
    isDueTimeValid () {
      return HOURS_MINUTES_RE.test(this.form.dueTime)
    },
    isApplyBtnDisabled () {
      return !this.isDueTimeValid ||
        this.form.dueDate.getTime() < new Date().getTime()
    },
    calendarEnabledDays () {
      const today = new Date()
      return [
        (date) => {
          return isSameDay(today, date) || isAfter(date, today) ||
            date.toDateString() === this.value.toDateString()
        }
      ]
    }
  },

  watch: {
    'value': {
      immediate: true,
      handler (value) {
        this.setDefaultDueDate()
      },
    },

    'form.dueDate': {
      immediate: true,
      handler (value) {
        this.form.dueTime = this.$fd(new Date(value), 'HH:mm')
      },
    },

    'form.dueTime': {
      immediate: false,
      handler (value) {
        const parsed = HOURS_MINUTES_RE.exec(value)
        if (!parsed) return

        const [, hours, minutes] = parsed
        this.form.dueDate = this.applyHours(this.form.dueDate, hours, minutes)
      },
    }
  },

  methods: {
    onCalendarSelect (date) {
      if (isSameDay(this.value, date)) {
        this.form.dueDate = this.value
        return
      }

      const now = new Date()
      this.form.dueDate = isSameDay(new Date(), date)
        ? this.addMinutes(now, 30)
        : this.applyHours(date, 14)
    },

    addMinutes (date, count, isRoundedToCount = false) {
      const round = (v, to) => v >= Math.abs(to / 2) ? Math.abs(to) : 0

      const newTime = new Date(date.getTime() + count * MINUTE)
      const minutes = Math.round(newTime.getMinutes() / 10) * 10

      return this.applyHours(
        date,
        newTime.getHours(),
        isRoundedToCount ? round(minutes, count) : minutes,
      )
    },

    applyHours (date, hours, minutes) {
      const newDate = new Date(date)
      newDate.setHours(hours || 0, minutes || 0, 0, 0)
      return newDate
    },

    setDefaultDueDate () {
      this.form.dueDate = isNullDate(this.value)
        ? this.addMinutes(new Date(), 30)
        : this.value
    },

    applyDueDate (date) {
      this.$emit('change', date)
      this.$emit('apply', date)
    },

    clearDueDate () {
      return this.applyDueDate(getNullDate())
    },
  }
}
</script>

<style scoped lang="scss">
.due-date-select {
  border: none;
  max-width: 17.05em + (1.25em * 2);

  &__content {
    &-footer {
      text-align: center;
      padding-top: 0.75em;
      margin-top: 1.75em;
      border-top: 1px solid $color-grey;
      display: flex;
      flex-direction: column;
    }
  }

  &__btn {
    &_danger {
      font-size: 0.9em;

      /deep/ .ui-button__button[look][fill] {
        color: $color-sys-warning;
      }
    }

    /deep/ .ui-button__button[look][fill] {
      border-radius: 1em;

      &:disabled {
        // rewrite styles of ui-button
        border-width: 0 !important;
        background: $color-light-grey !important;
        color: $color-grey !important;
      }
    }
  }

  &__time-lbl {
    &-wrp {
      margin-top: 1.5em;
      margin-bottom: 0.333333em;
    }

    font-size: 12px;
    line-height: 1.33;
    color: $color-dark;
  }

  &__time-input {
    line-height: 2.75em;

    /deep/ .ui-text__input[fill][look] {
      background: rgba(240, 242, 245, 0.4);
      border-radius: 1em;
      border-color: $color-grey;
      text-align: center;
      color: $color-dark;
    }

    /deep/ .ui-text__dock {
      width: 2.75em;
      height: 2.75em;
      line-height: 2.6em;
    }

    &-btn {
      border: none;
      background: none;
      color: $color-dark-grey;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      padding-bottom: 0.2em;
    }

    &-btn-ico {
      display: block;
      font-size: 1.35em;
    }
  }

  @include respond-below(sm) {
    &__button {
      flex: 1;

      &-text {
        flex: 1;
        text-align: left;
      }
    }
  }
}
</style>
<i18n>
{
  "en": {
    "DUE_TIME_LBL": "SET TIME",
    "CLEAR_DUE_DATE_BTN": "CLEAR",
    "APPLY_BTN": "Done"
  },
  "ka": {
    "DUE_TIME_LBL": "ᲡᲐᲐᲗᲘᲡ ᲓᲐᲧᲔᲜᲔᲑᲐ",
    "CLEAR_DUE_DATE_BTN": "ᲬᲐᲨᲚᲐ",
    "APPLY_BTN": "ᲨᲔᲜᲐᲮᲕᲐ"
  },
  "ru": {
    "DUE_TIME_LBL": "УСТАНОВИТЕ ВРЕМЯ",
    "CLEAR_DUE_DATE_BTN": "ОЧИСТИТЬ",
    "APPLY_BTN": "Готово"
  },
  "uk": {
    "DUE_TIME_LBL": "ВСТАНОВІТЬ ЧАС",
    "CLEAR_DUE_DATE_BTN": "ОЧИСТИТИ",
    "APPLY_BTN": "Готово"
  }
}
</i18n>
