<template>
  <div class="claims-skeleton">
    <page-subnav class="claims-skeleton__subnav" />

    <div class="claims-skeleton__list">
      <claim-item-skeleton />
      <claim-item-skeleton />
      <claim-item-skeleton />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import ClaimItemSkeleton from './ClaimItemSkeleton'

export default {
  name: 'claims-skeleton',
  components: {
    PageSubnav,
    ClaimItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
@import "../styles/claims";

.claims-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 0 $list-padding-side;
  }
}
</style>
