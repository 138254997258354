import { ModelBase } from './ModelBase'
import { enums } from '@shelf.network/js-sdk'

export class Deposit extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.amount = this._strFiat(raw.amount)
    this.currency = this._str(raw.currency)
    this.createdAt = this._date(raw.createdAt)
    this.updatedAt = this._date(raw.updatedAt)
    this.state = this._str(raw.state)
    this.isReturnable = DEPOSIT_RETURNABLE_STATES.includes(this.state)
    this.isReturned = DEPOSIT_RETURNED_STATES.includes(this.state)
  }

  static get statesEnum () {
    return {
      pending: enums.depositStates.pending,
      paid: enums.depositStates.paid,
      returned: enums.depositStates.returned,
      returning: enums.depositStates.returning,
      feeCharged: enums.depositStates.feeCharged,
      failed: enums.depositStates.failed,
      reversed: enums.depositStates.reversed,
      locked: enums.depositStates.locked,
      requestedWithdrawal: enums.depositStates.requestedWithdrawal,
    }
  }
}

const DEPOSIT_RETURNABLE_STATES = [
  Deposit.statesEnum.paid,
  Deposit.statesEnum.requestedWithdrawal,
]

const DEPOSIT_RETURNED_STATES = [
  Deposit.statesEnum.returned,
  Deposit.statesEnum.returning,
  Deposit.statesEnum.feeCharged,
  Deposit.statesEnum.reversed,
]
