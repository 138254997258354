<template>
  <ui-portal>
    <ui-modal
      class="confirm-dialog"
      mode-xs="drawerBottom"
      @close="cancel"
    >
      <div class="confirm-dialog__content">
        <p class="confirm-dialog__message">
          {{ message }}
        </p>
        <div class="confirm-dialog__actions">
          <ui-button
            class="confirm-dialog__confirm-btn"
            @click="confirm"
          >
            {{ $t('CONTINUE') }}
          </ui-button>
          <ui-button
            fill="none"
            class="confirm-dialog__cancel-btn"
            @click="cancel"
          >
            {{ $t('CANCEL') }}
          </ui-button>
        </div>
      </div>
    </ui-modal>
  </ui-portal>
</template>

<script>
import { UiModal, UiButton, UiPortal } from '@shelf.network/ui-kit'

export default {
  name: 'confirm-dialog',
  components: {
    UiModal,
    UiButton,
    UiPortal,
  },
  props: {
    message: {
      type: String,
      required: true,
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm')
    },
    cancel () {
      this.$emit('cancel')
    }
  },
}
</script>

<style lang="scss" scoped>
.confirm-dialog {
  &__content {
    width: 19em;
    padding-top: 3.5em;

    @include respond-below(xs) {
      width: 100%;
    }
  }

  &__message {
    margin-bottom: 2.5em;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: space-around;

    @include respond-below(xs) {
      flex-direction: column-reverse;
    }
  }

  &__cancel-btn {
    $col: $color-flag-is-error;

    .ui-button__button[fill][look] {
      color: $col;
      margin-bottom: 0.5em;

      &:hover {
        background: rgba($col, 0.12);
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CANCEL": "Cancel",
    "CONTINUE": "Continue"
  },
  "ka": {
    "CANCEL": "უარყოფა",
    "CONTINUE": "გაგრძელება"
  },
  "ru": {
    "CANCEL": "Отмена",
    "CONTINUE": "Продолжить"
  },
  "uk": {
    "CANCEL": "Скасувати",
    "CONTINUE": "Продовжити"
  }
}
</i18n>
