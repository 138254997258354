<template>
  <ui-dropdown
    class="lead-due-date-dropdown"
    ref="dropdownComponent"
    bubble
  >
    <ui-button
      class="lead-due-date-dropdown__button"
      :look="currentDueDate ? 'default' : 'secondary'"
      fill="frame-hover"
    >
      <span class="lead-due-date-dropdown__button-text">
        {{ displayDueDate }}
      </span>
      <ui-icon
        icon="sand-clock"
        class="lead-due-date-dropdown__button-icon"
      />
    </ui-button>

    <div slot="content">
      <due-date-select
        :can-clear="canClearDueDate"
        class="lead-due-date-dropdown__content"
        :value="lead.dueDate"
        @change="applyDueDate" />
    </div>
  </ui-dropdown>
</template>

<script>
import DueDateSelect from 'Common/DueDateSelect'
import { UiButton, UiDropdown, UiIcon } from '@shelf.network/ui-kit'
import { isNullDate, formatDateTimeNumeralRelative } from 'Utils/dateHelpers'
import { showSuccess } from 'Utils/notifications'
import { Lead } from 'Models/Lead'
import { mapActions } from 'vuex'
import { actions } from '../store/types'

export default {
  name: 'lead-due-date-dropdown',
  components: {
    DueDateSelect,
    UiButton,
    UiDropdown,
    UiIcon,
  },
  props: {
    lead: { type: Lead, required: true }
  },
  computed: {
    canClearDueDate () {
      return [
        Lead.statesEnum.waitingCall,
        Lead.statesEnum.offerSent,
        Lead.statesEnum.archived,
      ].includes(this.lead.state)
    },
    currentDueDate () {
      return isNullDate(this.lead.dueDate) ? undefined : this.lead.dueDate
    },
    displayDueDate () {
      if (isNullDate(this.lead.dueDate)) {
        return this.$t('DUE_DATE_PH')
      }

      return formatDateTimeNumeralRelative(this.lead.dueDate)
    },
  },
  methods: {
    ...mapActions('ui/leads', {
      updateLead: actions.UPDATE_LEAD,
      markLeadRequestsResolved: actions.MARK_LEAD_REQUESTS_RESOLVED,
    }),
    async applyDueDate (val) {
      this.$refs.dropdownComponent.$emit('close-dropdown')
      try {
        const undo = await this.updateLead({
          id: this.lead.id, dueDate: val.toISOString()
        })
        showSuccess({
          text: this.$t('DUE_DATE_CHANGED_NOTIFY'),
          undoAction: undo
        })
      } catch (err) {
        console.error(err)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.lead-due-date-dropdown {
  &__content {
    padding: 1.25em;
  }

  & /deep/ .ui-dropdown__trigger {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  & /deep/ .ui-dropdown__content {
    min-width: unset;
    background-color: $color-white !important;

    &::before {
      background-color: $color-white !important;
    }
  }

  &__button {
    & /deep/ .ui-button__button {
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-text {
      font-size: 1.1em;
    }

    &-icon {
      font-size: 1.5em;
      margin-left: 0.5em;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DUE_DATE_PH": "Schedule Call",
    "DUE_DATE_CHANGED_NOTIFY": "Due date changed"
  },
  "ka": {
    "DUE_DATE_PH": "დროის ჩანიშვნა",
    "DUE_DATE_CHANGED_NOTIFY": "თარიღი შეცვლილია"
  },
  "ru": {
    "DUE_DATE_PH": "Отложить звонок",
    "DUE_DATE_CHANGED_NOTIFY": "Дата изменена"
  },
  "uk": {
    "DUE_DATE_PH": "Відкласти дзвінок",
    "DUE_DATE_CHANGED_NOTIFY": "Дата змінена"
  }
}
</i18n>
