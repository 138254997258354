import { Notification } from 'Models/Notification'
import { bellGetters } from './types'

export default {
  [bellGetters.URGENT_NOTIFICATIONS]: state =>
    state.urgentNotifications.map(el => new Notification(el)),
  [bellGetters.INFO_NOTIFICATIONS]: state =>
    state.infoNotifications.map(el => new Notification(el)),
  [bellGetters.EXTENSION_NOTIFICATIONS]: state =>
    state.extensionNotifications.map(el => new Notification(el)),

  [bellGetters.URGENT_UNREAD_COUNT]: state => state.urgentUnreadCount,
  [bellGetters.INFO_UNREAD_COUNT]: state => state.infoUnreadCount,
  [bellGetters.EXTENSION_UNREAD_COUNT]: state => state.extensionUnreadCount,
}
