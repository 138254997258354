import inRange from 'lodash/inRange'
import round from 'lodash/round'

import { userGetters } from 'Store/entities/User/types'

import { currencySymbols } from 'Utils/currencySymbols'
import { formatNumber } from 'Utils/formatNumber'

const THIN_SPACE = '\u2009'

const formatCurrency = ({ currency = 'USD', precision = 0 }) =>
  (value, isSymbolUsed = false, isShorten = false) => {
    if (isNaN(value)) {
      return value
    }

    const parsed = Number.parseFloat(value) || 0
    let toLocalize = parsed
    let multiplier = ''

    if (isShorten) {
      const shorten = shortenMillionNumber(parsed)
      toLocalize = shorten.value
      multiplier = shorten.multiplier
    }

    if (!multiplier && precision < 0) {
      toLocalize = round(toLocalize, precision)
    }

    const formatPrecision = precision > 0 ? precision : 0
    const visiblePrecision = multiplier ? 1 : Number(formatPrecision)
    let localized = formatNumber(toLocalize, { precision: visiblePrecision })
      .concat(multiplier)

    if (isSymbolUsed) {
      const symbol = currencySymbols[currency]

      localized = symbol === currencySymbols.GEL
        ? `${symbol}${THIN_SPACE}${localized}`
        : `${symbol}${localized}`
    }

    return localized
  }

const shortenMillionNumber = (value) => {
  const millions = (Number(value) / 1000000).toFixed(1)

  return inRange(millions, 0, 1)
    ? { value, multiplier: '' }
    : { value: Number(millions), multiplier: 'M' }
}

function createFormatCurrencyShortcut ({ store, i18n }) {
  return (value, currency = '', params = {}) => {
    currency = currency || store.getters[`entities/user/${userGetters.PROFILE}`].currency
    const { precision = 0, isSymbolUsed = true, isShorten = true } = params

    return formatCurrency({
      locale: i18n.locale,
      currency,
      precision,
    })(value, isSymbolUsed, isShorten)
  }
}

function createConvertFormatCurrencyShortcut (
  { formatCurrencyFunc, convertMoneyFunc }
) {
  return (value, from, params = {}) => {
    const to = params.to
    const converted = convertMoneyFunc(value, from, { to })
    return formatCurrencyFunc(converted, to, params)
  }
}

export {
  formatCurrency,
  createFormatCurrencyShortcut,
  createConvertFormatCurrencyShortcut,
}
