<template>
  <div id="app">
    <template v-if="isAppInitialized">
      <plain-layout v-if="isPlainLayout" />
      <layout v-else />
    </template>

    <ui-spinner
      v-else
      class="app__spinner"
      type="circle"
      :overlay="true"
    />
  </div>
</template>

<script>
import { Layout, PlainLayout } from 'Components/Layout'
import { UiSpinner } from '@shelf.network/ui-kit'
import { mapGetters } from 'vuex'
import { localeToFontFamily } from './font-families/localeToFontFamily'
import { userGetters } from 'Store/entities/User/types'
import { rootGetters } from 'Store/root/types'
import logger from 'Services/logger'
import { rootGet } from 'Store/helpers/rootHelpers'

export default {
  name: 'app',

  components: {
    Layout,
    PlainLayout,
    UiSpinner
  },

  computed: {
    ...mapGetters({
      isAppInitialized: rootGetters.IS_APP_INITIALIZED,
      isLoggedIn: `entities/user/${userGetters.IS_LOGGED_IN}`,
      isCarfaxReseller: `entities/user/${userGetters.IS_CARFAX_RESELLER}`,
    }),

    isPlainLayout () {
      return this.isLoggedIn && this.isCarfaxReseller
    }
  },

  watch: {
    '$i18n.locale': {
      immediate: true,
      handler (locale) {
        document.body.setAttribute('locale', localeToFontFamily(locale))
      }
    },
    isLoggedIn: {
      immediate: true,
      handler (isLoggedIn) {
        if (isLoggedIn) {
          const uid = rootGet(`entities/user/${userGetters.ACCOUNT_ID}`)
          const platform = rootGet(`entities/user/${userGetters.PLATFORM}`)
          const profile = rootGet(`entities/user/${userGetters.PROFILE}`)

          const identifyUserData = {
            name: [`[${platform.code}]`, profile.firstName, profile.lastName].join(' ').trim(),
            email: profile.email,
            accountType: profile.accountTypeStr,
            platformCode: platform.code,
            platformId: platform.id,
          }

          logger.identify(uid, identifyUserData)
        } else {
          logger.identify()
        }
      }
    }
  },

  metaInfo () {
    return {
      title: this.$t('COMMON.APP_TITLE')
    }
  }
}
</script>

<style lang="scss">
@import "~@shelf.network/ui-kit/src/styles/default-vars.scss";
@import "~normalize.css/normalize.css";
@import "./font-families/font-families.scss";
@import "@/styles/v-tooltip.scss";

body {
  background-color: $color-ui-bg;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include respond-font-size(14px);
}

.app {
  &__spinner {
    --ui-spinner-color-a: #{$color-black};
    --ui-spinner-circle--size: 2em;
  }
}
</style>
