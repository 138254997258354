<template>
  <!-- eslint-disable max-len vue/multiline-html-element-content-newline -->

  <div class="analytics-item-skeleton analytics-cmn__row">
    <div class="analytics-item-skeleton__cell">
      <div class="analytics-item-skeleton__ava" />

      <div class="analytics-item-skeleton__name">
        <span
          class="analytics-item-skeleton__line"
          style="width: 16em;"
        >&nbsp;</span>

        <span
          class="analytics-item-skeleton__line"
          style="width: 12em;"
        >&nbsp;</span>
      </div>
    </div>

    <div
      v-for="i in 3"
      :key="i"
      class="analytics-item-skeleton__cell"
    >
      <span
        class="analytics-item-skeleton__line"
        style="width: 9em;"
      >&nbsp;</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'analytics-item-skeleton',
}
</script>

<style scoped lang="scss">
@import "../styles/analytics";
@import "@/styles/skeletons.scss";

.analytics-item-skeleton {
  padding: 2.1em 0;
  background: $color-light;
  border: 1px solid transparent;
  box-sizing: border-box;

  &__cell {
    min-width: 0;
    min-height: 0;
    display: grid;
    grid: auto / auto-flow auto;
    justify-content: start;
    align-items: center;
    grid-gap: 0.5em;
  }

  &__ava {
    @extend %skeleton-bg;

    max-width: 2.2em;
    min-width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
  }

  &__name {
    display: grid;
    gap: 0.4em;
  }

  &__line {
    @extend %skeleton-bg;

    border-radius: 50px;
    display: inline-block;
  }
}
</style>
