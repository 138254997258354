<template>
  <div class="edit-profile-skeleton">
    <!-- eslint-disable max-len vue/multiline-html-element-content-newline -->
    <ui-container class="edit-profile-skeleton__container">
      <section class="edit-profile-skeleton__section-avatar">
        <div class="edit-profile-skeleton__avatar" />

        <div class="edit-profile-skeleton__btns">
          <div class="edit-profile-skeleton__btn" />
          <div class="edit-profile-skeleton__btn" />
        </div>
      </section>

      <section class="edit-profile-skeleton__section">
        <span
          class="edit-profile-skeleton__heading"
          style="width: 8em;"
        >&nbsp;</span>

        <div class="edit-profile-skeleton__row">
          <div class="edit-profile-skeleton__field" />
          <div class="edit-profile-skeleton__field" />
        </div>
        <div class="edit-profile-skeleton__field" />
        <div class="edit-profile-skeleton__btn" />
      </section>

      <section class="edit-profile-skeleton__section">
        <span
          class="edit-profile-skeleton__heading"
          style="width: 8em;"
        >&nbsp;</span>

        <div class="edit-profile-skeleton__field" />
        <div class="edit-profile-skeleton__field" />
        <div class="edit-profile-skeleton__field" />
        <div class="edit-profile-skeleton__btn" />
      </section>
    </ui-container>
  </div>
</template>

<script>
import { UiContainer } from '@shelf.network/ui-kit'

export default {
  name: 'edit-profile-skeleton',

  components: {
    UiContainer,
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/skeletons.scss";

$avatar-btn-margin: 1.25em;

.edit-profile-skeleton {
  &__container {
    max-width: 39rem;
    padding-bottom: 3.75em;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 5em;
  }

  &__section,
  &__section-avatar {
    display: grid;
    grid-auto-flow: row;
    width: 100%;
    grid-gap: 1.875em;
  }

  &__section-avatar {
    justify-items: center;
    justify-self: center;
    max-width: 20em;
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + #{$avatar-btn-margin});
    margin: -$avatar-btn-margin 0 0 (-$avatar-btn-margin);
    justify-content: center;
  }

  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5em;
  }

  &__field,
  &__heading,
  &__btn,
  &__avatar {
    @extend %skeleton-bg;

    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    display: inline-block;
    vertical-align: middle;
    border-radius: 15px;
    height: 1em;
  }

  &__heading {
    font-size: 1.4em;
    height: 1.5em;
  }

  &__btn {
    @include respond(font-size, 15px, 14px, 13px, 12px);

    height: 2.8em;
  }

  &__btns > &__btn {
    margin: $avatar-btn-margin 0 0 $avatar-btn-margin;
    flex: 1;
    width: 100%;
    min-width: 10em;
    max-width: 16em;
    height: 3.2em;
  }

  &__field {
    @include respond-font-size($layout-ui-font-size);

    height: 3.2em;
    width: 100%;
  }

  &__avatar {
    @include respond(width, 200px, 180px, 160px, 140px);
    @include respond(height, 200px, 180px, 160px, 140px);

    border-radius: 50%;
    margin: 0 auto;
    display: block;
  }
}
</style>
