import { ModelBase } from './ModelBase'

import get from 'lodash/get'

export class LeadComment extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.leadId = this._str(get(raw, 'relationships.lead.id'))
    this.data = this._str(raw.data)
    this.createdAt = this._date(raw.createdAt)
    this.updatedAt = this._date(raw.updatedAt)
    this.authorId = this._str(get(raw, 'relationships.author.id'))
    this.isUpdated = raw.createdAt !== raw.updatedAt

    // might not be included if comes form response on post
    this.authorFullName =
      this._str(get(raw, 'relationships.author.relationships.basic.fullName'))
    this.authorAvatarUrl =
      this._str(get(raw, 'relationships.author.relationships.basic.avatarLink'))
  }
}
