<template>
  <picture class="responsive-image">
    <source
      v-if="webpImage"
      :srcset="webpImage.srcSet"
      type="image/webp"
    >
    <source
      v-if="image"
      :srcset="image.srcSet"
    >
    <img
      v-bind="$attrs"
      class="responsive-image__img"
    >
  </picture>
</template>

<script>
/**
 * Takes a provided src of a given image and makes a responsive srcset
 * for it (with webp format if browser supports it).
 *
 * Important: works only with images stored in @/assets/images folder due to
 * webpack limitations of dynamic imports
 */
export default {
  name: 'responsive-image',
  props: {
    src: {
      type: String,
      required: true
    },
  },
  data: _ => ({
    image: null,
    webpImage: null,
  }),
  created () {
    const src = this.src.replace('@/assets/images/', './')

    this.image = require.context('@/assets/images', false, /(\.png|\.jpg|\.jpeg)$/)(src)
    this.webpImage = require.context('@/assets/images?format=webp', false, /(\.png|\.jpg|\.jpeg)$/)(src)
  },
}
</script>

<style lang="scss" scoped>
.responsive-image {
  &__img {
    max-width: 100%;
    max-height: inherit;
    height: inherit;
    width: inherit;
    object-fit: inherit;
  }
}
</style>
