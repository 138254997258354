<template>
  <div class="cell-deed-lot cells-cmn__cell">
    <div class="cell-deed-lot__img-wrp">
      <ui-img
        class="cell-deed-lot__img"
        :alt="lot.id"
        :title="lot.name"
        v-bind="lazyLotImg"
      />

      <template v-if="isCountdownShown">
        <div
          class="cell-deed-lot__countdown-wrp"
          :title="new Date(lot.endTime).toLocaleString()"
        >
          <ui-countdown
            class="cell-deed-lot__countdown"
            :start="Date.now()"
            :end="lot.endTime"
            :text-days="$t('DAYS_ABBR')"
            :text-hours="$t('HOURS_ABBR')"
            :text-minutes="$t('MINUTES_ABBR')"
            :locale="$i18n.locale"
          />
        </div>
      </template>
    </div>

    <template v-if="isAttributesShown">
      <div class="cells-cmn__cell-col">
        <span
          class="cell-deed-lot__name cells-cmn__str cells-cmn__str_nb"
          :title="lot.name"
        >
          {{ lot.name }}
        </span>

        <div class="cell-deed-lot__platform">
          <div class="cell-deed-lot__platform-img-wrp">
            <img
              class="cell-deed-lot__platform-img"
              :src="publisherPlatform.logos.icon"
              :alt="publisherPlatform.name"
              :title="publisherPlatform.name"
            >
          </div>

          <span
            class="cells-cmn__str cells-cmn__str_sec"
            :title="publisherPlatform.name"
          >
            {{ publisherPlatform.name }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { UiImg, UiCountdown } from '@shelf.network/ui-kit'
import { NotificationLot } from 'Models/NotificationLot'
import { isAfter } from 'Utils/dateHelpers'
import { mapGetters } from 'vuex'
import { platformsGetters } from 'Store/entities/Platforms/types'
import { lazyTinyImg } from 'Utils/generateImageProps'
import placeholderPath from 'Assets/icons/placeholder.svg'

export default {
  name: 'cell-deed-lot',

  components: {
    UiImg,
    UiCountdown,
  },

  props: {
    lot: {
      type: NotificationLot,
      default: false,
    },

    isAttributesShown: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      platforms: `entities/platforms/${platformsGetters.PLATFORMS}`,
    }),

    publisherPlatform () {
      return this.platforms[this.lot.platformId]
    },

    lazyLotImg () {
      if (this.lot.images[0]) {
        return lazyTinyImg(this.lot.images[0])
      }

      return { src: placeholderPath }
    },

    isCountdownShown () {
      const now = Date.now()
      return isAfter(this.lot.endTime, now)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./cells.scss";

.cell-deed-lot {
  &__img-wrp {
    position: relative;
    height: 5.4em;
    max-width: 6.5em;
    min-width: 6.5em;
  }

  &__img {
    display: block;
    object-fit: cover;
    border-radius: 5px;
    height: 100%;
    width: 100%;
  }

  &__countdown-wrp {
    position: absolute;
    display: grid;
    place-items: center;
    height: 2em;
    padding-top: 0.25em;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 5px 5px;
    background:
      linear-gradient(
        0deg,
        rgba(13, 13, 14, 0.8) 50%,
        rgba(13, 13, 14, 0) 100%
      );
  }

  &__countdown {
    font-size: 1em;
    line-height: 1;
    color: $color-sys-attention;

    /deep/ .ui-countdown__icon {
      display: none;
    }
  }

  &__name {
    font-size: 1.25em; // 17.5px when font-size: 14px
  }

  &__platform {
    display: flex;
    align-items: center;
    margin-top: 0.35em;

    &-img-wrp {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5ch;
    }

    &-img {
      width: 1.9em;
      height: 1.45em;
      object-fit: contain;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DAYS_ABBR": "D",
    "HOURS_ABBR": "H",
    "MINUTES_ABBR": "M"
  },
  "ka": {
    "DAYS_ABBR": "დ",
    "HOURS_ABBR": "სთ",
    "MINUTES_ABBR": "წთ"
  },
  "ru": {
    "DAYS_ABBR": "Д",
    "HOURS_ABBR": "Ч",
    "MINUTES_ABBR": "М"
  },
  "uk": {
    "DAYS_ABBR": "Д",
    "HOURS_ABBR": "Г",
    "MINUTES_ABBR": "Х"
  }
}
</i18n>
