<template>
  <div
    class="plain-layout"
    :class="{ 'plain-layout_hide-ui': !isLoggedIn }"
  >
    <div class="plain-layout__wrap">
      <div class="plain-layout__wrap-header">
        <header-component
          class="plain-layout__wrap-header-component"
          hide-search
        />
      </div>
      <router-view class="plain-layout__wrap-view" />
    </div>
  </div>
</template>

<script>
import Header from 'Components/Header'
import { mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'

export default {
  name: 'plain-layout',
  components: {
    HeaderComponent: Header.rootComponent
  },

  computed: {
    ...mapGetters('entities/user', {
      isLoggedIn: userGetters.IS_LOGGED_IN,
    })
  },
}
</script>

<style lang="scss" scoped>
.plain-layout {
  display: flex;
  flex-direction: column;
  flex: 1;

  &_hide-ui &__wrap-header {
    transform: translateY(-100%);
    visibility: hidden;
    height: 0;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    flex: 1;

    &-header {
      position: sticky;
      top: 0;
      z-index: z-index(10);
      display: flex;
      box-shadow: 0 0 25px rgba($color-black, 0.1);
      background-color: $color-white;
      border-bottom: 1px solid $color-light;
      transition: all ease-out 280ms;
      padding: 0 1em;

      &-component {
        flex: 1;
        z-index: z-index(10);

        /deep/ {
          @include respond-below(sm) {
            .user-block {
              width: 100%;

              &__signout {
                display: block;
                margin-left: auto;
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    &-view {
      margin: 1em 2em;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  @include respond-below(sm) {
    &_hide-ui {
      transform: none;
    }
  }
}
</style>
