import { userGetters } from './types'
import { USER_CLAIMS } from 'Constants/userClaims'
import { accountTypes, platformTypes } from '@shelf.network/js-sdk'
import get from 'lodash/get'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import { config } from '@/config'

export default {
  [userGetters.IS_LOGGED_IN]: state => Boolean(state.accountId) &&
    !isEmpty(state.profile) &&
    !isEmpty(state.platform),

  [userGetters.ACCOUNT_ID]: state => state.accountId,

  [userGetters.PLATFORM]: state => state.platform,

  [userGetters.PROFILE]: state => state.profile,

  [userGetters.PLATFORM_ID]: state => state.profile.platformId,

  [userGetters.TRUSTING_PLATFORMS]: state => state.profile.trustingPlatforms,

  [userGetters.PLATFORMS]: (state, getters) => ([
    getters[userGetters.PLATFORM_ID],
    ...(getters[userGetters.TRUSTING_PLATFORMS] || []),
  ]),

  [userGetters.CLAIMS]: (state, getters) => buildClaims(state, getters),

  [userGetters.ACCOUNT_TYPE]: state => state.profile.accountType,

  [userGetters.IS_BROKER]: (state, getters) =>
    getters[userGetters.ACCOUNT_TYPE] === accountTypes.broker,

  [userGetters.IS_ADMIN]: (state, getters) =>
    getters[userGetters.ACCOUNT_TYPE] === accountTypes.admin,

  [userGetters.IS_CARFAX_RESELLER]: (state, getters) =>
    getters[userGetters.PLATFORM].platformType === platformTypes.carfaxReseller,
}

function buildClaims (state, getters) {
  let claims
  const claimNames = Object.values(USER_CLAIMS)

  // Apply platform-level claims
  const platformClaims = get(getters[userGetters.PLATFORM], 'frontFeatureFlags', {})
  claims = pick(platformClaims, claimNames)

  // Apply claims of a specific account
  const accountClaims = get(state.profile, 'details.claims', {})
  claims = Object.assign(claims, pick(accountClaims, claimNames))

  // Common for brokers and admins
  claims = Object.assign(claims, {
    [USER_CLAIMS.EDIT_PROFILE]: true,
    // Pre-leads legacy
    [USER_CLAIMS.LOCATION_BASED_BROKERS]: !claims[USER_CLAIMS.LEADS],
  })

  // Apply claims for the broker account type
  if (getters[userGetters.IS_BROKER]) {
    claims = Object.assign({
      [USER_CLAIMS.WON]: true,
      [USER_CLAIMS.CARFAX]: false,
      [USER_CLAIMS.MY_TRADES]: false,
      [USER_CLAIMS.UPLOAD]: false,
      [USER_CLAIMS.BROKER_ASSIGNMENT]: false,
      [USER_CLAIMS.TBC_PAYMENTS]: false,
      [USER_CLAIMS.DEPOSITS_VIEW]: true,
      [USER_CLAIMS.DEPOSITS_MANAGEMENT]: false,
      [USER_CLAIMS.WINNER_MANAGEMENT]: false,
      [USER_CLAIMS.NOTIFICATIONS]: true,
      [USER_CLAIMS.ACTIVE_TRADES]: true,
      [USER_CLAIMS.ARCHIVE]: claims[USER_CLAIMS.LEADS],
      [USER_CLAIMS.SHELF_AP_FEATURES]: config.featureFlags.SHELF_AP_FEATURES,
      [USER_CLAIMS.SEARCH]: true,
    }, claims)
  } else if (getters[userGetters.IS_ADMIN]) {
    claims = Object.assign({
      [USER_CLAIMS.WON]: true,
      [USER_CLAIMS.DEPOSITS_VIEW]: true,
      [USER_CLAIMS.DEPOSITS_MANAGEMENT]: true,
      [USER_CLAIMS.WINNER_MANAGEMENT]: true,
      [USER_CLAIMS.NOTIFICATIONS]: true,
      [USER_CLAIMS.MY_TRADES]: true,
      [USER_CLAIMS.DIRECT_SALE_MODERATION]: true,
      [USER_CLAIMS.UPLOAD]: true,
      [USER_CLAIMS.BROKER_ASSIGNMENT]: true,
      [USER_CLAIMS.ACTIVE_TRADES]: true,
      [USER_CLAIMS.REJECT_PARTICIPANT]: true,
      [USER_CLAIMS.ANALYTICS]: true,
      [USER_CLAIMS.ARCHIVE]: claims[USER_CLAIMS.LEADS],
      [USER_CLAIMS.LEAD_FOLLOW]: true,
      [USER_CLAIMS.USER_MANAGEMENT]: true,
      [USER_CLAIMS.SHELF_AP_FEATURES]: config.featureFlags.SHELF_AP_FEATURES,
      [USER_CLAIMS.BID_LIMIT_MANAGEMENT]: true,
      [USER_CLAIMS.CLOSE_PLAYING_LOTS]: true,
      [USER_CLAIMS.SEARCH]: true,

      [USER_CLAIMS.EXTENSION_DEALERS]: true,
      [USER_CLAIMS.EXTENSION_FEES]: true,
      [USER_CLAIMS.EXTENSION_WON]: true,
      [USER_CLAIMS.EXTENSION_ACCOUNTS]: true,
      [USER_CLAIMS.EXTENSION_ANALYTICS]: true,
      [USER_CLAIMS.EXTENSION_CLAIMS]: true,
      [USER_CLAIMS.EXTENSION_NOTIFICATIONS]: true,

      [USER_CLAIMS.IMPACT_EXTENSION_DEALERS]: true,
      [USER_CLAIMS.IMPACT_EXTENSION_WON]: true,
      [USER_CLAIMS.IMPACT_EXTENSION_ACCOUNTS]: true,
    }, claims)
  }

  return claims
}
