import { enumsActions, enumsMutations } from './types'
import { sdk } from 'Services/shelfNetworkSdk'
import { i18n } from 'I18n/instance'

export default {
  async [enumsActions.FETCH_ENUMS] ({ commit }) {
    const { rawData: enumerState } = await sdk.enumer.getState({
      filter: {
        enum: [
          'maker',
          'model',
          'fuel_type',
          'wheel_position',
          'transmission_type',
        ].join(','),
        fields: [
          'values',
          'names',
          'makers_to_models',
        ].join(','),
      }
    })

    commit(enumsMutations.SET_MAKER_NAMES, enumerState.maker_names)
    commit(enumsMutations.SET_MODEL_NAMES, enumerState.model_names)
    commit(enumsMutations.SET_MAKERS_TO_MODELS, enumerState.makers_to_models)
    commit(enumsMutations.SET_FUELS, enumerState.fuel_type_values)
    commit(enumsMutations.SET_WHEELS, enumerState.wheel_position_values)
    commit(enumsMutations.SET_TRANSMISSIONS,
      enumerState.transmission_type_values)
  },

  async [enumsActions.FETCH_TRANSLATIONS] ({ commit }, locale = i18n.locale) {
    const { rawData } = await sdk.enumer.getTranslations(locale)
    commit(enumsMutations.SET_TRANSLATIONS, rawData)
  },
}
