import get from 'lodash/get'
import { ModelBase } from './ModelBase'
import { Location } from './Location'
import { summarizerBy, fieldComparator, compareAlphabetically } from 'Utils/arrayHelpers'
import { Deposit } from './Deposit'
import { enums } from '@shelf.network/js-sdk'

export class Identity extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)
    const r11s = raw.relationships || {}

    this.type = this._str(raw.type)
    this.createdAt = this._date(raw.createdAt)
    this.feeLevel = this._str(raw.feeLevel)

    const basic = r11s.basic || {}
    this.fullName = this._str(basic.fullName)
    this.avatarLink = this._str(basic.avatarLink)
    this.email = this._str(basic.email)
    this.phone = this._str(basic.phone)
    this.login = this._str(basic.login)
    this.copartLogin = this._str(basic.copartLogin)
    this.platformId = get(raw, 'relationships.platform.id', '')
    this.location = ModelBase.modelFromCache(Location, r11s.location, cache)

    const rawDeposits = r11s.activeDeposits || []
    rawDeposits.sort(fieldComparator('id', compareAlphabetically, '-'))
    const deposits = rawDeposits.map(deposit => {
      return ModelBase.modelFromCache(Deposit, deposit, cache)
    })

    const totalAmount = deposits.reduce(summarizerBy('amount'), 0)
    const latestDeposit = deposits[0] || {}
    this.activeDepositsTotal = this._strFiat(totalAmount)
    this.activeDepositsIds = deposits.map(el => this._str(el.id))
    this.activeDepositsCurrency = this._str(latestDeposit.currency)
    this.activeDepositLatestCreatedAt = this._date(latestDeposit.createdAt)
  }

  static get typesEnum () {
    return {
      user: enums.userTypes.user,
      broker: enums.userTypes.broker,
      business: enums.userTypes.business,
    }
  }
}
