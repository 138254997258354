import { ModelBase } from './ModelBase'

export class NotificationActor extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.name = this._str(raw.name)
    this.avatarLink = this._str(raw.avatarLink)
    this.leadId = this._str(raw.leadId)
  }
}
