import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import TransportationSkeleton from './components/TransportationSkeleton'

const moduleName = 'transportation'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: TransportationSkeleton,
  importComponent: () => import('./Transportation'),
  async createDependencies () {
    const { default: transportationModule } = await import('./store')
    store.registerModule(['ui', moduleName], transportationModule)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
