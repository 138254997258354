<template>
  <ui-text
    v-bind="$attrs"
    :value="formattedLocation"
    readonly>
    <ui-spinner
      v-if="isLoading"
      slot="dock-right"
      type="pills">
      {{ translated || '&mldr;' }}
    </ui-spinner>
  </ui-text>
</template>

<script>
import { UiText, UiSpinner } from '@shelf.network/ui-kit'
import { Location } from 'Models/Location'
import { sdk } from 'Services/shelfNetworkSdk'
import { i18n } from 'I18n/instance'
import get from 'lodash/get'
import { formatLocation } from 'Utils/formatLocation'

export default {
  name: 'i18n-location',
  components: { UiSpinner, UiText },
  props: {
    location: { type: [Location, Object], required: true },
    locale: { type: String, default: '' }
  },
  data () {
    return {
      isLoading: false,
      translated: {
        city: '',
        country: '',
        region: ''
      }
    }
  },
  computed: {
    currentLocale () {
      return this.locale || i18n.locale
    },
    formattedLocation () {
      if (!this.hasAnyLocationId) return ''
      if (this.isLoading) return '...'
      return formatLocation(this.translated)
    },
    hasAnyLocationId () {
      return Boolean(
        this.location.countryId ||
          this.location.cityId ||
          this.location.regionId
      )
    }
  },
  watch: {
    locale: {
      immediate: true,
      handler () {
        this.getTranslatedLocation()
      }
    },
    location: {
      immediate: true,
      handler () {
        this.getTranslatedLocation()
      }
    }
  },

  methods: {
    async getTranslatedLocation () {
      if (!this.hasAnyLocationId) return

      this.isLoading = true

      try {
        const {
          data: [loc]
        } = await sdk.identity.locations.getAll(
          {
            'filter[country.id]': this.location.countryId || undefined,
            'filter[city.id]': this.location.cityId || undefined,
            'filter[region.id]': this.location.regionId || undefined,
            'page[limit]': 1,
            locale: this.currentLocale
          },
          this.currentLocale
        )

        this.translated.city = get(loc, 'relationships.city.name', '')
        this.translated.country = get(loc, 'relationships.country.name', '')
        this.translated.region = get(loc, 'relationships.region.name', '')
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    }
  }
}
</script>
