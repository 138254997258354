export class LoneSdkCall {
  constructor () {
    const latestCall = Promise.resolve()
    latestCall.cancel = () => { }

    this.latestCall = latestCall
  }

  takeLatest (sdkCall) {
    if (this.latestCall && this.latestCall.cancel) {
      this.cancelLatest()
    }
    this.latestCall = sdkCall
    return sdkCall
  }

  cancelLatest () {
    this.latestCall.cancel()
  }
}
