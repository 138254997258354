import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import ExtensionWonSkeleton from './components/ExtensionWonSkeleton'

const moduleName = 'extension-won'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: ExtensionWonSkeleton,
  importComponent: () => import('./ExtensionWon'),
  async createDependencies () {
    const { default: fees } = await import('./store')
    store.registerModule(['ui', moduleName], fees)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
