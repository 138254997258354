<template>
  <lead-subnav-select
    class="lead-bulk-assistant"
    :options="options"
    :is-clicks-skipped="false"
    @input="onChoose"
  >
    <button
      class="lead-bulk-assistant__btn"
      :class="{
        'lead-bulk-assistant__btn_deselect': isAnySelected,
      }"
      @click="toggleSelection"
    >
      <ui-icon
        class="lead-bulk-assistant__btn-ico"
        icon="minus"
      />
    </button>
  </lead-subnav-select>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import LeadSubnavSelect from './LeadsSubnavSelect'
import { mapGetters, mapActions } from 'vuex'
import { actions } from '../store/types'

const PREDICATES_DESCRIBERS = {
  all: 'all',
  none: 'none',
  prioritized: 'prioritized',
  unprioritized: 'unprioritized',
}

const PREDICATES = {
  [PREDICATES_DESCRIBERS.all]: () => true,
  [PREDICATES_DESCRIBERS.none]: () => false,
  [PREDICATES_DESCRIBERS.prioritized]: lead => lead.isPriorityHigh,
  [PREDICATES_DESCRIBERS.unprioritized]: lead => !lead.isPriorityHigh,
}

export default {
  name: 'lead-bulk-assistant',

  components: {
    UiIcon,
    LeadSubnavSelect,
  },

  computed: {
    ...mapGetters('ui/leads', ['bulkSelection']),

    isAnySelected () {
      return this.bulkSelection.length > 0
    },

    options () {
      return [
        {
          label: this.$t('SELECT_ALL_OPT'),
          value: PREDICATES_DESCRIBERS.all,
        },
        {
          label: this.$t('SELECT_NONE_OPT'),
          value: PREDICATES_DESCRIBERS.none,
        },
        {
          label: this.$t('SELECT_PRIORITIZED_OPT'),
          value: PREDICATES_DESCRIBERS.prioritized,
        },
        {
          label: this.$t('SELECT_UNPRIORITIZED_OPT'),
          value: PREDICATES_DESCRIBERS.unprioritized,
        },
      ]
    },
  },

  methods: {
    ...mapActions('ui/leads', {
      bulkPickBy: actions.BULK_PICK_BY,
    }),

    toggleSelection () {
      if (this.isAnySelected) {
        this.onChoose(PREDICATES_DESCRIBERS.none)
        return
      }

      this.onChoose(PREDICATES_DESCRIBERS.all)
    },

    onChoose (predicateDescriber) {
      this.bulkPickBy(PREDICATES[predicateDescriber])
    },
  },
}
</script>

<style lang="scss" scoped>
.lead-bulk-assistant {
  &__btn {
    background: transparent;
    border: 1px solid $color-dark-grey;
    border-radius: 0.4em;
    width: 1.7em;
    height: 1.7em;
    color: transparent;
    position: relative;
    display: block;
    transition: all linear 180ms;

    &_deselect {
      background: $color-dark;
      border-color: $color-dark;
      color: $color-light;
    }

    &-ico {
      font-weight: 700;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>

<i18n>
{
  "en": {
    "SELECT_ALL_OPT": "All",
    "SELECT_NONE_OPT": "None",
    "SELECT_PRIORITIZED_OPT": "Prioritized",
    "SELECT_UNPRIORITIZED_OPT": "Unprioritized"
  },
  "ka": {
    "SELECT_ALL_OPT": "ყველა",
    "SELECT_NONE_OPT": "არცერთი",
    "SELECT_PRIORITIZED_OPT": "პრიორიტეტიზაცია",
    "SELECT_UNPRIORITIZED_OPT": "პრიორიტეტის მოხსნა"
  },
  "ru": {
    "SELECT_ALL_OPT": "Все",
    "SELECT_NONE_OPT": "Ни одного",
    "SELECT_PRIORITIZED_OPT": "Приоритетные",
    "SELECT_UNPRIORITIZED_OPT": "Неприоритетные"
  },
  "uk": {
    "SELECT_ALL_OPT": "Всі",
    "SELECT_NONE_OPT": "Жодного",
    "SELECT_PRIORITIZED_OPT": "Пріоритетні",
    "SELECT_UNPRIORITIZED_OPT": "Непріоритетні"
  }
}
</i18n>
