<template>
  <div class="fees-skeleton">
    <page-subnav class="fees-skeleton__subnav" />

    <div class="fees-skeleton__list">
      <fee-item-skeleton />
      <fee-item-skeleton />
      <fee-item-skeleton />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import FeeItemSkeleton from './FeeItemSkeleton'

export default {
  name: 'fees-skeleton',
  components: {
    PageSubnav,
    FeeItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
@import "../styles/fees";

.fees-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 0 $list-padding-side;
  }
}
</style>
