<template>
  <transition name="leads-bulk-assigner">
    <div
      class="leads-bulk-assigner"
      v-if="$can($USER_CLAIMS.BROKER_ASSIGNMENT) && isAnySelected"
    >
      <ui-button
        class="leads-bulk-assigner__btn"
        fill="none"
        look="secondary"
        @click="archiveLeads"
      >
        <ui-icon
          class="leads-bulk-assigner__btn-ico"
          icon="archive"
        />
        <span class="leads-bulk-assigner__btn-txt">
          {{ $t('ARCHIVE_BTN') }}
        </span>
      </ui-button>

      <!-- TODO: Temporarily hidden -->
      <template v-if="false">
        <span class="leads-bulk-assigner__label">
          {{ $t('ASSIGN_LBL') }}
        </span>

        <div class="leads-bulk-assigner__divider" />

        <ui-button
          class="leads-bulk-assigner__btn"
          fill="none"
          look="secondary"
          @click="assignRandomized"
        >
          <ui-icon
            class="leads-bulk-assigner__btn-ico"
            icon="refresh"
          />
          <span class="leads-bulk-assigner__btn-txt">
            {{ $t('RANDOMIZE_BTN') }}
          </span>
        </ui-button>

        <ui-button
          class="leads-bulk-assigner__btn"
          fill="none"
          look="secondary"
          @click="assignByLocation"
        >
          <ui-icon
            class="leads-bulk-assigner__btn-ico"
            icon="pin-alt"
          />
          <span class="leads-bulk-assigner__btn-txt">
            {{ $t('BY_LOCATION_BTN') }}
          </span>
        </ui-button>
      </template>
    </div>
  </transition>
</template>

<script>
import { UiButton, UiIcon } from '@shelf.network/ui-kit'

import { mapGetters, mapActions } from 'vuex'
import { actions } from '../store/types'

import { Lead } from 'Models/Lead'
import { showError, showInfo, showSuccess } from 'Utils/notifications'

export default {
  name: 'leads-bulk-assigner',

  components: {
    UiButton,
    UiIcon,
  },

  computed: {
    ...mapGetters('ui/leads', ['bulkSelection']),

    isAnySelected () {
      return this.bulkSelection.length > 0
    }
  },

  methods: {
    ...mapActions('ui/leads', {
      updateLeads: actions.UPDATE_LEADS,
    }),

    assignRandomized () {
      showInfo({
        text: this.$t('RANDOMIZED_COMING_SOON_NOTIFY')
      })
    },

    assignByLocation () {
      showInfo({
        text: this.$t('BY_LOCATION_COMING_SOON_NOTIFY')
      })
    },

    async archiveLeads () {
      try {
        const undo = await this.updateLeads(
          this.bulkSelection.map(id => ({
            id,
            state: Lead.statesEnum.archived,
          }))
        )

        showSuccess({
          text: this.$t('ARCHIVED_SUCCESS_NOTIFY'),
          undoAction: undo
        })
      } catch (error) {
        showError(this.$t('ARCHIVED_FAIL_NOTIFY'))
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.leads-bulk-assigner {
  display: flex;
  align-items: center;
  transition: linear all 180ms;

  &-enter,
  &-leave-active {
    opacity: 0;
  }

  &__label {
    color: $color-dark-grey;
    margin: 0 1.5em;
  }

  &__divider {
    width: 1px;
    height: 3em;
    background-color: $color-grey;
  }

  &__btn {
    &.ui-button {
      margin-left: 0.75em;
    }

    & /deep/ .ui-button__button {
      padding: 0.75em;
    }

    &-ico {
      font-size: 1.2em;
      vertical-align: middle;
    }

    &-txt {
      vertical-align: middle;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "ASSIGN_LBL": "Assign:",
    "ARCHIVE_BTN": "Archive",
    "RANDOMIZE_BTN": "Randomize",
    "BY_LOCATION_BTN": "By location",
    "RANDOMIZED_COMING_SOON_NOTIFY": "Assign randomized is coming soon",
    "BY_LOCATION_COMING_SOON_NOTIFY": "Assign by location is coming soon",
    "ARCHIVED_SUCCESS_NOTIFY": "Leads archived",
    "ARCHIVED_FAIL_NOTIFY": "Cannot archive leads"
  },
  "ka": {
    "ASSIGN_LBL": "მიმაგრება",
    "ARCHIVE_BTN": "დაარქივება",
    "RANDOMIZE_BTN": "შერეულად",
    "BY_LOCATION_BTN": "ლოკაციის მიხედვით",
    "RANDOMIZED_COMING_SOON_NOTIFY": "მალე დაემატება",
    "BY_LOCATION_COMING_SOON_NOTIFY": "მალე დაემატება",
    "ARCHIVED_SUCCESS_NOTIFY": "დაარქივება",
    "ARCHIVED_FAIL_NOTIFY": "ლიდი ვერ დაარქივდა"
  },
  "ru": {
    "ASSIGN_LBL": "Назначить:",
    "ARCHIVE_BTN": "Архивировать",
    "RANDOMIZE_BTN": "Случайно",
    "BY_LOCATION_BTN": "По местоположению",
    "RANDOMIZED_COMING_SOON_NOTIFY": "Случайное назначение будет доступно в ближайшее время",
    "BY_LOCATION_COMING_SOON_NOTIFY": "Назначение по местоположению будет доступно в ближайшее время",
    "ARCHIVED_SUCCESS_NOTIFY": "Лиды помещены в архив",
    "ARCHIVED_FAIL_NOTIFY": "Не удалось архивировать лидов"
  },
  "uk": {
    "ASSIGN_LBL": "Призначити:",
    "ARCHIVE_BTN": "Архівувати",
    "RANDOMIZE_BTN": "Випадково",
    "BY_LOCATION_BTN": "За місцем розташування",
    "RANDOMIZED_COMING_SOON_NOTIFY": "Випадкове призначення буде доступне найближчим часом",
    "BY_LOCATION_COMING_SOON_NOTIFY": "Призначення за місцем розташування буде доступне найближчим часом",
    "ARCHIVED_SUCCESS_NOTIFY": "Поміщено в архів",
    "ARCHIVED_FAIL_NOTIFY": "Не вдалось архівувати лідів"
  }
}
</i18n>
