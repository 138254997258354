<template>
  <span class="channel-icon">
    <img
      v-if="channelIcon.type === CIT_IMG"
      class="channel-icon__img"
      :src="channelIcon.val"
    >
    <ui-icon
      v-else
      class="channel-icon__icon"
      :icon="channelIcon.val || 'close'" />
  </span>
</template>

<script>

import { Lead } from 'Models/Lead'
import { UiIcon } from '@shelf.network/ui-kit'
import IconFacebookMessenger from 'Assets/channels/facebook-messenger.svg'
import IconFacebook from 'Assets/channels/facebook.svg'
import IconPhone from 'Assets/channels/phone.svg'
import IconCarfax from 'Assets/channels/carfax.svg'
import IconTelegram from 'Assets/channels/telegram.svg'

const CIT_IMG = 1
const CIT_ICON = 2

export default {
  name: 'channel-icon',
  components: { UiIcon },
  props: {
    channel: { type: String, default: Lead.channelsEnum.website }
  },
  data () {
    return { CIT_IMG, CIT_ICON }
  },
  computed: {
    channelIcon () {
      switch (this.channel) {
        case Lead.channelsEnum.phone:
          return { type: CIT_IMG, val: IconPhone }
        case Lead.channelsEnum.facebook:
          return { type: CIT_IMG, val: IconFacebookMessenger }
        case Lead.channelsEnum.facebookWeb:
          return { type: CIT_IMG, val: IconFacebook }
        case Lead.channelsEnum.carfax:
          return { type: CIT_IMG, val: IconCarfax }
        case Lead.channelsEnum.telegram:
          return { type: CIT_IMG, val: IconTelegram }
        case Lead.channelsEnum.email:
          return { type: CIT_ICON, val: 'message-alt' }
        case Lead.channelsEnum.website:
        case Lead.channelsEnum.rajdeba:
        default:
          return { type: CIT_ICON, val: 'link' }
      }
    }
  }

}
</script>

<style scoped lang="scss">
.channel-icon {
  text-decoration: none !important;
  display: inline-flex;
  font-size: 1em;
  height: 1em;
  width: 1em;
  vertical-align: middle;
  align-content: center;
  align-items: center;

  &__img {
    width: 0.9em;
    display: block;
    object-fit: contain;
  }

  &__icon {
    font-size: 0.9em;
    color: $color-status-waiting;
  }
}
</style>
