import { i18n } from 'I18n/instance'
import { titleCase } from 'Utils/changeCase'

import isObject from 'lodash/isObject'

// TODO: Temporal hack, remove when back end sends proper country ISO codes.
const COUNTRIES_MAP = {
  GE: 'GEO',
  US: 'USA',
  LT: 'LTU',
  DE: 'DEU',
  UA: 'UKR',
  PL: 'POL',
  LV: 'LVA',
  EE: 'EST',
  AE: 'ARE',
  ES: 'ESP',
  JO: 'JOR',
  OM: 'OMN',
  SPAIN: 'ESP',
  IT: 'ITA',
  ITALY: 'ITA',
  POLAND: 'POL',
  DENMARK: 'DNK',
  SWITZERLAND: 'CHE',
  FRANCE: 'FRA',
  GERMANY: 'DEU',
  BELGIUM: 'BEL'
}

const MDASH = '—'

/**
 * Returns translated location, truncates nullable values
 * @param  {object|...string} args If an object provided it should contain
 * `city` and `country` properties
 */
export function formatLocation (...args) {
  let country, city, zip
  if (args.length === 1 && isObject(args[0])) {
    country = args[0].country
    city = args[0].city
    zip = args[0].zip
  } else {
    [country, city, zip] = args
  }

  if (!country && !city) {
    return MDASH
  }

  let translatedCountry, translatedCity

  if (country) {
    const mappedCountry = COUNTRIES_MAP[country.toUpperCase()] || country
    translatedCountry = i18n.t(`COUNTRIES.${mappedCountry}`)
    if (translatedCountry === `COUNTRIES.${mappedCountry}`) {
      translatedCountry = mappedCountry
    }
  }

  if (city) {
    translatedCity = i18n.t(`CITIES.${city}`)
    if (translatedCity === `CITIES.${city}`) {
      translatedCity = titleCase(city)
    }
  }

  return [translatedCountry, translatedCity, zip]
    .filter(val => val)
    .join(i18n.t('COMMON.SYMBOLS.COMMA'))
}
