import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import ImpactWonSkeleton from './components/ImpactWonSkeleton'

const moduleName = 'impact-won'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: ImpactWonSkeleton,
  importComponent: () => import('./ImpactWon'),
  async createDependencies () {
    const { default: won } = await import('./store')
    store.registerModule(['ui', moduleName], won)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
