import { enumsMutations } from './types'

export default {
  [enumsMutations.SET_FUELS]: (state, value) => {
    state.fuels = value
  },
  [enumsMutations.SET_MAKER_NAMES]: (state, value) => {
    state.makerNames = value
  },
  [enumsMutations.SET_MODEL_NAMES]: (state, value) => {
    state.modelNames = value
  },
  [enumsMutations.SET_MAKERS_TO_MODELS]: (state, value) => {
    state.makersToModels = value
  },
  [enumsMutations.SET_WHEELS]: (state, value) => {
    state.wheels = value
  },
  [enumsMutations.SET_TRANSMISSIONS]: (state, value) => {
    state.transmissions = value
  },
  [enumsMutations.SET_TRANSLATIONS]: (state, value) => {
    state.translations = value
  },
}
