import { sdk } from 'Services/shelfNetworkSdk'
import { Lead } from 'Models/Lead'
import { can } from 'Utils/userHelpers'
import { USER_CLAIMS } from 'Constants/userClaims'
import {
  LEADS_URL_PARAM_ASSIGNMENTS,
  LEADS_URL_PARAM_TABS,
  LEADS_URL_PARAM_LEAD_TABS,
} from 'Constants/leadUrlParams'

const STATE_TO_TAB_MAP = {
  [Lead.statesEnum.waitingCall]: LEADS_URL_PARAM_TABS.waiting,
  [Lead.statesEnum.onHold]: LEADS_URL_PARAM_TABS.hold,
  [Lead.statesEnum.lookingCar]: LEADS_URL_PARAM_TABS.looking,
  [Lead.statesEnum.offerSent]: LEADS_URL_PARAM_TABS.offered,
}

export async function showLeadBeforeEnter (to, from, next) {
  try {
    const newTo = {
      name: 'leads',
      params: { leadId: to.params.leadId },
      query: {},
    }

    const response = await sdk.backOffice.v2.getLead(to.params.leadId)
    const lead = new Lead(response)

    if (can(USER_CLAIMS.BROKER_ASSIGNMENT)) {
      if (lead.brokerId) {
        newTo.params.assignment = LEADS_URL_PARAM_ASSIGNMENTS.assigned
        newTo.query.broker = lead.brokerId
      } else {
        newTo.params.assignment = LEADS_URL_PARAM_ASSIGNMENTS.unassigned
      }
    }

    newTo.params.tab = STATE_TO_TAB_MAP[lead.state]
    newTo.params.leadTab = LEADS_URL_PARAM_LEAD_TABS.user

    next(newTo)
  } catch (error) {
    next({ name: 'leads' })
  }
}
