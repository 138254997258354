
import Vue from 'vue'
import { brokersMutations } from './types'
import { genBrokersState } from './state'

export default {
  [brokersMutations.SET_BROKERS]: (state, value) => { state.brokers = value },
  [brokersMutations.SET_BROKER]: (state, { cityId, broker }) => {
    Vue.set(state.brokersByCityId, cityId, broker)
  },
  [brokersMutations.RESET]: (state) => {
    Object.assign(state, genBrokersState())
  },
}
