<template>
  <div class="list-params-lead-states list-params-row">
    <label class="list-params-row__label">
      {{ label || $t('STATE_LBL') }}
    </label>

    <ui-multi-select
      class="list-params-row__field"
      fill="frame"
      look="secondary"
      :options="options"
      :placeholder="placeholder || $t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
      :null-option-label="$t('COMMON.SELECT_FIELD.ALL_OPT')"
      :value="arrValue"
      @input="onMultiInput"
    />
  </div>
</template>

<script>
import { UiMultiSelect } from '@shelf.network/ui-kit'
import { Lead } from 'Models/Lead'

export default {
  name: 'list-params-lead-states',
  components: { UiMultiSelect },

  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },

  computed: {
    arrValue () {
      const value = this.value || this.defaultValue
      return value ? value.split(',') : []
    },

    options () {
      return [
        {
          value: Lead.statesEnum.waitingCall,
          label: this.$t('WAITING_STATE'),
        },
        {
          value: Lead.statesEnum.onHold,
          label: this.$t('HOLD_STATE'),
        },
        {
          value: Lead.statesEnum.lookingCar,
          label: this.$t('LOOKING_STATE'),
        },
        {
          value: Lead.statesEnum.offerSent,
          label: this.$t('OFFERED_STATE'),
        },
      ]
    },
  },

  methods: {
    onMultiInput (value) {
      this.$emit('input', value.join())
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./styles/list-params-row.scss";

.list-params-lead-states {
  /deep/ .ui-multi-select__button-lbl-part {
    &[data-ov="waiting_for_call"] {
      color: $color-status-waiting;
    }

    &[data-ov="on_hold"] {
      color: $color-status-hold;
    }

    &[data-ov="looking_for_car"] {
      color: $color-status-looking;
    }

    &[data-ov="offer_sent"] {
      color: $color-status-offered;
    }
  }

  /deep/ .ui-items__list-item {
    &[data-ov="waiting_for_call"] {
      color: $color-status-waiting;

      &:hover {
        background-color: rgba($color-status-waiting, 0.1);
      }

      &.ui-items__list-item_checked {
        background-color: rgba($color-status-waiting, 0.25);
      }

      &.ui-items__list-item_checked:hover {
        color: $color-white;
        background-color: $color-status-waiting;
      }

      .ui-check-decor__frame_checked {
        border-color: inherit;
        background-color: $color-status-waiting;
      }
    }

    &[data-ov="on_hold"] {
      color: $color-status-hold;

      &:hover {
        background-color: rgba($color-status-hold, 0.1);
      }

      &.ui-items__list-item_checked {
        background-color: rgba($color-status-hold, 0.25);
      }

      &.ui-items__list-item_checked:hover {
        color: $color-white;
        background-color: $color-status-hold;
      }

      .ui-check-decor__frame_checked {
        border-color: inherit;
        background-color: $color-status-hold;
      }
    }

    &[data-ov="looking_for_car"] {
      color: $color-status-looking;

      &:hover {
        background-color: rgba($color-status-looking, 0.1);
      }

      &.ui-items__list-item_checked {
        background-color: rgba($color-status-looking, 0.25);
      }

      &.ui-items__list-item_checked:hover {
        color: $color-white;
        background-color: $color-status-looking;
      }

      .ui-check-decor__frame_checked {
        border-color: inherit;
        background-color: $color-status-looking;
      }
    }

    &[data-ov="offer_sent"] {
      color: $color-status-offered;

      &:hover {
        background-color: rgba($color-status-offered, 0.1);
      }

      &.ui-items__list-item_checked {
        background-color: rgba($color-status-offered, 0.25);
      }

      &.ui-items__list-item_checked:hover {
        color: $color-white;
        background-color: $color-status-offered;
      }

      .ui-check-decor__frame_checked {
        border-color: inherit;
        background-color: $color-status-offered;
      }
    }

    .ui-check-decor__label {
      color: inherit;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "STATE_LBL": "BY LEAD STATE",
    "WAITING_STATE": "Waiting for call",
    "HOLD_STATE": "On-hold",
    "LOOKING_STATE": "Looking for a car",
    "OFFERED_STATE": "Offer Sent"
  },
  "ka": {
    "STATE_LBL": "ᲚᲘᲓᲘᲡ ᲡᲢᲐᲢᲣᲡᲘᲗ",
    "WAITING_STATE": "დასარეკია",
    "HOLD_STATE": "ოდესმე",
    "LOOKING_STATE": "ძიება",
    "OFFERED_STATE": "გაგზავნილი"
  },
  "ru": {
    "STATE_LBL": "ПО СОСТОЯНИЮ ЛИДА",
    "WAITING_STATE": "В ожидании звонка",
    "HOLD_STATE": "На удержании",
    "LOOKING_STATE": "Поиск авто",
    "OFFERED_STATE": "Предложение отправлено"
  },
  "uk": {
    "STATE_LBL": "ЗА СТАНОМ ЛІДА",
    "WAITING_STATE": "В очікуванні дзвінка",
    "HOLD_STATE": "На утриманні",
    "LOOKING_STATE": "Пошук автомобіля",
    "OFFERED_STATE": "Відправлено"
  }
}
</i18n>
