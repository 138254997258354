import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from 'Constants/locales'

VueI18n.prototype.applyLocaleResources = function (locale, params = {}) {
  const { config = {}, ...messages } = params
  this.setLocaleMessage(locale, { ...messages })

  if (config.pluralization) {
    this.pluralizationRules[locale] = config.pluralization
  }
}

VueI18n.prototype.setLocale = async function (locale) {
  await this.loadLocaleAsync(locale)
  this.locale = locale
  axios.defaults.headers.common['Accept-Language'] = locale
  document.querySelector('html').setAttribute('lang', locale)
  return locale
}

VueI18n.prototype.onLocaleChange = function (cb) {
  return this.vm.$watch('locale', cb)
}

VueI18n.prototype.loadedLocales = []
VueI18n.prototype.loadLocaleAsync = async function (locale = DEFAULT_LOCALE) {
  if (!SUPPORTED_LOCALES.includes(locale)) {
    console.warn(`i18n.loadLocaleAsync(): Unsupported locale: ${locale}, falling back to ${DEFAULT_LOCALE}`)
    locale = DEFAULT_LOCALE
  }

  if (!this.loadedLocales.includes(locale)) {
    const resources = await import(/* webpackChunkName: "lang-[request]" */ './' + locale)
    this.applyLocaleResources(locale, resources)
    this.loadedLocales.push(locale)
  }
}

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  silentFallbackWarn: true,
  messages: { [DEFAULT_LOCALE]: {} },
})

// Hot module replacement
if (module.hot) {
  module.hot.accept(['./en', './ka', './ru', './uk'], function () {
    applyI18nLocaleResources()
  })
}

function applyI18nLocaleResources () {
  i18n.applyLocaleResources('en', require('./en'))
  i18n.applyLocaleResources('ka', require('./ka'))
  i18n.applyLocaleResources('ru', require('./ru'))
  i18n.applyLocaleResources('uk', require('./uk'))
}

export { i18n, applyI18nLocaleResources }
