<template>
  <div class="deposits-skeleton">
    <page-subnav class="deposits-skeleton__subnav" />

    <div class="deposits-skeleton__list">
      <deposit-item-skeleton />
      <deposit-item-skeleton />
      <deposit-item-skeleton />
    </div>
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import DepositItemSkeleton from './DepositItemSkeleton'

export default {
  name: 'deposits-skeleton',
  components: {
    PageSubnav,
    DepositItemSkeleton,
  },
}
</script>

<style scoped lang="scss">
@import "../styles/deposits";

.deposits-skeleton {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__list {
    padding: 0 $list-padding-side;
  }
}
</style>
