<template>
  <div class="bell-dropdown">
    <template v-if="!totalNotificationsLength">
      <p class="bell-dropdown__no-list-msg">
        {{ $t('NO_NOTIFICATIONS_MSG') }}
      </p>

      <router-link
        :to="notificationsRoute"
        class="bell-dropdown__link"
        @click.native="$emit('redirected')"
      >
        {{ $t('OLD_LINK') }}

        <ui-icon
          class="bell-dropdown__link-ico"
          icon="arrow-right"
        />
      </router-link>
    </template>

    <template v-if="urgentNotifications.length">
      <div class="bell-dropdown__title">
        {{ $tc('URGENT_TITLE', urgentCount, { count: urgentCount }) }}
      </div>

      <div class="bell-dropdown__list-wrp">
        <bell-notification
          class="bell-dropdown__list-item"
          v-for="urgentNotification of urgentNotifications"
          :key="urgentNotification.id"
          :notification="urgentNotification"
          @redirected="$emit('redirected')"
        />
      </div>

      <router-link
        :to="{
          name: 'notifications',
          params: { tab: NOTIFICATIONS_URL_PARAM_TABS.urgent },
        }"
        class="bell-dropdown__link"
        @click.native="$emit('redirected')"
      >
        {{ $t('ALL_LINK') }}

        <ui-icon
          class="bell-dropdown__link-ico"
          icon="arrow-right"
        />
      </router-link>
    </template>

    <template v-if="infoNotifications.length">
      <div class="bell-dropdown__title">
        {{ $tc('INFO_TITLE', infoCount, { count: infoCount }) }}
      </div>

      <div class="bell-dropdown__list-wrp">
        <bell-notification
          class="bell-dropdown__list-item"
          v-for="infoNotification of infoNotifications"
          :key="infoNotification.id"
          :notification="infoNotification"
        />
      </div>

      <router-link
        :to="{
          name: 'notifications',
          params: { tab: NOTIFICATIONS_URL_PARAM_TABS.info },
        }"
        class="bell-dropdown__link"
        @click.native="$emit('redirected')"
      >
        {{ $t('ALL_LINK') }}

        <ui-icon
          class="bell-dropdown__link-ico"
          icon="arrow-right"
        />
      </router-link>
    </template>

    <template v-if="extensionNotifications.length">
      <div class="bell-dropdown__title">
        {{ $tc('EXTENSION_TITLE', extensionCount, { count: extensionCount }) }}
      </div>

      <div class="bell-dropdown__list-wrp">
        <bell-notification
          class="bell-dropdown__list-item"
          v-for="extensionNotification of extensionNotifications"
          :key="extensionNotification.id"
          :notification="extensionNotification"
        />
      </div>

      <router-link
        :to="{ name: 'extension.copart.notifications.entry' }"
        class="bell-dropdown__link"
        @click.native="$emit('redirected')"
      >
        {{ $t('ALL_LINK') }}

        <ui-icon
          class="bell-dropdown__link-ico"
          icon="arrow-right"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { NOTIFICATIONS_URL_PARAM_TABS } from 'Constants/notificationsUrlParams'
import { mapGetters } from 'vuex'
import { bellGetters } from 'Store/entities/Bell/types'
import BellNotification from './BellNotification'

export default {
  name: 'bell-dropdown',

  components: {
    UiIcon,
    BellNotification,
  },

  data () {
    return {
      NOTIFICATIONS_URL_PARAM_TABS,
    }
  },

  computed: {
    ...mapGetters('entities/bell', {
      urgentCount: bellGetters.URGENT_UNREAD_COUNT,
      urgentNotifications: bellGetters.URGENT_NOTIFICATIONS,
      infoCount: bellGetters.INFO_UNREAD_COUNT,
      infoNotifications: bellGetters.INFO_NOTIFICATIONS,
      extensionCount: bellGetters.EXTENSION_UNREAD_COUNT,
      extensionNotifications: bellGetters.EXTENSION_NOTIFICATIONS,
    }),

    totalNotificationsLength () {
      return this.urgentNotifications.length +
        this.infoNotifications.length +
        this.extensionNotifications.length
    },

    notificationsRoute () {
      return {
        name: this.$can(this.$USER_CLAIMS.NOTIFICATIONS)
          ? 'notifications.entry'
          : 'extension.copart.notifications.entry'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$side-padding: 2.15em;

.bell-dropdown {
  width: 100vw;
  max-width: 35em;
  padding: 1.7em 0;

  &__title {
    color: $color-ui-secondary;
    margin-bottom: 0.65em;

    &:not(:first-of-type) {
      margin-top: 1.7em;
    }
  }

  &__list-wrp {
    margin-bottom: 1em;
  }

  &__title,
  &__list-item {
    padding-left: $side-padding;
    padding-right: $side-padding;
  }

  &__link {
    margin-left: $side-padding;
    text-decoration: none;
    color: $color-sys-info;

    &:hover {
      color: mix($color-sys-info, $color-ui-default, 70);
      text-decoration: none;
    }

    &-ico {
      display: inline-block;
      transform: translateY(10%);
    }
  }

  &__no-list-msg {
    padding: 0 $side-padding;
    margin-bottom: 1em;
  }
}
</style>

<i18n>
{
  "en": {
    "URGENT_TITLE": "NEEDS ACTION | NEEDS ACTION ({count}) | NEEDS ACTION ({count})",
    "INFO_TITLE": "INFORMATIVE | INFORMATIVE ({count}) | INFORMATIVE ({count})",
    "EXTENSION_TITLE": "EXTENSION | EXTENSION ({count}) | EXTENSION ({count})",
    "NO_NOTIFICATIONS_MSG": "No new notifications",
    "ALL_LINK": "All messages",
    "OLD_LINK": "Old messages"
  },
  "ka": {
    "URGENT_TITLE": "ᲓᲐᲡᲐᲓᲐᲡᲢᲣᲠᲔᲑᲔᲚᲘ | ᲓᲐᲡᲐᲓᲐᲡᲢᲣᲠᲔᲑᲔᲚᲘ ({count}) | ᲓᲐᲡᲐᲓᲐᲡᲢᲣᲠᲔᲑᲔᲚᲘ ({count})",
    "INFO_TITLE": "ᲘᲜᲤᲝᲠᲛᲐᲪᲘᲣᲚᲘ | ᲘᲜᲤᲝᲠᲛᲐᲪᲘᲣᲚᲘ ({count}) | ᲘᲜᲤᲝᲠᲛᲐᲪᲘᲣᲚᲘ ({count})",
    "EXTENSION_TITLE": "ᲐᲞᲚᲘᲙᲐᲪᲘᲐ | ᲐᲞᲚᲘᲙᲐᲪᲘᲐ ({count}) | ᲐᲞᲚᲘᲙᲐᲪᲘᲐ ({count})",
    "NO_NOTIFICATIONS_MSG": "შეტყობინებები ცარიელია",
    "ALL_LINK": "ყველა შეტყობინება",
    "OLD_LINK": "ძველი შეტყობინებები"
  },
  "ru": {
    "URGENT_TITLE": "ТРЕБУЕТСЯ ДЕЙСТВИЕ | ТРЕБУЕТСЯ ДЕЙСТВИЕ ({count}) | ТРЕБУЕТСЯ ДЕЙСТВИЕ ({count})",
    "INFO_TITLE": "ИНФОРМАЦИОННЫЕ | ИНФОРМАЦИОННЫЕ ({count}) | ИНФОРМАЦИОННЫЕ ({count})",
    "EXTENSION_TITLE": "РАСШИРЕНИЕ | РАСШИРЕНИЕ ({count}) | РАСШИРЕНИЕ ({count})",
    "NO_NOTIFICATIONS_MSG": "Нет новых оповещений",
    "ALL_LINK": "Все оповещения",
    "OLD_LINK": "Старые оповещения"
  },
  "uk": {
    "URGENT_TITLE": "ПОТРІБНА ДІЯ | ПОТРІБНА ДІЯ ({count}) | ПОТРІБНА ДІЯ ({count})",
    "INFO_TITLE": "ІНФОРМАЦІЙНІ | ІНФОРМАЦІЙНІ ({count}) | ІНФОРМАЦІЙНІ ({count})",
    "EXTENSION_TITLE": "РОЗШИРЕННЯ | РОЗШИРЕННЯ ({count}) | РОЗШИРЕННЯ ({count})",
    "NO_NOTIFICATIONS_MSG": "Немає нових сповіщень",
    "ALL_LINK": "Всі сповіщення",
    "OLD_LINK": "Старі сповіщення"
  }
}
</i18n>
