import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

import DealersSkeleton from './components/DealersSkeleton'

const moduleName = 'extension-dealers'

export default createLazyLoadWrapper({
  name: moduleName,
  skeletonComponent: DealersSkeleton,
  importComponent: () => import('./Dealers.vue'),
  async createDependencies () {
    const { default: dealers } = await import('./store')
    store.registerModule(['ui', moduleName], dealers)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
