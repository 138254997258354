<template>
  <div class="cell-deed cells-cmn__cell">
    <template v-if="mainActor">
      <lazy-ava-img
        class="cells-cmn__ava"
        :alt="mainActor.name"
        :title="mainActor.name"
        :src="mainActor.avatarLink"
      />
    </template>

    <p class="cell-deed__description">
      <template v-if="topic === TOPICS.userRequestedBuyNow">
        <i18n path="NOTIFICATION_MESSAGES.USER_REQUESTED_BUY_NOW_MSG">
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
          <template #lot>
            <span class="cell-deed__unit">{{ cargo.lot.name }}</span>
          </template>
        </i18n>
      </template>

      <template v-else-if="topic === TOPICS.userRequestedWithdrawal">
        <i18n path="NOTIFICATION_MESSAGES.USER_REQUESTED_WITHDRAWAL_MSG">
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
          <template #amount>
            <span class="cell-deed__unit">{{ formattedAmount }}</span>
          </template>
        </i18n>
      </template>

      <template v-else-if="topic === TOPICS.userMadeDeposit">
        <i18n path="NOTIFICATION_MESSAGES.USER_MADE_DEPOSIT_MSG">
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
          <template #amount>
            <span class="cell-deed__unit">{{ formattedAmount }}</span>
          </template>
        </i18n>
      </template>

      <template v-else-if="topic === TOPICS.userRequestedFinancing">
        <i18n path="NOTIFICATION_MESSAGES.USER_REQUESTED_FINANCING_MSG">
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
          <template #bank>
            <span class="cell-deed__unit">{{ cargo.bank.name }}</span>
          </template>
        </i18n>
      </template>

      <template v-else-if="topic === TOPICS.userFinancingApproved">
        <i18n path="NOTIFICATION_MESSAGES.USER_FINANCING_APPROVED_MSG">
          <template #bank>
            <span class="cell-deed__unit">{{ cargo.bank.name }}</span>
          </template>
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
        </i18n>
      </template>

      <template v-else-if="topic === TOPICS.brokerLeadDueDateExpired">
        <i18n path="NOTIFICATION_MESSAGES.BROKER_LEAD_DUE_DATE_EXPIRED_MSG">
          <template #actor>
            <span class="cell-deed__unit">{{ actorName }}</span>
          </template>
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
        </i18n>
      </template>

      <template v-else-if="topic === TOPICS.brokerLeadStateChanged">
        <i18n path="NOTIFICATION_MESSAGES.BROKER_LEAD_STATE_CHANGED_MSG">
          <template #actor>
            <span class="cell-deed__unit">{{ actorName }}</span>
          </template>
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
          <template #oldState>
            <pretty-lead-state
              class="cell-deed__unit"
              :state="cargo.oldState"
            />
          </template>
          <template #newState>
            <pretty-lead-state
              class="cell-deed__unit"
              :state="cargo.newState"
            />
          </template>
        </i18n>
      </template>

      <template v-else-if="topic === TOPICS.userVerified">
        <i18n path="NOTIFICATION_MESSAGES.USER_VERIFIED_MSG">
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
        </i18n>
      </template>

      <template v-else-if="topic === TOPICS.brokerUserMadeDeposit">
        <i18n path="NOTIFICATION_MESSAGES.BROKER_USER_MADE_DEPOSIT_MSG">
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
          <template #actor>
            <span class="cell-deed__unit">{{ actorName }}</span>
          </template>
          <template #amount>
            <span class="cell-deed__unit">{{ formattedAmount }}</span>
          </template>
        </i18n>
      </template>

      <template v-if="topic === TOPICS.brokerUserLotWon">
        <i18n path="NOTIFICATION_MESSAGES.BROKER_USER_LOT_WON_MSG">
          <template #lot>
            <span class="cell-deed__unit">{{ cargo.lot.name }}</span>
          </template>
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
          <template #actor>
            <span class="cell-deed__unit">{{ actorName }}</span>
          </template>
        </i18n>
      </template>

      <template v-if="topic === TOPICS.brokerUserLotLost">
        <i18n path="NOTIFICATION_MESSAGES.BROKER_USER_LOT_LOST_MSG">
          <template #lot>
            <span class="cell-deed__unit">{{ cargo.lot.name }}</span>
          </template>
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
          <template #actor>
            <span class="cell-deed__unit">{{ actorName }}</span>
          </template>
        </i18n>
      </template>

      <template v-if="topic === TOPICS.userLotWon">
        <i18n path="NOTIFICATION_MESSAGES.USER_LOT_WON_MSG">
          <template #lot>
            <span class="cell-deed__unit">{{ cargo.lot.name }}</span>
          </template>
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
        </i18n>
      </template>

      <template v-if="topic === TOPICS.userLotLost">
        <i18n path="NOTIFICATION_MESSAGES.USER_LOT_LOST_MSG">
          <template #lot>
            <span class="cell-deed__unit">{{ cargo.lot.name }}</span>
          </template>
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
        </i18n>
      </template>

      <template v-if="topic === TOPICS.leadAssigned">
        <i18n path="NOTIFICATION_MESSAGES.LEAD_ASSIGNED_MSG">
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
        </i18n>
      </template>

      <template v-if="topic === TOPICS.leadReassigned">
        <i18n path="NOTIFICATION_MESSAGES.LEAD_REASSIGNED_MSG">
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
        </i18n>
      </template>

      <template v-if="topic === TOPICS.leadCommented">
        <i18n path="NOTIFICATION_MESSAGES.LEAD_COMMENTED_MSG">
          <template #actor>
            <span class="cell-deed__unit">{{ actorName }}</span>
          </template>
          <template #customer>
            <span class="cell-deed__unit">{{ customerName }}</span>
          </template>
        </i18n>
      </template>

      <template v-if="topic === TOPICS.claimRequestCreated">
        <i18n path="NOTIFICATION_MESSAGES.CLAIM_REQUEST_CREATED_MSG">
          <template #actor>
            <span class="cell-deed__unit">{{ actorName }}</span>
          </template>
          <template #lotId>
            <span class="cell-deed__unit">{{ cargo.lotId }}</span>
          </template>
        </i18n>
      </template>
    </p>
  </div>
</template>

<script>
import LazyAvaImg from 'Common/LazyAvaImg'
import PrettyLeadState from 'Common/PrettyLeadState'

import { Notification } from 'Models/Notification'
import { TOPICS } from 'Constants/notificationTopics'

export default {
  name: 'cell-deed',

  components: {
    LazyAvaImg,
    PrettyLeadState,
  },

  props: {
    notification: {
      type: Notification,
      required: true,
    },
  },

  data () {
    return {
      TOPICS,
    }
  },

  computed: {
    topic () { return this.notification.topic },
    cargo () { return this.notification.cargo },
    customerName () { return this.notification.customerName },
    actorName () { return this.notification.actorName },
    formattedAmount () { return this.notification.formattedAmount },

    mainActor () {
      let res
      const cargo = this.notification.cargo
      switch (this.notification.topic) {
        case TOPICS.userRequestedBuyNow: res = cargo.customer; break
        case TOPICS.userRequestedWithdrawal: res = cargo.customer; break
        case TOPICS.userMadeDeposit: res = cargo.customer; break
        case TOPICS.userRequestedFinancing: res = cargo.customer; break
        case TOPICS.userFinancingApproved: res = cargo.bank; break
        case TOPICS.brokerLeadDueDateExpired: res = cargo.actor; break
        case TOPICS.brokerLeadStateChanged: res = cargo.actor; break
        case TOPICS.userVerified: res = cargo.customer; break
        case TOPICS.brokerUserMadeDeposit: res = cargo.customer; break
        case TOPICS.brokerUserLotWon: res = null; break
        case TOPICS.brokerUserLotLost: res = null; break
        case TOPICS.userLotWon: res = null; break
        case TOPICS.userLotLost: res = null; break
        case TOPICS.leadAssigned: res = cargo.customer; break
        case TOPICS.leadReassigned: res = cargo.customer; break
        case TOPICS.leadCommented: res = cargo.actor; break
      }
      return res
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./cells.scss";

.cell-deed {
  &__description {
    @extend .cells-cmn__str;
    @extend .cells-cmn__str_sec;
  }

  &__unit {
    color: $color-ui-default;
  }
}
</style>
