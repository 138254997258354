let rootStore = {
  dispatch () {
    throw new ReferenceError('rootDispatch is not initialized!')
  },
  get getters () {
    throw new ReferenceError('rootGet is not initialized!')
  },
}

export function rootDispatch (action, payload) {
  return rootStore.dispatch(action, payload)
}

export function rootGet (getter) {
  return rootStore.getters[getter]
}

// NOTE: Do not import the store to avoid issues with import loops
export function initRootHelpers (store) {
  rootStore = store
}
