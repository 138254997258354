<template>
  <form
    @submit.prevent="attachLot"
    class="lead-lot-add"
  >
    <div class="lead-lot-add__pic">
      <responsive-img
        src="@/assets/images/man-and-car.png"
        class="lead-lot-add__pic-img"
      />
      <div
        class="lead-lot-add__pic-text"
        v-if="isFirstLot"
      >
        {{ $t('HAVE_NO_CARS_MSG') }}
      </div>
    </div>
    <lot-finder
      @select-lot="attachLot"
      class="lead-lot-add__form"
    />

    <ui-spinner
      class="lead-lots-carousel__spinner"
      type="pills"
      :show="isLoading"
      overlay
    />
  </form>
</template>

<script>
import { UiSpinner } from '@shelf.network/ui-kit'
import { showSuccess } from 'Utils/notifications'
import { mapActions } from 'vuex'
import { actions } from '../store/types'
import { Lead } from 'Models/Lead'

import LotFinder from 'Common/LotFinder'
import ResponsiveImg from 'Common/ResponsiveImg'

export default {
  name: 'lead-lot-add',
  components: { LotFinder, UiSpinner, ResponsiveImg },
  props: {
    lead: { type: Lead, required: true }
  },
  data () {
    return {
      isLoading: false,
    }
  },
  computed: {
    isFirstLot () {
      return !this.lead.lots.length
    },
  },
  methods: {
    ...mapActions('ui/leads', {
      attachLeadLotAction: actions.ATTACH_LEAD_LOT,
      loadLead: actions.LOAD_LEAD
    }),

    async attachLot (lot) {
      this.isLoading = true

      try {
        if (lot && lot.id) {
          const undo = await this.attachLeadLotAction({
            leadId: this.lead.id,
            lotId: lot.id,
          })
          showSuccess({
            text: this.$t('LOT_ATTACHED_NOTIFICATION', lot),
            undoAction: undo,
          })

          this.resetForm(true)
        }
      } catch (err) {
        console.error('Unable to attach lot', err)
      }

      this.isLoading = false
    },
  }
}
</script>

<style scoped lang="scss">
.lead-lot-add {
  display: flex;
  position: relative;

  &__pic {
    width: 40%;
    text-align: center;
    padding: 3em 0;

    &-img {
      width: 45%;
    }

    &-text {
      color: $color-grey;
      font-weight: bold;
      font-size: 1.2em;
      text-align: center;
    }
  }

  &__form {
    width: 60%;
    padding: 3.2em 3em 2em 0;
  }

  @include respond-below(sm) {
    display: block;
    padding: 2em;

    &__pic {
      display: block;
      width: 100%;
    }

    &__form {
      margin: 1em 0;
      width: 100%;
      padding: 0;
    }
  }
}
</style>
<i18n>
{
  "en": {
    "LOT_ATTACHED_NOTIFICATION": "Lot attached - #{id}, {name}",
    "HAVE_NO_CARS_MSG": "Lead don’t have any car yet"
  },
  "ka": {
    "LOT_ATTACHED_NOTIFICATION": "ლოტი მიმაგრებულია - #{id}, {name}",
    "HAVE_NO_CARS_MSG": "სია ცარიელია"
  },
  "ru": {
    "LOT_ATTACHED_NOTIFICATION": "Лот прикреплен - #{id}, {name}",
    "HAVE_NO_CARS_MSG": "У лида еще нет автомобилей"
  },
  "uk": {
    "LOT_ATTACHED_NOTIFICATION": "Лот прикріплений - #{id}, {name}",
    "HAVE_NO_CARS_MSG": "Лід ще не має автомобілів"
  }
}
</i18n>
