import get from 'lodash/get'
import { enumsGetters } from 'Store/entities/Enums/types'

export function translateEnum ({
  name,
  value,
  translations,
  fallback
}) {
  return get(translations, `${name}.${value}`) || fallback || value
}

export function createEnumTranslateShortcut (store) {
  return (name, value, fallback) => {
    const translations =
      store.getters[`entities/enums/${enumsGetters.TRANSLATIONS}`]
    return translateEnum({ name, value, translations, fallback })
  }
}
