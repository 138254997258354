export const USERS_URL_PARAM_TABS = Object.freeze({
  regular: 'regular',
  pro: 'pro',
  brokers: 'brokers',
  dealers: 'dealers',
})

export const USERS_QUERY_SORTS = Object.freeze({
  createdAt: 'created_at',
  createdAtDesc: '-created_at',
  firstName: 'first_name',
  firstNameDesc: '-first_name',
  lastName: 'last_name',
  lastNameDesc: '-last_name',
  city: 'city',
  cityDesc: '-city',
  company: 'company',
  companyDesc: '-company',
})

export const USERS_TAB_DEFAULT_SORT = Object.freeze({
  [USERS_URL_PARAM_TABS.regular]:
    USERS_QUERY_SORTS.createdAtDesc,

  [USERS_URL_PARAM_TABS.pro]:
    USERS_QUERY_SORTS.createdAtDesc,

  [USERS_URL_PARAM_TABS.brokers]:
    USERS_QUERY_SORTS.createdAtDesc,

  [USERS_URL_PARAM_TABS.dealers]:
    USERS_QUERY_SORTS.createdAtDesc,
})
