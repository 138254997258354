<template>
  <div class="quick-search-lead">
    <button
      class="quick-search-lead__btn"
      @click="showOverview"
    >
      <div class="quick-search-lead__inner">
        <div class="quick-search-lead__inner-row">
          <template v-if="lead.isVerified">
            <span
              class="quick-search-lead__type"
              data-type="verified"
            >
              {{ $t('VERIFIED_TXT') }}{{ $t('COMMON.SYMBOLS.COMMA') }}
            </span>
          </template>

          <template v-else>
            <span
              class="quick-search-lead__type"
              data-type="guest"
            >
              {{ $t('GUEST_TXT') }}{{ $t('COMMON.SYMBOLS.COMMA') }}
            </span>
          </template>

          <pretty-lead-state
            class="quick-search-lead__state"
            :state="lead.state"
          />
        </div>

        <span class="quick-search-lead__name">
          {{ leadFullName }}
        </span>

        <span class="quick-search-lead__phone">
          {{ formattedLeadPhone }}
        </span>
      </div>
    </button>

    <template v-if="isOverviewShown">
      <lead-overview
        class="quick-search-lead__overview"
        :lead-id="lead.id"
        @close="hideOverview"
      />
    </template>
  </div>
</template>

<script>
import { LeadListEntry } from 'Models/LeadListEntry'
import PrettyLeadState from 'Common/PrettyLeadState'
import LeadOverview from 'Common/LeadOverview'
import { formatPhone } from '@shelf.network/ui-kit'
import { safeUserName } from 'Utils/safeUserName'

export default {
  name: 'quick-search-lead',

  components: {
    PrettyLeadState,
    LeadOverview,
  },

  props: {
    lead: {
      type: LeadListEntry,
      required: true,
    },
  },

  data () {
    return {
      isOverviewShown: false,
    }
  },

  computed: {
    formattedLeadPhone () {
      return formatPhone(this.lead.contactPhone)
    },

    leadFullName () {
      return safeUserName(this.lead.fullName)
    }
  },

  methods: {
    showOverview () {
      this.$emit('view-started')

      if (this.$route.name === 'leads') {
        this.$router.push({ params: { leadId: this.lead.id } })
        return
      }
      this.isOverviewShown = true
    },

    hideOverview () {
      this.$emit('view-finished')
      this.isOverviewShown = false
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-search-lead {
  &__btn {
    background: none;
    border: none;
    text-align: inherit;
    border-radius: 1em;
    width: 100%;

    &:hover {
      background: $color-light-grey;
    }
  }

  &__inner {
    padding: 0.75em 1em;
    display: grid;
    grid-template: "row row" "name phone" / 1fr auto;
    gap: 0.45em 1em;

    &-row {
      grid-area: row;
    }
  }

  &__type,
  &__state {
    font-size: 0.9em;
  }

  &__type {
    &[data-type="guest"] {
      color: $color-sys-attention;
    }

    &[data-type="verified"] {
      color: $color-sys-success;
    }
  }

  &__name {
    @include ellipsis();

    display: inline-block;
    grid-area: name;
  }

  &__phone {
    grid-area: phone;
    justify-self: end;
  }
}
</style>

<i18n>
{
  "en": {
    "GUEST_TXT": "Guest",
    "VERIFIED_TXT": "Verified"
  },
  "ka": {
    "GUEST_TXT": "უცნობი",
    "VERIFIED_TXT": "მომხმარებელი"
  },
  "ru": {
    "GUEST_TXT": "Гость",
    "VERIFIED_TXT": "Пользователь"
  },
  "uk": {
    "GUEST_TXT": "Гість",
    "VERIFIED_TXT": "Підтверджений"
  }
}
</i18n>
