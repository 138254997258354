<template>
  <!-- eslint-disable max-len vue/multiline-html-element-content-newline -->

  <div class="won-item-skeleton won-cmn__row">
    <div class="won-item-skeleton__cell">
      <div class="won-item-skeleton__img" />

      <div class="won-item-skeleton__cell-col">
        <span
          class="won-item-skeleton__line"
          style="width: 14em;"
        >&nbsp;</span>
        <span
          class="won-item-skeleton__line"
          style="width: 8em;"
        >&nbsp;</span>
      </div>
    </div>

    <div class="won-item-skeleton__cell">
      <div class="won-item-skeleton__ava" />

      <div class="won-item-skeleton__cell-col">
        <span
          class="won-item-skeleton__line"
          style="width: 9em;"
        >&nbsp;</span>
      </div>
    </div>

    <div class="won-item-skeleton__cell">
      <span
        class="won-item-skeleton__line"
        style="width: 9em;"
      >&nbsp;</span>
    </div>

    <div class="won-item-skeleton__cell">
      <span
        class="won-item-skeleton__line"
        style="width: 9em;"
      >&nbsp;</span>
    </div>

    <div class="won-item-skeleton__cell">
      <span
        class="won-item-skeleton__line"
        style="width: 9em;"
      >&nbsp;</span>
    </div>

    <div class="won-item-skeleton__cell">
      <span
        class="won-item-skeleton__line"
        style="width: 9em;"
      >&nbsp;</span>
    </div>

    <div class="won-item-skeleton__cell">
      <div class="won-item-skeleton__btn" />
      <div class="won-item-skeleton__btn" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'won-item-skeleton',
}
</script>

<style scoped lang="scss">
@import "@/styles/skeletons.scss";
@import '../styles/won';

.won-item-skeleton {
  background: $color-light;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 5em 1em 1em;

  &__cell {
    min-width: 0;
    min-height: 0;
    display: grid;
    grid: auto / auto-flow auto;
    justify-content: start;
    align-items: center;
    grid-gap: 0.5em;

    &:last-child {
      justify-content: flex-end;
    }

    &-col {
      display: grid;
      grid: auto-flow / auto;
      justify-content: center;
      align-items: center;
      width: 100%;
      grid-gap: 0.5em;
    }
  }

  &__img {
    @extend %skeleton-bg;

    height: 5.4em;
    max-width: 6.5em;
    min-width: 6.5em;
    margin-right: 0.9em;

    --ratio: calc(1 / 1);

    @include aspect-ratio();
  }

  &__ava {
    @extend %skeleton-bg;

    max-width: 2.2em;
    min-width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
  }

  &__line {
    @extend %skeleton-bg;

    border-radius: 50px;
    display: inline-block;
  }

  &__btn {
    @extend %skeleton-bg;

    width: 3.55em;
    height: 3.55em;
    border-radius: 50%;
  }
}
</style>
