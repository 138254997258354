import { USERS_URL_PARAM_TABS } from 'Constants/usersUrlParams'
import { UserEntry } from 'Models/UserEntry'

export const USERS_LIST_TEMPLATES = Object.freeze({
  regular: 'regular',
  pro: 'pro',
  brokers: 'brokers',
  dealers: 'dealers',
})

export const USER_CARD_TABS = Object.freeze({
  attributes: 'attributes',
})

export const ACCOUNT_TYPE_TO_USERS_TAB_MAP = Object.freeze({
  [UserEntry.accountTypesEnum.user]: USERS_URL_PARAM_TABS.regular,
  [UserEntry.accountTypesEnum.business]: USERS_URL_PARAM_TABS.pro,
  [UserEntry.accountTypesEnum.broker]: USERS_URL_PARAM_TABS.brokers,
  [UserEntry.accountTypesEnum.dealer]: USERS_URL_PARAM_TABS.dealers,
})

export const USERS_PAGE_LIMIT = 15
