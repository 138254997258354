import { i18n } from 'I18n/instance'
import { Contact } from 'Models/Contact'

export function getChannelOptions () {
  return [
    {
      value: Contact.channelsEnum.phone,
      label: i18n.t('COMMON.LEAD_CHANNELS.PHONE'),
    },
    {
      value: Contact.channelsEnum.email,
      label: i18n.t('COMMON.LEAD_CHANNELS.EMAIL'),
    },
    {
      value: Contact.channelsEnum.website,
      label: i18n.t('COMMON.LEAD_CHANNELS.WEBSITE'),
    },
    {
      value: Contact.channelsEnum.facebook,
      label: i18n.t('COMMON.LEAD_CHANNELS.FACEBOOK'),
    },
    {
      value: Contact.channelsEnum.carfax,
      label: i18n.t('COMMON.LEAD_CHANNELS.CARFAX')
    },
    {
      value: Contact.channelsEnum.telegram,
      label: i18n.t('COMMON.LEAD_CHANNELS.TELEGRAM')
    },
    {
      value: Contact.channelsEnum.facebookWeb,
      label: i18n.t('COMMON.LEAD_CHANNELS.FACEBOOK_WEB')
    },
    {
      value: Contact.channelsEnum.rajdeba,
      label: i18n.t('COMMON.LEAD_CHANNELS.RAJDEBA')
    }
  ]
}
