import store from 'Store'
import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'

const moduleName = 'analytics'

export default createLazyLoadWrapper({
  name: moduleName,
  importComponent: () => import('./Analytics'),
  async createDependencies () {
    const { default: analyticsModule } = await import('./store')
    store.registerModule(['ui', moduleName], analyticsModule)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
