import get from 'lodash/get'
import { ModelBase } from './ModelBase'
import { Identity } from './Identity'

export class AuditEvent extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.actor = ModelBase.modelFromCache(
      Identity,
      get(raw, 'relationships.actor'),
      cache
    )
    this.actorIdentityId = this._str(this.actor.id)
    this.date = (new Date(raw.createdAt))
    this.action = this._str(raw.action)

    this.value = raw.value
  }

  static get actionEnums () {
    return {
      brokerAssigned: 'lead_broker_assigned',
      dueDateUpdated: 'lead_due_date_updated',
      brokerUnassigned: 'lead_broker_unassigned',
      channelUpdated: 'lead_channel_updated',
      commentUpdated: 'lead_comment_updated',
      financingUpdated: 'lead_financing_updated',
      priorityUpdated: 'lead_priority_updated',
      stateUpdated: 'lead_state_updated',
      leadWon: 'lead_won',
      leadLost: 'lead_lost',
      leadCreated: 'lead_created',
      dueDateExpired: 'lead_due_date_expired',
    }
  }
}
