import { ModelBase } from './ModelBase'
import { Location } from './Location'

import { can } from 'Utils/userHelpers'
import { USER_CLAIMS } from 'Constants/userClaims'

import { safeUserName } from 'Utils/safeUserName'
import formatFullName from 'Utils/formatFullName'

export class UserEntry extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.firstName = this._str(raw.firstName)
    this.lastName = this._str(raw.lastName)
    this.avatarLink = this._str(raw.avatarLink)
    this.email = this._str(raw.email)
    this.phone = this._str(raw.phoneNumber)
    this.createdAt = this._date(raw.createdAt)
    this.isPhoneVerified = Boolean(raw.phoneVerified)
    this.isEmailVerified = Boolean(raw.emailVerified)
    this.accountType = this._str(raw.accountTypeStr)
    this.isPro = this.accountType === UserEntry.accountTypesEnum.business
    this.isBroker = this.accountType === UserEntry.accountTypesEnum.broker
    this.cityName = this._str(raw.city)

    if (can(USER_CLAIMS.SHELF_AP_FEATURES)) {
      this.middleName = this._str(raw.middleName)
      this.company = this._str(raw.company)
      this.corporateWebSite = this._str(raw.corporateWebSite)
      this.branchOfficeName = this._str(raw.branchOfficeName)
      this.officePhone = this._str(raw.officePhone)
      this.secondhandDealPermissionNumber =
        this._str(raw.secondhandDealPermissionNumber)
      this.state = this._str(raw.state)
      this.prefecture = this._str(raw.prefecture)
    }

    // TODO: missing fields
    this.lastActiveAt = this._date(raw.lastActiveAt)
    this.discountAmount = this._str(raw.discountAmount)
    this.discountCurrency = this._str(raw.discountCurrency)

    this.location = new Location({
      relationships: {
        city: { id: raw.cityId },
        country: { id: raw.countryId },
        region: { id: raw.region },
      },
    })
  }

  get fullName () {
    return safeUserName(formatFullName(this))
  }

  static get accountTypesEnum () {
    return {
      user: 'user',
      broker: 'broker',
      business: 'business',
      dealer: 'dealer',
    }
  }
}
